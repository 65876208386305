import { Component } from '@angular/core';
import { first } from 'rxjs/operators';
import { EntityVersionInformation } from 'src/app/server/model/save-response';
import { KTaskBarEventType } from '../../manager/ktaskbar/ktaskbar-event';
import { TaskRelation } from '../../manager/ktaskbar/task';
import { KBaseComponent } from '../kcomponents/kbase/kbase.component';

@Component({
	selector: 'app-kversions',
	templateUrl: './kversions.component.html',
	styleUrls: ['./kversions.component.scss'],
})
export class KVersionsComponent extends KBaseComponent {
	override isDecorative = true;
	icon!: string;
	dataSource!: EntityVersionInformation[];
	focusedRowKey = -1;
	popupVisible: boolean = false;
	confirmOptions: any;
	newName: string = '';

	override ngOnInit(): void {
		this.node.colSpan = 20;
		this.node.rowSpan = 10;

		const me = this;
		this.confirmOptions = {
			icon: 'save',
			text: 'Crea',
			onClick: function (e: any) {
				me.container.on(KTaskBarEventType.StartLoader);

				console.log(e, me.newName);

				me.container.service
					.createVersion(
						me.container.task.entityId,
						me.container.task.instanceId!,
						me.newName,
					)
					.pipe(first())
					.subscribe((c) => {
						me.popupVisible = !c.success;
						me.reload();
					});
			},
		};

		super.ngOnInit();
		this.container.on(KTaskBarEventType.StartLoader);
		this.reload();
	}

	reload() {
		this.container.service
			.getVersions(
				this.container.task.entityId,
				this.container.task.instanceId!,
			)
			.subscribe((versions) => {
				this.dataSource = versions;
				console.log(this.dataSource);
				this.container.on(KTaskBarEventType.StopLoader);
				this.cdr.markForCheck();
			});
	}

	onToolbarPreparing(e: any): any {
		e.toolbarOptions.items.unshift({
			location: 'after',
			widget: 'dxButton',
			options: {
				icon: 'icotbar icot-tb_create_version',
				onClick: () => {
					this.onAdd();
				},
			},
		});
	}

	onEdit(e: { data: EntityVersionInformation }) {
		// console.log(e.data);
		this.container.openTask(
			this.container.task.entityId,
			e.data.versionId,
			this.container.task.id,
			null,
			null,
			TaskRelation.Parent,
			'view',
		);
	}

	onAdd() {
		this.popupVisible = true;
		console.log(this.popupVisible);
		this.cdr.markForCheck();
	}
}
