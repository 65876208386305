import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import {
	DebugOption,
	showDebugInstructions,
	WebHelper,
} from './helpers/web-helper';

@Injectable({
	providedIn: 'root',
})
export class DiagnoseService {
	rawLevels: DebugOption[] = [];
	isDev: boolean;

	constructor() {
		this.rawLevels = (WebHelper.getQueryParam('d') ?? null)?.split(
			',',
		) as DebugOption[];

		this.isDev = !environment.production;

		if (this.isDebugOption('showHelp')) {
			console.log('Debug Options:', showDebugInstructions());
		}

		if (this.rawLevels?.length > 0) {
			console.log('disgnose running', this.rawLevels);
		}
	}

	public isDebugOption(option: DebugOption): boolean {
		if (this.isDev && option.toLocaleLowerCase().startsWith('skip')) {
			return true;
		}

		for (let index = 0; index < this.rawLevels?.length; index++) {
			const rawLevel: DebugOption = this.rawLevels[index];

			if (rawLevel.toLocaleLowerCase() === option.toLocaleLowerCase()) {
				return true;
			}
		}

		return false;
	}

	public logStatistics(data: any, key: string | null = null) {
		if (this.isDebugOption('showStatistics')) {
			console.log(data);

			if (key) {
				console.log(key, JSON.stringify(data).length);
			}
		}
	}

	public logIf(options: DebugOption, data: any) {
		if (this.isDebugOption(options)) {
			console.log(data);
		}
	}

	public needTo(option: DebugOption) {
		return this.isDebugOption(option);
	}
}
