export enum ViewItemFilterControl {
	Text,
	Number,
	Boolean,
	LookUp,
	SearchPopUp,
	Date,
	DateRange,
	DinamicDateRange,
	DinamicIdentity,
	Categories,
	None = -1,

	Role = 600,
	Unit = 601,
	Factory = 602,
}
