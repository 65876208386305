<div class="container" style="display:flex; flex-wrap: nowrap;">
	<div class="list" style="width:15% !important">
		<dx-list #list [dataSource]="dataSource" height="100%" [allowItemDeleting]="false" itemDeleteMode="toggle"
			selectionMode="single" [showSelectionControls]="true" (onSelectionChanged)="onSelectionChanged($event)"
			[selectedItemKeys]="initialSelection" menuMode="context" [menuItems]="menuItems">
			<div *dxTemplate="let data of 'item'">
				<div>{{ data.humanReadableName }}</div>
			</div>
		</dx-list>
	</div>
	<div class="detail" style="width:85% !important;">
		<app-kform-master-inline *ngIf="selected" [master]="selected"></app-kform-master-inline>
	</div>
</div>
