import {
	Component,
	EventEmitter,
	Input,
	Output,
	ViewChild
} from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';
import { EntityHolder } from './../../../server/model/entity-holder';
import {
	DatagridClickEventArg,
	DatagridColumnValueChange
} from './datagrid-click-event-args';
import {
	DatagridColumn,
	GridColumnDatasource,
	IDatagridContaner,
	IDatagridNewRowArg
} from './datagrid-column';
import { DatagridOption } from './datagrid-option';
import { DatagridService } from './datagrid.service';

@Component({
	selector: 'app-datagrid',
	templateUrl: './datagrid.component.html',
	styleUrls: ['./datagrid.component.scss'],
})
export class DatagridComponent {
	@Input() host: IDatagridContaner | null = null;
	@Input() pageIndex = 0;
	@Input() pageSize = 25;
	@Input() source: any;
	@Input() columns: DatagridColumn[] = [];
	@Output() selectionChange: EventEmitter<EntityHolder[]> = new EventEmitter<
		EntityHolder[]
	>();
	@Output() cellClick: EventEmitter<DatagridClickEventArg> =
		new EventEmitter<DatagridClickEventArg>();
	@Output() columnValueChange: EventEmitter<DatagridColumnValueChange> =
		new EventEmitter<DatagridColumnValueChange>();
	@Output() rowAdd: EventEmitter<void> = new EventEmitter<void>();
	@Output() rowEdit: EventEmitter<EntityHolder> =
		new EventEmitter<EntityHolder>();
	@Output() rowDelete: EventEmitter<EntityHolder> =
		new EventEmitter<EntityHolder>();
	@Output() stateChange: EventEmitter<any> = new EventEmitter<any>();
	@Output() rowUp: EventEmitter<EntityHolder> =
		new EventEmitter<EntityHolder>();
	@Output() rowDown: EventEmitter<EntityHolder> =
		new EventEmitter<EntityHolder>();
	@Input() option = new DatagridOption();

	@ViewChild(DxDataGridComponent, { static: false })
	dataGrid!: DxDataGridComponent;
	selectedRows: EntityHolder[] = [];
	service: DatagridService;
	storageKey: string;
	constructor(service: DatagridService) {
		this.storageKey = service.storageKey!;
		this.service = service;
		this.onRowEdit = this.onRowEdit.bind(this);
		this.onRowDelete = this.onRowDelete.bind(this);
		this.onRowDown = this.onRowDown.bind(this);
		this.onRowUp = this.onRowUp.bind(this);
	}

	onSelectionChanged($event: any): void {
		if ($event.selectedRowsData) {
			this.selectedRows = $event.selectedRowsData as EntityHolder[];
			this.selectionChange.emit($event.selectedRowsData);
		} else {
			this.dataGrid.instance.getSelectedRowsData().then((c) => {
				// console.log(c);
				this.selectedRows = c as EntityHolder[];
				this.selectionChange.emit(this.selectedRows);
			});
		}
	}

	// insertRow($event: any): void {
	//   if (!$event.row) return;

	//   const arg: DatagridClickEventArg = {
	//     attributeName: $event.column.dataField ?? null,
	//     row: $event.row?.data,
	//     command: $event.column.command ?? null
	//   };

	//   this.cellClick.emit(arg);
	// }

	onRowInserting($event: any): void {
		console.log($event);
	}

	onCellClick($event: any): void {
		if (!$event.row || $event.row.isEditing) return;
		// console.log($event);
		const arg: DatagridClickEventArg = {
			attributeName: $event.column.dataField ?? null,
			row: EntityHolder.parse($event.row?.data)!,
			command: $event.column.command ?? null,
		};

		this.cellClick.emit(arg);
	}

	// view get calculated value
	calculateCellValue(rowData: EntityHolder): any {
		if (!rowData?.fields) return;

		const column = this as any;
		const f = rowData.fields.find((c) => c.name === column.name);

		if (f) {
			if (f.value?.humanReadableName != undefined) {
				return f.value.humanReadableName;
			} else if (column.dataType === 'date' || column.dataType === 'datetime') {
				return f.value ? new Date(f.value) : null;
			}

			return f.value;
		}

		return column.defaultCalculateCellValue(rowData);
	}

	formatTypeSpan(info: any) {
		return info.column?.format?.formatter(info.value) ?? info.value;
	}

	// edit get calculated value
	getCellValue(arg: GridColumnDatasource): any {
		// console.log(arg);
		//  return arg.data.instanceId;

		if (arg.data && !(arg.data instanceof EntityHolder)) {
			arg.data = EntityHolder.parse(arg.data)!;
		}

		const v = arg.data?.getField(arg.column.dataField)?.value;

		// console.log(v);
		// static drop down
		if (arg.column.lookup && arg.column.editCellTemplate === 'DropColumn') {
			let id: string = v;

			if (v instanceof EntityHolder) {
				id = v.instanceId;
			}

			return arg.column.lookup?.items.find((c) => c.instanceId === String(id));
		}

		// console.log('getCellValue', v);

		return v;
	}

	// edit set calculated value
	setCellValue(arg: any, e1: any): void {
		const oldValue = arg.data.getField(arg.column.dataField).value;

		let value = e1.value;

		if (value.instanceId) {
			value = EntityHolder.parse(value);
		}

		// static drop down
		if (arg.column.lookup && arg.column.editCellTemplate === 'DropColumn') {
			value = value.instanceId;
		}
		arg.data.getField(arg.column.dataField).value = value;

		this.columnValueChange.emit({
			value: value,
			arg: arg,
		});
	}

	getColumnSource(arg: GridColumnDatasource): any {
		return this.host?.getColumnSource(arg);
	}

	// getCellValueId(e:any) :void {
	//   console.log(e);
	// }

	onInitNewRow(arg: IDatagridNewRowArg) {
		this.host?.getNewRow(arg);
	}

	getColorLabel(serialiedInfo: any): string {
		//console.log(serialiedInfo);
		return serialiedInfo.value.split('|')[0];
	}

	getColor(serialiedInfo: any): string {
		return serialiedInfo.value.split('|')[2];
	}

	log(e: any): any {
		console.log(e);
	}

	onRowEdit(e: any): any {
		e.event.preventDefault();
		e.event.stopPropagation();
		// console.log(e.row.data);
		this.rowEdit.emit(e.row.data);
	}

	onRowUp(e: any): any {
		e.event.preventDefault();
		e.event.stopPropagation();
		this.rowUp?.emit(e.row.data);
	}

	onRowDown(e: any): any {
		e.event.preventDefault();
		e.event.stopPropagation();
		// console.log(e.row.data);
		this.rowDown?.emit(e.row.data);
	}

	onRowDelete(e: any): any {
		e.event.preventDefault();
		e.event.stopPropagation();
		// console.log(e.row.data);
		this.rowDelete.emit(e.row.data);
	}

	onToolbarPreparing(e: any): any {
		// console.log(e.toolbarOptions.items);

		// se si può aggiungere una riga ma è attivo anche il multiselect
		// dovremmo essere d ifronte a una griglia moncolonna con selezione multipla del
		// main member in questo caso tolto l'add inlinea ( buttone inserito automaticamente da DE)
		let autoInsertIsRemoved: boolean = false;
		if (
			this.option.allowAdding &&
			this.option.allowUpdating &&
			this.option.multiselect
		) {
			e.toolbarOptions.items.splice(0, 1);
			autoInsertIsRemoved = true;
		}

		// quindi il bottone di insert va messo se ADD ma solo se non inlinea o se in linea multiselect con main member
		// quindi si presume che se inline con più di una colonna la non main member abbia un default valido
		if (
			this.option.allowAdding &&
			(!this.option.allowUpdating || autoInsertIsRemoved)
		) {
			e.toolbarOptions.items.unshift({
				location: 'after',
				widget: 'dxButton',
				options: {
					icon: 'insertrowabove',
					hint: 'Nuovo',
					onClick: () => {
						this.rowAdd.emit();
					},
				},
			});
		}

		// if (this.option.allowDelete) {
		//   e.toolbarOptions.items.unshift({
		//     location: 'after',
		//     widget: 'dxButton',
		//     options: {
		//       icon: 'clearformat',
		//       onClick: () => {

		//       }
		//     }
		//   });
		// }

		// if (!this.option.allowUpdating) {
		//   e.toolbarOptions.items.unshift({
		//     location: 'after',
		//     widget: 'dxButton',
		//     options: {
		//       icon: 'edit',
		//       onClick: () => {
		//         this.selectionChange.emit(this.selectedRows);
		//       }
		//     }
		//   });
		// }
	}

	onLoadState = () => {
		const rawState = localStorage.getItem(this.storageKey);
		if (rawState) {
			const state = JSON.parse(rawState);
			return state;
		} else return null;
	};

	onSaveState = (state: any) => {
		// console.log1(state);

		if (state) {
			for (let i = 0; i < state.columns.length; i++) {
				state.columns[i].filterValue = null;
			}
		}
		localStorage.setItem(this.storageKey, JSON.stringify(state));
		this.stateChange.emit(state);
	};
}
