<dx-toolbar>
	<dxi-item
		location="before"
		[widget]="canBeGeneratedByModel || isMultiInheritance ? 'dxDropDownButton' : 'dxButton'"
		locateInMenu="never"
		[options]="newButtonOptions"
	>
	</dxi-item>

	<dxi-item
		location="before"
		*ngIf="this.selectedRows.length > 0"
		widget="dxButton"
		locateInMenu="never"
		[options]="cloneButtonOptions"
	>
	</dxi-item>

	<dxi-item location="before" widget="dxMenu" locateInMenu="never" [options]="statusBeforeOptions"> </dxi-item>

	<dxi-item
		location="before"
		*ngIf="currentStatusOptions"
		widget="dxButton"
		locateInMenu="never"
		[options]="currentStatusOptions"
	>
	</dxi-item>

	<dxi-item location="before" widget="dxMenu" locateInMenu="never" [options]="statusAfterOptions"> </dxi-item>

	<dxi-item
		location="before"
		*ngIf="this.selectedRows.length > 0"
		widget="dxButton"
		locateInMenu="never"
		[options]="deleteButtonOptions"
	>
	</dxi-item>

	<dxi-item location="after" widget="dxMenu" locateInMenu="never" [options]="queriesOptions"> </dxi-item>

	<dxi-item location="after" widget="dxButton" locateInMenu="never" [options]="columnEditorButtonOptions"> </dxi-item>

	<dxi-item location="after" widget="dxButton" locateInMenu="never" [options]="filterEditorButtonOptions"> </dxi-item>

	<dxi-item location="after" widget="dxMenu" locateInMenu="never" [options]="toolsMenuOptions"> </dxi-item>
</dx-toolbar>
