import { Observable, of, throwError } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { EntityService } from 'src/app/server/entity.service';
import { KnowEntityIds, KnowItemNames } from 'src/app/server/helpers/constants';
import { EnumHelper } from 'src/app/server/helpers/enum-helper';
import { AttributeMetadata } from 'src/app/server/model/attribute-metadata';
import { EntityAttributeValueType } from 'src/app/server/model/entity-attribute-value-type';
import { EntityHolder } from 'src/app/server/model/entity-holder';
import { EntityInfo } from 'src/app/server/model/entity-info';
import { FieldValue } from 'src/app/server/model/field-value';
import { ViewNodeFilter } from 'src/app/server/model/filter';
import { LogicalAttributeType } from 'src/app/server/model/logical-attribute-type';
import { SearchParameter } from 'src/app/server/model/search-parameter';
import { SearchParameterLuceneOp } from 'src/app/server/model/search-parameter-lucene-op';
import { SearchParameterType } from 'src/app/server/model/search-parameter-type';
import { TaskRelation } from 'src/app/ui/manager/ktaskbar/task';
import { AdvancedData } from './../../../../server/model/advanced-data';
import { KFormData } from './../../kform/kform-data';
import { IKEntityComponent, IKEntityOrStaticComponent, IKStaticComponent } from './abstract';
import { SearchPayload } from './search-payload';

export function getPossibleOptions(
	cmp: IKEntityComponent | IKStaticComponent | IKEntityOrStaticComponent,
): Observable<EntityHolder[]> {
	// console.log(cmp.node);
	// console.log(cmp.node.attributeName);

	let output: EntityHolder[];

	const staticOrEntityCmp = cmp as IKEntityOrStaticComponent;
	let isStatic = false;

	if (staticOrEntityCmp) {
		isStatic = staticOrEntityCmp.isStatic;
	}

	const entityCmp = cmp as IKEntityComponent;

	if (entityCmp && !isStatic) {
		if (!entityCmp.getTargetEntityId()) return of([]);

		return entityCmp.getTargetEntityInfo().pipe(
			switchMap((info: EntityInfo) => {
				entityCmp.info = info;

				if (!entityCmp.isInAggregate) {
					// console.log(entityCmp.node.attributeName);
					return searchExtended(
						entityCmp.node.advancedData2,
						info,
						entityCmp.resultViewId,
						entityCmp.root,
						null,
						entityCmp.container.getMainHolder(),
						entityCmp.service,
					);
				} else {
					let fieldValue: FieldValue | null = entityCmp.root.getField(entityCmp.isInAggregate.name, false);

					if (!fieldValue && entityCmp.container.task.relation === TaskRelation.SubTask) {
						const state = entityCmp.container.getState(entityCmp.container.task.parentTaskId!);
						if (state?.data) {
							fieldValue = state.data.getField(entityCmp.isInAggregate.name, false);
						}
					}

					if (fieldValue) {
						return of(fieldValue.value);
					}
				}

				return [];
			}),
		);
	}

	const staticCmp = cmp as IKStaticComponent;

	if (staticCmp) {
		output = cmp.metadata.values.map((c) => EntityHolder.fromIdName(c));
		return of(output);
	}

	return of([]);
}

export function searchExtended(
	advancedData: AdvancedData | null = null,
	targetEntityInfo: EntityInfo,
	resultViewId: string | null = null,
	currentEntityHolder: EntityHolder,
	parentHolder: EntityHolder | null,
	rootEntityHolder: EntityHolder | null,
	service: EntityService,
): Observable<EntityHolder[]> {
	const result: SearchPayload = {
		filters: [],
		columns: [],
		orders: advancedData?.orders ?? [],
	};

	if (resultViewId) {
		const v = targetEntityInfo.getViewOrDefault(resultViewId);

		if (v?.nodes) {
			result.columns = v.nodes;
		}
	}

	if (advancedData?.filters) {
		result.filters = resolveFilters(
			targetEntityInfo,
			advancedData.filters,
			currentEntityHolder,
			parentHolder,
			rootEntityHolder,
		);
	}

	const ff: SearchParameter[] = (result.filters ?? [])?.map((c) =>
		SearchParameter.createWithOperator(c.attributeName, c.operator, c.value),
	);

	return service.search(targetEntityInfo.id, result.columns, result.orders, 0, 1000, ff, false).pipe(
		map((c) => {
			return c?.results ?? [];
		}),
	);
}

export function getGlobalTypeInfo(
	targetEntityInfo: EntityInfo,
	root: EntityHolder,
	isBase: boolean,
): [string, string | null] {
	let entityId = '';
	let subEntityId: string | null = '';

	if (targetEntityInfo.isSubEntity && !isBase && targetEntityInfo.parentId) {
		entityId = targetEntityInfo.parentId;
		subEntityId = targetEntityInfo.id;
	} else {
		entityId = targetEntityInfo.id;
		subEntityId = null;
	}

	return [entityId, subEntityId];
}

// CreateArgumentsForNewEntityOfType
export function createNewArguments(
	targetEntityInfo: EntityInfo,
	logicalType: LogicalAttributeType,
	isBase: boolean,
	rootHolder: EntityHolder,
	parentHolder: EntityHolder | null = null,
	advancedData: AdvancedData,
): Map<string, any> {
	const args = new Map<string, any>();

	if (targetEntityInfo.id === KnowEntityIds.GlobalCategoryBind) {
		args.set(KnowItemNames.Type, rootHolder.entityId);
		args.set(KnowItemNames.InstanceId, rootHolder.instanceId);
		return args;
	}

	if (targetEntityInfo.id === KnowEntityIds.GlobalType) {
		let entityId: string = '';
		let subEntityId: string | null = null;

		if (rootHolder.parentEntityId && !isBase) {
			entityId = rootHolder.parentEntityId;
			subEntityId = rootHolder.entityId;
		} else {
			entityId = rootHolder.entityId;
			subEntityId = null;
		}

		args.set(KnowItemNames.EntityId, entityId);
		args.set(KnowItemNames.SubEntityId, subEntityId);
		return args;
	}

	if (EnumHelper.is(logicalType, LogicalAttributeType.Nested)) {
		const parentAttribute = targetEntityInfo.metadata.getFirstByeLogicalType(LogicalAttributeType.Parent);

		if (parentAttribute != null) {
			// solo nuove pointer e solo guid ....
			args.set(parentAttribute.name, parentHolder?.instanceId ?? rootHolder.instanceId);
		}
	}

	if (EnumHelper.is(logicalType, LogicalAttributeType.Member)) {
		if (EnumHelper.is(logicalType, LogicalAttributeType.Collection)) {
			let o = targetEntityInfo.metadata.getOwner(rootHolder.entityId);

			if (!o) {
				o = targetEntityInfo.metadata.getOwner();
			}

			if (o) {
				args.set(o.name, rootHolder.instanceId);
			}
		} else {
			let o = targetEntityInfo.metadata.getOwner(rootHolder.entityId);

			if (!o) {
				o = targetEntityInfo.metadata.getOwner();
			}

			if (o) {
				args.set(o.name, rootHolder.instanceId);
			}
		}
	} else {
		if (EnumHelper.is(logicalType, LogicalAttributeType.Collection)) {
			addFirstOwnerOrPointerOfEntity(rootHolder, args, targetEntityInfo);
		} else {
			addFirstOwnerOrPointerOfEntity(rootHolder, args, targetEntityInfo);
		}
	}
	// 08/08/2019
	// product > techinal sheet per passare il tipo e forzare una creazione con il tipo giusto
	// uso il parametro di filtro che deve essere impostato sull'attributo
	if (advancedData) {
		for (const filter of advancedData.filters) {
			if (!filter.isRef && !args.has(filter.name)) {
				args.set(filter.name, filter.value);
			}
		}
	}

	return args;
}

function addFirstOwnerOrPointerOfEntity(
	rootHolder: EntityHolder,
	args: Map<string, any>,
	targetEntityInfo: EntityInfo,
): boolean {
	const entityIds: string[] = [rootHolder.entityId];
	if (rootHolder.parentEntityId) {
		entityIds.push(rootHolder.parentEntityId);
	}

	const o = getFirstOwnerOrPointerOfEntity(Array.from(targetEntityInfo.metadata.attributes.values()), entityIds);

	if (o != null) {
		args.set(o.name, rootHolder.instanceId);
		return true;
	}

	return false;
}

export function injectExtraAggreagateFilters(
	service: EntityService,
	formData: KFormData,
	parentFormData: KFormData | null,
	entityInfo: EntityInfo,
	filters: SearchParameter[],
): Observable<SearchParameter[]> {
	// auto inject global type filter entity
	if (entityInfo.id === KnowEntityIds.GlobalType) {
		let h: EntityHolder | null | undefined = null;

		if ((formData?.info?.virtuals?.length ?? 0) > 0) {
			h = formData.data?.getField(formData.info!.virtuals[0].type!)?.value as EntityHolder;
		} else if ((parentFormData?.info?.virtuals?.length ?? 0) > 0) {
			h = parentFormData?.data?.getField(parentFormData?.info!.virtuals[0].type!)?.value as EntityHolder;
		}
		// GetGlobalTypePointerFilters

		return service.getEntityInfo(h!.instanceId).pipe(
			switchMap((ei) => {
				const globalTypeFilter = new SearchParameter(null);
				globalTypeFilter.operator = SearchParameterType.Equal;
				globalTypeFilter.type = EntityAttributeValueType.Text;
				globalTypeFilter.attributeName = KnowItemNames.EntityId;
				globalTypeFilter.value = h!.instanceId;
				filters!.push(globalTypeFilter);

				// console.log(ei);

				if (!ei.isSubEntity) {
					const globalTypeFilter2 = new SearchParameter(null);
					globalTypeFilter2.attributeName = KnowItemNames.SubEntityId;
					globalTypeFilter2.value = ei.parentId;
					globalTypeFilter2.attributeName = KnowItemNames.EntityId;
					globalTypeFilter2.value = h!.instanceId;
					filters!.push(globalTypeFilter2);
				}

				return of(filters);
			}),
		);
	}

	return of(filters);
}

// EvalutateExtraFilters
export function resolveFilters(
	entityInfo: EntityInfo,
	filters: ViewNodeFilter[] | null,
	currentEntityHolder: EntityHolder,
	parentHolder: EntityHolder | null,
	rootEntityHolder: EntityHolder | null,
): SearchParameter[] {
	const innerFilters: SearchParameter[] = [];
	if (!filters || filters.length === 0) return innerFilters;

	filters.forEach((oFilter: ViewNodeFilter) => {
		const searchParameter = new SearchParameter(null);

		searchParameter.attributeName = oFilter.name;
		searchParameter.label = oFilter.name;

		searchParameter.operator = oFilter.op;
		searchParameter.type = oFilter.type;

		searchParameter.targetEntityId = entityInfo.id;

		searchParameter.isOptional = false;
		searchParameter.entityId = entityInfo.id;
		searchParameter.logicalType = LogicalAttributeType.Value;
		searchParameter.luceneOp = SearchParameterLuceneOp.SHOULD;

		if (oFilter.idType && oFilter.idLevel) {
			throwError('TODO EXTRA FILTERS');
			// EvalutateExtraFilters
		} else if (oFilter.isRef && oFilter.value) {
			if (oFilter.value === KnowItemNames.OwnerPlaceHolder) {
				searchParameter.value = currentEntityHolder.instanceId;
				searchParameter.valueHr = currentEntityHolder.humanReadableName;
			} else {
				let instanceIdOrHolder = currentEntityHolder.getField(oFilter.value, false)?.value;

				if (!instanceIdOrHolder && rootEntityHolder) {
					instanceIdOrHolder = rootEntityHolder.getField(oFilter.value)?.value;
				}

				if (instanceIdOrHolder instanceof EntityHolder) {
					searchParameter.value = instanceIdOrHolder.instanceId;
					searchParameter.valueHr = instanceIdOrHolder.humanReadableName;
				} else {
					searchParameter.value = instanceIdOrHolder;

					if (rootEntityHolder?.instanceId == instanceIdOrHolder) {
						searchParameter.valueHr = rootEntityHolder!.humanReadableName;
					}

					if (!searchParameter.valueHr && oFilter.label) {
						searchParameter.valueHr = oFilter.label;
					}

					if (!searchParameter.valueHr) {
						searchParameter.valueHr = instanceIdOrHolder;
					}
				}
			}
		} else {
			searchParameter.value = oFilter.value;

			if (!searchParameter.valueHr && oFilter.label) {
				searchParameter.valueHr = oFilter.label;
			}
		}
		// console.log(searchParameter.attributeName, searchParameter.value);
		innerFilters.push(searchParameter);
	});

	// console.log(innerFilters);
	return innerFilters;
}

export function toSearchParameters(inputs: ViewNodeFilter[]): SearchParameter[] {
	const output: SearchParameter[] = [];

	inputs.forEach((input: ViewNodeFilter) => {
		const s = new SearchParameter(null);
		s.attributeName = input.name;
		s.operator = input.op;
		s.type = input.type;
		s.value = input.value;

		output.push(s);
	});

	return output;
}

export function evalutateVirtualType(attributes: AttributeMetadata[], attributeName: string): string {
	const virtuals = attributes
		.filter((c) => EnumHelper.is(c.logicalType, LogicalAttributeType.Virtual))
		.map((c) => c.name);
	const types = attributes
		.filter((c) => EnumHelper.is(c.logicalType, LogicalAttributeType.VirtualType))
		.map((c) => c.name);
	const i = virtuals.indexOf(attributeName);
	return types[i];
}

export function getVirtualTypeOf(holder: EntityHolder, attributeName: string): string {
	const virtuals = holder.fields
		.filter((c) => EnumHelper.is(c.logicalType, LogicalAttributeType.Virtual))
		.map((c) => c.name);
	const types = holder.fields
		.filter((c) => EnumHelper.is(c.logicalType, LogicalAttributeType.VirtualType))
		.map((c) => c.name);
	const i = virtuals.indexOf(attributeName.substring(1));

	const v = holder.getField(types[i])?.value;

	if (v as EntityHolder) {
		return v.instanceId;
	} else {
		return v;
	}
}

export function getFirstOwnerOrPointerOfEntity(
	attributes: AttributeMetadata[],
	entityIds: string[],
): AttributeMetadata | null {
	let owners = attributes.filter(
		(c) => entityIds.indexOf(c.targetEntity?.id ?? '') >= 0 && EnumHelper.is(c.logicalType, LogicalAttributeType.Owner),
	);

	if (owners.length === 0) {
		owners = attributes.filter(
			(c) =>
				entityIds.indexOf(c.targetEntity?.id ?? '') >= 0 && EnumHelper.is(c.logicalType, LogicalAttributeType.Pointer),
		);
	}

	return owners.length > 0 ? owners[0] : null;
}
