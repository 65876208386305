export enum EntityAttributeType {
	Value,
	Computed,
	StaticLookUp,
	SqlLookUp,
	EntityLookUp,
	ManyToOne,
	OneToOne,
	OneToMany,
	ManyToMany,
	Extra,
}

export enum EntityAttachmentTypeEnum {
	Attachment,
}

export enum ModuleEntityViewTypeEnum {
	Print,
	UI,
	Filter,
	Result,
}
