export enum HtmlViewerOption {
	// per le opzioni di default, vedi codice
	Default = 0,

	// nasconde la label
	HideLabel = 2,

	// renderizza l'html per renderlo più piacevole
	Prettify = 4,
}
