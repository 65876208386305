export const KnowItemNames = {
	GUID_EMPTY: '00000000-0000-0000-0000-000000000000',
	NULL: '__NULL__',
	IdField: 'Id',
	NameField: 'Name',
	EntityField: '_entity',
	EntityId: 'EntityId',
	ParentId: 'ParentId',
	EntityType: 'EntityType',
	Category: 'Category',
	Type: 'Type',
	InstanceId: 'InstanceId',
	SubEntityId: 'SubEntityId',
	HumanReadableSpecialField: 'HumanReadable',
	OwnerPlaceHolder: '@OwnerId',
	ProfileRuleId: '@Profile',
	AccountRuleId: '@Account',
	MultiValueSeparator: '|',
	Status: 'Status',
	MemberId: 'MemberId',
	MemberType: 'MemberType',
	Parent: 'Parent',
	Index: 'Index',
	IsModel: 'IsModel',
};

export const KnowEntityIds = {
	GlobalType: 'ntt_install_global-type',
	GlobalCategoryBind: 'ntt_install_global-category-bind',
	GlobalCategory: 'ntt_install_global-category',
	Dashboard: 'ntt_install_entity-dashboard',
	Widget: 'ntt_install_entity-widget',

	DataPartitionUnit: 'ntt_install_data-partition-unit',
	Attached: 'ntt_install_entity-attachment',
	SurveyInstance: 'ntt_survey_instance',
	SurveyInstanceResultValue: 'ntt_survey_instance-result-value',
	UserFavourite: 'ntt_install_user-favorite',
	ModuleEntity: 'ntt_install_module-entity',
	Workspace: 'ntt_workspace_home',
	Technique: 'ntt_grc_technique',

	Todo: 'ntt_install_context',

	Phase: 'ntt_grc_phase',
	Goal: 'ntt_grc_goal',

	RiskAnalysisKnownTypeWorkflow: 'ntt_workflow_workflow-type',
	RiskAnalysisKnownTypeContext: 'ntt_grc_corporate-context',
	RiskAnalysisKnownTypeAssetProduct: 'ntt_core_item-class',

	Workflow: 'ntt_workflow_workflow',
	WorkflowInstanceMonitor: 'ntt_workflow_instance-monitor',

	Risk: 'ntt_grc_risk',
	RiskAnalysis: 'ntt_grc_risk-analysis',
	RiskAnalysisRisk: 'ntt_grc_risk-analysis-risk',
	RiskType: 'ntt_grc_risk-type',
	RiskTypeValutation: 'ntt_grc_risk-type-valutation',
	Law: 'ntt_grc_risk-analysis-risk-law',
	Event: 'ntt_grc_event',
	Consequence: 'ntt_grc_consequence',
	Cause: 'ntt_grc_cause',
	Action: 'ntt_grc_action',
	ActionType: 'ntt_grc_action-type',
	ActionTypeRequirement: 'ntt_grc_action-type-requirement',
	Scope: 'ntt_grc_risk-analysis-risk-scope',
};
