<div class="dx-field">
	<div class="dx-field-label">
		{{ label }} <span *ngIf="!optional" class="dx-field-required">*</span>
	</div>
	<div class="dx-field-value">
		<!-- {{node?.colSpan}} -->
		<dx-check-box
			[(value)]="value"
			(onValueChanged)="onValueChanged($event)"
		></dx-check-box>
	</div>
</div>
