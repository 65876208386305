export enum KTaskBarEventType {
	Ready,
	ShowContextMenu,
	ContextMenuCallBack,
	ShowDialog,
	DialogCallBack,
	TitleChange,
	FieldValueChange,
	OpenTask,
	FormSave,
	FieldValidChange,
	FormClose,
	FormAdd,
	FormUndo,
	FormAddFavorites,
	FormPrint,
	FormClone,
	FormDelete,
	SelectedUiElementChange,
	FormFieldRefresh,
	CloseSearchDialog,
	SearchComplete,
	QuickFilterChange,
	FormStatusChange,
	ExecuteRelated,
	ExecuteCommand,
	ProcessRuleStart,
	ProcessRuleError,
	ProcessRuleComplete,
	SearchSelect,
	FormFieldRefreshAfterSearchDialogClose,
	ShowPrint,
	SelectionChange,
	FavIsChanged,
	StartLoader,
	StopLoader,
	ShowColumnEditor,
	ShowFilterEditor,
	FileDownload,
	FormFromModel,
	SearchRefresh,
	FormModelSelected,
	FormFromParent,
	FormParentSelected,
	WidgetDelete,
	TaskClosed,
	SearchClose,
	ShowCommandDialog,
	CloseCommandDialog,
	SearchPopUpDelete,
	QuerySelect,
}

export class KTaskBarEvent {
	type: KTaskBarEventType = KTaskBarEventType.Ready;
	payload: any = null;

	constructor(type: KTaskBarEventType, payload: any) {
		this.type = type;
		this.payload = payload;
	}
}
