import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TreeNode } from 'src/app/ui/controls/tree/tree-note';
import { KAggregateTreeComponent } from './kaggregate-tree.component';

@Component({
	selector: 'app-kordered-tree',
	templateUrl: './kaggregate-tree.component.html',
	styleUrls: ['./kaggregate-tree.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KOrderedTreeComponent extends KAggregateTreeComponent {
	override searchOrNew: boolean = false;
	override hasDetails: boolean = true;
	override isTreeOrdered: boolean = true;

	onNodeClick(e: TreeNode): void {
		this.selected = e.holder;
		this.selectionInfo.selectedId = this.selected!.instanceId;
		this.setSelectedUiElement(this.selectionInfo);
	}
}
