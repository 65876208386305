import { EnumHelper } from '../helpers/enum-helper';
import { AdvancedData } from './advanced-data';
import { EntityAttributeType } from './entity-attribute-type';
import { EntityAttributeValueType } from './entity-attribute-value-type';
import { IdName } from './id-name';
import { LogicalAttributeType } from './logical-attribute-type';
import { TargetEntity } from './target-entity';
import { Validation } from './validation';

export class AttributeMetadata {
	type: EntityAttributeValueType;
	logicalType: LogicalAttributeType;

	id: string;
	// isBase: boolean;
	// isChanged: boolean;
	// isChild: boolean;
	isFlag: boolean;
	isNeedForCreation: boolean;
	isSearchIndex: boolean;
	label: string;
	name: string;
	inputType = '';
	metadata: any;
	values: IdName[] = [];
	validation: Validation | null = null;
	required = false;
	targetEntity: TargetEntity | null = null;
	isBase: boolean;
	allowNull: boolean;
	isNamePart: boolean;
	defaultValue: any;
	attributeType: EntityAttributeType;
	advancedData2: AdvancedData;

	constructor(private raw: any) {
		// todo remove
		// console.log(raw);
		this.metadata = raw;
		this.type = raw.type as EntityAttributeValueType;
		this.logicalType = raw.logicalType as LogicalAttributeType;

		this.id = raw.id; // "eba73379-0000-0000-0000-000000000000"
		// this.isBase = raw.isBase;
		// this.isChild = raw.isChild;
		this.isFlag = raw.isFlag;
		this.isBase = raw.isBase;
		this.isNeedForCreation = raw.isNeedForCreation;
		this.isSearchIndex = raw.isSearchIndex;
		this.defaultValue = raw.defaultValue;
		this.attributeType = raw.attributeType;

		this.allowNull = raw.allowNull;
		this.label = raw.label;
		this.name = raw.name;
		this.evaluateInputType(this.type);

		if (raw.staticValues !== undefined) {
			raw.staticValues.forEach((element: any) => {
				this.values.push({
					id: element.uniqueId,
					name: element.value,
				});
			});
		}

		if (this.raw.validation) {
			this.validation = new Validation(this.raw.validation);
		}

		// if (this.raw.validation ){

		// }

		if (raw.targetEntity) {
			this.targetEntity = Object.assign(new TargetEntity(), raw.targetEntity);
		}

		this.isNamePart = EnumHelper.is(
			this.logicalType,
			LogicalAttributeType.NamePart,
		);

		this.advancedData2 = raw.advancedData2;

		// if ( this.validators.length > 0 ){

		// }
	}

	// private getValidators(): Validation {

	//     // max: "255"
	//     // min: "1"
	//     // optional: true
	//     // regExp: null
	//     // type: 0

	//     return Object.assign(new Validation(),this.raw.Validation);

	//

	//   //   const vals = [];

	//   //   if ( ! (this.raw.allowNull ?? true)  && ( this.raw.validation == null || this.raw.validation?.optional == false)  ) {
	//   //   vals.push(   Validators.required);
	//   //   this.required = true;
	//   // }

	//   //   if ( this.raw.validation != null ){

	//   //   if ( this.raw.validation.type === EntityAttributeValidationType.Text) {

	//   //     if (  this.raw.validation.min > 0 ) {
	//   //       vals.push( Validators.minLength(this.raw.validation.min) );
	//   //         }

	//   //     if (  this.raw.validation.max > 0 ) {
	//   //       vals.push(     Validators.maxLength(this.raw.validation.max));
	//   //             }
	//   //   }
	//   //   else      if ( this.raw.validation.type == EntityAttributeValidationType.Number) {

	//   //     if (  this.raw.validation.min > 0 ) {
	//   //       vals.push(  Validators.min(this.raw.validation.min) );
	//   //         }

	//   //     if (  this.raw.validation.max > 0 ) {
	//   //       vals.push( Validators.min(this.raw.validation.max) );
	//   //             }

	//   //           }

	//   // }

	//   //   return vals;
	// }

	evaluateInputType(type: EntityAttributeValueType): void {
		//  button 	Defines a clickable button (mostly used with a JavaScript to activate a script)
		//  checkbox 	Defines a checkbox
		//  color 	Defines a color picker
		//  date 	Defines a date control (year, month, day (no time))
		//  datetime-local 	Defines a date and time control (year, month, day, time (no timezone)
		//  email 	Defines a field for an e-mail address
		//  file 	Defines a file-select field and a "Browse" button (for file uploads)
		//  hidden 	Defines a hidden input field
		//  image 	Defines an image as the submit button
		//  month 	Defines a month and year control (no timezone)
		//  number 	Defines a field for entering a number
		//  password 	Defines a password field
		//  radio 	Defines a radio button
		//  range 	Defines a range control (like a slider control)
		//  reset 	Defines a reset button
		//  search 	Defines a text field for entering a search string
		//  submit 	Defines a submit button
		//  tel 	Defines a field for entering a telephone number
		//  text 	Default. Defines a single-line text field
		//  time 	Defines a control for entering a time (no timezone)
		//  url 	Defines a field for entering a URL
		//  week 	Defines a week and year control (no timezone)

		switch (type) {
			case EntityAttributeValueType.Text:
				this.inputType = 'text';
				break;

			case EntityAttributeValueType.Date:
				this.inputType = 'date';
				break;
			case EntityAttributeValueType.DateTime:
				this.inputType = 'datetime-local';
				break;

			//  Int, // int
			//  Text, // nvarchar(400)
			//  LongText, // nvarchar(max)
			//  Boolean, // bit
			//  Date, // date
			//  Time, // time
			//  DateTime, // datetime
			//  Decimal, // Valute
			//  Double, // Decimali pesanti
			//  Guid,
			//  Serializable,
			//  TimeSpan,
			//  Long,
			//  // Virtual Type
			//  FullText = 100,
			//  ProcessType = 101,
			//  RequestType = 102,
			//  // Components 1000 +
			//  File = 1001, // File
			//  RichText = 1002, // Testo Formattato
			//  Image = 1003, // File
			//  // hrVirtial
			//  Role = 600,
			//  Unit = 601,
			//  Factory = 602
		}
	}

	is(logicalType: LogicalAttributeType): boolean {
		return EnumHelper.is(logicalType, this.logicalType);
	}
}
