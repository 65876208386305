<dx-validation-group #form>
	<dx-tab-panel
		#tabPanel
		height="100%"
		[dataSource]="tabs"
		[(selectedIndex)]="selectedIndex"
		[loop]="false"
		[animationEnabled]="true"
		[swipeEnabled]="false"
		[showNavButtons]="true"
		[elementAttr]="{ id: 'tabMain' }"
	>
		<div *dxTemplate="let tab of 'title'">
			<span>{{ tab.name }}</span>
		</div>

		<div *dxTemplate="let tab of 'item'" class="tab">
			<app-ktab [tab]="tab" [container]="this"></app-ktab>
		</div>
	</dx-tab-panel>
</dx-validation-group>
