import { EntityHolder } from './../../../../server/model/entity-holder';
import { PrintView } from './../../../../server/model/result-view';
import { Task } from 'src/app/ui/manager/ktaskbar/task';
import { Related } from './../../../../server/model/realted';

export class KPrintPayload {
	task: Task;
	print: PrintView;
	holders: EntityHolder[];

	constructor(task: Task, print: PrintView, holders: EntityHolder[]) {
		this.task = task;
		this.print = print;
		this.holders = holders;
	}
}

export class KRelatedPayload {
	task: Task;
	related: Related;
	// holder: EntityHolder;

	constructor(
		task: Task,
		related: Related,
		// , holder: EntityHolder
	) {
		this.task = task;
		this.related = related;
		// this.holder = holder;
	}
}
