import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output,
	ViewChild
} from '@angular/core';
import { DxTreeViewComponent } from 'devextreme-angular';
import { delay, map, switchMap, tap } from 'rxjs/operators';
import { EntityService } from 'src/app/server/entity.service';
import { KnowEntityIds, KnowItemNames } from 'src/app/server/helpers/constants';
import { EntityHolder } from 'src/app/server/model/entity-holder';
import { TreeNode } from '../tree/tree-note';
import { ApiResponse } from './../../../server/model/api.response';
import { IdName } from './../../../server/model/id-name';
import { SaveResponse } from './../../../server/model/save-response';
import { KItem } from './../../shared/kitem';

export class CategorySelectorArg {
	selecteds: EntityHolder[] = [];
}

@Component({
	selector: 'app-category-selector-dialog',
	templateUrl: './category-selector-dialog.component.html',
	styleUrls: ['./category-selector-dialog.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CategorySelectorDialogComponent implements OnInit {
	@Input() visible: boolean = true;
	@Input() entity: IdName | null = null;
	// @Input() leaftOnly: boolean = false;
	selecteds: EntityHolder[] = [];
	@Output() selectOrClose = new EventEmitter<CategorySelectorArg>();
	service: EntityService;
	// show:boolean = false;
	treeData: TreeNode[] = [];
	tabs: KItem[] = [];
	selectedTabIndex!: number;
	protected cdr: ChangeDetectorRef;
	selectButtonOptions: any;
	closeButtonOptions: any;
	addButtonOptions: any;
	deleteButtonOptions: any;
	refreshButtonOptions: any;
	screenHeightValue: number = screen.height;
	nodes: TreeNode[] = [];
	lastSelected: TreeNode | null = null;
	lastSelectedText: string | null = null;
	addIsVisible: boolean = false;
	deleteIsVisible: boolean = false;
	newCategoryName: string | null = null;
	@ViewChild('treeView') treeView!: DxTreeViewComponent;

	constructor(service: EntityService, cdr: ChangeDetectorRef) {
		this.service = service;
		const that = this;
		this.cdr = cdr;
		// const that = this;
		this.selectButtonOptions = {
			icon: 'check',
			text: 'Seleziona',
			hint: 'Seleziona',
			onClick: function (e: any) {
				that.doSelect(that.nodes);
			},
		};

		this.addButtonOptions = {
			icon: 'newfolder',
			text: 'Crea',
			hint: 'Crea',
			onClick: function (e: any) {
				that.newCategoryName = null;
				that.addIsVisible = true;
				that.deleteIsVisible = false;
				that.cdr.markForCheck();
			},
		};

		this.deleteButtonOptions = {
			icon: 'trash',
			text: 'Elimina',
			hint: 'Elimina',
			onClick: function (e: any) {
				that.newCategoryName = null;
				that.deleteIsVisible = true;
				that.addIsVisible = false;
				that.cdr.markForCheck();
			},
		};

		this.refreshButtonOptions = {
			icon: 'refresh',
			text: 'Ricarica',
			hint: 'aggiorna',
			onClick: function (e: any) {
				that.deleteIsVisible = false;
				that.addIsVisible = false;
				that.loadTree();
			},
		};

		this.closeButtonOptions = {
			text: 'Chiudi',
			hint: 'Chiudi',
			stylingMode: 'filled',
			onClick: function (e: any) {
				that.visible = false;
				that.cdr.markForCheck();
			},
		};
	}
	heightExprAddNotVisible() {
		return '35vh';
	}

	heightExprAddVisible() {
		return '27vh';
	}

	ngOnInit(): void {
		console.log('init');

		this.tabs = [new KItem('global', 'Globale')];

		if (this.entity) {
			this.tabs.push(new KItem(this.entity?.id ?? '', 'Entità ' + this.entity?.name));
		}

		console.log(this.tabs, this.entity);
		this.selectedTabIndex = 0;
		this.lastSelectedText = this.tabs[this.selectedTabIndex].text;
		this.loadTree();
	}

	onVisibleChange(isVisible: boolean) {
		if (!isVisible) {
			this.selectedTabIndex = 0;
			this.addIsVisible = false;
			this.deleteIsVisible = false;
			this.lastSelected = null;
			this.lastSelectedText = null;

			this.selectOrClose.emit({
				selecteds: this.selecteds,
			});
		}
	}

	onSelectedNodeChange(e: any) {
		this.nodes = this.treeView.instance.getSelectedNodes().map((c) => c.itemData as TreeNode) ?? [];
	}

	onNodeClick(e: { itemData: TreeNode }): void {
		if (this.lastSelected?.id === e.itemData.id) {
			this.lastSelected = null;
			this.lastSelectedText = this.tabs[this.selectedTabIndex].text;
		} else {
			this.lastSelected = e.itemData;
			this.lastSelectedText = this.tabs[this.selectedTabIndex].text + ' / ' + this.lastSelected?.text ?? 'Principale';
		}

		this.cdr.markForCheck();
	}

	onNewCategoryNameChange(e: any) {
		this.newCategoryName = e.value;
	}

	onDelete() {
		if (this.newCategoryName === 'elimina') {
			this.service.delete(KnowEntityIds.GlobalCategory, this.lastSelected!.id).subscribe((response: ApiResponse) => {
				if (response.success) {
					this.deleteIsVisible = false;
					this.loadTree(true);
				}
			});
		}
	}

	onSaveNew() {
		if (this.newCategoryName?.length ?? 0 > 3) {
			const args = new Map<string, any>();

			args.set(KnowItemNames.Index, 0);

			if (this.selectedTabIndex === 1) {
				args.set(KnowItemNames.Type, this.entity?.id);
			}

			if (this.lastSelected != null) {
				args.set(KnowItemNames.Parent, this.lastSelected?.id);
			}

			this.service
				.load(KnowEntityIds.GlobalCategory, null, args, null)
				.pipe(
					tap((e: EntityHolder | null) => {
						e!.setField(KnowItemNames.NameField, this.newCategoryName);
					}),
					switchMap((e: EntityHolder | null) => {
						return this.service.save(e!, null, false);
					}),
					delay(500),
				)
				.subscribe((response: SaveResponse) => {
					this.addIsVisible = false;
					this.loadTree(true);
				});
		}
	}

	onCloseNew() {
		this.newCategoryName = null;
		this.addIsVisible = false;

		this.cdr.markForCheck();
	}

	selectTab($event: { itemData: KItem }) {
		this.loadTree();
	}

	doSelect(nodes: TreeNode[]) {
		const chain: EntityHolder[] = [];

		nodes.forEach((node) => {
			let t: TreeNode | null = node;

			do {
				if (chain.indexOf(t.holder!) < 0) {
					chain.push(t.holder!);
				}
				t = t.parent ?? null;
			} while (t != null);
		});

		this.selecteds = chain.reverse();
		this.visible = false;
		this.cdr.markForCheck();
	}

	loadTree(refresh: boolean = false) {
		const entityId: string | null = this.selectedTabIndex === 0 ? null : this.entity?.id ?? null;

		this.service
			.getCategories(entityId, refresh)
			.pipe(
				map((results: EntityHolder[]) => {
					var tree = this.toNodes(results);
					return tree;
				}),
			)
			.subscribe((c) => {
				this.treeData = c;
				console.log(this.treeData);
				this.cdr.markForCheck();
			});
	}

	toNodes(holders: EntityHolder[], parentTree: TreeNode | null = null): TreeNode[] {
		const output: TreeNode[] = [];
		const parentId = parentTree?.holder?.instanceId ?? null;

		holders
			.filter((c) => c.parentId === parentId)
			.forEach((holder) => {
				const t = new TreeNode(holder.instanceId, holder.humanReadableName, parentTree);
				t.icon = 'folder';
				t.holder = holder;
				t.parent = parentTree;

				const childs = this.toNodes(holders, t);

				if (childs.length > 0) {
					t.items = childs;
				}

				output.push(t);
			});

		return output;
	}
}
