import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	Input,
	OnInit
} from '@angular/core';
import { Observable } from 'rxjs';
import { AttributeMetadata } from 'src/app/server/model/attribute-metadata';
import { UiControlConfiguration } from 'src/app/server/model/get-possible-ui-response';
import { IdNameIcon } from '../../../server/model/id-name';
import { ModuleEntityViewTypeEnum } from './../../../server/model/entity-attribute-type';
import { EntityInfo } from './../../../server/model/entity-info';
import { PivotCustomColumn } from './../../../server/model/save-response';
import { KColumnsEditorComponent } from './../kcolumns-editor/kcolumns-editor.component';

@Component({
	selector: 'app-kcolum-editor',
	templateUrl: './kcolum-editor.component.html',
	styleUrls: ['./kcolum-editor.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KColumEditorComponent implements OnInit {
	#column: PivotCustomColumn | null = null;
	cdr: ChangeDetectorRef;

	@Input() set column(value: PivotCustomColumn | null) {
		if (!value) {
			this.#column = null;
			return;
		}

		this.getPossibleUi(value!.name).subscribe((configuration) => {
			this.fillUiCombo(configuration);
			this.#column = this.fixInnerColumn(value);

			this.cdr.markForCheck();
		});
	}

	get column(): PivotCustomColumn | null {
		return this.#column;
	}

	parent: KColumnsEditorComponent;
	nameOption: any;
	controlOption: any;
	alignToOption: any;
	sortByOption: any;
	formatOption: any;
	entityInfo: EntityInfo;
	attributes: AttributeMetadata[] = [];

	constructor(parent: KColumnsEditorComponent, cdr: ChangeDetectorRef) {
		this.cdr = cdr;
		this.parent = parent;

		this.entityInfo = this.parent.parent.data!.info;
		this.attributes = Array.from(
			this.parent.parent.data!.info.metadata!.attributes.values(),
		);
		this.nameOption = {
			items: this.attributes.map((item) => {
				return {
					icon: 'spinnext', ...item,
				};
			}),
			displayExpr: 'label',
			valueExpr: 'name',
			onValueChanged: (e: { previousValue: string; value: string }) => {
				this.onAttributeChange(e.previousValue, e.value);
			},
		};

		this.sortByOption = {
			items: [
				new IdNameIcon('0', 'Predefinito', 'spinnext'),
				new IdNameIcon('1', 'Ascendente', 'spinnext'),
				new IdNameIcon('2', 'Discendente', 'spinnext'),
			],
			displayExpr: 'name',
			valueExpr: 'id',
		};

		// console.log('construct');
	}

	ngOnInit(): void {
		// console.log('init');
		// console.log(this.column);
	}

	onAttributeChange(oldValue: string, newValue: string): void {
		this.getPossibleUi(newValue).subscribe((configuration) => {
			this.fillUiCombo(configuration);
			this.fillAttributeInfoAfterChange(newValue);
			this.cdr.markForCheck();
		});
	}

	fixInnerColumn(column: PivotCustomColumn | null): PivotCustomColumn | null {
		if (!column) return null;
		if (column.size < 0) column.size = Math.abs(column.size);

		return column;
	}

	fillAttributeInfoAfterChange(newAttributeName: string) {
		const attribute = this.attributes.find((c) => c.name === newAttributeName)!;
		this.column!.caption = attribute.label;
		this.column!.type = attribute.type;
	}

	fillUiCombo(configuration: UiControlConfiguration) {
		this.controlOption = {
			items: configuration.controls.map((item) => {
				return {
					icon: 'spinnext', ...item,
				};
			}),
			displayExpr: 'text',
			valueExpr: 'value',
		};

		this.alignToOption = {
			items: configuration.aligns.map((item) => {
				return {
					icon: 'spinnext', ...item,
				};
			}),
			displayExpr: 'text',
			valueExpr: 'value',
		};

		this.formatOption = {
			items: configuration.formats.map((item) => {
				return {
					icon: 'spinnext', ...item,
				};
			}),
			displayExpr: 'text',
			valueExpr: 'value',
		};
	}

	getPossibleUi(attributeName: string): Observable<UiControlConfiguration> {
		const a = this.entityInfo.metadata.attributes.get(attributeName);

		return this.parent.parent.service.getPossibleUi(
			this.entityInfo.id,
			a!.logicalType,
			a!.attributeType,
			ModuleEntityViewTypeEnum.Result,
			a!.type,
		);
	}
}
