import { CommonModule } from '@angular/common';
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	NgModule,
	OnInit,
	Output,
} from '@angular/core';
import { Router } from '@angular/router';
import { DxButtonModule } from 'devextreme-angular/ui/button';
import { DxToolbarModule } from 'devextreme-angular/ui/toolbar';
import { GlobalService } from 'src/app/shared/services';
import { GlobalEvent } from '../../services/global/global-event';
import { GlobalEventType } from '../../services/global/global-event-type';
import { GlobalLoginArg } from '../../services/global/global-login-arg';
import {
	GlobalCleanCacheArg,
	GlobalLogoutArg,
} from '../../services/global/global-logout-arg';
import { GlobalTitleChangeArg } from '../../services/global/global-title-change-arg';
import { User } from '../../services/user';
import { UserPanelModule } from '../user-panel/user-panel.component';

@Component({
	selector: 'app-header',
	templateUrl: 'header.component.html',
	styleUrls: ['./header.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnInit {
	@Output()
	menuToggle = new EventEmitter<boolean>();

	@Input()
	menuToggleEnabled = false;

	title: string | null = null;
	icon: string | null = null;

	user: User | null = null;
	cdr: ChangeDetectorRef;

	userMenuItems = [
		{
			text: 'Profile',
			icon: 'user',
			onClick: () => {
				this.global.redirectToProfile();
			},
		},
		{
			text: 'Impostazioni',
			icon: 'preferences',
			onClick: () => {
				this.global.redirectToSettings();
			},
		},
		{
			text: 'Initializzazione',
			icon: 'globe',
			onClick: () => {
				this.global.on(GlobalEventType.Logout, new GlobalLogoutArg());
				this.global.redirectToSetup();
			},
		},
		{
			text: 'Pulisci cache',
			icon: 'clearformat',
			onClick: () => {
				this.global.on(GlobalEventType.CleanCache, new GlobalCleanCacheArg());
			},
		},
		{
			text: 'Logout',
			icon: 'runner',
			onClick: () => {
				this.global.logOut();
			},
		},
	];

	private global: GlobalService;
	private router: Router;

	constructor(global: GlobalService, router: Router, cdr: ChangeDetectorRef) {
		this.global = global;
		this.router = router;
		this.cdr = cdr;

		this.user = this.global.data.user;
	}

	ngOnInit() {
		this.global.trigger.subscribe((event: GlobalEvent) => {
			switch (event.type) {
				case GlobalEventType.TitleChange:
					if (event.arg instanceof GlobalTitleChangeArg) {
						this.title = event.arg.title;
						this.icon = event.arg.icon;
						this.cdr.markForCheck();
					}

					break;

				case GlobalEventType.Login:
					if (event.arg instanceof GlobalLoginArg) {
						this.user = event.arg.user;
						this.cdr.markForCheck();
					}
					break;

				case GlobalEventType.Logout:
					this.user = null;
					this.cdr.markForCheck();
					break;
			}
		});
	}

	toggleMenu = () => {
		this.menuToggle.emit();
	};
}

@NgModule({
	imports: [CommonModule, DxButtonModule, UserPanelModule, DxToolbarModule],
	declarations: [HeaderComponent],
	exports: [HeaderComponent],
})
export class HeaderModule {}
