import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import notify from 'devextreme/ui/notify';
import { Observable, Subject } from 'rxjs';
import { GlobalData } from 'src/app/server/model/global-data';
import { environment } from './../../../../environments/environment.prod';
import { GlobalEvent } from './global-event';
import { GlobalEventType } from './global-event-type';
import { GlobalLoginArg } from './global-login-arg';
import { GlobalLogoutArg } from './global-logout-arg';
import { GlobalNotifyArg } from './global-notify-arg';
import { GlobalServerUrlChangeArg } from './global-server-url-change-arg';
import { NotifyType } from './notify-type';

@Injectable({
	providedIn: 'root',
})
export class GlobalService {
	#events = new Subject<GlobalEvent>();
	public trigger: Observable<GlobalEvent> = this.#events;

	constructor(private router: Router) {
		this.loadDataFromStorage();

		this.trigger
			// .pipe(
			// tap((e:GlobalEvent) => {

			//   console.log(e);

			// }))
			.subscribe((e) => this.handleEvents(e));
	}

	on(type: GlobalEventType, arg: any = null) {
		this.#events.next(new GlobalEvent(type, arg));
	}

	showLoader() {
		this.#events.next(new GlobalEvent(GlobalEventType.ShowLoader, null));
	}

	hideLoader() {
		this.#events.next(new GlobalEvent(GlobalEventType.HideLoader, null));
	}

	showConfirm(arg0: string, callback: () => void) {
		// TODO sostituire con un pop-up a schermo
		if (window.confirm(arg0)) {
			callback();
		}
	}

	handleEvents(payload: GlobalEvent) {
		switch (payload.type) {
			case GlobalEventType.Login:
				this.onLogin(payload.arg as GlobalLoginArg);

				break;
			case GlobalEventType.Notify:
				this.showMessageByArg(payload.arg as GlobalNotifyArg);
				break;
			case GlobalEventType.ServerUrlChange:
				this.onServerUrlChange(payload.arg as GlobalServerUrlChangeArg);
				break;
		}
	}

	onServerUrlChange(arg: GlobalServerUrlChangeArg) {
		this._data = new GlobalData(arg.serverUrl, null, null, arg.isProxy);
		this.save();
		this.redirectToHome();
	}

	showError(message: string) {
		this.showMessage(message, 'error', 10000);
	}

	showWarning(message: string) {
		this.showMessage(message, 'warning', 10000);
	}

	showInfo(message: string) {
		this.showMessage(message, 'info', 2000);
	}

	showSuccess(message: string) {
		this.showMessage(message, 'success', 10000);
	}

	showMessageByArg(arg: GlobalNotifyArg) {
		this.showMessage(arg.message, arg.type, arg.duration);
	}

	showMessage(message: string, type: NotifyType, duration: number) {
		notify(
			{
				message: message,
				position: {
					my: 'center bottom',
					at: 'center bottom',
					offset: '20 -20',
				},
			},
			type,
			duration,
		);
	}

	onLogin(arg: GlobalLoginArg) {
		if (arg.success) {
			this._data = new GlobalData(this._data.serverUrl, arg.accessToken, arg.user, arg.isProxy);
			this.save();
			this.redirectToHome();
		} else {
			this._data = new GlobalData(this._data.serverUrl, null, null, arg.isProxy);
			this.save();
			this.showError(arg.message!);
		}

		// this.router.navigate([this._lastAuthenticatedPath]);
	}

	public get data(): GlobalData {
		return this._data;
	}

	GLOBAL_KEY = '__global';

	private _data: GlobalData = GlobalData.default();

	private loadDataFromStorage(): void {
		const raw = localStorage.getItem(this.GLOBAL_KEY);
		if (raw != null) {
			this._data = GlobalData.parse(JSON.parse(raw));
		}
	}

	save(): void {
		localStorage.setItem(this.GLOBAL_KEY, JSON.stringify(this._data));
	}

	// setData(data: GlobalData): void{
	//   localStorage.setItem(this.GLOBAL_KEY, JSON.stringify(data));
	// }

	isConfigured(): boolean {
		return this.data != null;
	}

	redirectToHome(): void {
		if (!environment.production) {
			window.location.href = window.location.origin;
		} else {
			window.location.href = window.location.origin + '/app';
		}

		// return this.router.navigate(['/']);
	}

	redirectToSetup() {
		this.router.navigate(['/setup']);
	}

	redirectToLogin() {
		this.router.navigate(['/login-form']);
	}

	redirectToNewAccount(): void {
		this.router.navigate(['/create-account']);
	}

	public get currentYear(): number {
		return new Date().getFullYear();
	}

	public get productName(): string {
		return 'work in progress';
	}

	public get isloggedIn() {
		return this.data.user != null;
	}

	redirectToProfile() {
		this.router.navigate(['/profile']);
	}

	redirectToSettings() {
		this.router.navigate(['/settings']);
	}

	logOut() {
		this._data = new GlobalData(this._data.serverUrl, null, null, this._data.isProxy);
		this.save();
		this.on(GlobalEventType.Logout, new GlobalLogoutArg());
		this.redirectToLogin();
	}
}
