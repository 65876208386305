import { Observable, of } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { EntityService } from 'src/app/server/entity.service';
import { UiRule } from 'src/app/server/model/load-runtime-information';
import { UiRuleTypeEnum } from 'src/app/server/model/rule-type';
import { ExecuteElaborateResponse } from 'src/app/server/model/save-response';
import { getNodeByAttributeName } from 'src/app/server/model/view';
import { KTaskBarEventType } from 'src/app/ui/manager/ktaskbar/ktaskbar-event';
import { EntityHolder } from './../../../server/model/entity-holder';

import { formReloadAfterElaborate } from './form-refresh-after-change';
import { KFormData } from './kform-data';
import { KFormComponent } from './kform.component';
/// torna true se deve essere ricaricata la form

export type ProcessRuleStartPayload = {
	attributeName: string;
	rules: UiRule[];
	taskId: string;
};
export type ProcessRuleErrorPayload = {
	attributeName: string;
	response: ExecuteElaborateResponse;
	rule: UiRule;
	taskId: string;
};
export type ProcessRuleCompletePayload = {
	attributeName: string;
	rule: UiRule;
	taskId: string;
};

export async function processOnChangeRule(
	form: KFormComponent,
	rules: UiRule[],
	attributeName: string,
): Promise<boolean> {
	// console.log('processOnChangeRule', attributeName);

	form.on(KTaskBarEventType.ProcessRuleStart, {
		attributeName,
		rules: rules, // attribute.node.advancedData2.uiRules
		taskId: form.task.id,
	});

	// attribute.container.container.manager.startLoader();
	const rule = rules.find(
		(rule: UiRule) =>
			rule.type === UiRuleTypeEnum.Reload ||
			rule.type === UiRuleTypeEnum.Refresh ||
			rule.type === UiRuleTypeEnum.Elaborate,
	);

	if (rule) {
		form.global.showLoader();
		// console.log('executeElaborate', rule?.toString());
		return form.service
			.executeElaborate(form.holder.entityId, form.holder, rule.nodeName)
			.toPromise()
			.then((response: ExecuteElaborateResponse) => {
				if (response.success && response.data) {
					form.on(KTaskBarEventType.ProcessRuleComplete, {
						attributeName,
						rule,
						taskId: form.task.id,
					});
					// console.log('executeElaborate-response', response.data);
					formReloadAfterElaborate(form, response.data, true);
					// forse è meglio tornare false?
					return true;
				} else {
					form.on(KTaskBarEventType.ProcessRuleError, {
						attributeName,
						response,
						rule,
						taskId: form.task.id,
					});

					return false;
				}
			});
	} else {
		form.on(KTaskBarEventType.ProcessRuleComplete, {
			attributeName,
			rule,
			taskId: form.task.id,
		});
		return true;
	}
}

export function evaluateIfElaborateIsNecessary(
	service: EntityService,
	formData: KFormData,
	attributeName: string,
	commit: boolean,
): Observable<{
	data: EntityHolder | null;
	success: boolean;
	required: boolean;
}> {
	console.log('evaluateIfElaborateIsNecessary and commit:', commit);

	const v = formData.info?.getUiOrDefault(formData.viewId);
	if (v) {
		const node = getNodeByAttributeName(v, attributeName);

		if (node) {
			const rules = node.advancedData2.uiRules;

			const rule = rules.find(
				(rule: UiRule) => rule.type === UiRuleTypeEnum.Reload || rule.type === UiRuleTypeEnum.Elaborate,
			);

			if (rule != null) {
				return service.executeElaborate(formData.data!.entityId, formData.data!, rule.nodeName, commit).pipe(
					first(),
					map((response: ExecuteElaborateResponse) => {
						// console.log(response.data);

						return {
							data: response.data,
							success: response.success,
							required: true,
						};
					}),
				);
			}
		}
	}

	return of({
		data: null,
		success: true,
		required: false,
	});
}
