import { fileDownload } from '../kcomponents/kfile-manager/file-functions';
import { EntityHolder } from './../../../server/model/entity-holder';
import { PrintView } from './../../../server/model/result-view';
import { KSearchComponent } from './../../search/ksearch/ksearch.component';
import { KFormComponent } from './kform.component';

export function showPrintForSearch(
	form: KSearchComponent,
	print: PrintView,
	holders: EntityHolder[],
) {
	console.log(print);

	form.service
		.getPrints(
			form.data!.info.id,
			holders.map((c) => c.instanceId),
			print.id,
		)
		.subscribe((result) => {
			if (result) {
				if (result.fileName) {
					fileDownload(result.fileName + '.pdf', result.data);
				} else {
					form.global.showError(
						'Impossibile generare la stampa: ' + print.name,
					);
				}
			}
		});
}

export function showPrint(form: KFormComponent, print: PrintView) {
	console.log(print);

	form.service
		.getPrints(form.holder.entityId, [form.holder.instanceId], print.id)
		.subscribe((result) => {
			if (result) {
				console.log(result);
				fileDownload(result.fileName + '.pdf', result.data);
			}
		});
}
