import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ComponentFactoryResolver,
	Input,
	OnInit,
	ViewChild,
} from '@angular/core';
import { ViewItemFilterControl } from 'src/app/server/model/view-item-filter-control';
import { HostDirective } from 'src/app/ui/shared/host.directive';
import { ViewNode } from './../../../../server/model/view-node';
import { evaluateFilterComponent } from './filter-editor-factory';

@Component({
	selector: 'app-kfilter-editor-host',
	template: '<ng-container appHost></ng-container>',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KFilterEditorHostComponent implements OnInit {
	#selectedFilter: ViewNode | null = null;
	@Input() set selectedFilter(value: ViewNode | null) {
		if (!value) {
			this.#selectedFilter = null;
		}

		this.#selectedFilter = value;

		if (this.#selectedFilter) {
			this.onReload(this.#selectedFilter);
		}
	}

	get selectedFilter(): ViewNode | null {
		return this.#selectedFilter;
	}

	@ViewChild(HostDirective, { static: true }) host!: HostDirective;
	private componentFactoryResolver: ComponentFactoryResolver;
	cdr: ChangeDetectorRef;

	constructor(
		componentFactoryResolver: ComponentFactoryResolver,
		cdr: ChangeDetectorRef,
	) {
		this.componentFactoryResolver = componentFactoryResolver;
		this.cdr = cdr;
	}

	ngOnInit(): void {}

	onReload(node: ViewNode) {
		const viewContainerRef = this.host.viewContainerRef;
		viewContainerRef.clear();

		// console.log(node);

		const controlType = evaluateFilterComponent(
			node.controlType as ViewItemFilterControl,
		);
		if (controlType) {
			const componentFactory =
				this.componentFactoryResolver.resolveComponentFactory(controlType);
			viewContainerRef.createComponent(componentFactory);
			// this.cdr.markForCheck();
		}
	}
}
