<dx-popup
	[width]="width"
	[height]="height"
	[(visible)]="visible"
	[showTitle]="title != null"
	[title]="title ?? ''"
	[dragEnabled]="false"
	[hideOnOutsideClick]="true"
>
	<div *dxTemplate="let data of 'content'">
		<app-kcommand-selector-host
			*ngIf="visible"
			[formEntries]="formEntries"
		></app-kcommand-selector-host>
	</div>

	<dxi-toolbar-item
		widget="dxButton"
		toolbar="bottom"
		location="left"
		[options]="saveButtonOptions"
	>
	</dxi-toolbar-item>

	<dxi-toolbar-item
		widget="dxButton"
		toolbar="bottom"
		location="left"
		[options]="closeButtonOptions"
	>
	</dxi-toolbar-item>
</dx-popup>
