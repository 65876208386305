export enum GroupableGridControlOption {
	// Tutto off
	None = 0,

	// abilita la funzionalità di raggruppamento manuale dall'utente
	Grouping = 1,

	// abilita la selezione multipla
	MultiSelect = 2,
}
