import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	forwardRef,
	OnDestroy,
	OnInit,
	ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import CustomStore from 'devextreme/data/custom_store';
import { forkJoin, Observable, of, Subject, Subscription } from 'rxjs';
import { delay, map, switchMap, tap } from 'rxjs/operators';
import { EntityService } from 'src/app/server/entity.service';
import { KTaskStateService } from 'src/app/server/kform-data-state.service';
import { EntityInfo } from 'src/app/server/model/entity-info';
import { EntityQuery } from 'src/app/server/model/entity-query';
import { OrderVersusEnum } from 'src/app/server/model/order-versus-enum';
import { QuickFilter } from 'src/app/server/model/quick-filters-response';
import { CacheService } from 'src/app/shared/caching/cache-service';
import { GlobalService } from 'src/app/shared/services';
import { GridColumnDatasource, IDatagridNewRowArg } from 'src/app/ui/controls/datagrid/datagrid-column';
import { DatagridOption } from 'src/app/ui/controls/datagrid/datagrid-option';
import { DatagridColumn } from '../../controls/datagrid/datagrid-column';
import { DatagridService } from '../../controls/datagrid/datagrid.service';
import { KCommandPayload } from '../../edit/kcomponents/kbase/kcommand-payload';
import { createColumns } from '../../edit/kcomponents/kgrid/grid-control-functions';
import { executeCommand, onExecuteCommand } from '../../edit/kform/execute-command';
import { showPrintForSearch } from '../../edit/kform/show-print';
import { KTaskComponent } from '../../manager/ktask/ktask.component';
import { KSearchTaskComponent } from '../../manager/ktask/search/ksearch-task.component';
import { KTaskBarEvent, KTaskBarEventType } from '../../manager/ktaskbar/ktaskbar-event';
import { KTaskbarService } from '../../manager/ktaskbar/ktaskbar.service';
import { createSearchKey } from '../../manager/ktaskbar/manager-functions';
import { Task } from '../../manager/ktaskbar/task';
import { KDialogCommandClosePayload } from '../../shared/kcommand-dialog/kdialog-command-close-payload';
import { KFiltersEditorComponent } from '../kfilters-editor/kfilters-editor.component';
import { KQuickFilterPayload } from '../kquick-filters/kquickfilter-payload';
import { KnowItemNames } from './../../../server/helpers/constants';
import { EntityHolder } from './../../../server/model/entity-holder';
import { PivotCustomColumn } from './../../../server/model/save-response';
import { SearchColumnResult } from './../../../server/model/search-column-result';
import { SearchParameter } from './../../../server/model/search-parameter';
import { DatagridClickEventArg } from './../../controls/datagrid/datagrid-click-event-args';
import { KPrintPayload } from './../../edit/kcomponents/kbase/krelated-payload';
import { KColumnsEditorComponent } from './../kcolumns-editor/kcolumns-editor.component';
import { KSearchCompontentBase } from './ksearch-base.component';
import { KSearchCompletePayload, KSearchSelectionChangePayload } from './ksearch-complete-payload';
import { argType, KSearchData, SearchArg } from './ksearch-data';

@Component({
	selector: 'app-ksearch',
	templateUrl: './ksearch.component.html',
	styleUrls: ['./ksearch.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: KTaskComponent,
			useExisting: forwardRef(() => KSearchTaskComponent),
		},
		DatagridService,
	],
})
export class KSearchComponent extends KSearchCompontentBase implements OnInit, OnDestroy {
	entity: EntityInfo | null = null;
	columns: DatagridColumn[] = [];
	multiselect = false;
	subs: Subscription | null = null;
	dataSource?: CustomStore;
	filters: QuickFilter[] = [];
	$refresh = new Subject<KSearchData>();
	option: DatagridOption = new DatagridOption();
	selectedRows: EntityHolder[] = [];
	saveButtonOptions: any;
	closeButtonOptions: any;
	resetButtonOptions: any;
	saveFilterButtonOptions: any;
	closeFilterButtonOptions: any;

	@ViewChild(KColumnsEditorComponent) columnsCmp!: KColumnsEditorComponent;
	@ViewChild(KFiltersEditorComponent) filtersCmp!: KFiltersEditorComponent;
	datagridService: DatagridService | undefined;

	constructor(
		manager: KTaskbarService,
		state: KTaskStateService,

		cdr: ChangeDetectorRef,
		cache: CacheService,
		route: ActivatedRoute,
		router: Router,

		global: GlobalService,
		service: EntityService,
		datagridService: DatagridService,
	) {
		super(manager, state, cdr, cache, route, router, global, service);

		this.datagridService = datagridService;
		this.datagridService.storageKey = this.task.id;
	}

	override ngOnInit(): void {
		const that = this;

		this.saveButtonOptions = {
			icon: 'save',
			text: 'Salva',
			hint: 'Salva',
			onClick: function (e: any) {
				that.onColumnsSave(that.columnsCmp.columns);
			},
		};

		this.resetButtonOptions = {
			icon: 'clear',
			text: 'Ripristina',
			onClick: function (e: any) {
				that.onColumnsReset(that.columnsCmp.columns);
			},
		};

		this.closeButtonOptions = {
			icon: 'close',
			text: 'Chiudi',
			onClick: function (e: any) {
				that.inUi(() => {
					that.columnEditorIsOpen = false;
				});
			},
		};

		this.saveFilterButtonOptions = {
			icon: 'save',
			text: 'Salva',
			hint: 'Salva',
			onClick: function (e: any) {
				that.onFilterSave(that.filtersCmp.filters);
			},
		};

		this.closeFilterButtonOptions = {
			icon: 'close',
			text: 'Chiudi',
			onClick: function (e: any) {
				that.inUi(() => {
					that.filterEditorIsOpen = false;
				});
			},
		};

		this.evaluateOptions();
		super.ngOnInit();
		this.createRefreshPipe();
		this.onInit();
	}

	onColumnsSave(columns: PivotCustomColumn[]) {
		this.service
			.setCustomResultColumns(this.data!.info.id, this.data!.query.userQueryId, columns)
			.pipe(
				switchMap(() => {
					return this.service.getEntityInfo(this.data!.info.id);
				}),
			)
			.subscribe((data: EntityInfo) => {
				if (!data) {
					this.global.showError('Impossibile salvare le colonne custom');
				} else {
					this.columnEditorIsOpen = false;
				}

				var q = data.queries.find((c) => c.userQueryId == this.data!.query.userQueryId);

				if (q?.customColumns) {
					this.data!.columns = q?.customColumns;
					this.$refresh.next(this.data!);
				}
			});
	}

	onColumnsReset(columns: PivotCustomColumn[]) {}

	onFilterSave(filters: SearchParameter[]) {
		if (this.data) {
			this.filterEditorIsOpen = false;
			this.data.addExtraFilters(filters);
			this.state.persist(this.task.id, this.data);
			this.$refresh.next(this.data);
			this.on(KTaskBarEventType.Ready, new KSearchCompletePayload(this.data));
		}
	}

	override handleEvents(event: KTaskBarEvent): void {
		switch (event.type) {
			case KTaskBarEventType.CloseCommandDialog:
				if (event.payload instanceof KDialogCommandClosePayload) {
					const main = EntityHolder.parse(this.selectedRows[0])!;
					event.payload.args.push(this.selectedRows.map((c) => c.instanceId).join(KnowItemNames.MultiValueSeparator));
					executeCommand(this, main.entityId, null, null, event.payload.command, event.payload.args);
				}

				break;

			case KTaskBarEventType.QuerySelect:
				var eq: EntityQuery = event.payload.payload;
				if (eq) {
					this.manager.goToSearch(eq.entityId, eq.id);
				}

				break;

			case KTaskBarEventType.SearchRefresh:
				this.$refresh.next(this.data!);
				break;

			case KTaskBarEventType.ExecuteCommand:
				if (event.payload instanceof KCommandPayload) {
					onExecuteCommand(this, event.payload.holders, event.payload.command);
				}
				break;

			case KTaskBarEventType.ShowPrint:
				if (event.payload instanceof KPrintPayload) {
					showPrintForSearch(this, event.payload.print, event.payload.holders);
				}
				break;

			default:
				super.handleEvents(event);
		}
	}

	onInDialogRefresh(): void {
		this.subs?.unsubscribe();

		this.evaluateOptions();
		this.onInit();
	}

	onInit(): void {
		this.subs = this.initPipe().subscribe({
			next: (arg: KSearchData | null) => {
				if (arg) {
					this.manager.on(KTaskBarEventType.Ready, new KSearchCompletePayload(arg));
					this.manager.stopLoader();
					this.cdr.markForCheck();
					this.onTitleChange(arg.info.metadata.name + ': ' + arg.query?.name ?? '');
				}
			},
			complete: () => {
				// console.log('completed');
			},
			error: (error) => {
				console.error(error);

				this.manager.stopLoader();
				this.global.showError('Impossibile connettersi al server, verificare la connessione al server e riprovare');
			},
		});
	}

	getColumnSource(arg: GridColumnDatasource) {
		console.log('getColumnSource', 'not implemented, do we need?');
	}

	getNewRow(arg: IDatagridNewRowArg) {
		console.log('getNewRow', 'not implemented, do we need?');
	}

	evaluateOptions() {
		this.option.multiselect = this.multiselect;

		this.option.multiselect = true;
		this.option.allowSelect = true;
		this.option.deferred = true;
		this.option.showCheckBoxesMode = 'always';

		if (this.option.multiselect) {
			this.option.selectionMode = 'multiple';
		}

		this.option.storeState = true;
		this.option.evaluateNumberOfIcons();
	}

	createRefreshPipe(): void {
		this.$refresh
			.pipe(
				tap((arg: KSearchData) => {
					// console.log(arg);
					this.columns = createColumns(arg.columns, arg.orders);
					this.state.persist(this.cacheKey, arg);
				}),
				switchMap((arg: KSearchData) => {
					return this.search(arg);
				}),
				tap((results: EntityHolder[]) => {
					// console.log(results);
					this.dataSource = new CustomStore({
						key: 'instanceId',
						load: (loadOptions) => {
							return results ?? [];
						},
						insert: function (values) {
							return Promise.resolve(null);
						},
					});
				}),
			)
			.subscribe((c) => {
				this.manager.stopLoader();
				this.cdr.markForCheck();
			});
	}

	override onSearchReady(payload: KSearchCompletePayload): void {
		this.$refresh.next(payload.data);
	}

	onStateChange(dgState: any) {
		this.state
			.getOrNull<KSearchData>(this.cacheKey)
			.toPromise()
			.then((state) => {
				if (state) {
					const orders: SearchColumnResult[] = [];

					dgState.columns.forEach((dgColumn: any) => {
						if (dgColumn.sortOrder) {
							const c = state.columns.find((c) => c.name === dgColumn.dataField);

							if (c) {
								const versus = dgColumn.sortOrder === 'asc' ? OrderVersusEnum.Asc : OrderVersusEnum.Desc;

								const n = new SearchColumnResult(c.name, c.isHumanReadable, versus, c.type);

								orders.push(n);
							}
						}
					});

					state.orders = orders;

					this.state.persist(this.cacheKey, state);
				}
			});
	}

	onSelect() {}

	onSelectionChange(rows: EntityHolder[]): void {
		this.selectedRows = rows;
		this.manager.on(KTaskBarEventType.SelectionChange, new KSearchSelectionChangePayload(rows));
	}

	override ngOnDestroy(): void {
		super.ngOnDestroy();
		this.subs?.unsubscribe();
		this.$refresh?.unsubscribe();
	}

	createSearchKey(task: Task): string {
		return createSearchKey(task);
	}

	createArg(): argType {
		return {
			entityId: this.task.entityId,
			queryId: this.task.instanceId,
			cacheKey: null,
		};
	}

	initPipe(): Observable<KSearchData | null> {
		const arg: argType = this.createArg();

		this.cacheKey = this.createSearchKey(this.task);
		arg.cacheKey = this.cacheKey;

		// console.log(arg);

		return of(arg).pipe(
			switchMap((args: argType) => {
				if (args.cacheKey) {
					return this.state.getOrNull<KSearchData>(args.cacheKey);
				} else {
					return of(null);
				}
			}),
			switchMap((data: KSearchData | null) => {
				if (!data) {
					// console.log('from db', arg.cacheKey, data);
					return this.loadFromDb(arg);
				} else {
					// console.log('from mem', arg.cacheKey, data);
					// return of(data);
					return of(data).pipe(delay(1));
					// return from([data]);
				}
			}),
			tap((data: KSearchData | null) => {
				this.onAddExtraFilters(data);
			}),
		);
	}

	onAddExtraFilters(data: KSearchData | null) {}

	loadFromDb(arg: argType): Observable<KSearchData | null> {
		return forkJoin({
			entity: this.service.getEntityInfo(arg.entityId),
			filters: this.service.getQuickFilters(arg.entityId),
		}).pipe(
			map((entityAndQuickFilters) => {
				return new KSearchData(entityAndQuickFilters.entity, entityAndQuickFilters.filters, arg.queryId);
			}),
		);
	}

	search(searchData: KSearchData): Observable<EntityHolder[]> {
		return of({
			searchData: searchData,
			caller: null,
			isForNew: false,
			excludeFilter: false,
			response: null,
			requireDynamicFilters: false,
			requirePostSearchAnalisys: false,
		}).pipe((b) => this.searchPipe(b));
	}

	searchPipe(data: Observable<SearchArg>): Observable<EntityHolder[]> {
		return data.pipe(
			switchMap((arg: SearchArg) => {
				if (arg.requireDynamicFilters) {
					console.log('execute requireDynamicFilters', arg.searchData.info.id);
					return this.service
						.getSearchDinamicFilters(arg.searchData.info.id, arg.caller, arg.searchData.apiFilters, arg.isForNew)
						.pipe(
							map((filters) => {
								// filtri che arrivano da qui va sommati a quelli selezionati nell'UI
								// SearchPopUp 1306
								//console.log(filters);
								for (const filter of filters) {
									//console.log(filter);
									arg.searchData.apiFilters.push(filter);
								}

								return arg;
							}),
						);
				} else {
					return of(arg);
				}
			}),
			switchMap((arg: SearchArg) => {
				// console.log(arg.searchData.info.id, arg);

				return this.service
					.search(
						arg.searchData.info.id,
						arg.searchData.columns,
						arg.searchData.orders,
						1,
						5000, // NB: impostato perché azioni > 1000 record; da riflettere su modifica logica
						arg.searchData.apiFilters,
						true,
					)
					.pipe(
						map((response) => {
							arg.response = response;
							return arg;
						}),
					);
			}),
			switchMap((arg: SearchArg) => {
				// console.log(arg);

				if (arg.requirePostSearchAnalisys) {
					console.log('execute requirePostSearchAnalisys', arg.searchData.info.id);
					return this.onQueryTap(arg);
				} else {
					return of(arg.response!.results);
				}
			}),
		);
	}

	onQueryTap(arg: SearchArg): Observable<EntityHolder[]> {
		console.log('requirePostSearchAnalisys');
		return this.service.postSearchAnalisys(
			arg.searchData.info.id,
			arg.caller,
			arg.response?.results ?? [],
			false,
			arg.searchData.columns,
		);
	}

	override onQuickFilterChange(payload: KQuickFilterPayload): void {
		if (this.data) {
			this.data.reApplyQuickFilterItems(payload.filters);
			this.$refresh.next(this.data);
		}
	}

	onCellClick(arg: DatagridClickEventArg): void {
		if (arg.command != null) {
			this.onRunCommand(arg.command);
		} else {
			this.router.navigate(['entity', 'edit', arg.row.entityId, arg.row.instanceId]);
		}
	}

	onRunCommand(command: string): void {
		// console.log(command);
	}
}
