export enum ViewItemResultControl {
	Column = 0, // Label

	DropColumn = 1, // Combo > Static look up

	SearchColumn = 2, // entita > Cerca, Vedi e elimina ( cestino + lente )

	LinkCoumn = 3, // Entita > Vedi

	EditableColumn = 4, // TextBox

	ChildColumn = 5, // Label

	ColoredColumn = 7, // Textbox colorato

	ImageButtonColumn = 8, // Bottone apertura immagine

	WeekDaysColumn = 9, // selettore giorni settimana
}
