import { Component } from '@angular/core';
import { EntityHolder } from 'src/app/server/model/entity-holder';
import { KEntityBaseComponent } from '../kcomponents/kbase/kentity-base.component';
import { KOrderedTreeRelatedInlineFormComponent } from './kordered-tree-related-inline-form.component';

@Component({
	selector: 'app-list-view',
	template: '',
})
export class KListViewComponent extends KEntityBaseComponent {
	selected: EntityHolder | null = null;
	override updateItem(holder: EntityHolder) {}
}
