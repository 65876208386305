import { EntityAttributeValueType } from './entity-attribute-value-type';
import { SearchParameter } from './search-parameter';
import { SearchParameterLuceneOp } from './search-parameter-lucene-op';
import { SearchParameterType } from './search-parameter-type';

export enum IdTypeEnum {
	None,
	Id,
	MainType,
}

export enum IdLevelEnum {
	Root,
	Parent,
	Self,
}

// export class Filter {

//   attributeName = '';
//   op: SearchParameterType = SearchParameterType.Contains;
//   isOptional = false;
//   luceneOp: SearchParameterLuceneOp = SearchParameterLuceneOp.MUST;
//   isRef = false;
//   type: EntityAttributeValueType = EntityAttributeValueType.Text;
//   value: string|null = null;

//   logical: LogicalAttributeType = LogicalAttributeType.Value;

// }

export class ViewNodeFilter {
	name = '';
	op: SearchParameterType = SearchParameterType.Contains;
	isOptional = false;
	luceneOp: SearchParameterLuceneOp = SearchParameterLuceneOp.MUST;
	isRef = false;
	type: EntityAttributeValueType = EntityAttributeValueType.Text;
	value: string | null = null;

	idLevel: IdLevelEnum | null = null;
	idType: IdTypeEnum | null = null;
	label: string | null = null;

	toApiFilter(): SearchParameter {
		return SearchParameter.createWithOperator(this.name, this.op, this.value);
	}
}
