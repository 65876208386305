import { LogicalHelper } from './logical-helper';
import { EntityHolder } from 'src/app/server/model/entity-holder';

export class GenericHelper {
	public static generateId(length: number = 6): string {
		return Math.random().toString(20).substr(2, length);
	}

	public static toIcon(entityId: string) {
		return 'icon icon-' + entityId;
	}

	public static toIconOrMainIcon(holder: EntityHolder) {
		const mm = LogicalHelper.getMainMember(holder.fields);

		if (mm) {
			// console.log(mm.entityId);
			return 'icon icon-' + mm.entityId;
		}

		return 'icon icon-' + holder.entityId;
	}
}
