export enum LogicalEntityType {
	None = 1,
	Root = 2,
	Partition = 4,
	Deletable = 8,
	Member = 16,
	Support = 32,
	Draftable = 64,
	Versionable = 128,
	Document = 256,
	Processable = 512,
	Attachable = 1024,
	Indexable = 2048,
	Link = 4096,
	Hierarchical = 8192,
	Extra = 16384,
	Library = 32768,
	Monotable = 65536,
	DataProtected = 131072,
	PerSubType = 262144,
	StoreProcedure = 524288,
	NoIndex = 1048576,
}
