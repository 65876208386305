import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	Input,
	OnDestroy,
	OnInit,
	ViewChild,
	ViewContainerRef,
} from '@angular/core';
import { ViewItemUiControl } from 'src/app/server/model/view-item-ui-control';
import { ViewNode } from 'src/app/server/model/view-node';
import { HostDirective } from '../../shared/host.directive';
import { KPartitionComponent } from '../KPartition/kpartition.component';
import { KAttachedComponent } from '../kattached/kattached.component';
import { ControlsFactory } from '../kcomponents/component-factory';
import { KAttributeBaseComponent } from '../kcomponents/kbase/kattribute-base.component';
import { KBaseComponent } from '../kcomponents/kbase/kbase.component';
import { KEntityBaseComponent } from '../kcomponents/kbase/kentity-base.component';
import { KEntityOrStaticBaseComponent } from '../kcomponents/kbase/kentity-or-static-base.component';
import { KFormComponent } from '../kform/kform.component';
import { KHistoryComponent } from './../khistory/khistory.component';
import { KVersionsComponent } from './../kversions/kversions.component';
import { ControlNode } from './createChildNode';

@Component({
	selector: 'app-ktab',
	templateUrl: './ktab.component.html',
	styleUrls: ['./ktab.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KtabComponent implements OnInit, OnDestroy {
	@Input() tab: ViewNode | null = null;
	@ViewChild(HostDirective, { static: true }) host?: HostDirective;

	@Input() container!: KFormComponent;

	private cdr: ChangeDetectorRef;

	constructor(cdr: ChangeDetectorRef) {
		this.cdr = cdr;
	}

	ngOnDestroy(): void {
		// console.log('destroing', this.tab?.name);

		if (this.host) {
			const viewContainerRef = this.host.viewContainerRef;
			viewContainerRef.clear();
		}
	}

	async ngOnInit(): Promise<void> {
		if (this.host) {
			const viewContainerRef = this.host.viewContainerRef;
			viewContainerRef.clear();

			const isReadOnly = this.container.task.action === 'view';

			if (this.tab?.controlType === ViewItemUiControl.Partition) {
				const dCmpInstance = viewContainerRef.createComponent(KPartitionComponent);
				dCmpInstance.instance.container = this.container;
				dCmpInstance.instance.readonly = isReadOnly;
			}

			if (this.tab?.controlType === ViewItemUiControl.Attach) {
				const dCmpInstance = viewContainerRef.createComponent(KAttachedComponent);
				dCmpInstance.instance.container = this.container;
				dCmpInstance.instance.readonly = isReadOnly;
			}

			if (this.tab?.controlType === ViewItemUiControl.Versions) {
				const dCmpInstance = viewContainerRef.createComponent(KVersionsComponent);
				dCmpInstance.instance.container = this.container;
				dCmpInstance.instance.readonly = isReadOnly;
			}

			if (this.tab?.controlType === ViewItemUiControl.History) {
				const dCmpInstance = viewContainerRef.createComponent(KHistoryComponent);
				dCmpInstance.instance.container = this.container;
				dCmpInstance.instance.readonly = isReadOnly;
			}

			for (const node of this.tab?.evaluatedNodes ?? []) {
				const cType1 = ControlsFactory.getComponent(node.node);

				if (typeof cType1 !== 'object') {
					this.build(viewContainerRef, node, isReadOnly, cType1);
				} else {
					await cType1.then(async (x) => {
						this.build(viewContainerRef, node, isReadOnly, x);
					});
				}
			}

			this.cdr.markForCheck();
		}
	}

	build(viewContainerRef: ViewContainerRef, node: ControlNode, isReadOnly: boolean, cmpType: any) {
		const componentRef = viewContainerRef.createComponent(cmpType);
		const cmp = componentRef.instance as KBaseComponent;
		this.setup(cmp, node, isReadOnly);
	}

	setup(cmp: KBaseComponent, node: ControlNode, isReadOnly: boolean) {
		cmp.container = this.container;
		cmp.node = node.node;
		cmp.readonly = node.readonly || isReadOnly;

		//console.log(node.node);

		node.isDecorative = cmp.isDecorative; // uhm

		cmp.description = node.node.description ?? '';
		cmp.buildOptions(node.node.optionId);

		if (cmp instanceof KAttributeBaseComponent) {
			cmp.metadata = node.metadata!;
			cmp.allowNull = node.allowNull;
			cmp.mandatory = node.mandatory;
			cmp.isType = node.isType;
		}

		if (cmp instanceof KEntityOrStaticBaseComponent) {
			cmp.isStatic = node.isStatic;
		}

		if (cmp instanceof KEntityBaseComponent) {
			cmp.isInAggregate = node.isInAggregate;
			cmp.isMember = node.isMember;
			cmp.isCollection = node.isCollection;
			cmp.resultViewId = node.resultViewId!;
		}

		cmp.onKeystoneInit();

		if (!cmp.isDecorative) {
			cmp.setValue(this.container.getField(node.node));
			this.container.registerComponent(node.node, cmp);
		}
	}
}
