import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EntityService } from 'src/app/server/entity.service';
import { KTaskStateService } from 'src/app/server/kform-data-state.service';
import { CacheService } from 'src/app/shared/caching/cache-service';
import { GlobalService } from 'src/app/shared/services';
import { KEditTaskComponent } from '../../manager/ktask/edit/kedit-task.component';
import { KTaskComponent } from '../../manager/ktask/ktask.component';
import { KTaskBarEventType } from '../../manager/ktaskbar/ktaskbar-event';
import { KTaskbarService } from '../../manager/ktaskbar/ktaskbar.service';
import { KOrderedTreeComponent } from '../kcomponents/kaggregate-tree/kordered-tree.component';
import { KFormInlineComponent } from './kform-inline.component';

@Component({
	selector: 'app-kform-tree-inline',
	templateUrl: './kform.component.html',
	styleUrls: ['./kform.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: KTaskComponent,
			useExisting: forwardRef(() => KEditTaskComponent),
		},
	],
})
export class KOrderedTreeInlineFormComponent extends KFormInlineComponent {
	constructor(
		ot: KOrderedTreeComponent,
		container: KTaskbarService,
		state: KTaskStateService,

		cdr: ChangeDetectorRef,
		cache: CacheService,
		route: ActivatedRoute,
		router: Router,

		global: GlobalService,
		service: EntityService,
	) {
		super(container, state, cdr, cache, route, router, global, service);
		this.hostComponent = ot;

		// console.log(this.attribute);
	}
}
