import { CacheEntry } from './cache-entry';
import { Cacheable } from './cacheable';

export class CacheHelper {
	static toKey(type: string, id: string): string {
		return `${type}_${id}`;
	}

	static toEntry(data: Cacheable, type: string): CacheEntry {
		return {
			parentId: data.parentId,
			type: type,
			id: this.toKey(type, data.id),
			data: data.Raw,
		};
	}
}
