import { Task } from 'src/app/ui/manager/ktaskbar/task';
import { KSearchData } from './../../search/ksearch/ksearch-data';

export class KSearchOpenPayload {
	search: KSearchData;
	parentTask: Task;

	constructor(parentTask: Task, search: KSearchData) {
		this.search = search;
		this.parentTask = parentTask;
	}
}
