import { NavigationNode } from 'src/app/server/model/navigation-node';

import { Cacheable } from './cacheable';

export class MenuCache extends Cacheable {
	constructor(raw: any) {
		super();

		this.id = 'Shared';
		this.nodes = raw.nodes;
		this._raw = JSON.stringify(raw);
	}

	nodes: NavigationNode[] = [];
}
