import { KToolbarPayload } from '../edit/ktoolbar/ktoolbar-payload';
import { KTaskBarEventType } from '../manager/ktaskbar/ktaskbar-event';
import { KTaskbarService } from './../manager/ktaskbar/ktaskbar.service';

export class FormSavePayLoad {
	constructor(asModel: boolean = false, andExit: boolean = false) {
		this.asModel = asModel;
		this.andExit = andExit;
	}

	asModel: boolean = false;
	andExit: boolean = false;
}

export function createSaveButton(
	manager: KTaskbarService,
	entityId: string,
	canBeGeneratedByModel: boolean,
	canSaveAs: boolean,
): any {
	// console.log(canBeGeneratedByModel, canSaveAs);

	if (!canBeGeneratedByModel && !canSaveAs) {
		return {
			icon: 'save',
			hint: 'Salva',
			stylingMode: 'filled',
			useSubmitBehavior: 'true',

			onClick: () => {
				manager.on(
					KTaskBarEventType.FormSave,
					new KToolbarPayload('save', manager.task!, [], new FormSavePayLoad()),
				);
			},
		};
	} else {
		const items = [];

		items.push({

			value: 1,
			name: 'Salva ed Esci',
			icon: 'back',
			disable: false,
		});
		if (canBeGeneratedByModel) {
			items.push({
				value: 2,
				name: 'Salva come modello',
				icon: 'fa fa-bookmark',
				disable: true,
			});
		}

		return {
			icon: 'save',
			text: 'Salva modifiche',
			hint: 'Salva',
			stylingMode: 'outlined',
			splitButton: true,
			useSelectMode: false,
			displayExpr: 'name',
			keyExpr: 'value',
			dropDownOptions: { width: 'auto', minWidth: '155px' },

			items: items,
			onItemClick: (e: any) => {
				manager.on(
					KTaskBarEventType.FormSave,
					new KToolbarPayload(
						'save',
						manager.task!,
						[],
						new FormSavePayLoad(e.itemData.value === 2, e.itemData.value === 1),
					),
				);
			},
			onButtonClick: (e: any) => {
				manager.on(
					KTaskBarEventType.FormSave,
					new KToolbarPayload('save', manager.task!, [], new FormSavePayLoad()),
				);
			},
		};
	}
}
