import { Directive, ViewContainerRef } from '@angular/core';

@Directive({
	selector: '[appHost]',
})
export class HostDirective {
	viewContainerRef: ViewContainerRef;
	constructor(viewContainerRef: ViewContainerRef) {
		this.viewContainerRef = viewContainerRef;
	}
}
