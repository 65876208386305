import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FilterEditorBaseComponent } from '../filter-editor-base.component';

@Component({
	selector: 'app-dinammic-range-filter',
	templateUrl: './dinammic-range-filter.component.html',
	styleUrls: ['./dinammic-range-filter.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DinammicRangeFilterComponent extends FilterEditorBaseComponent {}
