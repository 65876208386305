<app-kcomponent-container [label]="label" [mandatory]="mandatory" [description]="description">
	<!-- {{node?.colSpan}} -->
	<dx-number-box [readOnly]="readonly" [format]="format" [value]="innerValue" (onValueChanged)="onValueChanged($event)"
		[title]="description">
		<dx-validator (onOptionChanged)="onValidationChange($event)">
			<dxi-validation-rule *ngIf="this.mandatory" type="required" message="Campo obbligatorio"></dxi-validation-rule>

			<dxi-validation-rule *ngIf="this.validationType == 'stringLength'" type="stringLength"
				[ignoreEmptyValue]="!this.mandatory" [min]="this.validation.min" [max]="this.validation.max" [message]="
					'Deve essere tra ' + this.validation.min + ' e ' + this.validation.max
				"></dxi-validation-rule>

			<!-- <dxi-validation-rule type="email" message="Email is invalid"></dxi-validation-rule>
        <dxi-validation-rule type="async" message="Email is already registered" [validationCallback]="asyncValidation"></dxi-validation-rule> -->
		</dx-validator>
	</dx-number-box>
</app-kcomponent-container>
