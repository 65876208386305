import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	forwardRef,
	Optional,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EntityService } from 'src/app/server/entity.service';
import { KTaskStateService } from 'src/app/server/kform-data-state.service';
import { CacheService } from 'src/app/shared/caching/cache-service';
import { GlobalService } from 'src/app/shared/services';
import { KEditTaskComponent } from '../../manager/ktask/edit/kedit-task.component';
import { KTaskComponent } from '../../manager/ktask/ktask.component';
import { KTaskbarService } from '../../manager/ktaskbar/ktaskbar.service';
import { KMasterDetailComponent } from '../kcomponents/kmaster-detail/kmaster-detail.component';
import { KFormInlineComponent } from './kform-inline.component';

@Component({
	selector: 'app-kform-master-inline',
	templateUrl: './kform.component.html',
	styleUrls: ['./kform.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: KTaskComponent,
			useExisting: forwardRef(() => KEditTaskComponent),
		},
	],
})
export class KMasterDetailInlineFormComponent extends KFormInlineComponent {
	constructor(
		md: KMasterDetailComponent,
		container: KTaskbarService,
		state: KTaskStateService,

		cdr: ChangeDetectorRef,
		cache: CacheService,
		route: ActivatedRoute,
		router: Router,

		global: GlobalService,
		service: EntityService,
	) {
		super(container, state, cdr, cache, route, router, global, service);
		this.hostComponent = md;

		// console.log(this.attribute);
	}
}
