import { AdvancedData } from './advanced-data';
import { AlignToEnum } from './align-enum';
import { EntityAttributeValueType } from './entity-attribute-value-type';
import { LookUpEntry } from './lookup-entry';
import { SimpleEntityMetadata } from './simple-entity-metadata';
import { ViewItemResultControl } from './view-item-result-control';

export class ViewResultNode {
	id = '';
	name = '';
	caption = '';
	entityId = '';

	advancedData2!: AdvancedData;
	viewId: string | null = null;
	refAttributeName: string | null = null;
	refAttributeType: EntityAttributeValueType | null = null;

	size = 100;
	isHumanReadable = false;
	virtualTypeName: string | null = null;
	virtualIdName: string | null = null;
	values: LookUpEntry[] | null = null;
	control: ViewItemResultControl = ViewItemResultControl.Column;
	type: EntityAttributeValueType = EntityAttributeValueType.Int;
	alignTo: AlignToEnum | null = null;
	format: string | null = null;
	shortFormat: string | null = null;
	targetEntity: SimpleEntityMetadata | null = null;

	static parse(column: any): ViewResultNode {
		// console.log(column);

		// TODO parse view result node better
		const me = Object.assign(new ViewResultNode(), column);
		me.advancedData2 = AdvancedData.parse(column.advancedData2);
		return me;
	}

	getUniqueId(): string {
		return (this.refAttributeName ?? '') + this.name;
	}

	// attenzione il ref ... non è giusto?
	// static fromScratch(name: string): ViewResultNode{

	//   const me = new ViewResultNode();
	//   me.name = name;
	//   me.refAttributeName = name;
	//   me.refAttributeType = EntityAttributeValueType.Text;
	//   me.caption = 'Nome';

	//   return me;

	// }
}
