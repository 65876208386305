import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';
import { KSearchCompontentBase } from '../ksearch/ksearch-base.component';
import { KSearchCompletePayload } from '../ksearch/ksearch-complete-payload';

@Component({
	selector: 'app-kfilters',
	templateUrl: './kapplied-filters.component.html',
	styleUrls: ['./kapplied-filters.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KAppliedFiltersComponent
	extends KSearchCompontentBase
	implements OnInit
{
	tasks: any;

	override onSearchReady(payload: KSearchCompletePayload): void {
		// console.log('updating', payload);

		this.tasks = new DataSource({
			store: new ArrayStore({
				key: 'id',
				data: payload.data.filterItems,
			}),
		});

		this.cdr.markForCheck();
	}
}
