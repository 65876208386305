export class EnumHelper {
	enum: any;

	static is(value: any, containedIn: any): boolean {
		// tslint:disable-next-line: no-bitwise
		return containedIn === (value & containedIn);
	}

	static toHuman(flag: any, value: any): string {
		let output = '';

		Object.keys(flag).forEach((i: any) => {
			if (i <= value && (value - i) % 2 === 0) {
				output += flag[i] + '|';
			}
		});

		return output.substr(0, output.length - 1);
	}

	static toFlag(values: string[]): number {
		// tslint:disable-next-line: no-bitwise
		return values.map((v) => parseInt(v)).reduce((a, b) => a | b, 0);
	}

	static fromFlag(value: number, allValues: string[]): string[] {
		const values: string[] = [];
		allValues.forEach((collectionItem: string) => {
			if (EnumHelper.is(value, parseInt(collectionItem))) {
				values.push(collectionItem.toString());
			}
		});
		return values;
	}
}
