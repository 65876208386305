import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AngularSplitModule } from 'angular-split';
import {
	DxBulletModule,
	DxCheckBoxModule,
	DxColorBoxModule,
	DxDataGridModule,
	DxDateBoxModule,
	DxDropDownBoxModule,
	DxDropDownButtonModule,
	DxFileUploaderModule,
	DxFormModule,
	DxHtmlEditorModule,
	DxListModule,
	DxLoadIndicatorModule,
	DxLoadPanelModule,
	DxMenuModule,
	DxNumberBoxModule,
	DxPopupModule,
	DxProgressBarModule,
	DxSelectBoxModule,
	DxSortableModule,
	DxSwitchModule,
	DxTabPanelModule,
	DxTabsModule,
	DxTemplateModule,
	DxTextAreaModule,
	DxTextBoxModule,
	DxToolbarModule,
	DxTreeViewModule,
	DxValidationGroupModule,
	DxValidatorModule,
} from 'devextreme-angular';
import { DxButtonModule } from 'devextreme-angular/ui/button';
import { DxContextMenuModule } from 'devextreme-angular/ui/context-menu';
import { DxiItemModule } from 'devextreme-angular/ui/nested';
import { DxScrollViewModule } from 'devextreme-angular/ui/scroll-view';
import { CategorySelectorDialogComponent } from './controls/category-selector-dialog/category-selector-dialog.component';
import { DatagridComponent } from './controls/datagrid/datagrid.component';
import { ToolbarTwoBoxContainerComponent } from './controls/toolbar-two-box-container/toolbar-two-box-container.component';
import { TreeComponent } from './controls/tree/tree.component';
import { TwoBoxContainerComponent } from './controls/two-box-container/two-box-container.component';
import { WidgetBoxComponent } from './controls/widget-box/widget-box.component';
import { KPartitionComponent } from './edit/KPartition/kpartition.component';
import { KAttachedComponent } from './edit/kattached/kattached.component';
import { KAggregateTreeComponent } from './edit/kcomponents/kaggregate-tree/kaggregate-tree.component';
import { KOrderedTreeComponent } from './edit/kcomponents/kaggregate-tree/kordered-tree.component';
import { KOrderedTreeComponentRelated } from './edit/kcomponents/kaggregate-tree/kordered-tree.component-related';
import { KBarcodeComponent } from './edit/kcomponents/kbarcode/kbarcode.component';
import { KBaseComponent } from './edit/kcomponents/kbase/kbase.component';
import { KEntityBaseComponent } from './edit/kcomponents/kbase/kentity-base.component';
import { KCategoryComponent } from './edit/kcomponents/kcategory/kcategory.component';
import { KCheckBoxComponent } from './edit/kcomponents/kcheck-box/kcheck-box.component';
import { KColorBoxComponent } from './edit/kcomponents/kcolor-box/kcolor-box.component';
import { KComboboxComponent } from './edit/kcomponents/kcombobox/kcombobox.component';
import { KComponentContainerComponent } from './edit/kcomponents/kcomponent-container/kcomponent-container.component';
import { KDateboxComponent } from './edit/kcomponents/kdatebox/kdatebox.component';
import { KEmptyComponent } from './edit/kcomponents/kempty/kempty.component';
import { KFileManagerComponent } from './edit/kcomponents/kfile-manager/kfile-manager.component';
import { KFormViewComponent } from './edit/kcomponents/kform-view/kform-view.component';
import { KGridComponent } from './edit/kcomponents/kgrid/kgrid.component';
import { KGroupableGridComponent } from './edit/kcomponents/kgroupable-grid/kgroupable-grid.component';
import { KHtmlEditorComponent } from './edit/kcomponents/khtml-editor/khtml-editor.component';
import { KHtmlViewerComponent, SafeHtmlPipe } from './edit/kcomponents/khtml-viewer/khtml-viewer.component';
import { KImageComponent } from './edit/kcomponents/kimage/kimage.component';
import { KMasterDetailComponent } from './edit/kcomponents/kmaster-detail/kmaster-detail.component';
import { KNumboxComponent } from './edit/kcomponents/knumbox/knumbox.component';
import { KPictureListComponent } from './edit/kcomponents/kpicture-list/kpicture-list.component';
import { KProgressBoxComponent } from './edit/kcomponents/kprogress-box/kprogress-box.component';
import { KQueryComponent } from './edit/kcomponents/kquery/kquery.component';
import { KLinkComponent } from './edit/kcomponents/ksearch-panel/klink.component';
import { KSearchPanelComponent } from './edit/kcomponents/ksearch-panel/ksearch-panel.component';
import { KSeparatorComponent } from './edit/kcomponents/kseparator/kseparator.component';
import { KTextAreaComponent } from './edit/kcomponents/ktext-area/ktext-area.component';
import { KTextComponent } from './edit/kcomponents/ktext/ktext.component';
import { KTextboxComponent } from './edit/kcomponents/ktextbox/ktextbox.component';
import { KFormInlineComponent } from './edit/kform/kform-inline.component';
import { KFormStandAloneComponent } from './edit/kform/kform-standalone.component';
import { KFormComponent } from './edit/kform/kform.component';
import { KFormReadOnlyComponent } from './edit/kform/kfrom-read-only.component';
import { KMasterDetailInlineFormComponent } from './edit/kform/kmaster-detail-inline-form.component';
import { KOrderedTreeInlineFormComponent } from './edit/kform/kordered-tree-inline-form.component';
import { KOrderedTreeRelatedInlineFormComponent } from './edit/kform/kordered-tree-related-inline-form.component';
import { KHistoryComponent } from './edit/khistory/khistory.component';
import { KtabComponent } from './edit/ktab/ktab.component';
import { KToolbarComponent } from './edit/ktoolbar/ktoolbar.component';
import { KVersionsComponent } from './edit/kversions/kversions.component';
import { KEditTaskComponent } from './manager/ktask/edit/kedit-task.component';
import { KTaskComponent } from './manager/ktask/ktask.component';
import { KSearchTaskComponent } from './manager/ktask/search/ksearch-task.component';
import { KTaskbarComponent } from './manager/ktaskbar/ktaskbar.component';
import { KAppliedFiltersComponent } from './search/kapplied-filters/kapplied-filters.component';
import { KColumEditorComponent } from './search/kcolum-editor/kcolum-editor.component';
import { KColumnsEditorComponent } from './search/kcolumns-editor/kcolumns-editor.component';
import { BooleanFilterComponent } from './search/kfilter-editor/editors/boolean-filter/boolean-filter.component';
import { CategoriesFilterComponent } from './search/kfilter-editor/editors/categories-filter/categories-filter.component';
import { DateFilterComponent } from './search/kfilter-editor/editors/date-filter/date-filter.component';
import { DateRangeFilterComponent } from './search/kfilter-editor/editors/date-range-filter/date-range-filter.component';
import { DinamicIdentityFilterComponent } from './search/kfilter-editor/editors/dinamic-identity-filter/dinamic-identity-filter.component';
import { DinammicRangeFilterComponent } from './search/kfilter-editor/editors/dinammic-range-filter/dinammic-range-filter.component';
import { KFilterEditorHostComponent } from './search/kfilter-editor/editors/kfilter-editor-host.component';
import { LookUpFilterComponent } from './search/kfilter-editor/editors/look-up-filter/look-up-filter.component';
import { NumberFilterComponent } from './search/kfilter-editor/editors/number-filter/number-filter.component';
import { SearchPopUpFilterComponent } from './search/kfilter-editor/editors/search-pop-up-filter/search-pop-up-filter.component';
import { TextFilterComponent } from './search/kfilter-editor/editors/text-filter/text-filter.component';
import { KFilterEditorComponent } from './search/kfilter-editor/kfilter-editor.component';
import { KFiltersEditorComponent } from './search/kfilters-editor/kfilters-editor.component';
import { KQuickFiltersComponent } from './search/kquick-filters/kquick-filters.component';
import { KSearchDialogToolbarComponent } from './search/ksearch-toolbar/ksearch-dialog-toolbar.component';
import { KSearchToolbarComponent } from './search/ksearch-toolbar/ksearch-toolbar.component';
import { KSearchInDialogComponent } from './search/ksearch/ksearch-in-dialog.component';
import { KSearchComponent } from './search/ksearch/ksearch.component';
import { HostDirective } from './shared/host.directive';
import { KCommandDialogComponent } from './shared/kcommand-dialog/kcommand-dialog.component';
import { KcommandSelectorHostComponent } from './shared/kcommand-dialog/kcommand-selector-host/kcommand-selector-host.component';
import { KDialogCheckboxSelectorComponent } from './shared/kcommand-dialog/selectors/kdialog-checkbox-selector/kdialog-checkbox-selector.component';
import { KDialogSearchSelectorComponent } from './shared/kcommand-dialog/selectors/kdialog-search-selector/kdialog-search-selector.component';
import { KDialogTextareaSelectorComponent } from './shared/kcommand-dialog/selectors/kdialog-textarea-selector/kdialog-textarea-selector.component';
import { KDialogTextboxSelectorComponent } from './shared/kcommand-dialog/selectors/kdialog-textbox-selector/kdialog-textbox-selector.component';
import { KContextMenuComponent } from './shared/kcontext-menu/kcontext-menu.component';
import { KQuickSelectorPopUpComponent } from './shared/kquick-selector-pop-up/kquick-selector-pop-up.component';
import { KSearchDialogComponent } from './shared/ksearch-dialog/ksearch-dialog.component';

@NgModule({
	declarations: [
		DatagridComponent,
		KFormComponent,
		HostDirective,
		KtabComponent,
		KEmptyComponent,
		KBaseComponent,
		KTextboxComponent,
		KSeparatorComponent,
		KToolbarComponent,
		KImageComponent,
		KComboboxComponent,
		KEntityBaseComponent,
		KDateboxComponent,
		KCategoryComponent,
		KTaskbarComponent,
		KTaskComponent,
		KSearchComponent,
		KContextMenuComponent,
		KSearchPanelComponent,
		KSearchDialogComponent,
		KCommandDialogComponent,
		KCheckBoxComponent,
		KGridComponent,
		KHtmlEditorComponent,
		KQuickFiltersComponent,
		KAppliedFiltersComponent,
		KSearchToolbarComponent,
		KPictureListComponent,
		KTextAreaComponent,
		KAggregateTreeComponent,
		TreeComponent,
		KOrderedTreeComponent,
		KOrderedTreeComponentRelated,
		KQueryComponent,
		KBarcodeComponent,
		KTextComponent,
		CategorySelectorDialogComponent,
		KPartitionComponent,
		KFileManagerComponent,
		KCommandDialogComponent,
		KDialogTextboxSelectorComponent,
		KDialogCheckboxSelectorComponent,
		KDialogSearchSelectorComponent,
		KMasterDetailComponent,
		KSearchTaskComponent,
		KEditTaskComponent,
		KFormInlineComponent,
		KMasterDetailInlineFormComponent,
		KOrderedTreeRelatedInlineFormComponent,
		KOrderedTreeInlineFormComponent,
		KFormReadOnlyComponent,
		KColorBoxComponent,
		KAttachedComponent,
		KVersionsComponent,
		KHistoryComponent,
		KColumnsEditorComponent,
		KFiltersEditorComponent,
		KColumEditorComponent,
		KFilterEditorComponent,
		LookUpFilterComponent,
		TextFilterComponent,
		NumberFilterComponent,
		BooleanFilterComponent,
		SearchPopUpFilterComponent,
		DateFilterComponent,
		DateRangeFilterComponent,
		DinammicRangeFilterComponent,
		DinamicIdentityFilterComponent,
		CategoriesFilterComponent,
		KFilterEditorHostComponent,
		KQuickSelectorPopUpComponent,
		KGroupableGridComponent,
		WidgetBoxComponent,
		KComponentContainerComponent,
		KFormStandAloneComponent,
		KSearchInDialogComponent,
		KSearchDialogToolbarComponent,
		KProgressBoxComponent,
		KcommandSelectorHostComponent,
		ToolbarTwoBoxContainerComponent,
		TwoBoxContainerComponent,
		KLinkComponent,
		KFormViewComponent,
		KNumboxComponent,
		KHtmlViewerComponent,
		SafeHtmlPipe,
		KDialogTextareaSelectorComponent,
	],
	imports: [
		CommonModule,
		DxDataGridModule,
		DxTemplateModule,
		DxBulletModule,
		DxTabPanelModule,
		DxTextBoxModule,
		DxButtonModule,
		DxListModule,
		DxToolbarModule,
		DxSelectBoxModule,
		DxMenuModule,
		DxDropDownBoxModule,
		DxTreeViewModule,
		DxDropDownBoxModule,
		DxDateBoxModule,
		DxTabsModule,
		DxiItemModule,
		DxSortableModule,
		DxContextMenuModule,
		DxPopupModule,
		DxScrollViewModule,
		DxCheckBoxModule,
		DxLoadPanelModule,
		DxValidatorModule,
		DxListModule,
		DxTextAreaModule,
		DxHtmlEditorModule,
		DxFileUploaderModule,
		DxColorBoxModule,
		DxFormModule,
		DxNumberBoxModule,
		DxDropDownButtonModule,
		DxValidationGroupModule,
		DxProgressBarModule,
		AngularSplitModule,
		DxSwitchModule,
		DxLoadIndicatorModule,
	],
	exports: [
		DatagridComponent,
		KFormComponent,
		KFormStandAloneComponent,
		HostDirective,
		KTaskbarComponent,
		KtabComponent,
		DxTreeViewModule,
		WidgetBoxComponent,
		KFiltersEditorComponent,
		KColumnsEditorComponent,
		KQuickFiltersComponent,
		KAppliedFiltersComponent,
		KComponentContainerComponent,
		ToolbarTwoBoxContainerComponent,
		TwoBoxContainerComponent,
	],
})
export class UiModule {}
