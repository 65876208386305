import { EntityHolder } from './../../../server/model/entity-holder';
import { KSearchData } from './ksearch-data';

export class KSearchCompletePayload {
	data: KSearchData;
	constructor(data: KSearchData) {
		this.data = data;
	}
}
export class KSearchSelectionChangePayload {
	selectedRows: EntityHolder[];
	constructor(selectedRows: EntityHolder[]) {
		this.selectedRows = selectedRows;
	}
}
