import { IKEntityOrStaticComponent } from './abstract';
import { Component } from '@angular/core';
import { KEntityBaseComponent } from './kentity-base.component';

@Component({
	selector: 'app-adv-attribute-base',
	template: '',
})
export class KEntityOrStaticBaseComponent
	extends KEntityBaseComponent
	implements IKEntityOrStaticComponent
{
	isStatic = false;
}
