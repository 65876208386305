<app-kcomponent-container [label]="label">
	<div class="fpRow1">
		<div class="icons" style="width:20px !important">
			<i [ngClass]="{
			'dx-icon-docfile': fileExt === '.doc',
			'dx-icon-docxfile': fileExt === '.docx',
			'dx-icon-pdffile': fileExt === '.pdf',
			'dx-icon-xlsxfile': fileExt === '.xlsx',
			'dx-icon-xlsfile': fileExt === '.xls',
			'dx-icon-image': fileExt === '.png' || fileExt === '.jpg' || fileExt === '.jpeg' || fileExt === '.bmp' || fileExt === '.svg' || fileExt === '.dwg'
		}"></i>
		</div>
		<div class="text" style="width: 85%; overflow: hidden; text-overflow: ellipsis;">
			<label class="upload">{{fileName}}</label>
		</div>
		<!--
		<div class="buttons" style="width:100px !important">
			<dx-button class="first" [disabled]="readonly" icon="icotbar icot-tb_undo-gray" *ngIf="fileName"
				(onClick)="onClear()"></dx-button>
			<dx-button class="grayish" icon="icotbar icot-tb_undo-gray" *ngIf="!fileName"></dx-button>
			<dx-button class="second" icon="download" *ngIf="fileName" (onClick)="onView()">
			</dx-button>
			<dx-button class="grayish" [disabled]="readonly" icon="download" *ngIf="!fileName">
			</dx-button>
			<dx-button class="last" [disabled]="readonly" icon="attach" (onClick)="onShopUploadPanel()"></dx-button>
		</div>-->

		<div class="buttons" style="width:100px !important">
			<dx-button class="first" [disabled]="readonly || !fileName" icon="icotbar icot-tb_undo-gray" (onClick)="onClear()"
				hint="Elimina"></dx-button>
			<dx-button class="second" [disabled]="!fileName" icon="download" (onClick)="onView()" hint="Download">
			</dx-button>

			<dx-button class="last" [disabled]="readonly" icon="attach" (onClick)="onShopUploadPanel()" hint="Allega">
			</dx-button>

		</div>
	</div>



	<dx-file-uploader [readOnly]="readonly" #fileUploader [uploadUrl]="uploadUrl" [multiple]="false" accept="*"
		[visible]="false" [(value)]="files" uploadMode="instantly" selectButtonText="Seleziona" labelText=""
		[uploadHeaders]="headers" (onUploaded)="onFilesUploaded($event)" (onUploadError)="onError($event)"
		dialogTrigger=".last"></dx-file-uploader>
</app-kcomponent-container>
