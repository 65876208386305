import { Command } from 'src/app/server/model/commad';

export class KDialogCommandClosePayload {
	command: Command;
	args: string[];

	constructor(command: Command, args: string[]) {
		this.command = command;
		this.args = args;
	}
}
