import { CommonModule } from '@angular/common';
import { Component, Input, NgModule, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { DxDrawerModule } from 'devextreme-angular/ui/drawer';
import {
	DxScrollViewComponent,
	DxScrollViewModule,
} from 'devextreme-angular/ui/scroll-view';
import { ScreenService } from 'src/app/shared/services/screen.service';
import {
	HeaderModule,
	SideNavigationMenuModule,
} from '../../shared/components';

@Component({
	selector: 'app-side-nav-outer-toolbar',
	templateUrl: './side-nav-outer-toolbar.component.html',
	styleUrls: ['./side-nav-outer-toolbar.component.scss'],
})
export class SideNavOuterToolbarComponent implements OnInit {
	@ViewChild(DxScrollViewComponent, { static: true })
	scrollView: DxScrollViewComponent | null = null;
	selectedRoute = '';

	menuOpened = false;
	temporaryMenuOpened = false;

	@Input() title!: string;
	@Input() icon!: string | null;

	menuMode = 'shrink';
	menuRevealMode = 'expand';
	minMenuSize = 60;
	shaderEnabled = false;
	private screen: ScreenService;
	private router: Router;

	constructor(screen: ScreenService, router: Router) {
		this.screen = screen;
		this.router = router;
	}

	ngOnInit() {
		this.menuOpened = false; // this.screen.sizes['screen-large'];

		this.router.events.subscribe((val) => {
			if (val instanceof NavigationEnd) {
				this.selectedRoute = val.urlAfterRedirects.split('?')[0];

				this.menuOpened = false;
			}
		});

		this.screen.changed.subscribe(() => this.updateDrawer());
		this.updateDrawer();
	}

	updateDrawer() {
		const isXSmall = this.screen.sizes['screen-x-small'];
		const isLarge = this.screen.sizes['screen-large'];
		this.menuMode = isLarge ? 'shrink' : 'overlap';
		this.menuRevealMode = isXSmall ? 'slide' : 'expand';
		this.minMenuSize = isXSmall ? 60 : 60;
		this.shaderEnabled = !isLarge;
	}

	get hideMenuAfterNavigation() {
		// return this.menuMode === 'overlap' || this.temporaryMenuOpened;
		return true;
	}

	get showMenuAfterClick() {
		return !this.menuOpened;
	}

	navigationChanged(event: any) {
		// this.menuOpened = false;

		let path: string = event.itemData.path;
		const pointerEvent = event.event;

		console.log(path, this.menuOpened);

		if (path && this.menuOpened) {
			// if (event.node.selected) {
			//   pointerEvent.preventDefault();
			// } else {

			const parts = path.split('/');

			if (parts.length < 5) {
				path += '/any';
			}

			this.router.navigate([path]);
			this.scrollView?.instance.scrollTo(0);
			// }

			if (this.hideMenuAfterNavigation) {
				this.temporaryMenuOpened = false;
				this.menuOpened = false;
				pointerEvent.stopPropagation();
			}
		} else {
			pointerEvent.preventDefault();
		}
	}

	navigationClick() {
		if (this.showMenuAfterClick) {
			this.temporaryMenuOpened = true;
			this.menuOpened = true;
		}
	}
}

@NgModule({
	imports: [
		SideNavigationMenuModule,
		DxDrawerModule,
		HeaderModule,
		DxScrollViewModule,
		CommonModule,
	],
	exports: [SideNavOuterToolbarComponent],
	declarations: [SideNavOuterToolbarComponent],
})
export class SideNavOuterToolbarModule {}
