import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
	selector: 'app-kcomponent-container',
	templateUrl: './kcomponent-container.component.html',
	styleUrls: ['./kcomponent-container.component.scss'],
})
export class KComponentContainerComponent {
	@Input() label: string | null = null;
	@Input() mandatory: boolean | null = null;
	@Input() description: string | null = null;
	@Output() contextmenu = new EventEmitter<MouseEvent>();
	@Input() isLoading: boolean = false;
	onContextMenu(event: MouseEvent) {
		this.contextmenu.emit(event);
		console.log(this.contextmenu.emit(event));
	}
}
