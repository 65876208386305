import {
	ChangeDetectionStrategy,
	Component,
	OnInit,
	ViewChild,
} from '@angular/core';
import { AggregateMember } from 'src/app/server/model/agregate-member';
import { EntityHolder } from 'src/app/server/model/entity-holder';
import { LogicalAttributeType } from 'src/app/server/model/logical-attribute-type';
import { TreeNode } from 'src/app/ui/controls/tree/tree-note';
import { TaskRelation } from 'src/app/ui/manager/ktaskbar/task';
import { openContextMenu } from 'src/app/ui/shared/kcontext-menu/functions';
import { KContextPayloadCallback } from 'src/app/ui/shared/kcontext-menu/kcontext-payload';
import { KItem } from 'src/app/ui/shared/kitem';
import { KSearchDialogClosePayload } from 'src/app/ui/shared/ksearch-dialog/ksearch-dialog-close-payload';
import { EnumHelper } from '../../../../server/helpers/enum-helper';
import { LogicalHelper } from '../../../../server/helpers/logical-helper';
import { FieldValue } from '../../../../server/model/field-value';
import { TreeNodeContext } from '../../../controls/tree/tree-node-context';
import {
	NodeSelectionChangeArg,
	TreeComponent,
} from '../../../controls/tree/tree.component';
import { KListViewComponent } from '../../kform/klist-view.component';
import { SelectedInfo } from '../kbase/kentity-base.component';
import { KFormFieldRefreshAfterDialogClosePayload } from '../kbase/kfield-refresh-payload';
import {
	AggregateTreeNodeType,
	AggregateTreeOption,
} from './aggregate-tree-option';
import { AggreagateTreeUtil } from './aggregate-tree-util';
import {
	holderToAggregateTreeData,
	holderToTreeData,
} from './aggregatetree-holder-to-nodes';
import { TreeNodeContextReference, TreeNodeReference } from './model';

@Component({
	selector: 'app-kaggregate-tree',
	templateUrl: './kaggregate-tree.component.html',
	styleUrls: ['./kaggregate-tree.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
}) // senza detail
export class KAggregateTreeComponent
	extends KListViewComponent
	implements OnInit
{
	hasDetails: boolean = false;
	isTreeOrdered: boolean = false;
	treeData: TreeNode[] = [];
	protected searchOrNew: boolean = true;
	override selected: EntityHolder | null = null;
	selectionInfo: SelectedInfo = new SelectedInfo();

	@ViewChild('tree') tree!: TreeComponent;
	rootInfo: AggregateMember | null = null;

	is(option: AggregateTreeOption) {
		if (this.node.optionId && this.node.optionId !== AggregateTreeOption.None) {
			return EnumHelper.is(this.node.optionId, option);
		}

		return false;
	}

	override ngOnInit(): void {
		// allowEditing
		this.isMultiselect = this.is(AggregateTreeOption.MultiSelect);
		this.allowMultiMainType = this.is(AggregateTreeOption.AllowMultiMainType);
		this.selectionInfo = this.getSelectedUiElement();

		super.ngOnInit();
	}

	override setValue(value: FieldValue | null): void {
		this.container.service
			.getAggregateInfos(this.container.holder.entityId) // this.node.advancedData2.entityId!)
			.subscribe((rootInfo: AggregateMember) => {
				const currentValue = value?.value as EntityHolder[];
				this.HolderToTreeData(rootInfo, currentValue);
				this.markNodeAsExpanded(this.treeData, this.selectionInfo.openNodesIds);
				var h = this.selectSelectionInfoOrDefault();
				if (h) this.setCurrentNodeCurrentSelection(h);
				this.cdr.markForCheck();
			});
	}

	protected HolderToTreeData(
		rootInfo: AggregateMember,
		currentValue: EntityHolder[],
	) {
		this.rootInfo = rootInfo;

		if (this.isTreeOrdered) {
			if (!this.allowMultiMainType) {
				this.treeData = holderToTreeData(
					this.rootInfo!,
					this.container.holder,
					currentValue,
				).items!;
			} else {
				this.treeData = [
					holderToTreeData(this.rootInfo!, this.container.holder, currentValue),
				];
			}
		} else {
			this.treeData = [
				holderToAggregateTreeData(
					this.rootInfo!,
					this.node.advancedData2.entityId!,
					this.container.holder,
					currentValue,
				),
			];
		}
	}

	protected selectSelectionInfoOrDefault(): EntityHolder | null {
		if (this.treeData.length > 0) {
			let n: TreeNode | null = null;

			if (!this.selectionInfo.selectedId) {
				this.selectionInfo.selectedId = this.treeData[0].id;
				n = this.treeData[0];
			}

			if (!n) {
				n = this.findNodeRecursive(
					this.treeData,
					this.selectionInfo.selectedId!,
				);
			}

			if (n) {
				n.selected = true;

				if (n.holder) {
					return n.holder;
				}
			}
		}

		if (this.treeData.length > 0) return this.treeData[0].holder;

		return null;
	}

	onNodeSelectionChange(arg: NodeSelectionChangeArg): void {
		this.updateSelectedNodeIds(arg);
		this.setCurrentNodeCurrentSelection(arg.node.holder!);
		this.setSelectedUiElement(this.selectionInfo);
	}

	setCurrentNodeCurrentSelection(h: EntityHolder | null) {
		this.selectionInfo.selectedId = h?.instanceId ?? null;
		this.selected = h;
	}

	protected updateSelectedNodeIds(arg: NodeSelectionChangeArg) {
		var f: TreeNode | null = arg.node;
		var nodes: string[] = [];

		do {
			nodes.push(f.id);
			f = f.parent;
		} while (f && f.parent);

		this.selectionInfo.openNodesIds = nodes;
	}

	markNodeAsExpanded(treeData: TreeNode[], ids: string[]) {
		treeData.forEach((element) => {
			if (ids.indexOf(element.id) >= 0) {
				element.expanded = true;
			}
			if (element.items) {
				this.markNodeAsExpanded(element.items, ids);
			}
		});
	}

	onNodeClick2(e: TreeNode): void {
		this.onNodeSelectionChange({ node: e, collapse: false });
	}

	onRootAddContext(event: MouseEvent) {
		event.preventDefault();
		event.stopPropagation();

		if (this.isTreeOrdered && this.rootInfo) {
			const menus = AggreagateTreeUtil.getOrderTreeMenuItems(
				this.rootInfo,
				this.node.advancedData2.entityId!,
				null,
				this.isMultiselect,
			);

			openContextMenu(this, event.x, event.y, null, this.readonly, menus);
		}
	}

	onContext(context: TreeNodeContext) {
		// console.log(context);

		let menus: KItem[] = [];

		if (this.isTreeOrdered) {
			const selectedEntityId =
				context.node.holder?.instanceId === this.container.holder.instanceId
					? null
					: context.node.holder?.entityId ?? null;
			menus = AggreagateTreeUtil.getOrderTreeMenuItems(
				context.node.aggregateInfo!,
				this.node.advancedData2.entityId!,
				selectedEntityId,
				this.isMultiselect,
			);
		} else {
			const selectedEntityId = context.node.holder?.entityId;
			if (selectedEntityId) {
				menus = AggreagateTreeUtil.getAggregateMenuItems(
					context.node,
					this.node.advancedData2.entityId!,
					selectedEntityId,
					this.searchOrNew,
				);
			}
		}

		openContextMenu(
			this,
			context.x,
			context.y,
			new TreeNodeContextReference(context.node),
			this.readonly,
			menus,
		);
	}

	onNodeMoved(nodes: TreeNode[]): void {
		// console.log(nodes);
		const results: EntityHolder[] = [];
		this.flatTree(nodes, results, 0);
		this.fieldValueIsChanging(results);
	}

	flatTree(nodes: TreeNode[], results: EntityHolder[], index: number) {
		nodes.forEach((n) => {
			if (n.holder) {
				const indexFields = n.holder.getFirstByType(LogicalAttributeType.Index);

				if (indexFields) {
					indexFields.value = index;
				}

				if (n.type !== AggregateTreeNodeType.Root) {
					results.push(n.holder);
				}
			}

			if (n.items) {
				// console.log(n.items);
				this.flatTree(n.items, results, 0);
			}

			index++;
		});
	}

	override onSearch(payload: KContextPayloadCallback): void {
		const entityId = payload.item.value!;
		const reference = payload.reference as TreeNodeContextReference;

		this.service.getAggregateInfos(entityId).subscribe((member) => {
			if (member?.mainEntityName) {
				this.onSearchForSpecificField(
					new TreeNodeReference(
						reference?.node,
						member,
						member?.mainEntityName,
					),
					member?.mainEntityName!,
					member.mainEntityId,
					reference?.node?.holder,
				);
			} else {
				super.onSearch(payload);
			}
		});
	}

	override onAdd(payload: KContextPayloadCallback): void {
		this.createEntitiesFromMainMembers(
			payload.item.value!,
			null,
			[new EntityHolder()],
			payload.reference,
			this.node.attributeName,
			this.container.task.id,
		).subscribe((generatedEntities: EntityHolder[]) => {
			this.onFieldValueCollectionIsChanging(generatedEntities);
		});
	}

	override onAfterDialogClose(
		payload: KFormFieldRefreshAfterDialogClosePayload,
	) {
		const reference = payload.dialogPayload.reference as TreeNodeReference;
		this.addFromDialogSelector(
			reference.member.entityId,
			reference.mainMemberName,
			payload.dialogPayload,
			reference,
		);
	}

	findNodeRecursive(nodes: TreeNode[], instanceId: string): TreeNode | null {
		for (const node of nodes) {
			if (node.holder?.instanceId === instanceId) {
				return node;
			}

			if (node.items) {
				const n = this.findNodeRecursive(node.items, instanceId);

				if (n) {
					return n;
				}
			}
		}

		return null;
	}

	addFromDialogSelector(
		entityId: string,
		mainMemberName: string | null,
		dialogPayload: KSearchDialogClosePayload,
		node: TreeNodeReference,
	): void {
		// console.log('vai qui?', entityId, this.node.advancedData2.entityId!);

		if (!(node instanceof TreeNodeReference)) {
			console.error(node);
		}

		this.createEntitiesFromMainMembers(
			entityId,
			mainMemberName,
			dialogPayload.entities,
			node,
			dialogPayload.attributeName,
			dialogPayload.parentTaskId,
		).subscribe((generatedEntities: EntityHolder[]) => {
			// Event>Cause>Coseguences
			// normalmente si aggiorna la collezione del componente ma in questo caso se i figli sono dei figli
			// va aggiornata la giusta collezione

			// console.log(node);
			// console.log(dialogPayload);

			const realFielToUpdate = this.getRightCollection(
				node,
				generatedEntities[0],
				entityId,
			);

			if (realFielToUpdate) {
				const x = realFielToUpdate.holder.replaceInCollection(
					realFielToUpdate.field.name,
					generatedEntities,
				);
				realFielToUpdate.holder.setField(realFielToUpdate.field.name, x);
				this.fieldValueIsChanged(
					this.container.holder.getField(this.node.attributeName)!,
					false,
					true,
				);
			} else if (this.isCollection) {
				this.onFieldValueCollectionIsChanging(generatedEntities);
			} else if (generatedEntities.length > 0) {
				this.fieldValueIsChanging(generatedEntities[0]);
			}
		});
	}

	getRightCollection(
		node: TreeNodeReference,
		holder: EntityHolder,
		entityId: string,
	): {
		holder: EntityHolder;
		field: FieldValue;
	} | null {
		let realFielToUpdate: {
			holder: EntityHolder;
			field: FieldValue;
		} | null = null;

		/// nodo ricorsino non al primo livello
		/// ovvero quando si aggiunge un figlio ad una collezione ricorsiva
		// IEventConseguence > Causes > Causes
		if (
			node.member.recursive &&
			node.node?.holder?.entityId === holder.entityId
		) {
			// questo è il parent ...
			// TODO
			// console.log('Recursive child node');

			const owner = node.node?.holder?.getFirstByType(
				LogicalAttributeType.Owner,
			);
			const parentNode = this.findNodeRecursive(this.treeData, owner?.value!);

			// console.log(parentNode, parentNode?.aggregateInfo);

			const fieldToUpdate = parentNode?.holder?.getFirstCollectionByType(
				holder.entityId!,
			)!;

			const parentHolder = parentNode?.holder;

			realFielToUpdate = {
				holder: parentHolder!,
				field: fieldToUpdate,
			};
		}

		if (!realFielToUpdate) {
			realFielToUpdate = this.evaluateRightCollectionToUpdate(
				node.node!,
				entityId,
			);
		}

		// console.log(realFielToUpdate);

		if (!realFielToUpdate?.field || !realFielToUpdate.holder) {
			return null;
		}

		return realFielToUpdate;
	}

	evaluateRightCollectionToUpdate(
		node: TreeNode,
		entityId: string,
	): {
		holder: EntityHolder;
		field: FieldValue;
	} | null {
		if (!node) return null;

		const attributeTargetEntityId = this.node.advancedData2.entityId!;
		const refNodeTargetEntityId = node!.holder?.entityId;
		const refNodeTargetParentEntityId = node!.holder?.parentEntityId;
		const isInherited =
			refNodeTargetParentEntityId !== null &&
			refNodeTargetEntityId !== refNodeTargetParentEntityId;

		if (
			(isInherited &&
				refNodeTargetEntityId !== attributeTargetEntityId &&
				refNodeTargetParentEntityId !== attributeTargetEntityId &&
				node?.type !== AggregateTreeNodeType.Root) ||
			(!isInherited && attributeTargetEntityId !== entityId)
		) {
			const rightFieldToUpdate =
				node!.holder!.fields.find((c) => c.entityId === entityId) ?? null;

			// console.log(rightFieldToUpdate?.name);

			return {
				holder: node.holder!,
				field: rightFieldToUpdate!,
			};
		}

		return null;
	}

	override onEdit(payload: KContextPayloadCallback): void {
		this.onView(payload);
	}

	override onView(payload: KContextPayloadCallback): void {
		const e: EntityHolder = (payload.reference as TreeNodeContextReference).node
			.holder!;
		if (!this.isTreeOrdered) {
			const member = LogicalHelper.getMainMember(e.fields ?? []);

			if (member && member.value && member.value) {
				this.openTask(
					member.value.entityId,
					member.value.instanceId,
					TaskRelation.None,
				);
			}
		} else {
			this.openTask(
				e.entityId,
				e.instanceId,
				this.isMember ? TaskRelation.SubTask : TaskRelation.Parent,
			);
		}
	}

	override onDelete(payload: KContextPayloadCallback): void {
		this.container.global.showConfirm(
			'Sicuro di voler eliminare il selezionato e nodo sotto di esso?',
			() => {
				const e = payload.reference as TreeNodeContextReference;

				const tcr = new TreeNodeReference(
					e.node.parent,
					e.node.parent!.aggregateInfo!,
					e.node.aggregateInfo!.mainEntityName,
				);

				const realFielToUpdate = this.getRightCollection(
					tcr,
					e.node.holder!,
					e.node.holder!.entityId,
				);

				console.log(realFielToUpdate);

				if (realFielToUpdate) {
					const newCollectionEntries =
						realFielToUpdate.holder.removeInCollection(
							realFielToUpdate.field.name,
							e.node.holder!,
							true,
						);
					realFielToUpdate.holder.setField(
						realFielToUpdate.field.name,
						newCollectionEntries,
					);
					this.fieldValueIsChanged(
						this.container.holder.getField(this.node.attributeName)!,
						false,
						true,
					);
				} else {
					super.delete(e.node.holder, true);
				}
			},
		);
	}

	override updateItem(holder: EntityHolder) {
		this.updateTreeDataNode(this.treeData, holder);
		const updatedHolders: EntityHolder[] = [];
		this.collectHolders(this.treeData, updatedHolders);
		this.fieldValueIsChanging(updatedHolders, false);
	}

	collectHolders(treeData: TreeNode[], collection: EntityHolder[]) {
		for (const n of treeData) {
			if (n.holder) {
				collection.push(n.holder);

				if (n.items) {
					this.collectHolders(n.items, collection);
				}
			}
		}
	}

	updateTreeDataNode(treeData: TreeNode[], holder: EntityHolder) {
		for (let index = 0; index < treeData.length; index++) {
			const element = treeData[index];

			if (element.holder?.instanceId === holder.instanceId) {
				treeData[index].holder = holder;
				treeData[index].text = holder.humanReadableName;
				this.cdr.detectChanges();
				break;
			}
			if (element.items) {
				this.updateTreeDataNode(element.items, holder);
			}
		}
	}
}
