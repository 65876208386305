import { Type } from '@angular/core';
import { KnowItemNames } from 'src/app/server/helpers/constants';
import { EnumHelper } from 'src/app/server/helpers/enum-helper';
import { AttributeMetadata } from 'src/app/server/model/attribute-metadata';
import { LogicalAttributeType } from 'src/app/server/model/logical-attribute-type';
import { User } from 'src/app/shared/services/user';
import { KBaseComponent } from '../kcomponents/kbase/kbase.component';
import {
	isNodeHidden,
	isNodeMandatory,
	isNodeReadOnly,
} from '../kform/is-node';
import { ViewNode } from './../../../server/model/view-node';
import { KFormData } from './../kform/kform-data';

export class ControlNode {
	componentType!: Type<KBaseComponent>;
	node!: ViewNode;
	readonly: boolean = false;
	metadata: AttributeMetadata | null = null;
	allowNull: boolean = false;
	mandatory: boolean = false;
	isType: boolean = false;
	isDecorative: boolean = false;
	isStatic: boolean = false;
	isInAggregate: AttributeMetadata | null | undefined;
	isMember: boolean = false;
	isCollection: boolean = false;
	resultViewId: string | null = null;
	attribute: AttributeMetadata | null = null;
	isReference: boolean = false;
	isStaticLookUp: boolean = false;
}

export function createChildNodes(
	nodes: ViewNode[],
	data: KFormData,
	user: User,
	parentData: KFormData | null,
): ControlNode[] {
	const response: ControlNode[] = [];

	// nodes.forEach(node => {

	for (const node of nodes) {
		if (
			!isNodeHidden({
				node: node,
				data: data,
				accountId: user.accountId,
				userRoles: user.userRoles,
			})
		) {
			const n = new ControlNode();
			n.node = node;

			n.readonly = isNodeReadOnly({
				node: node,
				data: data,
				accountId: user.accountId,
				userRoles: user.userRoles,
			});

			n.metadata =
				data.info?.metadata?.attributes.get(node.attributeName) ?? null;

			if (n.metadata) {
				n.isReference = node.advancedData2?.entityId != null;
				n.isStaticLookUp = n.metadata.values.length > 0;

				n.allowNull = n.metadata.allowNull ?? false;
				n.mandatory =
					!n.allowNull &&
					(!n.metadata.validation || n.metadata.validation.optional === false);

				const mandatoryByRule = isNodeMandatory({
					node: node,
					data: data,
					accountId: user.accountId,
					userRoles: user.userRoles,
				});

				if (mandatoryByRule === true) {
					n.mandatory = true;
				}

				n.isType = EnumHelper.is(node.lt, LogicalAttributeType.Type);

				if (n.isStaticLookUp) {
					n.isStatic = !node.advancedData2?.entityId;
				}

				if (n.isReference) {
					if (n.metadata && node.advancedData2?.entityId) {
						let inAggregate = data.info?.metadata?.getInAggregate(
							node.advancedData2?.entityId,
						);

						if (!inAggregate && parentData) {
							inAggregate = parentData?.info?.metadata?.getInAggregate(
								node.advancedData2?.entityId,
							);
						}

						n.isInAggregate = inAggregate;

						// console.log(node.attributeName, n.isInAggregate);
						n.isMember = EnumHelper.is(node.lt, LogicalAttributeType.Member);
						n.isCollection = EnumHelper.is(
							node.lt,
							LogicalAttributeType.Collection,
						);
						n.resultViewId =
							node.advancedData2.resultViewId ?? KnowItemNames.GUID_EMPTY;
					}
				}

				// console.log(cmp.node.name, cmp.mandatory, cmp.metadata.validation, cmp.node.advancedData2);
				// } else if (!n.metadata && !n.isDecorative) {
			} else if (!n.metadata) {
				// n.isDecorative = true;
				// console.error(`Attributo senza metadata ${data.data?.entityId} - ${node.attributeName}`);
			}

			// if (!n.isDecorative) {
			//   n.setValue(this.container.getField(node));
			//   this.container.registerComponent(node, cmp);
			// }

			response.push(n);
		}
	}

	// console.log(response);
	return response;
}
