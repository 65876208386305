export type DebugOption =
	| 'showMenu'
	| 'skipCache'
	| 'skipMenuCache'
	| 'showStatistics'
	| 'skipQuickFilterCache'
	| 'skipCategoryCache'
	| 'skipOrganizationUnitCache'
	| 'skipAggregateCache'
	| 'skipDashboardCache'
	| 'showLog'
	| 'showHelp'
	| 'skipGetPossibleUiCache'
	| 'reindex'
	| 'reindex-clean'
	| 'resynch'
	| 'resynch-all';

export function showDebugInstructions(): string {
	// return 'showMenu', 'skipCache', 'skipMenuCache', 'showStatistics',
	// 'skipQuickFilterCache', 'skipCategoryCache',
	// 'skipOrganizationUnitCache', 'skipAggregateCache', 'skipDashboardCache', 'showLog', 'showHelp'

	return (
		'showMenu, skipCache, skipMenuCache, showStatistics ' +
		' skipQuickFilterCache, skipCategoryCache  skipOrganizationUnitCache, ' +
		' skipAggregateCache, skipDashboardCache, showLog, showHelp, skipGetPossibleUi,reindex,reindex-clean,resynch,resynch-all'
	);
}

export class WebHelper {
	public static getQueryParam(name: string): string | null {
		const sp: URLSearchParams = new URLSearchParams(window.location.search);
		return sp.get(name);
	}
}
