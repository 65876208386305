import { ChangeDetectionStrategy, Component } from '@angular/core';
import { KTaskComponent } from '../ktask.component';

@Component({
	selector: 'app-ksearch-task',
	templateUrl: './ksearch-task.component.html',
	styleUrls: ['./ksearch-task.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KSearchTaskComponent extends KTaskComponent {}
