import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding } from '@angular/core';
import { loadMessages, locale } from 'devextreme/localization';
import idMessages from '../assets/it.json';
import { DevService } from './server/dev.service';
import { CacheService } from './shared/caching/cache-service';
import { AuthService } from './shared/services/auth.service';
import { GlobalEvent } from './shared/services/global/global-event';
import { GlobalEventType } from './shared/services/global/global-event-type';
import { GlobalTitleChangeArg } from './shared/services/global/global-title-change-arg';
import { GlobalService } from './shared/services/global/global.service';
import { ScreenService } from './shared/services/screen.service';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent {
	@HostBinding('class') get getClass() {
		return Object.keys(this.screen.sizes)
			.filter((cl) => this.screen.sizes[cl])
			.join(' ');
	}

	working: boolean = false;
	private authService: AuthService;
	private screen: ScreenService;
	public global: GlobalService;
	private cdr: ChangeDetectorRef;
	title: string = 'Benvenuti in Keystone';
	icon: string | null = null;
	isLoggedIn: boolean = false;
	cache: CacheService;
	dev: DevService;

	constructor(
		authService: AuthService,
		screen: ScreenService,
		global: GlobalService,
		cdr: ChangeDetectorRef,
		cache: CacheService,
		dev: DevService,
	) {
		this.cdr = cdr;
		this.authService = authService;
		this.screen = screen;
		this.global = global;
		this.cache = cache;
		this.isLoggedIn = this.global.isloggedIn;
		this.global.trigger.subscribe((e) => this.handleGlobalEvents(e));
		this.dev = dev;

		loadMessages(idMessages);
		locale('it');
		// console.log(navigator.language);

		this.dev.execute();
	}

	handleGlobalEvents(handleGlobalEvents: GlobalEvent) {
		switch (handleGlobalEvents.type) {
			case GlobalEventType.Login:
				this.isLoggedIn = handleGlobalEvents.arg?.success ?? false;
				break;

			case GlobalEventType.CleanCache:
				this.cache.reset().then((result: boolean) => {
					this.global.redirectToHome();
				});

				break;

			case GlobalEventType.ShowLoader:
				this.working = true;
				break;

			case GlobalEventType.HideLoader:
				this.working = false;
				break;

			case GlobalEventType.Logout:
				this.cache.clearTask().then((result: any) => {
					this.isLoggedIn = false;
					this.global.redirectToHome();
				});

				break;

			case GlobalEventType.TitleChange:
				if (handleGlobalEvents.arg instanceof GlobalTitleChangeArg) {
					this.title = handleGlobalEvents.arg.title;
					this.icon = handleGlobalEvents.arg.icon;

					this.cdr.detectChanges();
				}

				break;
		}
	}
}
