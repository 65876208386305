import { User } from '../user';

export class GlobalLoginArg {
	constructor(
		success: boolean,
		user: User | null,
		message: string | null,
		accessToken: string | null,
		isProxy: boolean,
	) {
		this.success = success;
		this.user = user;
		this.message = message;
		this.accessToken = accessToken;
		this.isProxy = isProxy;
	}

	message: string | null = null;
	success: boolean = false;
	user: User | null = null;
	accessToken: string | null = null;
	isProxy: boolean = false;
}
