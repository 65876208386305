import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { DxListComponent } from 'devextreme-angular/ui/list';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';
import { EntityService } from 'src/app/server/entity.service';
import { Helper } from 'src/app/server/helpers/helper';
import { AttributeMetadata } from 'src/app/server/model/attribute-metadata';
import { getDefaultOperator } from 'src/app/server/model/search-parameter-type';
import { KSearchComponent } from '../ksearch/ksearch.component';
import { LookUpEntry } from './../../../server/model/lookup-entry';
import { SearchParameter } from './../../../server/model/search-parameter';
import { ViewNode } from './../../../server/model/view-node';

@Component({
	selector: 'app-kfilters-editor',
	templateUrl: './kfilters-editor.component.html',
	styleUrls: ['./kfilters-editor.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KFiltersEditorComponent implements OnInit {
	parent: KSearchComponent;
	dataSource!: DataSource;
	customStore!: ArrayStore;
	initialSelection: string[] = [];
	menuItems: any[] = [];
	emptyItems: any[] = [];
	selected: SearchParameter | null = null;
	filters: SearchParameter[] = [];
	allFilters: ViewNode[] = [];
	entityId: string;
	queryId: string;
	userQueryId: string;
	service: EntityService;
	cdr: ChangeDetectorRef;
	@ViewChild(DxListComponent) list!: DxListComponent;
	attributes: AttributeMetadata[] = [];

	constructor(parent: KSearchComponent, service: EntityService, cdr: ChangeDetectorRef) {
		this.cdr = cdr;
		this.parent = parent;
		this.service = service;
		this.entityId = this.parent.data!.info!.id;
		this.queryId = this.parent.data!.query.id;
		this.userQueryId = this.parent.data!.query.userQueryId;
		this.attributes = Array.from(this.parent.data!.info.metadata.attributes.values());
	}

	ngOnInit() {
		this.emptyItems.push({
			text: 'Aggiungi',
			icon: 'add',
		});

		this.menuItems.push(
			{
				text: 'Aggiungi',
				icon: 'add',
				action: () => {
					console.log(this);
					this.addNewColumn();
				},
			},
			{
				text: 'Elimina',
				icon: 'remove',
				action: (arg: { itemData: SearchParameter }) => {
					this.removeColumn(arg.itemData.attributeName);
				},
			},
		);

		this.allFilters = this.parent.data?.info.queries[0].allFilters ?? [];
		this.filters = this.parent.data?.query.filters ?? [];

		this.filters.forEach((f: SearchParameter) => {
			const ff = this.allFilters.find((c) => c.attributeName === f.attributeName);

			if (ff) {
				f.label = ff.name;
			}
		});

		this.customStore = new ArrayStore({
			key: 'attributeName',
			data: this.filters,
		});

		this.dataSource = new DataSource({
			store: this.customStore,
		});

		this.refresh().then((success) => {
			this.selectByIndex(0);
			this.cdr.markForCheck();
		});
	}

	removeColumn(attributeName: string) {
		const toRemoveIndex = this.filters.findIndex((c) => c.attributeName === attributeName);

		if (toRemoveIndex >= 0) {
			this.filters.splice(toRemoveIndex, 1);
			this.refresh().then((success) => {
				this.selectByIndex(0);
				this.cdr.markForCheck();
			});
		}
	}

	addNewColumn() {
		const newSearchParameter = new SearchParameter(null);

		const currentFilters: string[] = this.filters.map((c) => c.attributeName);

		const notUsedFilter = this.allFilters.find((c) => currentFilters.indexOf(c.attributeName) < 0)!;

		if (!notUsedFilter) {
			this.parent.global.showInfo('Non ci sono ulteriori filtri utilizzabili');
			return;
		}

		const notUsedAttribute = this.attributes.find((c) => c.name === notUsedFilter.attributeName)!;

		newSearchParameter.attributeName = notUsedAttribute.name;
		newSearchParameter.label = notUsedAttribute.label;
		newSearchParameter.operator = getDefaultOperator(notUsedAttribute);
		newSearchParameter.type = notUsedAttribute.type;
		newSearchParameter.logicalType = notUsedAttribute.logicalType;
		newSearchParameter.staticValues = notUsedAttribute.values.map((d) => new LookUpEntry(d.id, d.name));

		this.filters.push(newSearchParameter);

		this.refresh().then((success) => {
			this.selectByIndex(this.filters.length - 1);
			this.cdr.markForCheck();
		});
	}

	async refresh(): Promise<boolean> {
		return this.dataSource.reload();
	}

	selectByIndex(toSelectIndex: number) {
		if (this.filters.length > toSelectIndex) {
			this.selected = this.filters[toSelectIndex];
			this.initialSelection = [this.selected.attributeName];
		}
	}

	onDragStart(e: any) {
		e.itemData = e.fromData[e.fromIndex];
	}

	onItemReordered(e: any) {
		Helper.arrayMove(this.filters, e.fromIndex, e.toIndex);
	}

	onAdd(e: any) {
		e.toData.splice(e.toIndex, 0, e.itemData);
	}

	onRemove(e: any) {
		e.fromData.splice(e.fromIndex, 1);
	}

	onSelectionChanged(e: { addedItems: SearchParameter[] }) {
		this.selected = e.addedItems[0];
	}
}
