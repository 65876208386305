import { ChangeDetectorRef, Component, HostBinding, OnInit } from '@angular/core';
import { ViewNode } from 'src/app/server/model/view-node';
import { FieldValue } from '../../../../server/model/field-value';
import { KFormComponent } from '../../kform/kform.component';

@Component({
	selector: 'app-kbase',
	template: '',
})
export class KBaseComponent implements OnInit {
	public container!: KFormComponent;

	protected cdr: ChangeDetectorRef;

	constructor(cdr: ChangeDetectorRef) {
		// this.container = container;
		this.cdr = cdr;
	}

	// hidden: boolean = false;
	node: ViewNode = new ViewNode('');
	@HostBinding('style.grid-column') gridColumn = 'span 1';
	@HostBinding('style.grid-row') gridRow = 'span 1';
	@HostBinding('style.padding') padding = '0.5em';
	// @HostBinding('style.display') display = 'none';
	readonly = false;
	mandatory = false;
	label: string | null = null;
	hint = '';
	placeHolder = '';
	description = '';
	isDecorative = false; // un controllo che non contiene un valore del holder

	ngOnInit(): void {
		this.handleControlSize();

		if (this.isDecorative) {
			this.label = this.node?.description ?? this.node?.name ?? '';
		} else {
			this.label = this.node?.name ?? '';
		}

		this.placeHolder = this.node?.description ?? '';
		this.description = this.node?.description ?? this.label;
		// this.display = this.hidden ? 'none' : 'block';
	}

	handleControlSize() {
		this.gridColumn = 'span ' + this.node?.colSpan ?? 1;
		this.gridRow = 'span ' + this.node?.rowSpan ?? 1;
		// console.log(this.node.name, this.gridColumn, this.gridRow);
	}

	buildOptions(optionId: number | null) {}

	onKeystoneInit() {}

	setValue(value: FieldValue | null): void {
		// let t: EntityAttributeValueType = EntityAttributeValueType.Text;
		// if ( this.metadata) {
		//   t =  this.metadata.type;
		// }
		// this.innerValue = ToKString(value, t, value?.format);
	}
}
