import { ChangeDetectionStrategy, Component } from '@angular/core';
import { date2ddmmyyyy } from 'src/app/shared/formatting/formatting-helper';
import { FilterEditorBaseComponent } from '../filter-editor-base.component';

@Component({
	selector: 'app-date-filter',
	templateUrl: './date-filter.component.html',
	styleUrls: ['./date-filter.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateFilterComponent extends FilterEditorBaseComponent {
	override ngOnInit(): void {
		if (!this.value) {
			this.value = date2ddmmyyyy(new Date());
		}
	}
}
