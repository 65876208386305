import { Cacheable } from '../../shared/caching/cacheable';
import { SearchColumnResult } from './search-column-result';
import { SearchParameter } from './search-parameter';
import { ViewNode } from './view-node';
import { ViewResultNode } from './view-result.node';

export class EntityQuery extends Cacheable {
	filterId = '';
	resultId = '';
	filters: SearchParameter[] = [];
	// columns: SearchColumnResult[] = [] ;
	orders: SearchColumnResult[] = [];
	entityId = '';
	override id = '';
	name = '';
	userQueryId = '';

	customColumns: ViewResultNode[] = [];
	allFilters: ViewNode[] = [];

	static parse(raw: any): EntityQuery | null {
		const output: EntityQuery = new EntityQuery();

		output._raw = raw;

		if (!raw) {
			return null;
		}

		output.filterId = raw.filterId;
		output.resultId = raw.resultId;

		if (raw.order) {
			output.orders = [SearchColumnResult.parse(raw.order)!];
		}

		output.entityId = raw.entityId;
		output.parentId = raw.entityId;
		output.id = raw.id;
		output.name = raw.name;
		output.userQueryId = raw.userQueryId;

		raw.filters?.forEach((element: any) => {
			output.filters.push(new SearchParameter(element));
		});

		raw.allFilters?.forEach((element: any) => {
			output.allFilters.push(new ViewNode(element));
		});

		raw.customColumns?.forEach((element: any) => {
			output.customColumns.push(ViewResultNode.parse(element));
		});

		return output;
	}
}
