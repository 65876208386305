import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, NgModule } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { DxFormModule } from 'devextreme-angular/ui/form';
import { DxLoadIndicatorModule } from 'devextreme-angular/ui/load-indicator';
import { GlobalService } from 'src/app/shared/services';
import { AuthService } from '../../services/auth.service';
import { GlobalEventType } from '../../services/global/global-event-type';
import { GlobalLoginArg } from '../../services/global/global-login-arg';

@Component({
	selector: 'app-login-form',
	templateUrl: './login-form.component.html',
	styleUrls: ['./login-form.component.scss'],
})
export class LoginFormComponent {
	loading = false;
	formData: any = {};
	private authService: AuthService;
	private router: Router;
	private global: GlobalService;
	private cdr: ChangeDetectorRef;

	constructor(authService: AuthService, router: Router, global: GlobalService, cdr: ChangeDetectorRef) {
		this.authService = authService;
		this.router = router;
		this.global = global;
		this.cdr = cdr;
	}

	async onSubmit(e: any) {
		e.preventDefault();
		const { email, password } = this.formData;
		this.loading = true;

		this.authService.logIn(email, password).subscribe((response) => {
			this.loading = false;
			this.cdr.detectChanges();

			this.global.on(
				GlobalEventType.Login,
				new GlobalLoginArg(
					response.success,
					response.user,
					response.message,
					response.accessToken,
					this.global.data.isProxy,
				),
			);
		});
	}

	onCreateAccountClick = () => {
		this.global.redirectToNewAccount();
	};
}
@NgModule({
	imports: [CommonModule, RouterModule, DxFormModule, DxLoadIndicatorModule],
	declarations: [LoginFormComponent],
	exports: [LoginFormComponent],
})
export class LoginFormModule {}
