export enum LogicalAttributeType {
	Id = 1,

	Owner = 2,

	Pointer = 4,

	Parent = 8,

	NamePart = 16,

	Index = 32,

	Status = 64,

	Value = 128,

	Member = 256,

	Nested = 512,

	Extension = 1024,

	VirtualType = 2048,

	Virtual = 4096,

	Category = 8192,

	Collection = 16384,

	Type = 32768,

	GlobalCategory = 65536,

	Extra = 131072,

	DefaultGroup = 262144,

	VirtualName = 524288,

	AutoBind = 1048576,

	Protocol = 2097152,

	IsModel = 4194304,

	CleanOnClone = 8388608,

	Profile = 16777216,

	Account = 33554432,

	Code = 67108864,

	InputParameter = 134217728,
}
