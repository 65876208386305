import { CommonModule } from '@angular/common';
import { Component, Input, NgModule } from '@angular/core';

import { DxContextMenuModule } from 'devextreme-angular/ui/context-menu';
import { DxListModule } from 'devextreme-angular/ui/list';
import { GlobalService } from '../../services';
import { User } from '../../services/user';

// import { User } from '../../services';

@Component({
	selector: 'app-user-panel',
	templateUrl: 'user-panel.component.html',
	styleUrls: ['./user-panel.component.scss'],
})
export class UserPanelComponent {
	@Input() menuItems: any;
	@Input() menuMode = 'shrink';
	@Input() user: User | null = null;

	avatar: string;
	constructor(global: GlobalService) {
		this.avatar = `url("${global.data.serverUrl}/profiles/${global.data.user?.accountId}.jpeg") no-repeat #fff`;
	}
}

@NgModule({
	imports: [DxListModule, DxContextMenuModule, CommonModule],
	declarations: [UserPanelComponent],
	exports: [UserPanelComponent],
})
export class UserPanelModule {}
