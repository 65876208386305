import { QuickFilter } from 'src/app/server/model/quick-filters-response';
import { SimpleEntityMetadata } from 'src/app/server/model/simple-entity-metadata';
import { Cacheable } from './cacheable';

export class EntitiesCache extends Cacheable {
	constructor(raw: any) {
		super();

		this.id = 'Shared';
		this.entities = raw;
		this._raw = JSON.stringify(raw);
	}

	entities: SimpleEntityMetadata[] = [];
}

export class GetPossibleUiCache extends Cacheable {
	constructor(entityId: string, raw: any) {
		super();

		this.id = entityId;
		this.filters = raw;
		this._raw = JSON.stringify(raw);
	}

	filters: QuickFilter[] = [];
}

export class QuickFiltersCache extends Cacheable {
	constructor(entityId: string, raw: any) {
		super();

		this.id = entityId;
		this.filters = raw;
		this._raw = JSON.stringify(raw);
	}

	filters: QuickFilter[] = [];
}
