<app-kcomponent-container [label]="label" [mandatory]="mandatory" [description]="description">
	<dx-html-editor [readOnly]="readonly" [height]="height" valueType="html" [value]="this.innerValue" css
		(valueChange)="onValueChange($event)" [title]="description">
		<dxo-toolbar [multiline]="false">
			<dxi-item name="undo"></dxi-item>
			<dxi-item name="redo"></dxi-item>
			<dxi-item name="separator"></dxi-item>
			<dxi-item name="size" [acceptedValues]="[
					'8pt',
					'10pt',
					'12pt',
					'14pt',
					'18pt',
					'24pt',
					'36pt'
				]"></dxi-item>
			<dxi-item name="font" [acceptedValues]="[
					'Arial',
					'Courier New',
					'Georgia',
					'Impact',
					'Lucida Console',
					'Tahoma',
					'Times New Roman',
					'Verdana'
				]"></dxi-item>
			<dxi-item name="separator"></dxi-item>
			<dxi-item name="bold"></dxi-item>
			<dxi-item name="italic"></dxi-item>
			<dxi-item name="strike"></dxi-item>
			<dxi-item name="underline"></dxi-item>
			<dxi-item name="separator"></dxi-item>
			<dxi-item name="alignLeft"></dxi-item>
			<dxi-item name="alignCenter"></dxi-item>
			<dxi-item name="alignRight"></dxi-item>
			<dxi-item name="alignJustify"></dxi-item>
			<dxi-item name="separator"></dxi-item>
			<dxi-item name="orderedList"></dxi-item>
			<dxi-item name="bulletList"></dxi-item>
			<dxi-item name="separator"></dxi-item>
			<dxi-item name="header" [acceptedValues]="[false, 1, 2, 3, 4, 5]"></dxi-item>
			<dxi-item name="separator"></dxi-item>
			<dxi-item name="color"></dxi-item>
			<dxi-item name="background"></dxi-item>
			<!-- <dxi-item name="separator"></dxi-item>
          <dxi-item name="link"></dxi-item>
          <dxi-item name="image"></dxi-item>
          <dxi-item name="separator"></dxi-item>
          <dxi-item name="clear"></dxi-item>
          <dxi-item name="codeBlock"></dxi-item>
          <dxi-item name="blockquote"></dxi-item>
          <dxi-item name="separator"></dxi-item>
          <dxi-item name="insertTable"></dxi-item>
          <dxi-item name="deleteTable"></dxi-item>
          <dxi-item name="insertRowAbove"></dxi-item>
          <dxi-item name="insertRowBelow"></dxi-item>
          <dxi-item name="deleteRow"></dxi-item>
          <dxi-item name="insertColumnLeft"></dxi-item>
          <dxi-item name="insertColumnRight"></dxi-item>
          <dxi-item name="deleteColumn"></dxi-item> -->
		</dxo-toolbar>
		<dxo-media-resizing [enabled]="true"> </dxo-media-resizing>
	</dx-html-editor>
</app-kcomponent-container>
