import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CacheService } from '../shared/caching/cache-service';
import { ApiService } from './api.service';
import { ApiResponse } from './model/api.response';

@Injectable({
	providedIn: 'root',
})
export class ApiServiceBase {
	protected api: ApiService;
	protected cache: CacheService;
	private showStatistics = false;

	l(data: any) {
		if (this.showStatistics) {
			console.log(data);
		}
	}

	constructor(api: ApiService, cache: CacheService) {
		this.api = api;
		this.cache = cache;
	}

	post<T extends ApiResponse>(url: string, request: any): Observable<T> {
		return this.api.post<T>(this.api.getUrl(url), request).pipe(
			map(
				(d) => {
					this.l(d);
					return d as T;
				},
				catchError((e: any) => {
					console.error(e);
					return of(null);
				}),
			),
		);
	}
}
