import { EntityHolder } from 'src/app/server/model/entity-holder';
import { Command } from './../../../../server/model/commad';
import { Task } from './../../../manager/ktaskbar/task';
export class KCommandPayload {
	task: Task;
	command: Command;
	holders: EntityHolder[];
	constructor(task: Task, command: Command, holders: EntityHolder[]) {
		this.task = task;
		this.command = command;
		this.holders = holders;
	}
}
