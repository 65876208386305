import { isArray } from 'rxjs/internal-compatibility';
import { LogicalAttributeType } from 'src/app/server/model/logical-attribute-type';
import { EnumHelper } from '../helpers/enum-helper';
import { LogicalHelper } from '../helpers/logical-helper';
import { EntityMetadata } from './entity-metadata';
import { FieldValue } from './field-value';
import { IdName } from './id-name';

export class EntityHolder {
	instanceId = '';
	humanReadableName = '';
	isDraft = false;
	isNew = false;
	fields: FieldValue[] = [];
	history?: FieldValue[] = [];
	invalids?: string[] = [];
	id = '';
	name = '';
	// raw: any;
	entityId = '';
	parentEntityId: string | null = null;
	// parentId: string;
	moduleId = '';
	parentId: string | null = null;
	metadata?: EntityMetadata;
	nameFormat = '';
	private entities: string[] = [];
	// index:number|null = null;
	// categoryIndex:number|null = null;

	static fromScratch(
		entityId: string,
		humanReadable: string,
		id: string,
		parentEntityId: string | null = null,
	): EntityHolder {
		const o = new EntityHolder();
		o.entityId = entityId;
		o.parentEntityId = parentEntityId;
		o.name = humanReadable;
		o.humanReadableName = humanReadable;
		o.id = id;
		o.instanceId = id;
		return o;
	}

	static fromIdName(listEntry: IdName): EntityHolder {
		const o = new EntityHolder();
		// o.id = listEntry.id;
		// o.name = listEntry.name;
		o.id = listEntry.id;
		o.instanceId = listEntry.id;
		o.humanReadableName = listEntry.name;
		return o;
	}

	static parse(raw: any, owner: EntityHolder | null = null, isMutable: boolean = false): EntityHolder | null {
		const e = new EntityHolder();
		if (raw === undefined || raw === null) {
			return null;
		}

		if (raw.entityId === 'ntt_survey_question' && raw.instanceId === '06ffd1c8-b4ac-424f-9aef-af1a008769d7') {
			console.log(raw.fields.filter((d: FieldValue) => d.name === 'Survey'));
		}

		e.entityId = raw.entityId;
		e.parentEntityId = raw.parentEntityId;
		e.parentId = raw.parentId;
		e.instanceId = raw.instanceId;
		e.humanReadableName = raw.humanReadableName;
		e.isDraft = raw.isDraft;
		e.isNew = raw.isNew;
		e.nameFormat = raw.nameFormat;
		e.id = e.instanceId;
		e.name = e.humanReadableName;
		// e.raw = raw;
		e.entities.push(e.entityId);
		e.moduleId = EntityHolder.getModuleId(e.entityId);

		// const a = {};

		raw?.fields?.forEach((rawField: any) => {
			const f = Object.assign(new FieldValue(), rawField);

			if (f.value instanceof Array) {
				const values: EntityHolder[] = [];

				f.value.forEach((item: any) => {
					const ntt = EntityHolder.parse(item, e);

					if (ntt != null) {
						values.push(ntt);
					}

					if (e.entities.indexOf(item.entityId) === -1) {
						e.entities.push(item.entityId);
					}
				});

				f.value = values;
			} else if (f.value instanceof Object) {
				f.value = EntityHolder.parse(f.value, e);

				if (e.entities.indexOf(f.value.entityId) === -1) {
					e.entities.push(f.value.entityId);
				}
			}

			// if ( EnumHelper.is(f.logicalType, LogicalAttributeType.GlobalCategory)) {

			//   e.parentId = f.value?.instanceId;

			// }

			if (owner != null && EnumHelper.is(f.logicalType, LogicalAttributeType.Owner)) {
				if (f.entityId === owner.entityId) {
					f.value = owner?.id;

					if (e.entities.indexOf(owner.entityId) === -1) {
						e.entities.push(owner.entityId);
					}
				}
			}

			e.fields.push(f);

			// a[f.name] = f.value;
		});

		return e;
	}

	static getModuleId(entityId: string): string {
		return 'mdl_' + entityId.split('_')[1];
	}

	public getMemberTypes(): string[] {
		return this.entities;
	}

	getFirstByType(type: LogicalAttributeType): FieldValue | null {
		return this.fields.find((f) => EnumHelper.is(f.logicalType, type)) ?? null;
	}

	getFirstCollectionByType(entityId: string): FieldValue | null {
		return (
			this.fields.find(
				(f) => f.entityId === entityId && EnumHelper.is(f.logicalType, LogicalAttributeType.Collection),
			) ?? null
		);
	}

	#isModel: boolean | null = null;
	get isModel(): boolean {
		if (!this.#isModel) {
			this.#isModel = this.getFirstByType(LogicalAttributeType.IsModel) != null;
		}

		return this.#isModel;
	}

	_index: number | null = null;
	_categoryIndex: number | null = null;

	get index(): number | null {
		if (!this._index) {
			const indexField = this.getFirstByType(LogicalAttributeType.Index);

			if (indexField) {
				this._index = indexField.value;
			}
		}

		return this._index;
	}

	get categoryIndex(): number | null {
		if (!this._categoryIndex) {
			const categoryField = this.getFirstByType(LogicalAttributeType.Category)?.value as EntityHolder;

			if (categoryField) {
				this._categoryIndex = categoryField.index;
				// console.log(this._categoryIndex);
			}
		}

		return this._categoryIndex;
	}

	prepareForSave(): EntityHolder {
		const dolly = this.clone();

		delete dolly.invalids;
		delete dolly.history;
		delete dolly.metadata;

		dolly.fields.forEach((field: FieldValue) => {
			delete field.metadata;

			if (field.value instanceof EntityHolder) {
				field.value = field.value.prepareForSave();
			} else if (field.value instanceof Array) {
				const newCOllection: any[] = [];
				field.value.forEach((element2) => {
					newCOllection.push(element2.prepareForSave());
				});
				field.value = newCOllection;
			}
		});

		return dolly;
	}

	clone(): EntityHolder {
		const dolly = Object.assign(new EntityHolder(), this);

		dolly.history = [];

		// delete dolly.metadata;
		// delete dolly.raw;

		const dest: FieldValue[] = [];

		dolly.fields.forEach((f) => {
			const g = this.cloneFieldValue(f);
			if (g) {
				dest.push(g);
			}
		});

		dolly.fields = dest;

		return dolly;
	}

	private cloneFieldValue(f: FieldValue): FieldValue | null {
		if (f == null) {
			return null;
		}

		const m = Object.assign(new FieldValue(), f);
		// console.log(m);
		m.value = this.cloneValue(m.value);
		return m;
	}

	private cloneValue(f: any): any {
		if (f == null) {
			return null;
		}

		if (Array.isArray(f)) {
			const dest: EntityHolder[] = [];

			f.forEach((item2: EntityHolder) => {
				//console.log(item2);
				dest.push(item2.clone());
			});

			f = dest;
		} else if (f instanceof EntityHolder) {
			f = f.clone();
		}

		return f;
	}

	getStatusOrDefault(): number | null {
		const s = this.getField('Status', false);
		const sv = s?.value;

		if (this.isNew) return -1;
		// se c'è un valore è il valore
		if (sv != null) return sv;
		// se non c'è è un generico 0
		return 0;
	}

	// siamo sicuri?
	isCollection(attributeName: string): boolean {
		return Array.isArray(this.getValue(attributeName));
	}

	replaceInCollection(attributeName: string, items: EntityHolder | EntityHolder[]): EntityHolder[] | null {
		if (!Array.isArray(items)) {
			items = [items];
		}

		let source = this.cloneValue(this.getValue(attributeName)) as EntityHolder[];

		if (!source) {
			source = [];
		}

		if (source) {
			return EntityHolder.replaceInCollection(items, source);
		}

		return null;
	}

	public static replaceInCollection(items: EntityHolder[], sources: EntityHolder[]): EntityHolder[] {
		// console.log('replace');

		items.forEach((item) => {
			const f = sources.filter((c) => c.instanceId === item.instanceId)[0];

			if (f) {
				const i = sources.indexOf(f);
				sources.splice(i, 1, item);
			} else {
				const index = item.getFirstByType(LogicalAttributeType.Index);
				if (index) {
					let newIndex = 0;
					const categoryIndex = item.categoryIndex;

					if (categoryIndex) {
						const inCategoryItems = sources.filter((c) => c.categoryIndex === categoryIndex);

						if (inCategoryItems.length > 0) {
							newIndex = (inCategoryItems[inCategoryItems.length - 1].index ?? 0) + 1;
						}
					} else {
						if (sources.length > 0) {
							newIndex = (sources[sources.length - 1].index ?? 0) + 1;
						}
					}

					item.setField(index.name, newIndex, true);
				}

				sources.push(item);
			}
		});

		return sources;
	}

	removeInCollection(
		attributeName: string,
		holderOrHolders: EntityHolder | EntityHolder[],
		recursive: boolean,
	): EntityHolder[] {
		const current = this.getField(attributeName);

		if (!current) return [];

		const entities = this.cloneFieldValue(current)?.value as EntityHolder[];

		const holders: EntityHolder[] = isArray(holderOrHolders) ? holderOrHolders : [holderOrHolders];

		holders.forEach((holder) => {
			const toRemoveIndex = entities.findIndex((c) => c.instanceId === holder?.instanceId);

			if (toRemoveIndex >= 0) {
				entities.splice(toRemoveIndex, 1);
			}

			if (recursive && holder) {
				const p = LogicalHelper.getAllByType(holder.fields, [LogicalAttributeType.Parent], []);

				if (p.length > 0) {
					this.deleteByParent(entities, p[0].name, holder.instanceId);
				}
			}
		});

		return entities;
	}

	deleteByParent(entities: EntityHolder[], parentAttribute: string, parentId: string | null) {
		const toRemove: EntityHolder[] = [];

		entities.forEach((holder: EntityHolder) => {
			const p = holder.getField(parentAttribute)?.value as EntityHolder | null;

			if (p?.instanceId === parentId) {
				toRemove.push(holder);
			}
		});

		toRemove.forEach((holder: EntityHolder) => {
			const i = entities.findIndex((c) => c.instanceId === holder.instanceId);
			if (i >= 0) {
				entities.splice(i, 1);
				// console.log('removed', holder.humanReadableName);
			}
		});
	}

	private getValue(attributeName: string): any | null {
		return this.fields.find((c) => c.name === attributeName)?.value;
	}

	getField(attributeName: string, throwError: boolean = true): FieldValue | null {
		const v = this.fields.find((c) => c.name === attributeName);

		if (v != null) {
			return v;
		} else {
			if (throwError) {
				console.error(`Not found:  ${attributeName}, ${this.entityId}, ${this.fields.length}`);
			}
		}

		return null;
	}

	undo(): FieldValue | null {
		if (this.history?.length === 0) {
			return null;
		}

		const lastFieldChanged = this.history?.pop();

		if (lastFieldChanged) {
			this.setField(lastFieldChanged.name, lastFieldChanged.value, false);
			return lastFieldChanged;
		}

		return null;
	}

	printOutHistory(): void {
		this.history?.forEach((h) => {});
	}

	pushToHistoryIfNotExist(value: FieldValue): void {
		if (!this.history?.find((c) => c.name === value.name)) {
			this.history?.push(value);
		}
	}

	setField(attributeName: string, newValue: any, updateHistory: boolean = true): boolean {
		// getting the current value of the field
		const f = this.fields.find((c) => c.name === attributeName);
		if (f == null) {
			return false;
		}

		// move the current value to the history if need
		if (updateHistory && this.shouldGoInHistory(attributeName, newValue, f.value)) {
			this.history?.push(f);
			// console.log(this.history);
		}

		const f1 = this.cloneFieldValue(f);

		if (f1) {
			f1.value = newValue;

			const i = this.fields.indexOf(f);
			this.fields.splice(i, 1, f1);

			// console.log(f1.logicalType);
			if (
				EnumHelper.is(f1.logicalType, LogicalAttributeType.Index) ||
				EnumHelper.is(f1.logicalType, LogicalAttributeType.Category)
			) {
				this._index = null;
				this._categoryIndex = null;
				console.log('reset index for ' + f1.name);
			}

			if (EnumHelper.is(f1.logicalType, LogicalAttributeType.NamePart)) {
				this.recalculateHumanReadable();
			}
		}

		return true;
	}

	isArrayChanged(newValue: any, currentValue: any) {
		if (isArray(newValue) && isArray(currentValue)) {
			if (newValue!.length !== currentValue!.length) {
				return true;
			}

			let changed: boolean = false;
			for (let index = 0; index < newValue.length; index++) {
				const newElement: EntityHolder = newValue[index];
				const currentElement: EntityHolder = currentValue[index];

				for (let index2 = 0; newElement.fields.length > index2; index2++) {
					const newElementProp = newElement.fields[index2];
					const currentElementProp = currentElement.fields[index2];
					console.log(newElementProp.name, newElementProp.value, currentElementProp.value);
					if (newElementProp.value !== currentElementProp.value) {
						changed = true;
					}
				}
			}

			if (changed) {
				console.log('changed');
				return true;
			}
		}

		return false;
	}

	shouldGoInHistory(attributeName: string, newValue: any, currentValue: any): boolean {
		// const isArrayChanged = this.isArrayChanged(newValue, currentValue);
		// if (isArrayChanged) return true;

		if (isArray(newValue)) {
			for (let index = 0; index < newValue.length; index++) {
				const element: EntityHolder = newValue[index];
				if ((element.history?.length ?? 0) > 0) {
					return true;
				}
			}
		}

		if (newValue === currentValue || !this.history) {
			return false;
		}

		if (this.history.length > 0 && this.history[this.history.length - 1].name === attributeName) {
			return false;
		}

		return true;
	}

	isChanged(attributeName: string): boolean {
		return this.history?.find((c) => c.name === attributeName) != null;
	}

	// toReadble(): object {

	//   const o = {};

	//   this.fields.forEach(f => {

	//     if ( f.value instanceof EntityHolder){
	//       o[f.name] = f.value.entityId + '' + f.value.instanceId;
	//     }else    if ( f.value instanceof Array){

	//       f.value.forEach(f1 => {
	//         o[f.name] = f1.value.entityId + '' + f1.value.instanceId;
	//       });

	//     }
	//     else {
	//       o[f.name] = f.value;
	//   }
	//   });

	//   return o;

	// }

	recalculateHumanReadable(): void {
		const nameParts: any[] = [];

		this.fields.forEach((f) => {
			if (EnumHelper.is(f.logicalType, LogicalAttributeType.NamePart)) {
				nameParts.push(f.value ?? '');
			}
		});

		let n = this.nameFormat;

		if (n != null) {
			for (let i = 0; i < nameParts.length; i++) {
				let n2: string = nameParts[i] ?? '';

				if (nameParts[i].humanReadableName !== undefined) {
					n2 = nameParts[i].humanReadableName;
				} else {
					n2 = nameParts[i];
				}

				n = n.replace('{' + i + '}', n2);
			}

			this.humanReadableName = n;
		}
	}

	toReadable(): any {
		const a: any = {
			humanReadableName: this.humanReadableName,
			instanceId: this.instanceId,
		};

		this.fields.forEach((f) => {
			if (f.value instanceof EntityHolder) {
				a[f.name] = f.value.toReadable();
			} else {
				a[f.name] = f.value;
			}
		});

		return a;
	}
}

export function SortHolders(holders: EntityHolder[]) {
	return holders.sort((a, b) => {
		const ai = (a.categoryIndex ?? 0) * 1000 + (a.index ?? 0);
		const bi = (b.categoryIndex ?? 0) * 1000 + (b.index ?? 0);
		if (ai < bi) return -1;
		return 0;
	});
}
