import { ChangeDetectionStrategy, Component, forwardRef, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { EntityHolder } from 'src/app/server/model/entity-holder';
import { KCommandPayload } from '../../edit/kcomponents/kbase/kcommand-payload';
import { KPrintPayload } from '../../edit/kcomponents/kbase/krelated-payload';
import { KToolbarPayload } from '../../edit/ktoolbar/ktoolbar-payload';
import { KTaskComponent } from '../../manager/ktask/ktask.component';
import { KSearchTaskComponent } from '../../manager/ktask/search/ksearch-task.component';
import { KTaskBarEventType } from '../../manager/ktaskbar/ktaskbar-event';
import { createDeleteButton } from '../../shared/delete -button';
import { createNewButton } from '../../shared/new-button';
import { KSearchCompontentBase } from '../ksearch/ksearch-base.component';
import { KSearchCompletePayload } from '../ksearch/ksearch-complete-payload';
import { EntityInfo } from './../../../server/model/entity-info';
import { KSearchSelectionChangePayload } from './../ksearch/ksearch-complete-payload';

@Component({
	selector: 'app-ksearch-toolbar',
	templateUrl: './ksearch-toolbar.component.html',
	styleUrls: ['./ksearch-toolbar.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: KTaskComponent,
			useExisting: forwardRef(() => KSearchTaskComponent),
		},
	],
})
export class KSearchToolbarComponent extends KSearchCompontentBase implements OnInit, OnDestroy {
	closeButtonOptions: any;
	override subscription: Subscription | null = null;
	isNew = false;
	isChanged = false;

	newButtonOptions: any;
	cloneButtonOptions: any;

	selectButtonOptions: any;
	toolsMenuOptions: any;
	selectedRows: EntityHolder[] = [];
	statusBeforeOptions: any;
	statusAfterOptions: any;
	deleteButtonOptions: any;
	currentStatusOptions: any;
	queriesOptions: any;
	columnEditorButtonOptions: any;
	filterEditorButtonOptions: any;
	canBeGeneratedByModel: boolean = false;
	isMultiInheritance: boolean = false;

	override onSearchReady(payload: KSearchCompletePayload): void {
		this.isNew = this.task.instanceId == null;
		this.createMenuItems(payload.data.info);
		this.cdr.markForCheck();
	}

	createMenuItems(entityInfo: EntityInfo): void {
		this.canBeGeneratedByModel = entityInfo.canBeGeneratedByModel;
		this.isMultiInheritance = entityInfo.isMultiInheritance;

		this.newButtonOptions = createNewButton(
			this.manager,
			this.task.entityId,
			this.canBeGeneratedByModel,
			this.isMultiInheritance,
			entityInfo.parentId,
		);

		this.cloneButtonOptions = {
			icon: 'copy',
			stylingMode: 'contained',
			onClick: () => {
				this.manager.on(KTaskBarEventType.FormClone, new KToolbarPayload('clone', this.task, this.selectedRows));
			},
		};

		this.selectButtonOptions = {
			icon: 'check',
			onClick: () => {
				this.manager.on(KTaskBarEventType.SearchSelect, new KToolbarPayload('select', this.task));
			},
		};

		this.deleteButtonOptions = createDeleteButton(this.manager, this);

		this.closeButtonOptions = {
			icon: 'export',
			// type: 'success',
			onClick: () => {
				this.manager.on(KTaskBarEventType.SearchClose, new KToolbarPayload('close', this.task));
			},
		};

		this.queriesOptions = {
			displayExpr: 'name',
			showFirstSubmenuMode: 'onHover',
			onItemClick: (e: any) => {
				e.event.preventDefault();
				e.event.stopPropagation();
				this.manager.on(KTaskBarEventType.QuerySelect, new KToolbarPayload('query', this.task, [], e.itemData));
			},
			dataSource: [
				{
					visible: (entityInfo.queries.length ?? 0) > 0,
					icon: 'find',
					items: entityInfo.queries.map((item) => {
						return {
							icon: 'find',
							...item,
						};
					}),
				},
			],
		};

		this.columnEditorButtonOptions = {
			hint: 'Configurazione colonne',
			icon: 'columnchooser',
			onClick: () => {
				this.manager.on(KTaskBarEventType.ShowColumnEditor, new KToolbarPayload('select', this.task));
			},
		};

		this.filterEditorButtonOptions = {
			icon: 'filter',
			hint: 'Configurazione filtri',
			onClick: () => {
				this.manager.on(KTaskBarEventType.ShowFilterEditor, new KToolbarPayload('select', this.task));
			},
		};

		this.toolsMenuOptions = {
			displayExpr: 'name',
			showFirstSubmenuMode: 'onHover',
			onItemClick: (e: any) => {
				e.event.preventDefault();
				e.event.stopPropagation();
				if (e.itemData.isDefault !== undefined) {
					this.manager.on(KTaskBarEventType.ShowPrint, new KPrintPayload(this.task, e.itemData, this.selectedRows));
				} else if (e.itemData.id) {
					this.manager.on(
						KTaskBarEventType.ExecuteCommand,
						new KCommandPayload(this.task, e.itemData, this.selectedRows),
					);
				}
			},
			dataSource: [
				{
					visible: (entityInfo.prints.length ?? 0) > 0,
					icon: 'print',
					items: entityInfo.prints.map((item) => {
						return {
							icon: 'print',
							...item,
						};
					}),
				},
				{
					visible: (entityInfo.commands.filter((c) => c.availableInSearch).length ?? 0) > 0,
					icon: 'icotbar icot-tb_action',
					items: entityInfo.commands
						.filter((c) => c.availableInSearch)
						.map((item) => {
							return {
								icon: 'icotbar icot-tb_action',
								...item,
							};
						}),
				},
			],
		};
	}

	override ngOnDestroy(): void {
		this.subscription?.unsubscribe();
	}

	override onSelectionChanged(payload: KSearchSelectionChangePayload) {
		this.selectedRows = payload.selectedRows;
		this.cdr.markForCheck();
	}
}
