import { OrderVersusEnum } from 'src/app/server/model/order-versus-enum';
import { SearchColumnResult } from 'src/app/server/model/search-column-result';
import { ViewResultNode } from 'src/app/server/model/view-result.node';
import { DatagridColumn } from 'src/app/ui/controls/datagrid/datagrid-column';
import { KSearchDialogClosePayload } from 'src/app/ui/shared/ksearch-dialog/ksearch-dialog-close-payload';
import { EntityHolder } from './../../../../server/model/entity-holder';

export function createColumns(
	columns: ViewResultNode[],
	orders: SearchColumnResult[],
): DatagridColumn[] {
	const outColumns: DatagridColumn[] = [];
	columns.forEach((column) => {
		const c = DatagridColumn.parse(column);
		const i = orders.findIndex((d) => d.attributeName === c.field);
		if (i >= 0) {
			c.sortIndex = i;
			c.sortOrder = orders[i].versus === OrderVersusEnum.Desc ? 'desc' : 'asc';
		}

		outColumns.push(c);
	});

	return outColumns;
}

export function itemIsPresent(
	currentItems: EntityHolder[],
	attributeName: string,
	newEntries: EntityHolder[],
): EntityHolder[] {
	const duplicates: EntityHolder[] = [];

	if (currentItems?.length >= 0) {
		newEntries.forEach((e) => {
			currentItems.forEach((g) => {
				const type = g.getField(attributeName)?.value as EntityHolder;

				if (type) {
					if (type.instanceId === e.instanceId) {
						duplicates.push(type);
					}
				}
			});
		});

		// console.log(duplicates);
	}

	return duplicates;
}

export function handleDuplicateError(
	isMultiselect: boolean,
	allowMultiMainType: boolean,
	currentItems: EntityHolder[],
	payload: KSearchDialogClosePayload,
): string[] {
	const errors: string[] = [];

	if (isMultiselect && !allowMultiMainType) {
		const duplicates = itemIsPresent(
			currentItems,
			payload.reference,
			payload.entities,
		);

		if (duplicates.length > 0) {
			duplicates.forEach((e) => {
				errors.push(`'${e.humanReadableName}' già presente in elenco`);
			});
		}
	}

	return errors;
}
