<div class="container">
	<dx-data-grid id="gridContainer" [dataSource]="dataSource" [remoteOperations]="false" [allowColumnReordering]="false"
		[rowAlternationEnabled]="true" [allowColumnResizing]="true" [showBorders]="true" columnResizingMode="widget"
		[focusedRowEnabled]="false" [(focusedRowKey)]="focusedRowKey" [keyExpr]="'id'" (onCellDblClick)="onEdit($event)">
		<dxo-paging [pageSize]="10"></dxo-paging>
		<dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50, 100]"></dxo-pager>
		<dxo-search-panel [visible]="false" [highlightCaseSensitive]="true"></dxo-search-panel>
		<dxo-group-panel [visible]="false"></dxo-group-panel>
		<dxo-grouping [autoExpandAll]="false"></dxo-grouping>

		<dxi-column dataField="occurredAt" dataType="datetime" caption="Data" format="shortDate" width="20%"></dxi-column>
		<dxi-column dataField="requestName" caption="Richiesta" width="20%"></dxi-column>
		<dxi-column dataField="processTypeName" caption="Processo" width="20%"></dxi-column>
		<dxi-column dataField="activityName" caption="Attività" width="20%"></dxi-column>
		<dxi-column dataField="actionName" caption="Azione" width="20%"></dxi-column>
	</dx-data-grid>
</div>

<ng-container appHost></ng-container>
