<div class="container">
	<dx-data-grid
		id="gridContainer"
		[dataSource]="dataSource"
		[remoteOperations]="false"
		[allowColumnReordering]="true"
		[rowAlternationEnabled]="true"
		[allowColumnResizing]="true"
		[showBorders]="true"
		columnResizingMode="widget"
		[focusedRowEnabled]="true"
		[(focusedRowKey)]="focusedRowKey"
		[keyExpr]="'id'"
		(onRowRemoved)="onRowRemoved($event)"
		(onCellDblClick)="onEdit($event)"
		(onToolbarPreparing)="onToolbarPreparing($event)"
	>
		<dxo-paging [pageSize]="10"></dxo-paging>
		<dxo-pager
			[showPageSizeSelector]="true"
			[allowedPageSizes]="[10, 25, 50, 100]"
		></dxo-pager>
		<dxo-search-panel
			[visible]="true"
			[highlightCaseSensitive]="true"
		></dxo-search-panel>
		<dxo-group-panel [visible]="true"></dxo-group-panel>
		<dxo-grouping [autoExpandAll]="false"></dxo-grouping>

		<!-- <dxo-selection
      selectAllMode="allPages"
      showCheckBoxesMode="onClick"
      mode="multiple"
      [deferred]="false"
    ></dxo-selection> -->

		<dxo-editing
			mode="row"
			[allowUpdating]="false"
			[allowDeleting]="true"
			[allowAdding]="false"
			[useIcons]="true"
		>
			<!-- <dxo-popup
        title="Allegato"
        [showTitle]="true"
        [width]="700"
        [height]="525"
      >
      </dxo-popup>
      <dxo-form [colCount]="1">
        <dxi-item
          dataField="memberTypeId"
          editorType="dxSelectBox"
          [editorOptions]="{}"
        >
        </dxi-item>

        <dxi-item
          dataField="memberId"
          editorType="dxSelectBox"
          [editorOptions]="{}"
        >
        </dxi-item>

        <dxi-item
          dataField="includeReversible"
          editorType="dxCheckBox"
          [editorOptions]="{}"
        >
        </dxi-item>

        <dxi-item
          dataField="type"
          editorType="dxSelectBox"
          [editorOptions]="{}"
        >
        </dxi-item>
      </dxo-form> -->
		</dxo-editing>

		<dxi-column
			dataField="targetTypeName"
			caption="Tipologia"
			width="20%"
		></dxi-column>
		<dxi-column dataField="type" caption="Modalità" width="20%"></dxi-column>
		<dxi-column dataField="name" caption="Descrizione" width="60%"></dxi-column>
	</dx-data-grid>
</div>
