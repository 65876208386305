import { Type } from '@angular/core';
import { KWorkflowViewComponent } from 'src/app/modules/workflow/ui/components/kworkflow-view/kworkflow-view.component';
import { ViewItemUiControl } from 'src/app/server/model/view-item-ui-control';
import { ViewNode } from 'src/app/server/model/view-node';
import { KAggregateTreeComponent } from './kaggregate-tree/kaggregate-tree.component';
import { KOrderedTreeComponent } from './kaggregate-tree/kordered-tree.component';
import { KOrderedTreeComponentRelated } from './kaggregate-tree/kordered-tree.component-related';
import { KBarcodeComponent } from './kbarcode/kbarcode.component';
import { KBaseComponent } from './kbase/kbase.component';
import { KCategoryComponent } from './kcategory/kcategory.component';
import { KCheckBoxComponent } from './kcheck-box/kcheck-box.component';
import { KColorBoxComponent } from './kcolor-box/kcolor-box.component';
import { KComboboxComponent } from './kcombobox/kcombobox.component';
import { KDateboxComponent } from './kdatebox/kdatebox.component';
import { KEmptyComponent } from './kempty/kempty.component';
import { KFileManagerComponent } from './kfile-manager/kfile-manager.component';
import { KFormViewComponent } from './kform-view/kform-view.component';
import { KGridComponent } from './kgrid/kgrid.component';
import { KGroupableGridComponent } from './kgroupable-grid/kgroupable-grid.component';
import { KHtmlEditorComponent } from './khtml-editor/khtml-editor.component';
import { KHtmlViewerComponent } from './khtml-viewer/khtml-viewer.component';
import { KImageComponent } from './kimage/kimage.component';
import { KMasterDetailComponent } from './kmaster-detail/kmaster-detail.component';
import { KNumboxComponent } from './knumbox/knumbox.component';
import { KPictureListComponent } from './kpicture-list/kpicture-list.component';
import { KProgressBoxComponent } from './kprogress-box/kprogress-box.component';
import { KQueryComponent } from './kquery/kquery.component';
import { KLinkComponent } from './ksearch-panel/klink.component';
import { KSearchPanelComponent } from './ksearch-panel/ksearch-panel.component';
import { KSeparatorComponent } from './kseparator/kseparator.component';
import { KTextAreaComponent } from './ktext-area/ktext-area.component';
import { KTextComponent } from './ktext/ktext.component';
import { KTextboxComponent } from './ktextbox/ktextbox.component';

// https://medium.com/@ckyidr9/lazy-load-feature-modules-without-routing-in-angular-9-ivy-220851cc7751

export class ControlsFactory {
	static getComponent(node: ViewNode): Type<KBaseComponent> | Promise<any> {
		// return KEmptyComponent;

		switch (node.controlType) {
			case ViewItemUiControl.MoneyBox:
			case ViewItemUiControl.IntBox:
			case ViewItemUiControl.FloatBox:
			case ViewItemUiControl.FloatBox4:
				return KNumboxComponent;

			case ViewItemUiControl.Text:
				return KTextComponent;
			case ViewItemUiControl.Label:
			case ViewItemUiControl.TextBox:
			case ViewItemUiControl.TimeBox:
			case ViewItemUiControl.TimeSpanBox:
			case ViewItemUiControl.PasswordBox:

			case ViewItemUiControl.VersionId:
			case ViewItemUiControl.VersionLabel:
				return KTextboxComponent;

			case ViewItemUiControl.TextArea:
				return KTextAreaComponent;

			case ViewItemUiControl.MasterDetail:
				return KMasterDetailComponent;

			case ViewItemUiControl.Image:
				return KImageComponent;
			case ViewItemUiControl.PictureList:
				return KPictureListComponent;
			case ViewItemUiControl.ComboBox:
				// search box diventano auto
				// if ( node.refEntityId != null ) {

				//   return AutocompleteComponent;
				// }
				// else if ( ! node.attribute.isFlag ) {
				return KComboboxComponent;
			// }
			// else {
			//   // i flag gli facciamo un componente con badge
			//   return NotImplementedComponent;
			// }

			case ViewItemUiControl.HtmlViewer:
				return KHtmlViewerComponent;
			case ViewItemUiControl.RichTextEditor:
				return KHtmlEditorComponent;

			case ViewItemUiControl.DateBox:
				return KDateboxComponent;
			case ViewItemUiControl.SearchPanel:
				return KSearchPanelComponent;

			case ViewItemUiControl.CheckBox:
				return KCheckBoxComponent;

			case ViewItemUiControl.Separator:
				return KSeparatorComponent;

			case ViewItemUiControl.Categories:
				return KCategoryComponent;

			case ViewItemUiControl.GridInline:
			case ViewItemUiControl.GridPopUp:
				return KGridComponent;
			case ViewItemUiControl.GroupableGrid:
				return KGroupableGridComponent;

			case ViewItemUiControl.AggregateTree:
				return KAggregateTreeComponent;

			case ViewItemUiControl.OrderedTree:
				return KOrderedTreeComponent;
			case ViewItemUiControl.OrderedTreeRelated:
				return KOrderedTreeComponentRelated;

			case ViewItemUiControl.Query:
				return KQueryComponent;
			case ViewItemUiControl.Link:
				return KLinkComponent;
			case ViewItemUiControl.Form:
				return KFormViewComponent;

			case ViewItemUiControl.BarCode:
				return KBarcodeComponent;

			case ViewItemUiControl.WorkflowView:
				return KWorkflowViewComponent; // TODO: bisona metere un AttributeBaseControl che dato xml visualizza diagramma

			case ViewItemUiControl.ProgressBox:
				return KProgressBoxComponent;

			case ViewItemUiControl.FileManager:
				return KFileManagerComponent;

			case ViewItemUiControl.Dashboard:
				return import('src/app/modules/charts/charts.module').then(({ ChartsModule }) => {
					return import('src/app/modules/charts/ui/components/kinline-dashboard/kinline-dashboard.component').then(
						({ KInlineDashboardComponent }) => {
							return KInlineDashboardComponent;
						},
					);
				});

			case ViewItemUiControl.DashboardFilterValueSelector:
				return import('src/app/modules/charts/charts.module').then(({ ChartsModule }) => {
					return import(
						'src/app/modules/charts/ui/components/kdashboard-filter-value-selector/kdashboard-filter-value-selector.component'
					).then(({ KDashboardFilterValueSelectorComponent }) => {
						return KDashboardFilterValueSelectorComponent;
					});
				});

			case ViewItemUiControl.Chart:
				return import('src/app/modules/charts/charts.module').then(({ ChartsModule }) => {
					return import('src/app/modules/charts/ui/components/kchart/kchart.component').then(({ KChartComponent }) => {
						return KChartComponent;
					});
				});

			// case ViewItemUiControl.FileManager:
			// return KFileManagerComponent;

			case ViewItemUiControl.AnalysisCustomControl:
				return import('src/app/modules/grc/grc.module').then(({ GrcModule }) => {
					return import(
						'src/app/modules/grc/ui/components/kanalysis-custom-control/kanalysis-custom-control.component'
					).then(({ KAnalysisCustomControlComponent }) => {
						return KAnalysisCustomControlComponent;
					});
				});

			case ViewItemUiControl.RiskScore:
				return import('src/app/modules/grc/grc.module').then(({ GrcModule }) => {
					return import('src/app/modules/grc/ui/components/risk-score/risk-score.component').then(
						({ RiskScoreComponent }) => {
							return RiskScoreComponent;
						},
					);
				});

			case ViewItemUiControl.RiskScoreVals:
				return import('src/app/modules/grc/grc.module').then(({ GrcModule }) => {
					return import('src/app/modules/grc/ui/components/score-vals/score-vals.component').then(
						({ ScoreValsComponent }) => {
							return ScoreValsComponent;
						},
					);
				});

			case ViewItemUiControl.QuestionsControl:
				return import('src/app/modules/survey/survey.module').then(({ SurveyModule }) => {
					return import('src/app/modules/survey/ui/components/kquestions/kquestions.component').then(
						({ KQuestionsComponent }) => {
							return KQuestionsComponent;
						},
					);
				});

			case ViewItemUiControl.KQuestionMinMax:
				return import('src/app/modules/survey/survey.module').then(({ SurveyModule }) => {
					return import('src/app/modules/survey/ui/components/kquestion-min-max/kquestion-min-max.component').then(
						({ KQuestionMinMaxComponent }) => {
							return KQuestionMinMaxComponent;
						},
					);
				});

			case ViewItemUiControl.RiskDetail:
				return import('src/app/modules/grc/grc.module').then(({ GrcModule }) => {
					return import('src/app/modules/grc/ui/components/risk-detail/risk-detail.component').then(
						({ RiskDetailComponent }) => {
							return RiskDetailComponent;
						},
					);
				});

			case ViewItemUiControl.Color:
				return KColorBoxComponent;

			case ViewItemUiControl.KEventsCustomControlComponent:
				return import('src/app/modules/grc/grc.module').then(({ GrcModule }) => {
					return import(
						'src/app/modules/grc/ui/components/kevents-custom-control/kevents-custom-control.component'
					).then(({ KEventsCustomControlComponent }) => {
						return KEventsCustomControlComponent;
					});
				});

			case ViewItemUiControl.Gantt:
				return import('src/app/modules/planning/planning.module').then(({ PlanningModule }) => {
					return import('src/app/modules/planning/ui/components/kplan/kplan.component').then(({ KPlanComponent }) => {
						return KPlanComponent;
					});
				});

			case ViewItemUiControl.KSessionDetailCustomControl:
				return import('src/app/modules/grc/grc.module').then(({ GrcModule }) => {
					return import(
						'src/app/modules/grc/ui/components/ksession-detail-custom-control/ksession-detail-custom-control.component'
					).then(({ KSessionDetailCustomControlComponent }) => {
						return KSessionDetailCustomControlComponent;
					});
				});

			case ViewItemUiControl.KStrategyMapCustomControl:
				return import('src/app/modules/grc/grc.module').then(({ GrcModule }) => {
					return import(
						'src/app/modules/grc/ui/components/kstrategy-map-custom-control/kstrategy-map-custom-control.component'
					).then(({ KStrategyMapCustomControlComponent }) => {
						return KStrategyMapCustomControlComponent;
					});
				});

			case ViewItemUiControl.KBscCustomControl:
				return import('src/app/modules/grc/grc.module').then(({ GrcModule }) => {
					return import('src/app/modules/grc/ui/components/kbsc-custom-control/kbsc-custom-control.component').then(
						({ KBscCustomControlComponent }) => {
							return KBscCustomControlComponent;
						},
					);
				});

			case ViewItemUiControl.KBscThroughYearsCustomControl:
				return import('src/app/modules/grc/grc.module').then(({ GrcModule }) => {
					return import(
						'src/app/modules/grc/ui/components/kbsc-through-years-custom-control/kbsc-through-years-custom-control.component'
					).then(({ KBscThroughYearsCustomControlComponent }) => {
						return KBscThroughYearsCustomControlComponent;
					});
				});

			case ViewItemUiControl.GoalScoreCustomControl:
				return import('src/app/modules/grc/grc.module').then(({ GrcModule }) => {
					return import(
						'src/app/modules/grc/ui/components/goal-score-custom-control/goal-score-custom-control.component'
					).then(({ GoalScoreCustomControlComponent }) => {
						return GoalScoreCustomControlComponent;
					});
				});

			case ViewItemUiControl.SysReportMatrix:
				return import('src/app/modules/grc/grc.module').then(({ GrcModule }) => {
					return import('src/app/modules/grc/ui/components/ksys-report-matrix/ksys-report-matrix.component').then(
						({ KSysReportMatrixComponent }) => {
							return KSysReportMatrixComponent;
						},
					);
				});

			default:
				return KEmptyComponent;
		}
	}
}
