import { UiRuleTypeEnum } from 'src/app/server/model/rule-type';
import { UiRule } from './../../../server/model/load-runtime-information';
import { ViewNode } from './../../../server/model/view-node';

type evaluateChangedNodesResult = { node: ViewNode; uiRules: UiRule[] };

export function requireReload(
	nodesWithRuleAffected: evaluateChangedNodesResult[],
) {
	return (
		nodesWithRuleAffected.filter(
			(c) =>
				c.uiRules.filter(
					(d) =>
						d.type === UiRuleTypeEnum.HideIf ||
						d.type === UiRuleTypeEnum.ReadOnlyIf,
				).length > 0,
		).length > 0
	);
}

export function getNodesWithRules(
	nodes: ViewNode[],
): evaluateChangedNodesResult[] {
	let evaluatedNodes: evaluateChangedNodesResult[] = [];

	for (const node of nodes) {
		if (node.advancedData2?.uiRules?.length > 0) {
			evaluatedNodes.push({
				node: node,
				uiRules: node.advancedData2.uiRules,
			});
		}

		if (node?.nodes?.length > 0) {
			evaluatedNodes = evaluatedNodes.concat(getNodesWithRules(node.nodes));
		}
	}

	return evaluatedNodes;
}

export function evaluateChangedNodes(
	nodes: ViewNode[],
	changedAttributeNames: string[],
): evaluateChangedNodesResult[] {
	const output: evaluateChangedNodesResult[] = [];

	nodes.forEach((node: ViewNode) => {
		const uiRules = isTargetNodeOf(
			node.advancedData2.uiRules,
			changedAttributeNames,
		);

		if (uiRules.length > 0) {
			output.push({
				node,
				uiRules,
			});
		}

		// if (changedAttributeNames.indexOf(node.attributeName) >= 0) {
		//   output.push(node);
		// }

		if (node.nodes.length > 0) {
			evaluateChangedNodes(node.nodes, changedAttributeNames).forEach(
				(node) => {
					output.push(node);
				},
			);
		}
	});

	return output;
}

function isTargetNodeOf(
	uiRules: UiRule[],
	changedAttributeNames: string[],
): UiRule[] {
	const founds: UiRule[] = [];

	changedAttributeNames.forEach((targeNode: string) => {
		uiRules.forEach((uiRule: UiRule) => {
			if (uiRule.targetNodeNames?.indexOf(targeNode) >= 0) {
				founds.push(uiRule);
			}
		});
	});

	return founds;
}
