import { Guid } from 'guid-ts';
import { GenericHelper } from '../../../server/helpers/generic-helper';

export type TaskAction = 'edit' | 'search' | 'none' | 'view' | 'todo';

export enum TaskActionType {
	Plan = 0,
	Start = 1,
	Choice = 2,
	Sign = 3,
	Workflow = 4,
	End = 5,
	List = 6,
	DynamicWorkflow = 7,
	Check = 8,
	Dictionary = 9,
	DictionaryAssign = 10,
	Tickler = 20,
	Fulfill = 21,
	PersonalCommitment = 22,
}

export enum TaskRelation {
	None = 0, // Form Indipendente
	SubTask = 1, // Sponda member
	Parent = 2, // Sponda non member
	Inline = 3, // master detail
	StandAlone = 4, // master detail
	InlineRelatedSubTask = 5, // inline Sponda member
	InlineRelatedParent = 6, // inline Sponda non member
}
// https://js.devexpress.com/Documentation/ApiReference/UI_Components/dxTabs/Configuration/items/#badge
export class Task {
	constructor(
		id: string,
		text: string,
		icon: string = 'icon icon-sec_infrastructure',
		entityId: string,
		instanceId: string | null = null,
		action: TaskAction,
	) {
		this.id = id;
		this.icon = icon;
		this.entityId = entityId;
		this.instanceId = instanceId;
		this.action = action;
		this.changeName(text);
	}

	id: string;
	text = '';

	shorttext = '';
	icon: string;
	//  content = '';
	entityId: string;
	instanceId: string | null = null;
	action: string;

	//  isChanged = false;

	#isChanged: boolean = false;
	get isChanged(): boolean {
		return this.#isChanged;
	}

	set isChanged(value: boolean) {
		this.#isChanged = value;
		// console.error(value);
	}

	isInvalid = false;
	relation = TaskRelation.None;
	attributeName: string | null = null;
	parentTaskId: string | null = null;
	resultViewId: string | null = null;
	rootTaskId: string | null = null;
	rootAttributeName: string | null = null;
	rootChildInstanceId: string | null = null;

	stepOrTab = 0;
	currentCmp: string | null = null;
	currentCmpElement: string | null = null;

	innerStepOrTab: number | null = null;
	innerCmp: string | null = null;
	innerElement: string | null = null;

	static fromRaw(raw: any): Task {
		const t = new Task(
			raw.id,
			raw.text,
			raw.ico,
			raw.entityId,
			raw.instanceId,
			raw.action,
		);
		t.stepOrTab = raw.stepOrTab;
		t.currentCmp = raw.currentCmp;
		t.currentCmpElement = raw.currentCmpElement;

		t.innerStepOrTab = raw.innerStepOrTab;
		t.innerCmp = raw.innerCmp;
		t.innerElement = raw.innerElement;

		t.relation = raw.relation;
		t.attributeName = raw.attributeName;
		t.parentTaskId = raw.parentTaskId;
		t.resultViewId = raw.resultViewId;
		t.rootTaskId = raw.rootTaskId;
		t.rootAttributeName = raw.rootAttributeName;
		t.rootChildInstanceId = raw.rootChildInstanceId;
		t.isChanged = raw.isChanged;
		t.isInvalid = raw.isInvalid;
		t.icon = raw.icon;
		return t;
	}

	static untitled(entityId: string): Task {
		const icon = GenericHelper.toIcon(entityId);
		return new Task(
			GenericHelper.generateId(),
			'untitled',
			icon,
			entityId,
			Guid.newGuid().toString(),
			'edit',
		);
	}

	changeName(text: string): void {
		this.text = text;
		this.shorttext = text?.length > 12 ? text?.substr(0, 9) + '...' : text;
	}
}
