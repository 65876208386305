import { ChangeDetectionStrategy, Component, forwardRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DxValidationGroupComponent } from 'devextreme-angular';
import notify from 'devextreme/ui/notify';
import { forkJoin, Observable, of, Subject, Subscription } from 'rxjs';
import { first, switchMap } from 'rxjs/operators';
import { AttributeMetadata } from 'src/app/server/model/attribute-metadata';
import { EntityHolder } from 'src/app/server/model/entity-holder';
import { LogicalAttributeType } from 'src/app/server/model/logical-attribute-type';
import { ExecuteCustomActionResponse } from 'src/app/server/model/save-response';
import { FileResponse } from 'src/app/server/model/temporari-image-url-response';
import { ViewNode } from 'src/app/server/model/view-node';
import { Task, TaskAction } from 'src/app/ui/manager/ktaskbar/task';
import { FieldValue } from '../../../server/model/field-value';
import { KEditTaskComponent } from '../../manager/ktask/edit/kedit-task.component';
import { KTaskBaseComponent } from '../../manager/ktask/ktask-base.component';
import { KTaskComponent } from '../../manager/ktask/ktask.component';
import { KTaskSelectedElementChange } from '../../manager/ktaskbar/ktask-chage-payload';
import { KTaskBarEvent, KTaskBarEventType } from '../../manager/ktaskbar/ktaskbar-event';
import { taskForNewParent } from '../../manager/ktaskbar/task-functions';
import { KSearchDialogClosePayload } from '../../shared/ksearch-dialog/ksearch-dialog-close-payload';
import { FormSavePayLoad } from '../../shared/save-button';
import { KBaseComponent } from '../kcomponents/kbase/kbase.component';
import {
	KFormFieldRefreshAfterDialogClosePayload,
	KFormFieldRefreshPayload,
} from '../kcomponents/kbase/kfield-refresh-payload';
import { KFieldValueChangePayload } from '../kcomponents/kbase/kfield-Value-change-payload';
import { KFormClosePayload } from '../kcomponents/kbase/kform-close-payload';
import { fileDownload } from '../kcomponents/kfile-manager/file-functions';
import { createChildNodes } from '../ktab/createChildNode';
import { EnumHelper } from './../../../server/helpers/enum-helper';
import { EntityStatus } from './../../../server/model/entity-status';
import { LogicalEntityType } from './../../../server/model/logical-entity-type';
import { ExecuteElaborateResponse } from './../../../server/model/save-response';
import { View } from './../../../server/model/view';
import { ViewItemUiControl } from './../../../server/model/view-item-ui-control';
import { TaskRelation } from './../../manager/ktaskbar/task';
import { KDialogCommandClosePayload } from './../../shared/kcommand-dialog/kdialog-command-close-payload';
import { KToolbarStatusPayload } from './../ktoolbar/ktoolbar-payload';
import { executeCommand, onExecuteCommand } from './execute-command';
import { formReloadAfterElaborate } from './form-refresh-after-change';
import { isNodeHidden } from './is-node';
import { IKFormData, KFormData } from './kform-data';
import { formExecuteRelated, processCustomActionResponse } from './kform-functions';
import { evaluateIfElaborateIsNecessary } from './process-on-change-rule.function';
import { showPrint } from './show-print';

export type KFormLoadArg = {
	entityId: string;
	instanceId: string | null;
	relation: TaskRelation;
	taskId: string;
	parentTaskId: string | null;
	attributeName: string | null;
	resultViewId: string | null;
};

@Component({
	selector: 'app-kform',
	templateUrl: './kform.component.html',
	styleUrls: ['./kform.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: KTaskComponent,
			useExisting: forwardRef(() => KEditTaskComponent),
		},
	],
})
export class KFormComponent extends KTaskBaseComponent implements OnInit, OnDestroy {
	arg!: KFormLoadArg;
	view: View | null = null;
	isPartitionable: boolean = false;
	isAttachable: boolean = false;
	isVersionable: boolean = false;
	isProcessable: boolean = false;
	isSubForm: boolean = false;

	unitsIds: string[] | null = null;
	tabs: ViewNode[] = [];
	sub: Subscription | null = null;
	components = new Map<string, KBaseComponent>();
	#selectedIndex = 0;

	get selectedIndex(): number {
		return this.#selectedIndex;
	}

	set selectedIndex(index: number) {
		this.#selectedIndex = index;
		if ((this.task?.stepOrTab ?? 0) !== this.selectedIndex) {
			// this.task.stepOrTab = this.selectedIndex;
			this.broadcastSelectedUiElementChange(this.selectedIndex);
		}
	}

	@ViewChild('form') form!: DxValidationGroupComponent;

	broadcastSelectedUiElementChange(
		stepOrTab: number,
		currentCmp: string | null = null,
		currentCmpElement: string | null = null,
	) {
		this.on(
			KTaskBarEventType.SelectedUiElementChange,
			new KTaskSelectedElementChange(this.task, stepOrTab, currentCmp, currentCmpElement),
		);
	}

	get data(): KFormData | null {
		return this.state.get(this.task.id);
	}

	get holder(): EntityHolder {
		if (this.data == null) {
			console.error(this.task);
		}

		return this.data!.data!;
	}

	updateHolder(holder: EntityHolder): void {
		this.state.updateHolder(this.task.id, holder);
	}

	updateHolderAndPersist(updatedData: EntityHolder | null) {
		if (this.data && updatedData) {
			this.data.updateHolder(updatedData);
			this.state.persist(this.task.id, this.data);
			return true;
		} else {
			return false;
		}
	}

	markForCheck() {
		this.cdr.markForCheck();
	}

	override ngOnDestroy(): void {
		this.sub?.unsubscribe();
		super.ngOnDestroy();
	}

	getParentHolder(): EntityHolder | null {
		if (this.task.parentTaskId) {
			return this.getParentData()?.data ?? null;
		} else {
			return null;
		}
	}

	getParentData(): KFormData | null {
		if (this.task.parentTaskId) {
			return this.state.get<KFormData>(this.task.parentTaskId) ?? null;
		} else {
			return null;
		}
	}

	$refreshForm = new Subject<KFormLoadArg>();

	override ngOnInit(): void {
		// viene chiamato l'inner ngInit registra più volte il task server
		// questo non è bene perchè ripete il save
		// ma non sono sicuro nel caso di related se questo sia necessario
		// Inline è usato in KMasterDetail e KAggregateTree
		// tolto questo chi salva?
		if (!this.isSubForm) {
			super.ngOnInit();
		}

		this.sub = this.$refreshForm.pipe(switchMap((arg) => this.loadPipe(arg))).subscribe((allData) => {
			this.onLoadComplete(allData);
		});

		// if (!this.isSubForm) {
		this.load();
		// }
	}

	loadPipe(arg: KFormLoadArg): Observable<{ data: KFormData | null; arg: KFormLoadArg }> {
		// console.log(`${TaskRelation[this.task.relation]} ${this.task.action}`, this.task);

		return of(arg).pipe(
			switchMap((arg: KFormLoadArg) => {
				// console.log(arg);

				// cambiato il 26/07/2022
				// la regola è se non è stato pre caricato nel task
				// è nullo e quindi viene creato dal metodo sul server
				// se è stato precaricato viene preso quello
				// il rischio è qualche task che resti sporco

				// if (arg.relation === TaskRelation.SubTask && arg.instanceId) {
				//   return of(null);
				// } else {
				return this.state.getOrNull<KFormData>(arg.taskId!) as Observable<KFormData | null>;
				// }
			}),
			switchMap((data: IKFormData | null) => {
				// console.log('data', data);

				if (arg.relation === TaskRelation.Inline) {
					return forkJoin({
						data: of((this.state.get(arg.taskId) as KFormData).data),
						info: this.service.getEntityInfo(arg.entityId),
						status: of(null),
						isFav: of(false),
					});
				} else if (arg.relation !== TaskRelation.StandAlone && data == null) {
					return forkJoin({
						data: this.service.load(arg.entityId, arg.instanceId),
						info: this.service.getEntityInfo(arg.entityId),
						status: of(null),
						isFav: of(false),
					});
				} else if (arg.relation !== TaskRelation.SubTask && data == null) {
					return forkJoin({
						data: this.service.load(arg.entityId, arg.instanceId),
						info: this.service.getEntityInfo(arg.entityId),
						status: of(null),
						isFav: this.service.isFavorite(arg.entityId, arg.instanceId!),
					});
				} else if (
					data != null &&
					data.info == null &&
					arg.relation !== TaskRelation.None &&
					arg.attributeName &&
					arg.instanceId &&
					arg.parentTaskId
				) {
					return forkJoin({
						data: of(data.data),
						info: this.service.getEntityInfo(arg.entityId),
						status: of(null),
						isFav: of(false),
					});
				} else if (arg.relation === TaskRelation.SubTask && arg.attributeName && arg.instanceId && arg.parentTaskId) {
					let d = this.state.getTaskField(arg.parentTaskId, arg.attributeName, arg.instanceId);

					if (!d) {
						console.log(' new row');
						d = (this.state.get(arg.taskId) as KFormData)?.data;
					}

					return forkJoin({
						data: of(d),
						info: this.service.getEntityInfo(arg.entityId),
						status: of(null),
						isFav: of(false),
					});
				} else if (
					data != null &&
					data.info == null &&
					arg.relation === TaskRelation.Parent &&
					arg.instanceId &&
					arg.parentTaskId
				) {
					return forkJoin({
						data: of(data.data),
						info: this.service.getEntityInfo(arg.entityId),
						status: of(null),
						isFav: of(false),
					});
				}

				if (data != null) {
					// console.log('normal');
					return of(data);
				}

				return of(null);
			}),
			switchMap((data: IKFormData | null) => {
				if (data?.data != null) {
					const fd = new KFormData(data.data, data.info, data.isFav);
					this.task.isChanged = (fd.data?.history?.length ?? 0) > 0;
					// console.log(this.task.isChanged);
					return of({
						data: fd,
						arg: arg,
					});
				}

				return of({
					data: null,
					arg: arg,
				});
			}),
		);
	}

	onLoadComplete(allData: { data: KFormData | null; arg: KFormLoadArg }) {
		this.fillUI(allData.data, allData.arg);
		this.cdr.markForCheck();
		this.manager.stopLoader();
	}

	load(): void {
		this.selectedIndex = this.task.stepOrTab;

		const arg: KFormLoadArg = {
			entityId: this.task.entityId,
			instanceId: this.task.instanceId,
			relation: this.task.relation,
			taskId: this.task.id,
			parentTaskId: this.task.parentTaskId,
			attributeName: this.task.attributeName,
			resultViewId: this.task.resultViewId,
		};

		this.loadWitArg(arg);
	}

	loadWitArg(arg: KFormLoadArg): void {
		this.arg = arg;
		this.$refreshForm.next(arg);
	}

	reload(): void {
		// this.selectedIndex = -1;
		// this.tabs = [];
		// this.cdr.detectChanges();
		this.loadWitArg(this.arg);
	}

	fillUI(allData: KFormData | null, arg: KFormLoadArg) {
		if (allData) {
			// console.log(allData.info);

			if (!allData.info) {
				console.error('missing info for ', arg.entityId, arg.resultViewId);
			}

			this.view = allData.info!.getUiOrDefault(arg.resultViewId);
			// console.log(this.view);
			// console.log(allData.info?.uis);
			if (!this.view) {
				console.error('missing view for ', arg.entityId, arg.resultViewId);
				return;
			}

			allData.viewId = this.view!.id;

			this.state.persist(arg.taskId, allData);

			// console.log(this.view);
			this.tabs = [];

			let parentData: KFormData | null;

			(this.view?.nodes ?? []).forEach((tab) => {
				if (arg.relation === TaskRelation.SubTask) {
					parentData = this.state.get(arg.parentTaskId!);
					// console.log(parentData);
				}

				const tabNodes = createChildNodes(tab.nodes, this.data!, this.global.data.user!, parentData);

				const asChild = tabNodes.length > 0;
				const isHidden = isNodeHidden({
					node: tab,
					data: this.data,
					accountId: this.global.data.user!.accountId,
					userRoles: this.global.data.user!.userRoles,
				});

				if (asChild && !isHidden) {
					tab.evaluatedNodes = tabNodes;
					this.tabs.push(tab);
				}
			});

			if (EnumHelper.is(allData.info?.logicalType, LogicalEntityType.Partition)) {
				this.isPartitionable = true;

				const partitionTab = new ViewNode();
				partitionTab.attributeName = '@Partition';
				partitionTab.colSpan = 20;
				partitionTab.rowSpan = 10;
				partitionTab.description = '';
				partitionTab.name = 'Unità';
				partitionTab.controlType = ViewItemUiControl.Partition;
				partitionTab.nodes = [];

				this.tabs.push(partitionTab);
			}

			if (EnumHelper.is(allData.info?.logicalType, LogicalEntityType.Attachable)) {
				this.isAttachable = true;

				const attachTab = new ViewNode();
				attachTab.attributeName = '@Attach';
				attachTab.colSpan = 20;
				attachTab.rowSpan = 10;
				attachTab.description = '';
				attachTab.name = 'Allegati';
				attachTab.controlType = ViewItemUiControl.Attach;
				attachTab.nodes = [];
				this.tabs.push(attachTab);
			}

			if (EnumHelper.is(allData.info?.logicalType, LogicalEntityType.Versionable)) {
				this.isVersionable = true;

				const versionTab = new ViewNode();
				versionTab.attributeName = '@Versions';
				versionTab.colSpan = 20;
				versionTab.rowSpan = 10;
				versionTab.description = '';
				versionTab.name = 'Versioni';
				versionTab.controlType = ViewItemUiControl.Versions;
				versionTab.nodes = [];

				this.tabs.push(versionTab);
			}

			if (EnumHelper.is(allData.info?.logicalType, LogicalEntityType.Processable)) {
				this.isProcessable = true;

				const versionTab = new ViewNode();
				versionTab.attributeName = '@History';
				versionTab.colSpan = 20;
				versionTab.rowSpan = 10;
				versionTab.description = '';
				versionTab.name = 'Storico';
				versionTab.controlType = ViewItemUiControl.History;
				versionTab.nodes = [];

				this.tabs.push(versionTab);
			}

			this.onTitleChange(this.data?.data?.humanReadableName ?? '');

			this.on(KTaskBarEventType.Ready, allData);
		}
	}

	override handleEvents(event: KTaskBarEvent): void {
		super.handleEvents(event);

		switch (event.type) {
			case KTaskBarEventType.ExecuteRelated:
				formExecuteRelated(this, event.payload);
				break;
			case KTaskBarEventType.ShowPrint:
				showPrint(this, event.payload.print);
				break;

			case KTaskBarEventType.FileDownload:
				this.service
					.getDocument(this.task.entityId, this.task.instanceId!)
					.pipe(first())
					.subscribe((file: FileResponse | null) => {
						if (file) {
							fileDownload(file.fileName, file.data);
						}
					});
				break;

			case KTaskBarEventType.ExecuteCommand:
				onExecuteCommand(this, [this.holder], event.payload.command);
				break;

			case KTaskBarEventType.FormStatusChange:
				if (event.payload instanceof KToolbarStatusPayload) {
					this.onFormStatusChange(event.payload);
				}
				break;

			case KTaskBarEventType.CloseCommandDialog:
				if (event.payload instanceof KDialogCommandClosePayload) {
					executeCommand(
						this,
						this.holder.entityId,
						this.holder.instanceId,
						this.holder,
						event.payload.command,
						event.payload.args,
					);
				}
				break;

			case KTaskBarEventType.CloseSearchDialog:
				if (event.payload instanceof KSearchDialogClosePayload) {
					this.on(
						KTaskBarEventType.FormFieldRefreshAfterSearchDialogClose,
						new KFormFieldRefreshAfterDialogClosePayload(this.task, event.payload.attributeName!, event.payload),
					);
				}
				break;

			case KTaskBarEventType.FormUndo:
				if (this.data?.data) {
					const u = this.data.data.undo();

					if (u) {
						this.on(
							KTaskBarEventType.FormFieldRefresh,
							new KFormFieldRefreshPayload(this.task, u.name, u, this.data.data.history?.length ?? 0),
						);
					}
				}

				break;

			case KTaskBarEventType.FormAddFavorites:
				this.toggleFavorite(this.data!.data!);
				break;

			case KTaskBarEventType.FormSave:
				if (this.data?.data && (event.payload.payload as FormSavePayLoad)) {
					this.save(this.data.data, event.payload.payload.asModel, event.payload.payload.andExit);
				}

				break;
			default:
		}
	}

	holderIsChanged(source: EntityHolder, target: EntityHolder) {
		if ((source == null && target != null) || (source != null && target == null)) {
			console.log('one or both null', source, target);
			return true;
		}
		if (source.instanceId !== target.instanceId) {
			console.log('instanceId is changed', source.instanceId, target.instanceId);
			return true;
		}

		for (const ef of source.fields) {
			const sv = ef.value;
			const tv = target.getField(ef.name)?.value;

			if (sv instanceof EntityHolder || tv instanceof EntityHolder) {
				if (this.holderIsChanged(sv, tv)) {
					// console.log('field holder is changed', ef.value, tv);
					return true;
				}
			} else if (sv !== tv) {
				// console.log('field is changed', ef.value, tv);
				return true;
			}
		}

		return false;
	}

	valueIsChanged(value: FieldValue, newValue: FieldValue): boolean {
		if (value.value instanceof EntityHolder) {
			if (newValue.value instanceof EntityHolder) {
				return this.holderIsChanged(value.value, newValue.value); // value.value.instanceId !== newValue.value?.instanceId;
			}

			return true;
		} else if (Array.isArray(value.value)) {
			if (Array.isArray(newValue.value) && value.value.length !== newValue.value.length) {
				return true;
			}

			const oldArray = value.value as EntityHolder[];
			const newArray = newValue.value as EntityHolder[];

			for (const i in newArray) {
				const isChanged = this.holderIsChanged(oldArray[i], newArray[i]);

				if (isChanged) {
					console.log('Holder changed', value.name);
					return true;
				}
			}

			return false;
		} else return value.value !== newValue.value;
	}

	toggleFavorite(data: EntityHolder): void {
		this.service.toggleFavorite(data.entityId, data.instanceId!).subscribe((result) => {
			this.on(KTaskBarEventType.FavIsChanged, result);
		});
	}

	#activeInlineForm: KFormComponent | null = null;

	public registerInlineForm(activeInlineForm: KFormComponent) {
		this.#activeInlineForm = activeInlineForm;
	}

	save(data: EntityHolder, asModel: boolean, andExist: boolean): void {
		// console.log('save', asModel, andExist);

		const validationResult = this.form.instance.validate();
		if (!validationResult.isValid) {
			this.global.showError('Ricontrollare la scheda, alcuni campi non sono compilati corretamente o mancanti');
			return;
		}

		this.manager.startLoader();

		if (this.isPartitionable && this.holder.isNew && !this.unitsIds) {
			notify('Se un entità è nuova usare la tab "unità" per selezione le unità', 'error', 2000);
			this.manager.stopLoader();
			return;
		}

		if (this.task.relation === TaskRelation.InlineRelatedParent && this.task.rootTaskId) {
			this.saveAndUpdateParent(data, asModel, andExist, this.task.parentTaskId);
		} else if (this.task.relation === TaskRelation.InlineRelatedSubTask && this.task.rootTaskId) {
			this.saveAndUpdateParent(data, asModel, andExist, this.task.parentTaskId);
		} else if (this.task.relation === TaskRelation.Parent && this.task.parentTaskId) {
			this.saveAndUpdateParent(data, asModel, andExist, this.task.parentTaskId);
		} else if (this.task.relation === TaskRelation.Parent && this.task.parentTaskId && this.task.attributeName) {
			this.saveAndUpdateParent(data, asModel, andExist, this.task.parentTaskId);
		} else if (this.task.relation === TaskRelation.SubTask) {
			this.updateParent(data, asModel, andExist);
		} else {
			this.saveRoot(data, asModel, andExist);
		}
	}

	saveAndUpdateParent(data: EntityHolder, asModel: boolean, andExist: boolean, parentTaskId: string | null = null) {
		console.log('saveAndUpdateParent', data);
		this.service.save(data, this.unitsIds, asModel).subscribe({
			next: (result) => {
				console.log(result);

				if (result.success) {
					this.manager.stopLoader();
					this.cdr.markForCheck();

					if (parentTaskId) {
						this.updateParentAndClose(result.data!);
						this.closeAndReopenParent();
					} else {
						this.closeAndReopenParent();
					}
				} else {
					this.manager.stopLoader();
					this.cdr.markForCheck();
					notify(result.message, 'error', 2000);
				}
			},
		});
	}

	getRightParent(rootTaskId: string | null, taskId: string) {
		return this.manager.state.get(rootTaskId ?? taskId);
	}

	updateParent(data: EntityHolder, asModel: boolean, andExist: boolean) {
		console.log('updateParent', data);

		if (!this.task.parentTaskId || !this.task.attributeName) {
			console.log('Un sub task senza parent task non può savare');
			return;
		}

		// il task parent è ancora aperto?
		const parent = this.getRightParent(this.task.rootTaskId, this.task.parentTaskId);

		if (parent) {
			const valueToSend = this.data?.data!;

			if (this.data?.info?.metadata.requireElaborate) {
				this.service
					.executeElaborate(valueToSend.entityId, valueToSend, null)
					.subscribe((response: ExecuteElaborateResponse) => {
						if (response.success) {
							this.updateParentAndClose(response.data!);
						} else {
							console.error('updateParent.executeElaborate', valueToSend, response);
							this.manager.stopLoader();

							this.global.showError(response.message!);
							this.cdr.markForCheck();
						}
					});
			} else {
				this.updateParentAndClose(valueToSend);
			}
		}
	}

	saveRoot(data: EntityHolder, asModel: boolean, andExist: boolean) {
		if (this.#activeInlineForm && this.#activeInlineForm.holder) {
			this.#activeInlineForm.saveRoot(this.#activeInlineForm.holder, false, false);
		}

		console.log('saveRoot', data.humanReadableName, data.instanceId);
		this.service.save(data, this.unitsIds, asModel).subscribe({
			next: (result) => {
				if (result.success) {
					if (andExist) {
						this.on(KTaskBarEventType.FormClose, new KFormClosePayload(this.task.id, null));
					} else {
						const t = this.manager.getTask(this.task.id);
						const reloaded = formReloadAfterElaborate(this, result.data!, false);
						if (!reloaded && t.task) {
							t.task.isChanged = false;
							this.manager.updateTask(t).then(() => {
								this.manager.stopLoader();
								this.cdr.markForCheck();
							});
						}
					}
				} else {
					this.manager.stopLoader();
					this.cdr.markForCheck();
					notify(result.message, 'error', 2000);
				}
			},
		});
	}

	updateParentAndClose(valueToSend: EntityHolder): Promise<boolean> {
		console.log('updateParentAndClose', this.task);
		let x: Promise<{
			data: EntityHolder | null;
			success: boolean;
			required: boolean;
		}> = new Promise<{
			data: EntityHolder | null;
			success: boolean;
			required: boolean;
		}>(() => true);

		let taskIdToUpdate: string | null = this.task.parentTaskId;
		let attributeToUpdate: string | null = this.task.attributeName;

		// root > collection member > item > collection item
		//

		if (
			this.task.rootTaskId &&
			this.task.rootAttributeName // se non c'è il root attribute è inline related e non member
		) {
			console.log('updateParentAndClose InlineForm', this.task.rootTaskId, this.task.rootAttributeName);
			const rootState = this.manager.state.get(this.task.rootTaskId!) as KFormData;

			// prendo l'attributo della root ( es Entries per coporate context)
			const rootCollection = rootState.data?.getField(this.task.rootAttributeName!)?.value as EntityHolder[];

			// individuo il figli di cui l'inline form è sia esso grid o tree  TODO cosa succede se non è un tree normale??? ma è una sotta proprietà?
			// es un entries in corporate context
			const rootCollectionChild: EntityHolder = rootCollection.find(
				(c) => c.instanceId === this.task.rootChildInstanceId,
			)!;

			// aggiorno la proprietà figlia del tree o grid, supponendo lei sia una collezione TODO può essere pointer
			const childFieldToUpdate = rootCollectionChild.getField(this.task.attributeName!);

			let childFileToUpdateValue: EntityHolder[] | EntityHolder | null = null;

			if (EnumHelper.is(childFieldToUpdate?.logicalType, LogicalAttributeType.Collection)) {
				childFileToUpdateValue = rootCollectionChild?.replaceInCollection(this.task.attributeName!, valueToSend);
			} else {
				childFileToUpdateValue = valueToSend;
			}

			rootCollectionChild.setField(this.task.attributeName!, childFileToUpdateValue);

			taskIdToUpdate = this.task.rootTaskId!;
			attributeToUpdate = this.task.rootAttributeName!;
			valueToSend = rootCollectionChild;
		}

		if (
			taskIdToUpdate &&
			attributeToUpdate &&
			this.state.updateTaskField(taskIdToUpdate, attributeToUpdate, [valueToSend])
		) {
			console.log('updateTaskField', taskIdToUpdate);

			const parentFormData = this.manager.state.get(taskIdToUpdate) as KFormData;

			if (parentFormData) {
				var requireCommit =
					this.task.relation == TaskRelation.InlineRelatedParent ||
					this.task.relation == TaskRelation.InlineRelatedSubTask;

				x = evaluateIfElaborateIsNecessary(this.service, parentFormData, attributeToUpdate, requireCommit).toPromise();
			}

			x.then((result) => {
				if (result.success) {
					if (result.required) {
						result.data!.history = parentFormData.data?.history;
						parentFormData.updateHolder(result.data!);
					}
					this.closeAndReopenParent();
				}
			});
		}
		return x.then((a) => {
			console.log('updateParentAndClose', a);
			return a.success;
		});
	}

	closeAndReopenParent(): void {
		console.log('closeAndReopenParent');
		this.on(
			KTaskBarEventType.FormClose,
			new KFormClosePayload(this.task.id, this.task.rootTaskId ?? this.task.parentTaskId),
		);
	}

	registerComponent(node: ViewNode, component: KBaseComponent): void {
		this.components.set(node.attributeName, component);
	}

	getField(node: ViewNode): FieldValue | null {
		return this.data?.data?.getField(node.attributeName, false) ?? null;
	}

	onFormStatusChange(payload: KToolbarStatusPayload) {
		if (!payload.status.id) return;

		this.manager.startLoader();

		const status = this.data?.info?.metadata.statuses.get(parseInt(payload.status.id));

		const s = this.service.save(this.holder, this.unitsIds, false).subscribe({
			next: (response) => {
				if (response.success) {
					this.doOnExecuteCustomAction(status!, response.data!);
				} else {
					this.global.showError(response.message!);
				}
			},
			error: () => {
				console.log('error');
			},
			complete: () => {
				this.manager.stopLoader();
				this.cdr.markForCheck();
				console.log(s);
			},
		});
	}

	doOnExecuteCustomAction(status: EntityStatus, saveHolder: EntityHolder, choice: number = -1) {
		return this.service
			.executeCustomAction(this.task.entityId, saveHolder, status!.id, choice, [], this.task.instanceId!, true)
			.pipe(
				switchMap((response: ExecuteCustomActionResponse) => {
					return processCustomActionResponse(this, response, false);
				}),
			)
			.subscribe({
				error: (e) => {
					console.log(e);
				},
				next: (needReload: boolean) => {
					this.manager.stopLoader();
					this.cdr.markForCheck();
				},
				// complete: () => { console.log('executeCustomAction completed'); }
			});
	}

	// usato per ADD related
	// usato per tutti i member
	// non esistendo il record deve essere creato prima e poi aperto
	// il record esiste ma è da prendere dalla tab corrente ( dalla cache )
	createNewTaskAndRedirect(data: EntityHolder | null, attributeName: string, isMember: boolean, action: TaskAction) {
		const t = this.createChildTask(data, attributeName, isMember, action);
		this.manager.addNewFormTaskAndRedirect(t, data);
	}

	protected createChildTask(
		targetEntity: EntityHolder | null,
		attributeName: string,
		isMember: boolean,
		action: TaskAction,
	): Task {
		var t = taskForNewParent(
			this.task.id,
			targetEntity!.humanReadableName,
			targetEntity!.entityId,
			targetEntity!.instanceId,
			action,
			attributeName,
		);

		t.relation = isMember ? TaskRelation.SubTask : TaskRelation.Parent;

		return t;
	}

	saveState() {
		this.state.persist(this.task.id, this.data!);
	}

	getState(taskId: string): KFormData | null {
		return this.state.get(taskId) as KFormData;
	}

	updateField(attributeName: string, newValue: any) {
		this.holder.setField(attributeName, newValue);
		this.state.persist(this.task.id, this.data!);
	}

	updateTaskField(attributeName: string, holders: EntityHolder[]) {
		this.state.updateTaskField(this.task.id, attributeName, holders);
	}

	get events(): Observable<KTaskBarEvent> {
		return this.manager.trigger;
	}

	notifyFieldValueChanged(node: ViewNode, field: FieldValue, changes: number) {
		this.on(KTaskBarEventType.FieldValueChange, new KFieldValueChangePayload(this.task, node, field, changes));
	}

	getMainHolder(): EntityHolder {
		return this.holder!;
	}

	getInAggregate(entityId: string, currentEntityId: string, currentAttributeName: string): AttributeMetadata | null {
		const isInInlineAggregate = this.data!.info!.metadata.getInAggregate(entityId);

		if (
			isInInlineAggregate
			// &&      (isInInlineAggregate.targetEntity?.id !== currentEntityId || isInInlineAggregate.name !== currentAttributeName)
		) {
			console.log(isInInlineAggregate.name, this.data!.data?.entityId);

			console.log(currentAttributeName, currentEntityId);

			return isInInlineAggregate;
		}

		return null;
	}
}
