import { AttributeMetadata } from './attribute-metadata';
import { EntityAttributeValueType } from './entity-attribute-value-type';
import { IntIdName } from './id-name';
import { ViewItemFilterControl } from './view-item-filter-control';
import { ViewItemUiControl } from './view-item-ui-control';

export enum SearchParameterType {
	Contains,
	Major,
	Minor,
	Equal,
	Lesser,
	Greater,
	NotEqual,
	StartWith,
	EndsWith,
	NotContains,
}

export function getOperatorSign(type: SearchParameterType): string {
	switch (type) {
		case SearchParameterType.Contains:
			return '<>';
		case SearchParameterType.Major:
			return '>';
		case SearchParameterType.Minor:
			return '<';
		case SearchParameterType.Equal:
			return '=';
		case SearchParameterType.Lesser:
			return '<=';
		case SearchParameterType.Greater:
			return '>=';
		case SearchParameterType.NotEqual:
			return '!=';
		case SearchParameterType.StartWith:
			return '%*';
		case SearchParameterType.EndsWith:
			return '*%';
		case SearchParameterType.NotContains:
			return '><';
	}
}

export function getDefaultOperator(attribute: AttributeMetadata): SearchParameterType {
	if (!attribute) return SearchParameterType.Equal;

	if (attribute.type === undefined) {
		return SearchParameterType.Contains;
	}

	return SearchParameterType.Equal;
}

export function allOperators(
	type: EntityAttributeValueType,
	controlType: ViewItemFilterControl | ViewItemUiControl,
): IntIdName[] {
	const output: IntIdName[] = [];

	switch (type) {
		case EntityAttributeValueType.Guid:
		case EntityAttributeValueType.Boolean:
		case null:
			output.push({ id: SearchParameterType.Equal as number, name: 'Uguale', icon: 'spinnext' as string });
			output.push({
				id: SearchParameterType.NotEqual as number,
				name: 'Non uguale',
				icon: 'spinnext',
			});

			break;
		case undefined:
			output.push({
				id: SearchParameterType.Contains as number,
				name: 'Contiene',
				icon: 'spinnext',
			});
			output.push({
				id: SearchParameterType.NotContains as number,
				name: 'Non Contiene',
				icon: 'spinnext',
			});
			break;

		case EntityAttributeValueType.Text:
			output.push({
				id: SearchParameterType.StartWith as number,
				name: 'Inizia con',
				icon: 'spinnext',
			});
			output.push({ id: SearchParameterType.Equal as number, name: 'Uguale', icon: 'spinnext' });
			output.push({
				id: SearchParameterType.Contains as number,
				name: 'Contiene',
				icon: 'spinnext',
			});
			output.push({
				id: SearchParameterType.EndsWith as number,
				name: 'Finisce con',
				icon: 'spinnext',
			});
			break;

		case EntityAttributeValueType.Long:
		case EntityAttributeValueType.Int:
		case EntityAttributeValueType.Decimal:
		case EntityAttributeValueType.Double:
			output.push({ id: SearchParameterType.Equal as number, name: 'Uguale', icon: 'spinnext' });
			output.push({
				id: SearchParameterType.NotEqual as number,
				name: 'Non uguale',
				icon: 'spinnext',
			});

			output.push({
				id: SearchParameterType.Major as number,
				name: 'Maggiore',
				icon: 'spinnext',
			});
			output.push({ id: SearchParameterType.Minor as number, name: 'Minore', icon: 'spinnext' });
			output.push({
				id: SearchParameterType.Lesser as number,
				name: 'Minore uguale',
				icon: 'spinnext',
			});
			output.push({
				id: SearchParameterType.Greater as number,
				name: 'Maggiore uguale',
				icon: 'spinnext',
			});
			break;

		case EntityAttributeValueType.Date:
		case EntityAttributeValueType.DateTime:
			if (controlType === ViewItemFilterControl.DateRange) {
				output.push({ id: SearchParameterType.Contains as number, name: 'Compresa', icon: 'spinnext' });
				output.push({ id: SearchParameterType.NotContains as number, name: 'Non compresa', icon: 'spinnext' });
			} else {
				output.push({ id: SearchParameterType.Equal as number, name: 'Uguale', icon: 'spinnext' });
				output.push({ id: SearchParameterType.Minor as number, name: 'Minore', icon: 'spinnext' });
				output.push({ id: SearchParameterType.Major as number, name: 'Maggiore', icon: 'spinnext' });
				output.push({ id: SearchParameterType.Lesser as number, name: 'Minore uguale', icon: 'spinnext' });
				output.push({ id: SearchParameterType.Greater as number, name: 'Maggiore uguale', icon: 'spinnext' });
			}
			break;
	}

	return output;
}
