import { KToolbarPayload } from '../edit/ktoolbar/ktoolbar-payload';
import { KTaskBarEventType } from '../manager/ktaskbar/ktaskbar-event';
import { KTaskbarService } from '../manager/ktaskbar/ktaskbar.service';
import { KSearchToolbarComponent } from '../search/ksearch-toolbar/ksearch-toolbar.component';

export function createDeleteButton(
	manager: KTaskbarService,
	payload: KSearchToolbarComponent,
) {
	return {
		icon: 'trash',
		type: 'danger',
		stylingMode: 'outlined',
		onClick: () => {
			if (
				confirm(
					`Sicuro di voler cancellare ${payload.selectedRows.length} elementi?`,
				)
			) {
				manager.on(
					KTaskBarEventType.FormDelete,
					new KToolbarPayload('delete', manager.task!, payload.selectedRows),
				);
			}
		},
	};
}
