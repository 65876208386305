import { Component } from '@angular/core';
import { FieldValue } from 'src/app/server/model/field-value';
import {
	ControlFormatEnum,
	NumberFormats,
} from 'src/app/shared/formatting/formatting-helper';
import { KAttributeBaseComponent } from '../kbase/kattribute-base.component';
import { TextBoxChangeArg } from '../shared/function';
import { ViewItemUiControl } from './../../../../server/model/view-item-ui-control';

@Component({
	selector: 'app-knumbox',
	templateUrl: './knumbox.component.html',
	styleUrls: ['./knumbox.component.scss'],
})
export class KNumboxComponent extends KAttributeBaseComponent {
	format: string = '';
	innerValue: number = 0;

	override ngOnInit(): void {
		//console.log(this.node.name, this.mandatory);
		super.ngOnInit();

		if (this.node.controlType === ViewItemUiControl.IntBox) {
			if (this.node.formatId === ControlFormatEnum.Number)
				this.format = NumberFormats.IntKSeparators;
			else this.format = NumberFormats.IntNoKSeparators;
		} else if (this.node.controlType === ViewItemUiControl.MoneyBox) {
			if (this.node.formatId === ControlFormatEnum.Money)
				this.format = NumberFormats.MoneyKSeparators;
			else if (this.node.formatId === ControlFormatEnum.Number)
				this.format = NumberFormats.Float2DgtKSeparators;
			else if (this.node.formatId === ControlFormatEnum.Decimal)
				this.format = NumberFormats.Float4DgtKSeparators;
			else this.format = NumberFormats.Float2DgtKSeparators;
		} else if (this.node.controlType === ViewItemUiControl.FloatBox) {
			this.format = NumberFormats.Float2DgtKSeparators;
		} else if (this.node.controlType === ViewItemUiControl.FloatBox4) {
			this.format = NumberFormats.Float4DgtKSeparators;
		} else {
			if (this.node.formatId === ControlFormatEnum.Money) {
				this.format = NumberFormats.MoneyKSeparators;
			} else if (this.node.formatId === ControlFormatEnum.Number) {
				this.format = NumberFormats.Float2DgtKSeparators;
			} else if (this.node.formatId === ControlFormatEnum.Decimal) {
				this.format = NumberFormats.Float4DgtKSeparators;
			} else {
				this.format = NumberFormats.IntKSeparators;
			}
		}

		// console.log(
		// 	ViewItemUiControl[this.node.controlType],
		// 	this.format,
		// 	ControlFormatEnum[this.node.formatId!],
		// );
	}

	override setValue(value: FieldValue | null): void {
		super.setValue(value);

		this.innerValue = value?.value;

		//console.log("NUMBOX", value);

		// if (value?.value == null && this.node.attribute?.defaultValue) {
		//     value!.value = this.node.attribute?.defaultValue;
		//   // console.log(this.node.attributeName, value!.value);
		// }

		//this.innerValue = ToKString(this.value?.value, this.type ?? EntityAttributeValueType.Text, value?.format,this.node.formatId);

		// console.log(this.node.attributeName, value?.value, this.innerValue);

		this.cdr.markForCheck();
	}

	onValueChanged(event: TextBoxChangeArg): void {
		if (this.readonly) return;

		if (this.value && event?.previousValue !== event?.value) {
			this.innerValue = +event.value as number;
			console.log(this.innerValue);
			super.fieldValueIsChanging(this.innerValue);
		}
	}
}
