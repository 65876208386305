<div [class]="hasDetails ? 'container' : 'noDetails'">
	<div class="list" (contextmenu)="onRootAddContext($event)">
		<app-tree
			#tree
			[nodes]="treeData"
			(onContext)="onContext($event)"
			(onNodeClick)="onNodeClick2($event)"
			(onNodeMoved)="onNodeMoved($event)"
			(onNodeSelectionChange)="onNodeSelectionChange($event)"
		>
		</app-tree>
	</div>
	<div class="detail" *ngIf="hasDetails">
		<app-kform-tree-inline
			*ngIf="selected"
			[master]="selected"
		></app-kform-tree-inline>
	</div>
</div>
