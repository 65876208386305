import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { AdvancedData } from 'src/app/server/model/advanced-data';
import { EntityHolder } from 'src/app/server/model/entity-holder';
import { FieldValue } from 'src/app/server/model/field-value';
import { KListViewComponent } from '../../kform/klist-view.component';

@Component({
	selector: 'app-kform-view',
	templateUrl: './kform-view.component.html',
	styleUrls: ['./kform-view.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KFormViewComponent extends KListViewComponent {
	override selected: EntityHolder | null = null;
	refresh!: Subject<{ data: AdvancedData; holder: EntityHolder }>;
	refreshSubscription: Subscription | null = null;

	override onKeystoneInit() {
		// console.error('onKeystoneInit');

		if (!this.refresh) {
			this.refresh = new Subject<{
				data: AdvancedData;
				holder: EntityHolder;
			}>();
			this.refreshSubscription = this.refresh
				.pipe(
					switchMap((arg) => this.service.getLinkHolder(arg.data, arg.holder)),
					switchMap((e) => this.service.load(e!.entityId, e!.instanceId)),
					map((e) => {
						var fv = new FieldValue();
						fv.value = EntityHolder.parse(e);
						fv.entityId = this.node.advancedData2.entityId!;
						fv.format = '{0}';
						fv.name = this.node.name;
						return fv;
					}),
				)
				.subscribe((r) => {
					this.value = r;
					// super.setValue(r);
					// this.selected = r.value;
					// this.cdr.markForCheck();
					this.onValueReady();
				});
		}
	}

	override setValue(value: FieldValue | null): void {
		// console.log('setValue');
		this.refresh.next({
			data: this.node.advancedData2,
			holder: this.container.holder,
		});
	}

	override onValueReady(): void {
		this.selected = this.value?.value;
		this.cdr.markForCheck();
	}
}
