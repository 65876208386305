import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FieldValue } from 'src/app/server/model/field-value';
import { CategorySelectorArg } from 'src/app/ui/controls/category-selector-dialog/category-selector-dialog.component';
import { TreeNode } from 'src/app/ui/controls/tree/tree-note';
import { openContextMenu } from 'src/app/ui/shared/kcontext-menu/functions';
import { KContextPayloadCallback } from 'src/app/ui/shared/kcontext-menu/kcontext-payload';

import { AggreagateTreeUtil } from '../kaggregate-tree/aggregate-tree-util';
import { KAggregateTreeComponent } from '../kaggregate-tree/kaggregate-tree.component';
import { KEntityBaseComponentMenuActions } from '../kbase/kentity-base.component';
import { KnowItemNames } from './../../../../server/helpers/constants';
import { EntityHolder } from './../../../../server/model/entity-holder';
import { IdName } from './../../../../server/model/id-name';

@Component({
	selector: 'app-kcategory',
	templateUrl: './kcategory.component.html',
	styleUrls: ['./kcategory.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KCategoryComponent extends KAggregateTreeComponent {
	// treeData: TreeNode[] = [];
	selectorIsVisible: boolean = false;
	holderEntity: IdName | null = null;
	alreadyInCategories: string[] = [];

	override setValue(value: FieldValue | null): void {
		this.value = value;
		this.treeData = [];
		this.alreadyInCategories = [];

		if (value?.value as EntityHolder[]) {
			this.convertBindingToCategoryTree(this.treeData, value!.value, null, null);
		}

		this.cdr.markForCheck();
	}

	convertBindingToCategoryTree(
		container: TreeNode[],
		holders: EntityHolder[],
		parentId: string | null = null,
		parentNode: TreeNode | null,
	): void {
		holders
			.map((binding) => {
				return {
					category: binding.getField(KnowItemNames.Category)?.value,
					binding: binding,
				};
			})
			.filter((info) => info.category.parentId === parentId)
			.forEach((info: { category: EntityHolder; binding: EntityHolder }) => {
				const m: TreeNode = {
					id: info.category.instanceId,
					text: info.category.humanReadableName,
					holder: info.category,
					parent: parentNode,
					tag: info.binding,
					icon: 'assets/images/core/Folder.svg',
				};

				m.items = [];

				this.convertBindingToCategoryTree(m.items, holders, info.category.instanceId, m);

				this.alreadyInCategories.push(info.category.instanceId);
				container.push(m);
			});
	}

	override onContext(context: any) {
		// console.log(context);
		const isEmpty = context instanceof MouseEvent;

		if (isEmpty) {
			context.stopPropagation();
			context.preventDefault();
		}

		//  const menus = AggreagateTreeUtil.getMenuItems(context.node, this.node.advancedData2.entityId!, true);

		const menus = [
			AggreagateTreeUtil.createMenuItem(KEntityBaseComponentMenuActions.Add, '', this.node.advancedData2.entityId!),
		];

		if (!isEmpty) {
			menus.push(
				AggreagateTreeUtil.createMenuItem(
					KEntityBaseComponentMenuActions.DeleteRecursive,
					'',
					this.node.advancedData2.entityId!,
				),
			);
		}

		openContextMenu(this, context.x, context.y, context.node, this.readonly, menus);
	}

	override onAdd(payload: KContextPayloadCallback): void {
		this.getTargetEntityInfo(this.container.holder.entityId).subscribe((entityInfo) => {
			this.selectorIsVisible = true;
			console.log(entityInfo.categoryEntityId);
			this.holderEntity = new IdName(entityInfo.categoryEntityId!, entityInfo.metadata.name);
			//console.log(this.holderEntity);
			this.cdr.markForCheck();
		});

		// console.log('add');
		// const reference = payload.reference as { node:TreeNode, member: AggregateMember, mainMemberName: string};
		// console.log(payload);

		// this.createEntitiesFromMainMembers(payload.item.value!, null, [new EntityHolder()], payload.reference,
		//  this.node.attributeName, this.container.container.task.id)
		//   .subscribe((generatedEntities:EntityHolder[]) => {
		//     updateStateSetField(this.node.attributeName!, generatedEntities, this.container, this.container.container.task.id); // non sicuro task id???
		//   });
	}

	onSelectorClose(arg: CategorySelectorArg) {
		this.selectorIsVisible = false;
		// this.cdr.markForCheck();

		// create argument forse va mandato a mano
		// deve avere type entità parent, id e categoria??'

		// console.log(arg.selecteds);

		// rimuovo le categorie che sono già nella struttura
		arg.selecteds = arg.selecteds.filter((i) => this.alreadyInCategories.indexOf(i.instanceId) < 0);

		// console.log(arg.selecteds);

		this.createEntitiesFromMainMembers(
			this.node.advancedData2.entityId!,
			'Category',
			arg.selecteds,
			null,
			this.node.attributeName,
			this.container.task.id,
		).subscribe((generatedEntities) => {
			// console.log(generatedEntities);
			this.onFieldValueCollectionIsChanging(generatedEntities);
			// updateStateSetField(this.node.attributeName!, generatedEntities, this.container, this.container.container.task.id);
		});
	}

	override onDelete(payload: KContextPayloadCallback): void {
		const e = payload.reference as TreeNode;

		if (e) {
			const toDelete: string[] = [];

			const recursiveGetId = (node: TreeNode, collection: string[]) => {
				if (collection.indexOf(node.tag.id) < 0) {
					collection.push(node.tag.id);
				}
				node.items?.forEach((c) => {
					recursiveGetId(c, collection);
				});
			};

			recursiveGetId(e, toDelete);

			const newCollection = this.value?.value.filter((c: EntityHolder) => toDelete.indexOf(c.instanceId) < 0);
			this.fieldValueIsChanging(newCollection, true);
		}

		// super.delete(e.holder, true);
	}
	// onNodeClick(e: TreeNode):void {

	// }

	// onNodeMoved(nodes:TreeNode[]): void {
	//   // console.log(nodes);
	//   // const results:EntityHolder[] = [];
	//   // this.flatTree(nodes, results, 0);
	//   // this.onValueChanged(results);
	// }
}
