import { NotifyType } from './notify-type';

export class GlobalNotifyArg {
	duration: number;
	message: string;
	type: NotifyType;

	constructor(message: string, type: NotifyType = 'error', duration = 5000) {
		this.message = message;
		this.type = type;
		this.duration = duration;
	}
}
