import { Component } from '@angular/core';
import { first } from 'rxjs/operators';
import { EntityHolder } from 'src/app/server/model/entity-holder';
import { FieldValue } from 'src/app/server/model/field-value';
import { IKStaticComponent } from './abstract';

import { Subscription } from 'rxjs';
import { getPossibleOptions } from './functions';
import { KAttributeBaseComponent } from './kattribute-base.component';

@Component({
	selector: 'app-adv-attribute-base',
	template: '',
})
export class KStaticBaseComponent extends KAttributeBaseComponent implements IKStaticComponent {
	options: EntityHolder[] = [];
	s: Subscription | null = null;
	isLoading: boolean = false;

	override setValue(value: FieldValue | null): void {
		this.isLoading = true;

		this.s = getPossibleOptions(this)
			.pipe(first())
			.subscribe((c) => {
				this.options = [];

				if (c) {
					this.options = c;
				}

				super.setValue(value);
				this.isLoading = false;

				this.onValueReady();
			});
	}

	onValueReady(): void {}

	override ngOnDestroy(): void {
		super.ngOnDestroy();
		this.s?.unsubscribe();
	}
}
