import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-toolbar-two-box-container',
	templateUrl: './toolbar-two-box-container.component.html',
	styleUrls: ['./toolbar-two-box-container.component.scss'],
})
export class ToolbarTwoBoxContainerComponent {
	@Input() toolbarHeight: string = '2em';
	@Input() navSize: number = 60;
	@Input() detailSize: number = 40;
}
