export enum GlobalEventType {
	Login,
	Logout,
	TitleChange,
	Notify,
	ServerUrlChange,
	CleanCache,
	ShowLoader,
	HideLoader,
}
