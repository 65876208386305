export enum ViewItemUiControl {
	TextBox,
	ComboBox,
	CheckBox,
	TextArea,
	SearchPanel,
	DateBox,
	IntBox,
	TimeBox,
	MoneyBox,
	FloatBox,
	GridInline,
	GridPopUp,
	MasterDetail,
	OrderedTree,
	ManyToMany,
	ManyToManyDisconnect,
	Label,
	RichTextEditor,
	FileUpload,
	PasswordBox,
	TimeSpanBox,
	AggregateTree,
	Image,
	Color,
	Categories,
	WeekDays,
	GroupableGrid,
	BarCode,
	ProgressBox,
	Widget,
	PictureList,
	Dashboard,
	FloatBox4,
	WorkflowView,
	RadioButton,
	Chart,
	OrderedTreeRelated,
	HtmlViewer,

	OriginalName = 200,
	Size = 201,
	Extension = 202,
	CreationDate = 203,
	LastUpdate = 204,
	IsManual = 205,
	Download = 206,
	Upload = 207,
	FileManager = 208,

	VersionId = 301,
	VersionLabel = 302,

	Separator = 401,
	Text = 402,
	Query = 403,
	Form = 404,
	Link = 405,

	Role = 600,
	Unit = 601,
	Factory = 602,

	Intro = -1,
	Partition = -2,
	Attach = -3,
	Versions = -4,
	History = -5,

	RiskScore = 1432422376,
	RiskDetail = 1918853543,
	RiskScoreVals = 1123566973,

	QuestionsControl = -1774243916,
	KQuestionMinMax = 913646766,
	AnalysisCustomControl = 204654251,
	Gantt = 1800509146,
	KEventsCustomControlComponent = 164319595,
	KSessionDetailCustomControl = -1738924198,
	KStrategyMapCustomControl = -857102084,
	DashboardFilterValueSelector = -872029900,
	KBscCustomControl = -1214984251,
	KBscThroughYearsCustomControl = -162734281,
	GoalScoreCustomControl = 953432995,
	SysReportMatrix = 887001897,
}
