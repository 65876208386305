<dx-form id="form" *ngIf="column" [(formData)]="column">
	<dxi-item dataField="name" [label]="{ text: 'Colonna' }" editorType="dxSelectBox" [editorOptions]="nameOption">
	</dxi-item>
	<dxi-item dataField="caption" [label]="{ text: 'Testata' }"></dxi-item>
	<dxi-item dataField="size" [label]="{ text: 'Larghezza (niente indica automatica)' }"></dxi-item>
	<dxi-item dataField="control" [label]="{ text: 'Tipo di colonna' }" editorType="dxSelectBox"
		[editorOptions]="controlOption"></dxi-item>
	<dxi-item dataField="format" [label]="{ text: 'Formattazione' }" editorType="dxSelectBox"
		[editorOptions]="formatOption"></dxi-item>
	<dxi-item dataField="alignTo" [label]="{ text: 'Allineamento' }" editorType="dxSelectBox"
		[editorOptions]="alignToOption"></dxi-item>
	<dxi-item dataField="isGroup" [label]="{ text: 'Raggruppa' }" editorType="dxCheckBox"></dxi-item>
	<dxi-item dataField="versus" [label]="{ text: 'Ordinamento' }" editorType="dxSelectBox"
		[editorOptions]="sortByOption"></dxi-item>
	<dxi-item dataField="isHumanReadable" [label]="{ text: 'Leggibile' }" editorType="dxCheckBox"></dxi-item>
</dx-form>
