import { Component } from '@angular/core';
import { FieldValue } from 'src/app/server/model/field-value';
import { KAttributeBaseComponent } from '../kbase/kattribute-base.component';

@Component({
	selector: 'app-kcheck-box',
	templateUrl: './kcheck-box.component.html',
	styleUrls: ['./kcheck-box.component.scss'],
})
export class KCheckBoxComponent extends KAttributeBaseComponent {
	#innerValue: boolean | undefined | null;

	get innerValue(): boolean | undefined | null {
		return this.#innerValue!;
	}

	set innerValue(value: boolean | undefined | null) {
		if (this.allowNull && value === true && this.#innerValue !== undefined) {
			this.#innerValue = undefined;
			return;
		}

		// if (value as string) {
		//   this.#innerValue = true;
		// }

		this.#innerValue = value as boolean;
	}

	override setValue(value: FieldValue | null): void {
		super.setValue(value);

		if (value?.value != null) {
			this.#innerValue = value?.value;
		}

		if (!this.allowNull && this.#innerValue === undefined) {
			this.#innerValue = this.metadata?.defaultValue ?? false;
		}

		this.cdr.markForCheck();
	}

	onValueChanged(value: any) {
		this.fieldValueIsChanging(value.value);
	}
}
