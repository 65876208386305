import { ChangeDetectionStrategy, Component } from '@angular/core';
import { EnumHelper } from 'src/app/server/helpers/enum-helper';
import { FieldValue } from 'src/app/server/model/field-value';
import { ViewItemUiControl } from 'src/app/server/model/view-item-ui-control';
import { SetKString, ToKString } from 'src/app/shared/formatting/formatting-helper';
import { KAttributeBaseComponent } from '../kbase/kattribute-base.component';
import { TextBoxChangeArg } from '../shared/function';
import { EntityAttributeValueType } from './../../../../server/model/entity-attribute-value-type';
import { IdName } from './../../../../server/model/id-name';
import { LabelControlOption } from './ktextbox-option';

@Component({
	selector: 'app-ktextbox',
	templateUrl: './ktextbox.component.html',
	styleUrls: ['./ktextbox.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KTextboxComponent extends KAttributeBaseComponent {
	innerValue = '';
	mask: string = '';
	mode: string = 'text';
	isTimeSpan: boolean = false;
	staticValues: IdName[] = [];
	hideLabel: boolean = false;
	alertstyle: boolean = false;
	elementa: string = '';

	override onKeystoneInit() {
		super.onKeystoneInit();
		if (this.metadata?.values?.length > 0) {
			this.staticValues = this.metadata.values;
		}
	}

	override setValue(value: FieldValue | null): void {
		super.setValue(value);
		if (this.staticValues.length > 0) {
			this.innerValue = this.staticValues.find((c) => value?.value.toString() == c.id)?.name ?? '';
		} else {
			if (value?.value == null && this.node.attribute?.defaultValue) {
				value!.value = this.node.attribute?.defaultValue;
			}

			this.innerValue = ToKString(
				this.value?.value,
				this.type ?? EntityAttributeValueType.Text,
				value?.format,
				this.node.formatId,
			);
		}

		if (this.hideLabel) {
			this.label = null;
		}

		this.cdr.markForCheck();
	}

	override ngOnInit(): void {
		super.ngOnInit();
		if (!this.readonly) {
			this.readonly =
				this.node.controlType === ViewItemUiControl.Label ||
				this.node.controlType === ViewItemUiControl.VersionId ||
				this.node.controlType === ViewItemUiControl.VersionLabel;
		}

		// 'email' | 'password' | 'search' | 'tel' | 'text' | 'url'
		this.mode = this.node.controlType === ViewItemUiControl.PasswordBox ? 'password' : 'text';

		if (this.type === EntityAttributeValueType.TimeSpan) {
			this.mask = '00:00';
			this.isTimeSpan = true;
		}

		if (this.hideLabel) {
			this.label = null;
			this.alertstyle = true;
		}
	}

	onValueChanged(event: TextBoxChangeArg): void {
		if (this.readonly) return;
		// if (this.innerValue === event?.value) return;
		// previousValue: "NATALE"
		// value: "NATALE 12"

		if (this.value && event?.previousValue !== event?.value) {
			const newValue = SetKString(event.value, this.type ?? EntityAttributeValueType.Text, this.value?.format);
			// this.innerValue = newValue;
			// console.log(newValue);
			super.fieldValueIsChanging(newValue);
		}
	}

	override buildOptions(optionId: number | null): void {
		if (EnumHelper.is(optionId, LabelControlOption.HideLabel)) {
			this.hideLabel = true;
		}

		if (EnumHelper.is(optionId, LabelControlOption.Alert)) {
			this.alertstyle = true;
		}
	}
}
