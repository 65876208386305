import { AggregateMember } from './../../../../server/model/agregate-member';
import { TreeNode } from './../../../controls/tree/tree-note';

export class TreeNodeContextReference {
	node: TreeNode;

	constructor(node: TreeNode) {
		this.node = node;
	}
}

export class TreeNodeReference {
	node: TreeNode | null;
	member: AggregateMember;
	mainMemberName: string | null;

	constructor(
		node: TreeNode | null,
		member: AggregateMember,
		mainMemberName: string | null,
	) {
		this.node = node;
		this.member = member;
		this.mainMemberName = mainMemberName;
	}
}
