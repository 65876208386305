import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { KFilterEditorComponent } from '../../kfilter-editor.component';
import { FilterEditorBaseComponent } from '../filter-editor-base.component';

@Component({
	selector: 'app-number-filter',
	templateUrl: './number-filter.component.html',
	styleUrls: ['./number-filter.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NumberFilterComponent extends FilterEditorBaseComponent {
	constructor(parent: KFilterEditorComponent, cdr: ChangeDetectorRef) {
		super(parent, cdr);

		if (this.parent.filter && this.parent.filter.value) this.value = this.parent.filter.value;
		else this.value = null;
	}
}
