<h1>Inizializzazione</h1>
<form class="login-form" (submit)="onSubmit($event)">
	<dx-form [formData]="formData" [disabled]="loading">
		<dxi-item
			dataField="serverUrl"
			editorType="dxTextBox"
			[editorOptions]="{
				stylingMode: 'filled',
				placeholder: 'Indirizzo del server',
				mode: 'url'
			}"
		>
			<dxi-validation-rule
				type="required"
				message="L'indirizzo del server è obbligatorio"
			></dxi-validation-rule>
			<dxi-validation-rule
				type="pattern"
				[pattern]="namePattern"
				message="https://your.domain.info/"
			></dxi-validation-rule>
			<dxo-label [visible]="false"></dxo-label>
		</dxi-item>

		<dxi-item itemType="button">
			<dxo-button-options
				width="100%"
				type="default"
				[useSubmitBehavior]="true"
				[template]="'signInTemplate'"
			>
			</dxo-button-options>
		</dxi-item>

		<ng-container *dxTemplate="let item of 'signInTemplate'">
			<span class="dx-button-text">
				<ng-container *ngIf="loading; else notLoading">
					<dx-load-indicator
						width="24px"
						height="24px"
						[visible]="true"
					></dx-load-indicator>
				</ng-container>
				<ng-template #notLoading>Memorizza</ng-template>
			</span>
		</ng-container>
	</dx-form>
</form>
