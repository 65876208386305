import { Injectable } from '@angular/core';
import { forkJoin, from, Observable, of } from 'rxjs';
import { catchError, first, map, switchMap, tap } from 'rxjs/operators';
import { KnowItemNames } from 'src/app/server/helpers/constants';
import { AggregateMember } from 'src/app/server/model/agregate-member';
import { CacheService } from '../shared/caching/cache-service';
import { EntitiesCache, GetPossibleUiCache, QuickFiltersCache } from '../shared/caching/entities-cache';
import { MenuCache } from '../shared/caching/menu-cache';
import { ApiService } from './api.service';
import { DiagnoseService } from './diagnose.service';
import { entityCachaType } from './entity-cache-type';
import { KnowEntityIds } from './helpers/constants';
import { EnumHelper } from './helpers/enum-helper';
import { AdvancedData } from './model/advanced-data';
import { AggregateMemberCache, CategoryCache, OrganizationTreeCache } from './model/agregate-member';
import { ApiResponse } from './model/api.response';
import { Command } from './model/commad';
import { EntityAttachmentTypeEnum, EntityAttributeType, ModuleEntityViewTypeEnum } from './model/entity-attribute-type';
import { EntityAttributeValueType } from './model/entity-attribute-value-type';
import { EntityHolder } from './model/entity-holder';
import { EntityInfo } from './model/entity-info';
import { EntityResponse } from './model/entity-response';
import { GetPossibleUiResponse, UiControlConfiguration } from './model/get-possible-ui-response';
import { LoadApiArgRequest } from './model/load-api-request-arg';
import { LoadResponse } from './model/load-response';
import { LogicalAttributeType } from './model/logical-attribute-type';
import { LogicalEntityType } from './model/logical-entity-type';
import { MenuResponse } from './model/menu-response';
import { NavigationNode } from './model/navigation-node';
import { QuickFilter } from './model/quick-filters-response';
import {
	CloneApiResponse,
	DataPartition,
	DataPartitionUnit,
	EntityHistory,
	EntityVersionInformation,
	ExecuteCustomActionResponse,
	ExecuteElaborateResponse,
	GetAttachmentsResponse,
	GetCustomResultColumnsResponse,
	GetDynamicFiltersResponse,
	GetHistoryResponse,
	GetLinkHolderResponse,
	GetOrganizationTreeResponse,
	GetRelatedTreeResponse,
	GetUnitsResponse,
	GetVersionsResponse,
	PivotCustomColumn,
	PostSearchAnalisysResponse,
	rawToExecuteCustomActionResponse,
	SaveResponse,
	SetCustomResultColumnsResponse,
} from './model/save-response';
import { SearchApiColumn } from './model/search-api-column';
import { SearchColumnResult } from './model/search-column-result';
import { SearchParameter } from './model/search-parameter';
import { SearchParameterType } from './model/search-parameter-type';
import { SearchRequest } from './model/search-request';
import { SearchResponse } from './model/search-response';
import { SimpleEntityMetadata } from './model/simple-entity-metadata';
import { FavoriteApiResponse, FileResponse, TemporaryImageUrlResponse } from './model/temporari-image-url-response';
import { ViewResultNode } from './model/view-result.node';

// TODO ereditare da ApiServiceBase
@Injectable({
	providedIn: 'root',
})
export class EntityService {
	private api: ApiService;
	private cache: CacheService;
	private diagnose: DiagnoseService;

	constructor(api: ApiService, cache: CacheService, diagnose: DiagnoseService) {
		this.api = api;
		this.cache = cache;
		this.diagnose = diagnose;
	}

	createNewWithExistingParent(fromTypeId: string, toTypeId: string, instanceId: string): Observable<ApiResponse> {
		const request = {
			fromTypeId,
			toTypeId,
			instanceId,
		};

		console.log(request);

		return this.api.post<SaveResponse>(this.api.getUrl('/api/entity/new-from-parent'), request).pipe(
			map(
				(raw) => {
					return Object.assign(new CloneApiResponse(), raw);
				},
				catchError(() => {
					return of({
						success: false,
						message: `createNewWithExistingParent failed for: ${fromTypeId}-${instanceId}`,
					});
				}),
			),
		);
	}

	private getMetadata(
		entityId: string,
		viewId: string | null = null,
		level: number = 0,
		skipCache: boolean,
	): Observable<EntityInfo> {
		const request = {
			entityId,
			viewId,
			skipCache,
		};

		return this.api.post(this.api.getUrl('/api/entity/metadata'), request).pipe(
			map((raw: any) => {
				this.diagnose.logStatistics(raw, entityId);
				if (!raw.entity) {
					return null;
				}

				return raw;
			}),
		);
	}

	getEntityInfo(entityId: string): Observable<EntityInfo> {
		if (!entityId) {
			console.error('Empty Entity Id');
		}

		const o = from(this.cache.get(entityId, entityCachaType.EntityInfo));
		let needStore = false;

		return o.pipe(
			map((d) => {
				if (d && !this.diagnose.needTo('skipCache')) {
					return d.data;
				} else {
					return null;
				}
			}),
			switchMap((d) => {
				if (d == null) {
					needStore = true;
					return this.getMetadata(entityId, undefined, undefined, this.diagnose.needTo('skipCache'));
				} else {
					return of(d);
				}
			}),
			map((d) => {
				if (!d) {
					console.error(entityId);
				}
				return EntityInfo.parse(d);
			}),
			tap((d) => {
				if (needStore) {
					this.cache.set(d, entityCachaType.EntityInfo);
				}
			}),
		);
	}

	getEntity(entityId: string): Observable<SimpleEntityMetadata | null> {
		return this.getEntities(false).pipe(
			first(),
			map((c: SimpleEntityMetadata[]) => {
				return c.find((d) => d.id === entityId) ?? null;
			}),
		);
	}

	getQuickFilters(entityId: string): Observable<QuickFilter[]> {
		const o = from(this.cache.get(entityId, entityCachaType.QuickFiltersCache));
		let needStore = false;

		const request = {
			entityId,
		};

		return o.pipe(
			map((d) => {
				if (d && !this.diagnose.needTo('skipQuickFilterCache')) {
					return d.data;
				} else {
					return null;
				}
			}),
			switchMap((d: string | null) => {
				if (d == null) {
					needStore = true;

					return this.api.post(this.api.getUrl('/api/entity/search-quick-filters'), request).pipe(
						map((queryFilterResponse: any) => {
							return queryFilterResponse.filters.map((c: any) => QuickFilter.parse(c)); // QuickFiltersResponse.parse(entityId, c);
						}),
					);
				} else {
					return of(d);
				}
			}),
			tap((d: QuickFilter[]) => {
				if (needStore && d) {
					this.cache.set(new QuickFiltersCache(entityId, d), entityCachaType.QuickFiltersCache);
				}
			}),
			map((d: QuickFilter[]) => {
				return d;
			}),
		);
	}

	getPossibleUi(
		entityId: string,
		logicalType: LogicalAttributeType,
		attributeType: EntityAttributeType,
		viewType: ModuleEntityViewTypeEnum,
		valueAttributeType: EntityAttributeValueType,
	): Observable<UiControlConfiguration> {
		const k = entityId + logicalType + attributeType + viewType + valueAttributeType;
		const o = from(this.cache.get(k, entityCachaType.GetPossibleUiCache));
		let needStore = false;

		const request = {
			entityId,
			logicalType,
			attributeType,
			viewType,
			valueAttributeType,
		};

		return o.pipe(
			map((d) => {
				if (d && !this.diagnose.needTo('skipGetPossibleUiCache')) {
					return d.data;
				} else {
					return null;
				}
			}),
			switchMap((d: UiControlConfiguration | null) => {
				if (d == null) {
					needStore = true;

					return this.api.post<GetPossibleUiResponse>(this.api.getUrl('/api/entity/get-possible-ui'), request).pipe(
						map((response: GetPossibleUiResponse) => {
							return response.ui;
						}),
					);
				} else {
					return of(d);
				}
			}),
			tap((d: UiControlConfiguration) => {
				if (needStore && d) {
					this.cache.set(new GetPossibleUiCache(k, d), entityCachaType.GetPossibleUiCache);
				}
			}),
			map((d: UiControlConfiguration) => {
				return d;
			}),
		);
	}

	getEntities(rootOnly: boolean): Observable<SimpleEntityMetadata[]> {
		const o = from(this.cache.get('Shared', entityCachaType.EntitiesCache));
		let needStore = false;

		return o.pipe(
			map((d) => {
				if (d) {
					return d.data;
				} else {
					return null;
				}
			}),
			switchMap((d: SimpleEntityMetadata[] | null) => {
				if (d == null) {
					needStore = true;

					return this.api.post<EntityResponse>(this.api.getUrl('/api/entity/get-entities'), {}).pipe(
						map((c) => {
							if (rootOnly) {
								return c.results.filter((d1) => EnumHelper.is(d1.logicalType, LogicalEntityType.Root));
							} else {
								return c.results;
							}
						}),
					);
				} else {
					return of(d);
				}
			}),
			tap((d: SimpleEntityMetadata[] | null) => {
				if (needStore && d) {
					this.cache.set(new EntitiesCache(d), entityCachaType.EntitiesCache);
				}
			}),
			map((d: SimpleEntityMetadata[] | null) => {
				return d ?? [];
			}),
		);
	}

	getMenu(): Observable<NavigationNode[]> {
		const o = from(this.cache.get('Shared', entityCachaType.MenuCache));
		let needStore = false;

		return o.pipe(
			map((d) => {
				if (d && !this.diagnose.needTo('skipMenuCache')) {
					return d.data;
				} else {
					return null;
				}
			}),
			switchMap((d: string | null) => {
				if (d == null) {
					needStore = true;
					return this.api
						.post<MenuResponse>(this.api.getUrl('/api/entity/menu'), {
							isGodMode: true,
						})
						.pipe(
							map((c2: MenuResponse) => {
								return new MenuCache(c2);
							}),
						);
				} else {
					return of(new MenuCache(d));
				}
			}),
			tap((d: MenuCache | null) => {
				if (needStore && d) {
					this.cache.set(d, entityCachaType.MenuCache);
				}
			}),
			map((d: MenuCache | null) => {
				this.diagnose.logIf('showMenu', d?.nodes);
				return d?.nodes ?? [];
			}),
		);
	}

	private innerGetAggregateInfos(entityId: string): Observable<AggregateMember | null> {
		const request = {
			entityId,
		};

		return this.api.post(this.api.getUrl('/api/entity/get-aggregate-infos'), request).pipe(
			map((response: any) => {
				if (response.success) {
					return response.info;
				}
				return null;
			}),
		);
	}

	getAggregateInfos(entityId: string): Observable<AggregateMember> {
		const o = from(this.cache.get(entityId, entityCachaType.AgregateMemberCache));

		let needStore = false;

		return o.pipe(
			map((d) => {
				if (d && !this.diagnose.needTo('skipAggregateCache')) {
					return d.data;
				} else {
					return null;
				}
			}),
			switchMap((d) => {
				if (d == null) {
					needStore = true;
					return this.innerGetAggregateInfos(entityId);
				} else {
					return of(d);
				}
			}),
			map((d) => {
				return d;
			}),
			tap((d) => {
				if (needStore) {
					this.cache.set(new AggregateMemberCache(entityId, d), entityCachaType.AgregateMemberCache);
				}
			}),
		);
	}

	getCategories(entityId: string | null, refresh: boolean = false): Observable<EntityHolder[]> {
		let cacheKey: string = '';

		if (entityId) {
			cacheKey = entityId;
		}

		const o = from(this.cache.get(cacheKey, entityCachaType.CategoryCache));

		let needStore = false;

		return o.pipe(
			map((d) => {
				if (!refresh && d && !this.diagnose.needTo('skipCategoryCache')) {
					return {
						results: d.data,
					};
				} else {
					return null;
				}
			}),
			switchMap((d) => {
				if (d == null) {
					needStore = true;

					const filters: SearchParameter[] = [];

					const filter = SearchParameter.createWithOperator(
						KnowItemNames.Type,
						SearchParameterType.Equal,
						cacheKey === '' ? null : cacheKey,
					);
					filters.push(filter);
					const columns: SearchApiColumn[] = [new SearchApiColumn('Parent'), new SearchApiColumn('Index')];
					return this.search(KnowEntityIds.GlobalCategory, columns, null, 1, 1000, filters, false);
				} else {
					return of(d);
				}
			}),
			map((d) => {
				if (d instanceof SearchResponse) {
					return d.results;
				} else {
					return d?.results;
				}
			}),
			tap((d) => {
				if (needStore) {
					this.cache.set(new CategoryCache(cacheKey, d), entityCachaType.CategoryCache);
				}
			}),
		);
	}

	setCustomResultColumns(entityId: string, queryId: string, columns: PivotCustomColumn[]): Observable<boolean> {
		const request = {
			entityId,
			queryId,
			columns,
		};

		this.cache.delete(entityId, entityCachaType.EntityInfo);

		return this.api
			.post<SetCustomResultColumnsResponse>(this.api.getUrl('/api/entity/set-custom-result-column'), request)
			.pipe(
				map(
					(raw) => {
						this.diagnose.logStatistics(raw);
						return raw.success;
					},
					catchError(() => {
						return of(null);
					}),
				),
			);
	}

	getCustomResultColumns(entityId: string, queryId: string, userQueryId: string): Observable<PivotCustomColumn[]> {
		const request = {
			entityId,
			queryId,
			userQueryId,
		};

		return this.api
			.post<GetCustomResultColumnsResponse>(this.api.getUrl('/api/entity/get-custom-result-column'), request)
			.pipe(
				map(
					(raw) => {
						this.diagnose.logStatistics(raw);
						return raw.columns;
					},
					catchError(() => {
						return of(null);
					}),
				),
			);
	}

	getHistory(entityId: string, instanceId: string): Observable<EntityHistory[]> {
		const request = {
			entityId,
			instanceId,
		};

		return this.api.post<GetHistoryResponse>(this.api.getUrl('/api/entity/get-history'), request).pipe(
			map(
				(raw) => {
					this.diagnose.logStatistics(raw);
					return raw.histories;
				},
				catchError(() => {
					return of(null);
				}),
			),
		);
	}

	getVersions(entityId: string, instanceId: string): Observable<EntityVersionInformation[]> {
		const request = {
			entityId,
			instanceId,
		};

		return this.api.post<GetVersionsResponse>(this.api.getUrl('/api/entity/get-versions'), request).pipe(
			map(
				(raw) => {
					this.diagnose.logStatistics(raw);
					return raw.versions;
				},
				catchError(() => {
					return of(null);
				}),
			),
		);
	}

	getAttachments(
		memberId: string,
		memberTypeId: string,
		type: EntityAttachmentTypeEnum | null = null,
		includeReversible: boolean = false,
	) {
		const request = {
			memberId,
			memberTypeId,
			type,
			includeReversible,
		};

		return this.api.post<GetAttachmentsResponse>(this.api.getUrl('/api/entity/get-attachments'), request).pipe(
			map(
				(raw) => {
					this.diagnose.logStatistics(raw);
					return raw.attachments;
				},
				catchError(() => {
					return of(null);
				}),
			),
		);
	}

	getOrganizationTree(id: string | null): Observable<DataPartition> {
		let cacheKey: string = '';

		if (id) {
			cacheKey = id;
		}

		const o = from(this.cache.get(cacheKey, entityCachaType.OrganizationTreeCache));

		let needStore = false;

		return o.pipe(
			map((d) => {
				if (d && !this.diagnose.needTo('skipOrganizationUnitCache')) {
					return {
						tree: d.data,
					};
				} else {
					return null;
				}
			}),
			switchMap((d) => {
				if (d == null) {
					needStore = true;

					const request = {
						id: null,
					};

					return this.api.post<GetOrganizationTreeResponse>(this.api.getUrl('/api/entity/organization-tree'), request);
				} else {
					return of(d);
				}
			}),
			map((d) => {
				return d.tree as DataPartition;
			}),
			tap((d) => {
				if (needStore) {
					this.cache.set(new OrganizationTreeCache(cacheKey, d), entityCachaType.OrganizationTreeCache);
				}
			}),
		);
	}

	getUnits(entityId: string, id: string | null = null): Observable<DataPartitionUnit[]> {
		const request = {
			entityId,
			id,
		};

		// console.log(request);

		return this.api.post<GetUnitsResponse>(this.api.getUrl('/api/entity/entity-units'), request).pipe(
			map(
				(raw) => {
					this.diagnose.logStatistics(raw);
					return raw.units;
				},
				catchError(() => {
					return of(null);
				}),
			),
		);
	}

	postSearchAnalisys(
		entityId: string,
		caller: EntityHolder | null,
		items: EntityHolder[],
		excludeFilter: boolean,
		columns: ViewResultNode[],
	): Observable<EntityHolder[]> {
		const request = {
			entityId,
			caller: caller?.prepareForSave(),
			items,
			excludeFilter,
			columns,
		};

		// console.log(request);

		return this.api.post<PostSearchAnalisysResponse>(this.api.getUrl('/api/entity/post-search-analysis'), request).pipe(
			map(
				(raw) => {
					this.diagnose.logStatistics(raw);
					return raw.items;
				},
				catchError(() => {
					return of([]);
				}),
			),
		);
	}

	getSearchDinamicFilters(
		entityId: string,
		caller: EntityHolder | null,
		items: SearchParameter[],
		isForNew: boolean,
	): Observable<SearchParameter[]> {
		const request = {
			entityId,
			caller: caller?.prepareForSave(),
			items,
			isForNew,
		};

		// console.log(request);

		return this.api.post<GetDynamicFiltersResponse>(this.api.getUrl('/api/entity/get-dynamic-filters'), request).pipe(
			map(
				(raw) => {
					this.diagnose.logStatistics(raw);
					return raw.filters;
				},
				catchError(() => {
					return of([]);
				}),
			),
		);
	}

	load(
		entityId: string,
		id: string | null = null,
		args: Map<string, any> | null = null,
		owner: EntityHolder | null = null,
	): Observable<EntityHolder | null> {
		const argsToSend: LoadApiArgRequest[] = [];

		if (args) {
			args.forEach((value: string, key: string) => {
				argsToSend.push(new LoadApiArgRequest(key, value));
			});
		}

		const request = {
			entityId,
			id,
			args: argsToSend,
		};

		// console.log(request);

		return this.api.post<LoadResponse>(this.api.getUrl('/api/entity/load'), request).pipe(
			map(
				(raw) => {
					this.diagnose.logStatistics(raw);
					const e = Object.assign(new LoadResponse(), raw);
					const x = EntityHolder.parse(e.data, owner, true);
					return x;
				},
				catchError(() => {
					return of(null);
				}),
			),
		);
	}

	createVersion(entityId: string, instanceId: string | null, versionLabel: string): Observable<ApiResponse> {
		const request = {
			entityId,
			instanceId,
			versionLabel,
		};

		return this.api.post<ApiResponse>(this.api.getUrl('/api/entity/create-version'), request);
	}

	executeCommand(
		entityId: string,
		instanceId: string | null,
		currentData: EntityHolder | null,
		command: Command,
		args: string[],
		commit: boolean,
	): Observable<ExecuteCustomActionResponse> {
		const request = {
			entityId: entityId,
			instanceId: instanceId,
			data: currentData?.prepareForSave(),
			command,
			args,
			commit,
		};

		return this.api.post<ExecuteCustomActionResponse>(this.api.getUrl('/api/entity/execute-command'), request).pipe(
			map(
				(raw) => {
					this.diagnose.logStatistics(raw);
					return rawToExecuteCustomActionResponse(raw);
				},
				catchError(() => {
					return of({
						success: false,
						message: 'execute elaborate failed for:' + request.entityId,
					});
				}),
			),
		);
	}

	reSynch(entityId: string, payload: any): Observable<ApiResponse> {
		const request = {
			entityId,
			includeRelated: payload === 'resych-all',
		};

		return this.api.post<ApiResponse>(this.api.getUrl('/api/entity/resynch'), request).pipe(
			map(
				(raw) => {
					this.diagnose.logStatistics(raw);
					return raw;
				},
				catchError(() => {
					return of({
						success: false,
						message: 'execute ReSych failed for:' + request.entityId,
					});
				}),
			),
		);
	}

	reIndex(entityId: string, cleanUp: boolean): Observable<ApiResponse> {
		const request = {
			entityId,
			cleanUp,
		};

		return this.api.post<ApiResponse>(this.api.getUrl('/api/entity/reindex'), request).pipe(
			map(
				(raw) => {
					this.diagnose.logStatistics(raw);
					return raw;
				},
				catchError(() => {
					return of({
						success: false,
						message: 'execute reIndex failed for:' + request.entityId,
					});
				}),
			),
		);
	}

	executeElaborate(
		entityId: string,
		currentData: EntityHolder,
		attributeChangedOrFunction: string | null = null,
		commit: boolean = false,
	): Observable<ExecuteElaborateResponse> {
		const request = {
			entityId: entityId,
			currentData: currentData.prepareForSave(),
			attributeChangedOrFunction: attributeChangedOrFunction,
			commit: commit,
		};

		return this.api.post<ExecuteElaborateResponse>(this.api.getUrl('/api/entity/execute-elaborate'), request).pipe(
			map(
				(raw) => {
					this.diagnose.logStatistics(raw);
					return ExecuteElaborateResponse.parse(raw);
				},
				catchError(() => {
					return of({
						success: false,
						message: 'execute elaborate failed for:' + request.entityId,
					});
				}),
			),
		);
	}

	executeCustomAction(
		entityId: string,
		data: EntityHolder,
		customActionId: string,
		choice: number,
		units: string[],
		instanceId: string,
		persist: boolean,
	): Observable<ExecuteCustomActionResponse> {
		// TODO executeElaborate

		const request = {
			entityId,
			data: data.prepareForSave(),
			customActionId,
			choice,
			units,
			instanceId,
			persist,
		};

		return this.api
			.post<ExecuteCustomActionResponse>(this.api.getUrl('/api/entity/execute-custom-action'), request)
			.pipe(
				map((raw: any) => {
					// console.log(raw);
					return rawToExecuteCustomActionResponse(raw);
				}),
			);
	}

	deleteMany(entries: { entityId: string; id: string }[]): Observable<ApiResponse> {
		const request = {
			entries: entries,
		};
		return this.api.post<ApiResponse>(this.api.getUrl('/api/entity/delete-many'), request);
	}

	delete(entityId: string, instanceId: string): Observable<ApiResponse> {
		const request = {
			entityId,
			id: instanceId,
		};
		return this.api.post<ApiResponse>(this.api.getUrl('/api/entity/delete'), request);
	}

	clone(
		entityId: string,
		masterId: string,
		newName: string | null = null,
		replaceEntireName: boolean = true,
	): Observable<CloneApiResponse> {
		const request = {
			entityId,
			masterId,
			newName,
			replaceEntireName,
		};

		return this.api.post<SaveResponse>(this.api.getUrl('/api/entity/clone'), request).pipe(
			map(
				(raw) => {
					return Object.assign(new CloneApiResponse(), raw);
				},
				catchError(() => {
					return of({
						success: false,
						message: `clone failed for: ${entityId}-${masterId}`,
					});
				}),
			),
		);
	}

	saveAll(data: EntityHolder[], units: string[] | null = null): Observable<SaveResponse[]> {
		const arr = data.map((ad) => this.save(ad, units, false));
		return forkJoin(arr);
	}

	save(data: EntityHolder, units: string[] | null = null, asModel: boolean): Observable<SaveResponse> {
		const request = {
			data: data.prepareForSave(),
			units,
			asModel,
		};

		this.diagnose.logStatistics(request);
		// console.log(request.data);

		return this.api.post<SaveResponse>(this.api.getUrl('/api/entity/web-save'), request).pipe(
			map(
				(raw) => {
					this.diagnose.logStatistics(raw);
					return SaveResponse.parse(raw);
				},
				catchError(() => {
					return of({
						success: false,
						message: 'save failed for:' + request.data.entityId,
					});
				}),
			),
		);
	}

	search(
		entityId: string,
		columns: ViewResultNode[] | SearchApiColumn[] | null = null,
		orders: SearchColumnResult[] | SearchApiColumn[] | null = null,
		page: number = 1,
		pageSize: number = 20,
		filters: SearchParameter[],
		applyMetadata: boolean = true,
	): Observable<SearchResponse | null> {
		const innerColumns: SearchApiColumn[] = [];

		columns?.forEach((column: ViewResultNode | SearchApiColumn) => {
			if (column instanceof ViewResultNode) {
				innerColumns.push(new SearchApiColumn(column.name));
			} else {
				innerColumns.push(column);
			}
		});

		const innerOrders: SearchApiColumn[] = [];

		orders?.forEach((order: SearchColumnResult | SearchApiColumn) => {
			if (order instanceof SearchColumnResult) {
				// console.log(order.attributeName);
				innerOrders.push(new SearchApiColumn(order.attributeName, order.versus));
			} else {
				innerOrders.push(order);
			}
		});

		const request: SearchRequest = {
			entityId,
			onlyActive: false,
			filters,
			columns: innerColumns,
			orders: innerOrders,
			page,
			pageSize,
		};

		// console.log(request);

		return this.api.post<SearchResponse>(this.api.getUrl('/api/search'), request).pipe(
			tap((d: SearchResponse) => {
				this.diagnose.logStatistics(d);
			}),
		);
	}

	quickSearch(
		entityId: string,
		namePart: string | null = null,
		filters: SearchParameter[] | null = null,
		columns: ViewResultNode[] | null = null,
		orders: SearchColumnResult[] | null = null,
	): Observable<SearchResponse | null> {
		const searchFilters: SearchParameter[] = [];

		if (namePart) {
			searchFilters.push(SearchParameter.createWithOperator('HumanReadable', SearchParameterType.Contains, namePart));
		}

		return this.search(entityId, columns, orders, 0, 1000, searchFilters, false);
	}

	createBarcode(barcode: string): Observable<TemporaryImageUrlResponse | boolean> {
		const request = {
			barcode,
		};

		return this.api.post<TemporaryImageUrlResponse>(this.api.getUrl('/api/entity/create-barcode'), request).pipe(
			map(
				(d) => {
					console.log(d);
					return new TemporaryImageUrlResponse(this.api.getUrl(''), d.fileName, d.url, d.size, barcode);
				},
				catchError(() => {
					return of(false);
				}),
			),
		);
	}

	getDocument(entityId: string, instanceId: string): Observable<FileResponse | null> {
		const request = {
			entityId,
			instanceId,
		};

		return this.api.post<FileResponse>(this.api.getUrl('/api/entity/get-doc'), request).pipe(
			map(
				(d) => {
					return d as FileResponse;
				},
				catchError(() => {
					return of(null);
				}),
			),
		);
	}

	getFile(tempId: string, onlyInfo: boolean): Observable<FileResponse> {
		const request = {
			TokenId: tempId,
			OnlyInfo: onlyInfo,
			Type: EntityAttributeValueType.File,
		};

		return this.api.post<TemporaryImageUrlResponse>(this.api.getUrl('/api/entity/temp-image'), request).pipe(
			map(
				(d) => {
					// console.log(d);
					return Object.assign(new FileResponse(), d);
				},
				catchError(() => {
					return of(false);
				}),
			),
		);
	}

	getImage(tempId: string, niceName: string | null = null): Observable<TemporaryImageUrlResponse | boolean> {
		const request = {
			TokenId: tempId,
			OnlyInfo: false,
			Type: EntityAttributeValueType.Image,
		};

		return this.api.post<TemporaryImageUrlResponse>(this.api.getUrl('/api/entity/temp-image-url'), request).pipe(
			map(
				(d) => {
					// console.log(d);
					return new TemporaryImageUrlResponse(this.api.getUrl(''), d.fileName, d.url, d.size, niceName);
				},
				catchError(() => {
					return of(false);
				}),
			),
		);
	}

	getPrints(entityId: string, ids: string[], printId: string): Observable<FileResponse | null> {
		const request = {
			entityId,
			ids,
			printId,
		};

		return this.api.post<FileResponse>(this.api.getUrl('/api/entity/generate-prints'), request).pipe(
			map(
				(d) => {
					console.log(d);
					return d as FileResponse;
					// return new TemporaryImageUrlResponse(this.api.getUrl(''), d.fileName, d.url, d.size);
				},
				catchError(() => {
					return of(null);
				}),
			),
		);
	}

	toggleFavorite(entityId: string, instanceId: string): Observable<boolean> {
		const request = {
			entityId,
			instanceId,
		};

		return this.api.post<FavoriteApiResponse>(this.api.getUrl('/api/entity/toggle-favorite'), request).pipe(
			map(
				(d) => {
					console.log(d);
					return d.isFavorite;
				},
				catchError(() => {
					return of(false);
				}),
			),
		);
	}

	isFavorite(entityId: string, instanceId: string): Observable<boolean> {
		const request = {
			entityId,
			instanceId,
		};

		return this.api.post<FavoriteApiResponse>(this.api.getUrl('/api/entity/is-favorite'), request).pipe(
			map(
				(d) => {
					// console.log(d);
					return d.isFavorite;
				},
				catchError(() => {
					return of(false);
				}),
			),
		);
	}

	getRelatedTree(
		entityId: string,
		filters: SearchParameter[],
		orders: SearchColumnResult[],
	): Observable<EntityHolder[]> {
		const request = {
			entityId,
			filters,
			orders,
		};

		return this.api.post<GetRelatedTreeResponse>(this.api.getUrl('/api/entity/get-related-tree'), request).pipe(
			map(
				(raw) => {
					this.diagnose.logStatistics(raw);
					return (
						raw.holders.map((c) => {
							var e = EntityHolder.parse(c)!;
							return e;
						}) ?? []
					);
				},
				catchError(() => {
					return of(null);
				}),
			),
		);
	}

	getLinkHolder(data: AdvancedData, holder: EntityHolder): Observable<EntityHolder | null> {
		const request = {
			data,
			holder,
		};

		return this.api.post<GetLinkHolderResponse>(this.api.getUrl('/api/entity/get-link-holder'), request).pipe(
			map(
				(raw) => {
					this.diagnose.logStatistics(raw);
					return raw.holder;
				},
				catchError(() => {
					return of(null);
				}),
			),
		);
	}
}
