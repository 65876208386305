import { Injectable } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Observable, Subject, Subscription, from, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { entityCachaType } from 'src/app/server/entity-cache-type';
import { EntityService } from 'src/app/server/entity.service';
import { GenericHelper } from 'src/app/server/helpers/generic-helper';
import { KTaskStateService } from 'src/app/server/kform-data-state.service';
import { ApiResponse } from 'src/app/server/model/api.response';
import { EntityHolder } from 'src/app/server/model/entity-holder';
import { CloneApiResponse } from 'src/app/server/model/save-response';
import { CacheEntry } from 'src/app/shared/caching/cache-entry';
import { CacheService } from 'src/app/shared/caching/cache-service';
import { TasksCache } from 'src/app/shared/caching/tasks-cache';
import { knownRuotes } from 'src/app/shared/known-ruotes';
import { GlobalService } from 'src/app/shared/services';
import { GlobalEventType } from 'src/app/shared/services/global/global-event-type';
import { GlobalTitle } from 'src/app/shared/services/global/global-title';
import { GlobalTitleChangeArg } from 'src/app/shared/services/global/global-title-change-arg';
import { KFieldValueChangePayload } from '../../edit/kcomponents/kbase/kfield-Value-change-payload';
import { KFormFieldRefreshPayload } from '../../edit/kcomponents/kbase/kfield-refresh-payload';
import { KFormClosePayload } from '../../edit/kcomponents/kbase/kform-close-payload';
import { KFormData } from '../../edit/kform/kform-data';
import { KToolbarPayload, KToolbarSelectorload } from '../../edit/ktoolbar/ktoolbar-payload';
import { KContextPayloadCallback } from '../../shared/kcontext-menu/kcontext-payload';
import { KRouteRequestPayload } from './kroute-request-payload';
import { KSearchOpenPayload } from './ksearch-open-payload';
import { KTaskSelectedElementChange } from './ktask-chage-payload';
import { KTaskBarEvent, KTaskBarEventType } from './ktaskbar-event';
import { KTitleChangePayload } from './ktitle-change-payload';
import { managerOpenSearchTask, managerOpenTask } from './manager-functions';
import { Task, TaskAction, TaskRelation } from './task';
import { removeAllBuNotThis, removeAllTasks, removeTask } from './task-functions';
import { TaskServiceArg } from './task-service-arg';

@Injectable({
	providedIn: 'root',
})
export class KTaskbarService {
	#events = new Subject<KTaskBarEvent>();
	public trigger: Observable<KTaskBarEvent> = this.#events;
	task: Task | null = null;
	cache: CacheService;
	route: ActivatedRoute;
	service: EntityService;
	router: Router;
	global: GlobalService;
	state: KTaskStateService;
	tasks: Task[] = [];
	subscription: Subscription | null = null;
	isReload = true;
	quickSelectorVisible: boolean = false;
	quickSelectorPayload: KToolbarSelectorload | null = null;

	constructor(
		cache: CacheService,
		route: ActivatedRoute,
		service: EntityService,
		router: Router,
		global: GlobalService,
		state: KTaskStateService,
	) {
		this.cache = cache;
		this.route = route;
		this.service = service;
		this.router = router;
		this.global = global;
		this.state = state;

		this.subscription = this.trigger.subscribe((e) => this.handleEvents(e));
	}

	init(params: Params): Observable<TaskServiceArg> {
		const action = params['actionId'];
		if (action) {
			this.startLoader();
		}

		let entityId: string | null = params['entityId'];
		let id: string | null = params['id'];

		if (action === 'none') {
			entityId = null;
			id = null;
		}

		return this.getTasks().pipe(switchMap((r) => this.onSelectTask(r, entityId, action, id)));
	}

	onSelectTask(
		tasks: Task[],
		entityId: string | null,
		action: TaskAction | null,
		id: string | null,
	): Observable<TaskServiceArg> {
		this.tasks = tasks;
		const x: TaskServiceArg = {
			tasks: tasks,
			entityId,
			action,
			id,
			selectedIndex: -1,
		};

		return of(this.selectTask(x));
	}

	selectTask(x: TaskServiceArg): TaskServiceArg {
		let currentIndex = x.selectedIndex;
		let isNewTask = false;

		if (x.action && x.entityId) {
			currentIndex = this.indexOf(x.action, x.entityId, x.id);

			if (currentIndex === -1) {
				this.tasks.push(this.createNewTask(x.entityId, x.action, x.id));
				currentIndex = this.tasks.length - 1;
				isNewTask = true;
			}
		}

		if (currentIndex === -1 && this.tasks.length > 0) {
			currentIndex = this.tasks.length - 1;
		}

		if (currentIndex >= 0) {
			this.task = this.tasks[currentIndex] ?? null;
		} else {
			this.task = null;
		}

		x.selectedIndex = currentIndex;

		if (isNewTask) {
			this.updateTasks(this.tasks);
		}

		return x;
	}

	startLoader() {
		this.global.showLoader();
	}

	stopLoader() {
		this.global.hideLoader();
	}

	on(type: KTaskBarEventType, arg: any = null) {
		this.#events.next(new KTaskBarEvent(type, arg));
	}

	handleEvents(event: KTaskBarEvent) {
		switch (event.type) {
			case KTaskBarEventType.StartLoader:
				this.startLoader();
				break;

			case KTaskBarEventType.StopLoader:
				this.stopLoader();
				break;

			case KTaskBarEventType.SelectedUiElementChange:
				if (event.payload instanceof KTaskSelectedElementChange) {
					const t = this.getTask(event.payload.task.id);

					if (t.task == null) {
						console.error('task no found', t.task);
					} else {
						t.task.stepOrTab = event.payload.stepOrTab;
						t.task.currentCmp = event.payload.currentCmp;
						t.task.currentCmpElement = event.payload.currentCmpElement;

						t.task.innerStepOrTab = event.payload.innerStepOrTab;
						t.task.innerCmp = event.payload.innerCmp;
						t.task.innerElement = event.payload.innerElement;

						this.updateTask(t);
						// console.log('Task Updated', t.task.id, t.task.innerStepOrTab);
					}
				}

				break;

			case KTaskBarEventType.FormDelete:
				if (event.payload instanceof KToolbarPayload) {
					if (event.payload.task.parentTaskId) {
						// in caso di add/edit member, e poi pressione su delete, devo eliminare il reference
						// per ora non guardo i privilegi
						const formData = this.state.get(event.payload.task.parentTaskId!) as KFormData;
						const pointerField = formData.data?.getField(this.task!.attributeName!);
						if (pointerField) {
							pointerField.value = null;
						}
					}

					const isInSearch = event.payload.task.action === 'search';

					for (const holder of event.payload.refs) {
						this.service.delete(holder.entityId, holder.instanceId).subscribe((response: ApiResponse) => {
							if (response.success) {
								if (!isInSearch) {
									this.on(KTaskBarEventType.FormClose, new KFormClosePayload(event.payload.task.id, null));
								} else {
									this.on(KTaskBarEventType.SearchRefresh, new KFormClosePayload(event.payload.task.id, null));
								}
							} else {
								this.global.showError('Impossibile cancellare ' + response.message);
							}
						});
					}
				}

				break;

			case KTaskBarEventType.FormParentSelected:
				if (event.payload instanceof KToolbarPayload) {
					for (const holder of event.payload.refs) {
						this.service
							.createNewWithExistingParent(holder.entityId, this.task!?.entityId, holder.instanceId)
							.subscribe((response: ApiResponse) => {
								console.log(response);
								if (response.success) {
									this.on(
										KTaskBarEventType.OpenTask,
										new KRouteRequestPayload(
											'edit',
											this.task!?.entityId,
											holder.instanceId,
											TaskRelation.None,
											null,
											null,
											'',
											GenericHelper.toIcon(this.task!?.entityId),
											null,
										),
									);
								} else {
									this.global.showError('Impossibile clonare');
								}
							});
					}
				}

				break;

			case KTaskBarEventType.FormModelSelected:
			case KTaskBarEventType.FormClone:
				if (event.payload instanceof KToolbarPayload) {
					for (const holder of event.payload.refs) {
						this.service.clone(holder.entityId, holder.instanceId).subscribe((response: CloneApiResponse) => {
							if (response.success) {
								this.on(
									KTaskBarEventType.OpenTask,
									new KRouteRequestPayload(
										'edit',
										response.data.entityId,
										response.data.instanceId,
										TaskRelation.None,
										null,
										null,
										'',
										GenericHelper.toIcon(response.data.entityId),
										null,
									),
								);
							} else {
								this.global.showError('Impossibile clonare');
							}
						});
					}
				}

				break;

			case KTaskBarEventType.FormAdd:
				if (event.payload instanceof KToolbarPayload) {
					const t = Task.untitled(event.payload.task.entityId);
					this.tasks.push(t);

					this.updateTasks(this.tasks).then(() => {
						this.ruoteToTask(t);
					});
				}

				break;

			case KTaskBarEventType.OpenTask:
				if (event.payload instanceof KSearchOpenPayload) {
					managerOpenSearchTask(this, event.payload);
				} else if (event.payload instanceof KRouteRequestPayload) {
					managerOpenTask(this, event.payload);
				}

				break;
			case KTaskBarEventType.FieldValidChange:
				if (event.payload instanceof KFieldValueChangePayload) {
					const task = this.getTask(event.payload.task.id);

					if (task.task) {
						task.task.isInvalid = true;
						this.updateTask(task);
						// this.updateTasks(this.tasks.toArray());
					}
				}
				break;
			case KTaskBarEventType.FormClose:
				if (event.payload instanceof KFormClosePayload) {
					let task = this.getTask(event.payload.sourceTaskId);

					if (task.task) {
						const result = removeTask(task.task, this.tasks, true);

						if (result.success) {
							this.state.remove(task.task.id);
							this.updateTasks(this.tasks);
						}

						if (event.payload.targetTaskId) {
							task = this.getTask(event.payload.targetTaskId);

							if (task.task) {
								this.ruoteToTask(task.task);
							}
						} else {
							if (this.tasks.length > 0) {
								this.ruoteToTask(this.tasks[this.tasks.length - 1]);
							} else {
								this.global.redirectToHome();
							}
						}
					}
				}

				break;

			case KTaskBarEventType.FieldValueChange:
				if (event.payload instanceof KFieldValueChangePayload) {
					const task = this.getTask(event.payload.task.id);

					if (task && task.task) {
						task.task.isChanged = event.payload.pendingChanges > 0;
						this.updateTask(task);
					}
				}

				break;

			case KTaskBarEventType.FormFieldRefresh:
				if (event.payload instanceof KFormFieldRefreshPayload) {
					const task = this.getTask(event.payload.task.id);

					if (task && task.task) {
						task.task.isChanged = event.payload.pendingChanges > 0;
						this.updateTask(task);
					}
				}

				break;
			case KTaskBarEventType.TitleChange:
				if (event.payload instanceof KTitleChangePayload) {
					const task = this.getTask(event.payload.task.id);

					if (task.task) {
						task.task.changeName(event.payload.newTitle);
						this.updateTask(task);
						this.global.on(
							GlobalEventType.TitleChange,
							new GlobalTitleChangeArg(new GlobalTitle(event.payload.entityId, event.payload.newTitle)),
						);
					}
				}

				break;

			case KTaskBarEventType.ContextMenuCallBack:
				if (event.payload instanceof KContextPayloadCallback) {
					switch (event.payload.item.id) {
						case 'tbr-force':
						case 'tbr-close':
							this.closeTab(event.payload.target, event.payload.item.id === 'tbr-force');
							break;
						case 'tbr-closeall':
							this.closeAll();

							break;

						case 'tbr-notThis':
							this.closeAllExcept(event.payload.target);

							break;
					}
				}
				break;
		}
	}

	async updateTask(task: { task: Task | null; index: number }): Promise<boolean> {
		// console.log('update',task)

		if (task.task) {
			this.tasks[task.index] = task.task;
			return this.updateTasks(this.tasks);
		} else {
			return of(false).toPromise();
		}
	}

	getTask(id: string): { task: Task | null; index: number } {
		const t = this.tasks.find((c) => c.id === id) ?? null;
		let index = -1;

		if (t) {
			index = this.tasks.indexOf(t);
		}

		return {
			task: t,
			index,
		};
	}

	indexOf(action: string, entityId: string, id: string | null = null): number {
		let v: Task | null = null;

		v = this.tasks.find((c) => c.entityId === entityId && c.action === action && c.instanceId === id) ?? null;

		if (!v) {
			return -1;
		}
		return this.tasks.indexOf(v);
	}

	createNewTask(entityId: string, action: TaskAction, instanceId: string | null = null): Task {
		return new Task(GenericHelper.generateId(), 'untitled', 'icon icon-' + entityId, entityId, instanceId, action);
	}

	getTasks(): Observable<Task[]> {
		const o = from(this.cache.get('Shared', entityCachaType.TasksCache));

		return o.pipe(
			map((data?: CacheEntry) => {
				if (!data) {
					return [];
				}
				return (data?.data ?? []).map((t: any) => Task.fromRaw(t));
			}),
		);
	}

	updateTasks(tasks: Task[]): Promise<boolean> {
		return this.cache.set(new TasksCache(tasks), entityCachaType.TasksCache);
	}

	goToSearch(entityId: string, queryId: string) {
		var t = Task.untitled(entityId);
		t.action = 'search';
		t.instanceId = queryId;
		this.ruoteToTask(t);
	}

	ruoteToTask(task: Task | null = null): Promise<boolean> {
		// entity/:actionId/:entityId/:id
		const segments: string[] = ['entity'];

		if (task) {
			segments.push(task.action);

			if (task.entityId) {
				segments.push(task.entityId);
			}

			if (task.instanceId) {
				segments.push(task.instanceId);
			}
		}
		// , { skipLocationChange: true, replaceUrl: true }
		return this.router.navigate(segments);
	}

	onAfterCloseTask(result: { success: boolean; index: number }) {
		// console.log(result);

		if (this.tasks.length > 0) {
			this.ruoteToTask(result.index >= 0 ? this.tasks[result.index] : null);
		} else {
			// this.host?.viewContainerRef.clear();
			this.task = null;
			this.router.navigate([knownRuotes.default]);
		}

		this.on(KTaskBarEventType.TaskClosed, result);
	}

	addNewFormTaskAndRedirect(t: Task, data: EntityHolder | null) {
		const k = new KFormData(data, null, false);
		this.tasks.push(t);
		this.updateTasks(this.tasks);
		this.state.persist(t.id, k);
		this.ruoteToTask(t);
	}

	closeTab(task: Task, forceClose: boolean = false) {
		const result = removeTask(task, this.tasks, forceClose);

		if (!result.success) {
			return;
		}

		this.state.remove(task.id);
		this.updateTasks(this.tasks);
		this.onAfterCloseTask(result);
	}

	closeAllExcept(task: Task) {
		const result = removeAllBuNotThis(task, this.tasks);

		if (result.success) {
			// this.selectedIndex = result.index;
			this.updateTasks(this.tasks);
			this.onAfterCloseTask(result);
		}
	}

	closeAll() {
		const result = removeAllTasks(this.tasks);

		if (result.success) {
			// this.selectedIndex = -1;
			this.updateTasks(this.tasks);
			this.onAfterCloseTask(result);
		}
	}

	closeCurrentTab(forceClose: boolean = false) {
		this.closeTab(this.task!, forceClose);
	}

	selectTab(task: Task) {
		this.ruoteToTask(task);
	}

	changeTitle(newTitle: string) {
		this.on(KTaskBarEventType.TitleChange, new KTitleChangePayload(newTitle, this.task!, this.task!.entityId));
	}
}
