import { TreeNode } from './tree-note';
export class TreeNodeContext {
	x: number;
	y: number;
	node: TreeNode;

	constructor(x: number, y: number, node: TreeNode) {
		this.x = x;
		this.y = y;
		this.node = node;
	}
}
