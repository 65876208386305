import { EntityAttributeValueType } from 'src/app/server/model/entity-attribute-value-type';
import { UiRule } from 'src/app/server/model/load-runtime-information';
import { SearchParameterType } from 'src/app/server/model/search-parameter-type';
import { ViewNode } from 'src/app/server/model/view-node';
import { KnowItemNames } from '../../../server/helpers/constants';
import { EnumHelper } from '../../../server/helpers/enum-helper';
import { EntityHolder } from '../../../server/model/entity-holder';
import { EntityMetadata } from '../../../server/model/entity-metadata';
import { LogicalAttributeType } from '../../../server/model/logical-attribute-type';
import { UiRuleTypeEnum } from './../../../server/model/rule-type';
import { KFormData } from './kform-data';

export interface NodeIsArg {
	node: ViewNode;
	data: KFormData | null | undefined;
	accountId: string;
	userRoles: string[];
}

export function isNodeReadOnly(nodeIsArg: NodeIsArg) {
	return isNode(
		nodeIsArg.node,
		nodeIsArg.data,
		UiRuleTypeEnum.ReadOnlyIf,
		nodeIsArg.accountId,
		nodeIsArg.userRoles,
	);
}

export function isNodeHidden(nodeIsArg: NodeIsArg) {
	return isNode(
		nodeIsArg.node,
		nodeIsArg.data,
		UiRuleTypeEnum.HideIf,
		nodeIsArg.accountId,
		nodeIsArg.userRoles,
	);
}

export function isNodeMandatory(nodeIsArg: NodeIsArg) {
	return isNode(
		nodeIsArg.node,
		nodeIsArg.data,
		UiRuleTypeEnum.Mandatory,
		nodeIsArg.accountId,
		nodeIsArg.userRoles,
	);
}

function isNode(
	node: ViewNode,
	data: KFormData | null | undefined,
	type: UiRuleTypeEnum,
	accountId: string,
	userRoles: string[],
) {
	if (data && data.data && data.info) {
		return evaluateNodeRule(
			node,
			data.data,
			data.info.metadata,
			type,
			accountId,
			userRoles,
		);
	}
	console.error(node.name, 'Mancano le informazioni sul soggetto della form');
	return false;
}

function evaluateNodeRule(
	node: ViewNode,
	holder: EntityHolder,
	metadata: EntityMetadata,
	type: UiRuleTypeEnum,
	accountId: string,
	userRoles: string[],
): boolean {
	const myRules = node.advancedData2.uiRules.filter((c) => c.type === type);
	const results: { rule: UiRule; targetNode: string; result: boolean }[] = [];

	if (myRules.length <= 0) {
		return false;
	}

	// console.log(holder);

	myRules?.forEach((rule: UiRule) => {
		// console.log(rule.toString());

		rule.targetNodeNames.forEach((targetNode: string) => {
			let result: boolean = false;
			const targetAttribute = metadata.attributes.get(targetNode);

			if (targetAttribute) {
				const field = holder.getField(targetNode);
				let targetValue = field?.value;

				if (targetValue instanceof EntityHolder) {
					targetValue = targetValue?.instanceId;
				}

				// AppendUiRuleOverride
				if (!field && holder.isNew && targetNode === KnowItemNames.Status) {
					targetValue = -1;
				} else if (
					field &&
					EnumHelper.is(LogicalAttributeType.Status, field?.logicalType) &&
					holder.isNew
				) {
					targetValue = -1;
				}

				if (rule.value === '' && field?.entityId) {
					// survey question model
					// se si imposta hideif quando il valore di un puntatore è null
					// il sistema invia "", ma è chiaramente "NULL"
					rule.value = null;
				}

				result = evaluateRule(
					rule,
					targetAttribute.type,
					targetValue,
					rule.value,
				);

				// console.log(rule.toString(), targetValue, rule.value, result);
			} else {
				let type: EntityAttributeValueType;
				let value: string | null = null;

				switch (targetNode) {
					case KnowItemNames.ProfileRuleId:
						value = userRoles.join('|');
						type = EntityAttributeValueType.Text;
						break;

					case KnowItemNames.AccountRuleId:
						value = accountId;
						type = EntityAttributeValueType.Guid;

						break;
					default:
						value = null;
						type = EntityAttributeValueType.Text;
						break;
				}

				result = evaluateRule(rule, type, value, rule.value);
				// console.log(rule.toString(), result);
			}

			results.push({
				rule: rule,
				targetNode: targetNode,
				result: result,
			});
		});
	});

	// almeno una che matcha ritorna true
	if (results.length > 0 && results.find((c) => c.result) != null) {
		// console.log(results[0].rule.toString());
		return true;
	} else if (results.length > 0) {
		// fino false
		return false;
	}

	return false;

	// return data?.runtime?.rules.find(
	//   c => c.nodeName === node.attributeName && c.type === UiRuleTypeEnum.HideIf) == null;
}

function evaluateRule(
	rule: UiRule,
	type: EntityAttributeValueType,
	value: string | null = null,
	checkValue: string,
): boolean {
	let match: boolean = false;

	try {
		switch (rule.operator) {
			case SearchParameterType.Equal:
				// console.log(rule.nodeName, value, checkValue);
				match = isInside(value, checkValue);
				break;

			case SearchParameterType.NotEqual:
				match = !isInside(value, checkValue);
				break;

			case SearchParameterType.Contains:
				match = isInside(value, checkValue);
				break;

			case SearchParameterType.NotContains:
				match = !isInside(value, checkValue);
				break;

			case SearchParameterType.Lesser:
				match = isLesser(type, value, checkValue);
				break;

			case SearchParameterType.Greater:
				match = isGreater(type, value, checkValue);
				break;

			case SearchParameterType.Minor:
				match = isMinor(type, value, checkValue);
				break;

			case SearchParameterType.Major:
				match = isMajor(type, value, checkValue);
				break;
		}
	} catch (error) {
		console.warn(rule);
		console.error(error);
	}

	return match;
}

function isInside(
	value: string | null = null,
	checkValue: string | null = null,
): boolean {
	if (value == null && checkValue == null) return true;
	let result: boolean = false;

	if (value == null && checkValue == null) return true;
	if (value != null && checkValue == null) return false;
	if (checkValue != null && value == null) return false;

	if (checkValue != null) {
		toArray(checkValue.toString()).forEach((s: string) => {
			if (toArray(value?.toString() ?? '').indexOf(s) >= 0) {
				if (!result) {
					result = true;
				}
			}
		});
	}

	return result;
}

function isMajor(
	type: EntityAttributeValueType,
	value: string | null = null,
	checkValue: string,
): boolean {
	switch (type) {
		case EntityAttributeValueType.Int:
			return parseInt(value ?? '0') > parseInt(checkValue ?? '0');
		default:
			return false;
		// throw new ArgumentOutOfRangeException(nameof(type), type, null);
	}
}

function isMinor(
	type: EntityAttributeValueType,
	value: string | null = null,
	checkValue: string,
): boolean {
	switch (type) {
		case EntityAttributeValueType.Int:
			return parseInt(value ?? '0') < parseInt(checkValue ?? '0');

		default:
			return false;
		//                    throw new ArgumentOutOfRangeException(nameof(type), type, null);
	}
}

function isLesser(
	type: EntityAttributeValueType,
	value: string | null = null,
	checkValue: string,
): boolean {
	switch (type) {
		case EntityAttributeValueType.Int:
			return parseInt(value ?? '0') <= parseInt(checkValue ?? '0');
		default:
			throw new Error(
				`impossibile eseguire regola isLesser su tipo: ${EntityAttributeValueType[type]}`,
			);
	}
}

function isGreater(
	type: EntityAttributeValueType,
	value: string | null = null,
	checkValue: string,
): boolean {
	switch (type) {
		case EntityAttributeValueType.Int:
		case EntityAttributeValueType.Text:
			return parseInt(value ?? '0') >= parseInt(checkValue ?? '0');
		default:
			throw new Error(
				`impossibile eseguire regola isGreater su tipo: ${EntityAttributeValueType[type]}, ${value}`,
			);
	}
}

function toArray(multiPartString: string): string[] {
	return multiPartString?.split('|')?.map((c) => c.toLowerCase()) ?? [];
}
