import { EntityAttributeValueType } from './entity-attribute-value-type';
import { LogicalAttributeType } from './logical-attribute-type';
import { LookUpEntry } from './lookup-entry';
import { SearchParameterLuceneOp } from './search-parameter-lucene-op';
import { SearchParameterType } from './search-parameter-type';

// value anche come SearchApIFiler
export class SearchParameter {
	type: EntityAttributeValueType = EntityAttributeValueType.Text;
	luceneOp: SearchParameterLuceneOp = SearchParameterLuceneOp.MUST;
	icon: string = 'spinnext';

	attributeName = '';
	operator: SearchParameterType = SearchParameterType.Contains;
	value: string | null = null;

	constructor(raw: any) {
		if (!raw) {
			return;
		}

		this.operator = raw.operator;
		this.isOptional = raw.isOptional;
		this.luceneOp = raw.luceneOp;
		this.value = raw.value;
		this.type = raw.type;
		this.attributeName = raw.attributeName;
		this.label = raw.label;
		this.entityId = raw.entityId;
		this.targetEntityId = raw.targetEntityId;
		this.logicalType = raw.logicalType;
		this.valueHr = raw.valueHr;

		raw.staticValues?.forEach((element: any) => {
			this.staticValues.push(new LookUpEntry(element.uniqueId, element.value));
		});
	}

	isOptional = false;
	label = '';
	entityId = '';
	targetEntityId: string | null = null;
	logicalType: LogicalAttributeType = LogicalAttributeType.Value;
	staticValues: LookUpEntry[] = [];
	valueHr: string | null = null;

	static create(attributeName: string, value: string | null): SearchParameter {
		const x = new SearchParameter(null);
		x.operator = SearchParameterType.Equal;
		x.attributeName = attributeName;
		x.value = value;

		return x;
	}

	static createWithOperator(
		name: string,
		op: SearchParameterType = SearchParameterType.Equal,
		value: string | null,
	): SearchParameter {
		return {
			attributeName: name,
			operator: op,
			value: value,
			icon: 'spinnext',
		} as SearchParameter;
	}
}
