import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Observable, Subscription, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { FieldValue } from 'src/app/server/model/field-value';
import { TemporaryImageUrlResponse } from 'src/app/server/model/temporari-image-url-response';
import { KAttributeBaseComponent } from '../kbase/kattribute-base.component';
import { TextBoxChangeArg } from '../shared/function';

@Component({
	selector: 'app-kbarcode',
	templateUrl: './kbarcode.component.html',
	styleUrls: ['./kbarcode.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KBarcodeComponent extends KAttributeBaseComponent {
	code: string = '';
	image: TemporaryImageUrlResponse = new TemporaryImageUrlResponse(
		this.container.global.data.serverUrl!,
	);

	handler: Subscription | null = null;

	// questo viene chiamato dal motore
	override setValue(value: FieldValue | null): void {
		super.setValue(value);

		if (!this.value || !this.value.value) {
			return;
		}

		this.code = value?.value;
		this.handler = this.createBarcode(this.code).subscribe((c) => {
			console.log('finito');
		});
	}

	createBarcode(code: string): Observable<boolean> {
		if (!code.length && code === '') return of(true);
		if (code.length !== 12) return of(false);

		return this.container.service.createBarcode(this.code).pipe(
			map((result) => {
				// console.log(result);
				if (result instanceof TemporaryImageUrlResponse) {
					this.image = result;

					this.cdr.markForCheck();
					return true;
				}
				return false;
			}),
		);
	}

	onBarcodeChange(e: TextBoxChangeArg) {
		this.createBarcode(this.code).subscribe((succes) => {
			if (succes) {
				// questo va chiamato a mano per dire al motore che il valore cambia
				this.fieldValueIsChanging(this.code);
			}
		});
	}

	override ngOnDestroy() {
		super.ngOnDestroy();
		this.handler?.unsubscribe();
	}
}
