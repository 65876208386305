export class DatagridOption {
	public rowFilterEnable: boolean = false;
	public searchPanelEnabled: boolean = true;
	public groupEnabled: boolean = true;
	public pagingEnabled: boolean = true;
	public multiselect: boolean = false;
	public allowUpdating: boolean = false;
	public allowDelete: boolean = false;
	public allowReorder: boolean = false;
	public allowAdding: boolean = false;
	public columnChooser: boolean = false;
	public allowSelect: boolean = false;
	public deferred: boolean = false;
	public selectionMode: 'multiple' | 'none' | 'single' = 'multiple';
	public showCheckBoxesMode: 'always' | 'none' | 'onClick' | 'onLongTap' =
		'none';
	public numberOfButtons: number = 0;
	public width: number = 0;
	public sortMode: 'multiple' | 'none' | 'single' = 'multiple';
	public storeState: boolean = false;

	evaluateNumberOfIcons(): number {
		this.numberOfButtons = 0;

		if (this.allowSelect && !this.allowUpdating) {
			this.numberOfButtons++;
		}
		if (this.allowAdding) {
			this.numberOfButtons++;
		}
		if (this.allowDelete && !this.allowUpdating) {
			this.numberOfButtons++;
		}
		if (this.allowUpdating) {
			this.numberOfButtons += 2;
		}
		if (this.allowReorder) {
			this.numberOfButtons += 2;
		}

		this.width = 24 * this.numberOfButtons;

		if (this.numberOfButtons === 1) {
			this.width += 24;
		}

		return this.numberOfButtons;
	}
}
