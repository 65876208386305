import {
	ChangeDetectorRef,
	Component,
	ComponentFactoryResolver,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CacheService } from 'src/app/shared/caching/cache-service';
import { KPageComponent } from '../k-page';
import { EntityService } from './../../server/entity.service';

@Component({
	templateUrl: 'home.component.html',
	styleUrls: ['./home.component.scss'],
})
export class HomeComponent extends KPageComponent {
	constructor(
		private componentFactoryResolver: ComponentFactoryResolver,
		private cdr: ChangeDetectorRef,
		cache: CacheService,
		route: ActivatedRoute,
		service: EntityService,
		router: Router,
	) {
		super(cache, route, service, router);
	}

	override kInit(): void {}
}
