import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	forwardRef,
	Input,
	Output,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EntityService } from 'src/app/server/entity.service';
import { GenericHelper } from 'src/app/server/helpers/generic-helper';
import { KTaskStateService } from 'src/app/server/kform-data-state.service';
import { EntityHolder } from 'src/app/server/model/entity-holder';
import { CacheService } from 'src/app/shared/caching/cache-service';
import { GlobalService } from 'src/app/shared/services';
import { Task } from 'src/app/ui/manager/ktaskbar/task';
import { KEditTaskComponent } from '../../manager/ktask/edit/kedit-task.component';
import { KTaskComponent } from '../../manager/ktask/ktask.component';
import { KTaskBarEvent } from '../../manager/ktaskbar/ktaskbar-event';
import { TaskRelation } from '../../manager/ktaskbar/task';
import { KTaskbarService } from './../../manager/ktaskbar/ktaskbar.service';
import { KFormData } from './kform-data';
import { KFormComponent, KFormLoadArg } from './kform.component';

@Component({
	selector: 'app-kform-standalone',
	templateUrl: './kform.component.html',
	styleUrls: ['./kform.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: KTaskComponent,
			useExisting: forwardRef(() => KEditTaskComponent),
		},
	],
})
export class KFormStandAloneComponent extends KFormComponent {
	override isSubForm: boolean = true;

	@Input() instanceId!: string;
	@Input() entityId!: string;
	@Output() loaded = new EventEmitter<EntityHolder>();
	@Output() changed = new EventEmitter<EntityHolder>();

	constructor(
		container: KTaskbarService,
		state: KTaskStateService,
		cdr: ChangeDetectorRef,
		cache: CacheService,
		route: ActivatedRoute,
		router: Router,
		global: GlobalService,
		service: EntityService,
	) {
		super(container, state, cdr, cache, route, router, global, service);
		this.task = new Task(
			this.task.id,
			this.task.text,
			GenericHelper.toIcon(this.entityId + '-inverted'),
			this.entityId,
			this.instanceId,
			'edit',
		);
	}

	override load(): void {
		const t1 = this.manager.getTask(this.task.id);
		this.selectedIndex = t1.task?.innerStepOrTab ?? 0;

		const arg: KFormLoadArg = {
			entityId: this.entityId,
			instanceId: this.instanceId,
			relation: TaskRelation.StandAlone,
			taskId: this.task.id,
			parentTaskId: null,
			attributeName: null,
			resultViewId: null,
		};

		this.loadWitArg(arg);
	}

	override fillUI(allData: KFormData | null, arg: KFormLoadArg): void {
		super.fillUI(allData, arg);
		this.loaded.emit(this.holder);
	}

	override handleEvents(event: KTaskBarEvent): void {
		super.handleEvents(event);
		// console.log(KTaskBarEventType[event.type]);
		this.changed.emit(this.holder);
	}
}
