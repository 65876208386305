import { Component } from '@angular/core';

import { EntityAttributeValueType } from 'src/app/server/model/entity-attribute-value-type';
import { FieldValue } from 'src/app/server/model/field-value';
import {
	SetKString,
	ToKString,
} from 'src/app/shared/formatting/formatting-helper';
import { KAttributeBaseComponent } from '../kbase/kattribute-base.component';

@Component({
	selector: 'app-ktext-area',
	templateUrl: './ktext-area.component.html',
	styleUrls: ['./ktext-area.component.scss'],
})
export class KTextAreaComponent extends KAttributeBaseComponent {
	innerValue = '';

	override setValue(value: FieldValue | null): void {
		super.setValue(value);
		this.innerValue = ToKString(
			this.value?.value,
			this.type ?? EntityAttributeValueType.Text,
			value?.format,
		);
		// console.log(value?.name, value?.value, this.innerValue);
		this.cdr.markForCheck();
	}

	onValueChanged(event: any): void {
		if (this.readonly) return;

		if (this.value && event?.previousValue !== event?.value) {
			const newValue = SetKString(
				event.value,
				this.type ?? EntityAttributeValueType.Text,
				this.value?.format,
			);
			this.innerValue = newValue;
			super.fieldValueIsChanging(newValue);
		}
	}
}
