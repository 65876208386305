import { isObject } from 'rxjs/internal-compatibility';
import { EntityAttributeValueType } from './entity-attribute-value-type';
import { OrderVersusEnum } from './order-versus-enum';

export class SearchColumnResult {
	attributeName = '';
	isHumanReadable = false;
	versus: OrderVersusEnum = OrderVersusEnum.Asc;
	type: EntityAttributeValueType = EntityAttributeValueType.Int;

	constructor(
		attributeName: string,
		isHumanReadable: boolean = false,
		versus: OrderVersusEnum = OrderVersusEnum.Asc,
		type: EntityAttributeValueType = EntityAttributeValueType.Int,
	) {
		if (isObject(attributeName)) {
			console.log(attributeName);
		}

		this.attributeName = attributeName;
		this.isHumanReadable = isHumanReadable;
		this.versus = versus;
		this.type = type;

		// console.log(this.attributeName);
	}

	static parse(raw: any): SearchColumnResult | null {
		if (!raw) {
			return null;
		}

		const me = new SearchColumnResult(
			raw.attributeName,
			raw.isHumanReadable,
			raw.versus,
			raw.type,
		);

		return me;
	}
}
