<div class="user-panel">
	<div class="user-info">
		<div class="image-container">
			<div class="user-image" [style.background]="avatar"></div>
		</div>
		<div class="user-name">{{ user?.userName }}</div>
	</div>
	<dx-context-menu
		*ngIf="menuMode === 'context'"
		[items]="menuItems"
		target=".user-button"
		showEvent="dxclick"
		width="210px"
		[position]="{ my: 'top', at: 'center' }"
		cssClass="user-menu"
	>
	</dx-context-menu>
	<dx-list
		*ngIf="menuMode === 'list'"
		class="dx-toolbar-menu-action"
		[items]="menuItems"
	>
	</dx-list>
</div>
