import { Task } from './task';
export class KTaskSelectedElementChange {
	stepOrTab;
	currentCmp: string | null;
	currentCmpElement: string | null;

	innerStepOrTab: number | null = null;
	innerCmp: string | null = null;
	innerElement: string | null = null;

	constructor(
		public task: Task,
		stepOrTab = 0,
		currentCmp: string | null = null,
		currentCmpElement: string | null = null,

		innerStepOrTab: number | null = null,
		innerCmp: string | null = null,
		innerElement: string | null = null,
	) {
		this.stepOrTab = stepOrTab;
		this.currentCmp = currentCmp;
		this.currentCmpElement = currentCmpElement;

		this.innerStepOrTab = innerStepOrTab;
		this.innerCmp = innerCmp;
		this.innerElement = innerElement;
	}
}
