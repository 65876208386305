import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EntityService } from 'src/app/server/entity.service';
import { CacheService } from 'src/app/shared/caching/cache-service';
import { GlobalService } from '../../../shared/services/global/global.service';
import { KTaskbarService } from './../ktaskbar/ktaskbar.service';

@Component({
	selector: 'app-ktask',
	template: '',
})
export class KTaskComponent implements OnInit, OnDestroy {
	public taskService: KTaskbarService;
	protected cdr: ChangeDetectorRef;
	public cache: CacheService;
	private route: ActivatedRoute;
	protected service: EntityService;
	private router: Router;
	private global: GlobalService;
	// public dialog: KDialogComponent|null;

	constructor(
		taskService: KTaskbarService,
		cdr: ChangeDetectorRef,
		cache: CacheService,
		route: ActivatedRoute,
		service: EntityService,
		router: Router,
		global: GlobalService,
		// @Optional() dialog: KDialogComponent
	) {
		this.taskService = taskService;
		this.cdr = cdr;
		this.cache = cache;
		this.route = route;
		this.service = service;
		this.router = router;
		this.global = global;
		// this.dialog = dialog;
	}

	ngOnDestroy(): void {
		// this.task = Task.untitled();
	}

	ngOnInit(): void {
		// console.log('init', this.task);
	}
}
