import { EntityHolder } from 'src/app/server/model/entity-holder';
import { FieldValue } from 'src/app/server/model/field-value';
import { KTaskBarEventType } from '../../manager/ktaskbar/ktaskbar-event';
import { KFormFieldRefreshPayload } from '../kcomponents/kbase/kfield-refresh-payload';
import { getNodesWithRules, requireReload } from './evaluate-changed-nodes';
import { KFormComponent } from './kform.component';

export function formReloadAfterElaborate(
	form: KFormComponent,
	newHolder: EntityHolder,
	keepEntityModified: boolean,
): boolean {
	// console.log('refreshForm');
	const fieldChanges: FieldValue[] = [];
	const changedNames: string[] = [];
	const statusIsChanged: boolean = form.holder.getStatusOrDefault() !== newHolder.getStatusOrDefault();

	// console.log('begin refreshForm');
	// console.log('statusIsChanged', statusIsChanged);

	// se ricarico è perchè non è più nuovo
	// vedi dopo cambio stato es: nc da nuova a analisi se resta nuovo la tab analisis non si vede
	form.holder.isNew = false;

	// valuto nodi cambiati
	newHolder.fields.forEach((field: FieldValue) => {
		const f = form.holder.getField(field.name, false);

		if (f == null) {
			console.error(field);
		}

		if (!f || form.valueIsChanged(f, field)) {
			fieldChanges.push(field);
			changedNames.push(field.name);
			// aggiorno il valore nel holder
			form.holder.setField(field.name, field.value);
			// console.log(f?.name, field.value);
		}
		// else {
		//   console.log(f?.name, false);
		// }
	});

	if (!keepEntityModified) {
		form.holder.history = [];
	}

	// aggiorno lo state
	form.updateHolder(newHolder);

	// notifico ai nodi cambiati di aggiornare il valore
	// ma non processo le regole
	// le regole di refresh o valuechange non vanno eseguite in questo momento perchè l'holder torna già aggiornato
	// console.log(fieldChanges);
	// console.log('fieldChanges', fieldChanges);
	fieldChanges.forEach((field: FieldValue) => {
		// console.log('FormFieldRefresh', field.name);
		form.on(
			KTaskBarEventType.FormFieldRefresh,
			new KFormFieldRefreshPayload(form.task, field.name, field, form.holder.history?.length ?? 0, true, true),
		);
	});

	// vanno però rivalutati tutti le modifiche di layout e i readonly
	// const nodeWithRuleAffected = evaluateChangedNodes(
	// 	form.view!.nodes,
	// 	changedNames,
	// );
	const nodeWithRuleAffected = getNodesWithRules(form.view!.nodes);

	// console.log('nodeWithRuleAffected', nodeWithRuleAffected);
	// console.log('changedNames', changedNames);

	const containHideRules = requireReload(nodeWithRuleAffected);

	if (containHideRules || statusIsChanged) {
		form.reload();
		return true;
	}

	nodeWithRuleAffected.forEach((affected) => {
		// console.log(affected.node.name, UiRuleTypeEnum[affected.uiRules[0].type]);
		const fieldValue = form.holder.getField(affected.node.attributeName);

		form.on(
			KTaskBarEventType.FormFieldRefresh,
			new KFormFieldRefreshPayload(
				form.task,
				affected.node.attributeName,
				fieldValue,
				form.holder.history?.length ?? 0,
				true,
				false,
			),
		);
	});

	// comunico ai componeti che la form è cambiata
	form.on(KTaskBarEventType.Ready, form.data);

	return false;
}
