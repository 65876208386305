import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { EntityService } from 'src/app/server/entity.service';
import { FormEntry } from 'src/app/server/model/commad';
import { KCommandDialogComponent } from './../kcommand-dialog.component';

@Component({
	selector: 'app-kdialog-base-selector',
	template: '',
})
export class KDialogBaseSelectorComponent implements OnInit {
	entry!: FormEntry;
	label: string | null = null;
	optional: boolean = false;
	value: any;
	container: KCommandDialogComponent;
	public service: EntityService;
	protected cdr: ChangeDetectorRef;
	index: number = 0;

	constructor(
		container: KCommandDialogComponent,
		service: EntityService,
		cdr: ChangeDetectorRef,
	) {
		this.container = container;
		this.service = service;
		this.cdr = cdr;
	}

	ngOnInit(): void {
		// console.log(this.entry);
		this.label = this.entry.label;
		this.optional = this.entry.optional;
	}

	onValueChanged(newValue: any) {
		this.container.setArg(this.index, newValue.value);
	}
}
