import { KRouteRequestPayload } from './kroute-request-payload';
import { KSearchOpenPayload } from './ksearch-open-payload';
import { KTaskbarService } from './ktaskbar.service';
import { Task } from './task';
import { taskFromPayload, taskFromSearch } from './task-functions';

export function createSearchKeyForDialog(task: Task): string {
	return (
		task.parentTaskId +
		'_' +
		task.attributeName +
		'_' +
		task.entityId +
		'_' +
		task.instanceId
	);
}

export function createSearchKey(task: Task): string {
	return task.entityId + '_' + task.instanceId;
}

export function managerOpenSearchTask(
	bar: KTaskbarService,
	payload: KSearchOpenPayload,
) {
	const t = taskFromSearch(payload);
	bar.tasks.push(t);

	const cacheKey = createSearchKey(t);

	bar.state.persist(cacheKey, payload.search);

	bar.updateTasks(bar.tasks).then(() => {
		bar.ruoteToTask(t);
	});
}

export function managerOpenTask(
	bar: KTaskbarService,
	payload: KRouteRequestPayload,
) {
	const t = taskFromPayload(payload);

	if (payload.parentTaskId) {
		const task = bar.getTask(payload.parentTaskId);

		if (task.task) {
			bar.tasks.splice(task.index + 1, 0, t);
		}
	} else {
		bar.tasks.push(t);
	}

	bar.updateTasks(bar.tasks).then(() => {
		bar.ruoteToTask(t);
	});
}
