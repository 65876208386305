export enum AggregateTreeOption {
	None = 0,
	Border = 1,
	HideLegends = 2,
	MultiSelect = 4,
	AllowMultiMainType = 8,
}

export enum AggregateTreeNodeType {
	Root,
	Node,
}
