import { LogicalEntityType } from './logical-entity-type';

export class TargetEntity {
	id = '';
	isCustom = false;
	isSubEntity = false;
	logicalType: LogicalEntityType = LogicalEntityType.Root;
	moduleId = '';
	name = '';
	nameFormat = '';
	parentId = '';
	parentModuleId = '';
}
