import { ChangeDetectionStrategy, Component } from '@angular/core';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import { map } from 'rxjs/operators';
import { EntityHolder } from 'src/app/server/model/entity-holder';
import { KDialogBaseSelectorComponent } from '../kdialog-base-selector';

@Component({
	selector: 'app-kdialog-search-selector',
	templateUrl: './kdialog-search-selector.component.html',
	styleUrls: ['./kdialog-search-selector.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KDialogSearchSelectorComponent extends KDialogBaseSelectorComponent {
	datasource!: DataSource;
	items: EntityHolder[] = [];

	override ngOnInit(): void {
		super.ngOnInit();

		const me = this;

		const store = new CustomStore({
			key: 'instanceId',
			// loadMode: 'raw',
			cacheRawData: true,

			load: () => {
				return me.service
					.search(me.entry.entityId!, null, null, 1, 100, [], false)
					.pipe(
						map((response) => {
							return response?.results ?? [];
						}),
					)
					.toPromise();
			},
		});

		this.datasource = new DataSource({
			store: store,
		});
	}
}
