import { OrderVersusEnum } from './order-versus-enum';

export class SearchApiColumn {
	name: string;
	versus: OrderVersusEnum | null = null;
	constructor(name: string, versus: OrderVersusEnum | null = null) {
		this.name = name;
		this.versus = versus;
	}
}
