import { Component, OnInit } from '@angular/core';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import { of, throwError } from 'rxjs';
import { first, switchMap } from 'rxjs/operators';
import { KnowItemNames } from 'src/app/server/helpers/constants';
import { EntityHolder, SortHolders } from 'src/app/server/model/entity-holder';
import { UiRule } from 'src/app/server/model/load-runtime-information';
import { ResultView } from 'src/app/server/model/result-view';
import { UiRuleTypeEnum } from 'src/app/server/model/rule-type';
import { SearchColumnResult } from 'src/app/server/model/search-column-result';
import { ViewItemUiControl } from 'src/app/server/model/view-item-ui-control';
import { ViewResultNode } from 'src/app/server/model/view-result.node';
import {
	DatagridColumn,
	GridColumnDatasource,
	IDatagridContaner,
	IDatagridNewRowArg,
} from 'src/app/ui/controls/datagrid/datagrid-column';
import { DatagridOption } from 'src/app/ui/controls/datagrid/datagrid-option';
import { DatagridService } from 'src/app/ui/controls/datagrid/datagrid.service';
import { TaskRelation } from 'src/app/ui/manager/ktaskbar/task';
import { removeHolderFromCollectionById } from '../../kform/updateStateAndField';
import { getVirtualTypeOf, searchExtended } from '../kbase/functions';
import { KEntityBaseComponent } from '../kbase/kentity-base.component';
import { KFormFieldRefreshAfterDialogClosePayload } from '../kbase/kfield-refresh-payload';
import { EnumHelper } from './../../../../server/helpers/enum-helper';
import { ApiResponse } from './../../../../server/model/api.response';
import { EntityInfo } from './../../../../server/model/entity-info';
import { IdName } from './../../../../server/model/id-name';
import { LogicalAttributeType } from './../../../../server/model/logical-attribute-type';
import {
	DatagridClickEventArg,
	DatagridColumnValueChange,
} from './../../../controls/datagrid/datagrid-click-event-args';
import { IKEntityComponent } from './../kbase/abstract';
import { createColumns, handleDuplicateError } from './grid-control-functions';
import { GridControlOption } from './grid-control-option';

@Component({
	selector: 'app-kgrid',
	templateUrl: './kgrid.component.html',
	styleUrls: ['./kgrid.component.scss'],
	providers: [DatagridService],
})
export class KGridComponent extends KEntityBaseComponent implements OnInit, IKEntityComponent, IDatagridContaner {
	loadState() {}

	saveState(state: any): void {}

	columns: DatagridColumn[] = [];
	dataSource?: DataSource;
	option: DatagridOption = new DatagridOption();
	view: ResultView | null = null;
	orders: SearchColumnResult[] = [];

	override ngOnInit(): void {
		super.ngOnInit();

		// Multiselect, indica di solito mono colonna con Main member e selezione multipla del main member
		this.isMultiselect = !this.readonly && EnumHelper.is(this.node.optionId, GridControlOption.MultiSelect);
		this.allowMultiMainType = !this.readonly && EnumHelper.is(this.node.optionId, GridControlOption.AllowMultiMainType);
		// console.log(`${this.node.name} readonly:${this.readonly} option:`, this.node.optionId);

		this.option.allowSelect = true;
		this.option.multiselect = this.isMultiselect ?? false;
		this.option.allowUpdating = !this.readonly && EnumHelper.is(this.node.optionId, GridControlOption.InLine);
		this.option.groupEnabled = EnumHelper.is(this.node.optionId, GridControlOption.UseFirstColumnAsGroup);

		// aggiunge il più nella toolbar del grid ma funziona bene solo per inline perchè prima aggiunge riga in grid
		// da valutare
		this.option.allowAdding = !this.readonly && EnumHelper.is(this.node.optionId, GridControlOption.Add);
		this.option.allowDelete = !this.readonly && EnumHelper.is(this.node.optionId, GridControlOption.Delete);

		// il groupable grid non ha option, viene caricato se UseFirstColumnAsGroup e poi perde le option ;P
		if (!this.readonly && this.node.controlType === ViewItemUiControl.GroupableGrid) {
			this.option.allowAdding = true;
			this.option.allowDelete = true;
			this.option.groupEnabled = true;
		}

		this.option.pagingEnabled = false;
		this.option.evaluateNumberOfIcons();
	}

	override onValueReady(): void {
		this.getTargetEntityInfo().subscribe((targetEntityInfo: EntityInfo) => {
			if (this.resultViewId === KnowItemNames.GUID_EMPTY) {
				this.view = targetEntityInfo.results[0];
			} else {
				this.view = targetEntityInfo.results.find((d) => d.id === this.node.refViewId) ?? targetEntityInfo.results[0];
			}

			this.setupReorderButtons(targetEntityInfo);
			this.columns = createColumns(this.view.nodes, this.orders);

			const me = this;
			// console.log(this.option);
			// https://js.devexpress.com/Documentation/ApiReference/Data_Layer/CustomStore/
			const customStore: CustomStore = new CustomStore({
				key: 'instanceId',
				// loadMode: 'raw',
				load: (loadOptions) => {
					this.options = SortHolders(this.options);
					// console.log(this.options);
					return this.options;
				},
				insert: function (values) {
					if (me.isMember) {
						return of(me.addMemberInline(values)).toPromise();
					} else {
						return of(me.addRelatedInline(values)).toPromise();
					}
				},

				onUpdating: function (values) {
					console.log('onUpdating');
					// Your code goes here
				},

				update: (key, values) => {
					// console.log(key, values);

					const holder = this.value?.value.find((c: EntityHolder) => c.instanceId === key);
					if (me.isMember) {
						const keys = Object.keys(values);
						return of(me.updateMemberInline(holder, keys, values)).toPromise();
					} else {
						return of(me.updateRelatedInline(holder)).toPromise();
					}
				},

				onUpdated: function (values, key) {
					console.log('onUpdated');
					// Your code goes here
				},

				onInserting: function (values) {
					console.log('onInserting');
					// Your code goes here
				},
				onInserted: function (values, key) {
					console.log('onInserted');
					// Your code goes here
				},

				onModified: function () {
					console.log('onModified');
					// Your code goes here
				},
				onModifying: function () {
					console.log('onModifying');
					// Your code goes here
				},
				onPush: function (changes) {
					console.log('onPush', changes);
					// Your code goes here
				},

				remove: (key) => {
					if (me.isMember) {
						return of(this.deleteMember(key)).toPromise();
					} else {
						return of(this.deleteRelated(key)).toPromise();
					}
				},
				errorHandler: function (error: any) {
					console.log(error.message);
				},
			});

			this.dataSource = new DataSource({
				store: customStore,
			});

			this.cdr.markForCheck();
		});
	}

	updateRelatedInline(holder: any): any {
		throw new Error('Method not implemented.');
	}

	updateMemberInline(holder: EntityHolder, keys: string[], values: any): any {
		console.log(this.value?.value);

		keys.forEach((k) => {
			// console.log(holder.getField(k)?.value, values[k]);
			holder.setField(k, values[k], true);
		});

		this.container.holder.setField(this.node.attributeName, this.value?.value);
		this.fieldValueIsChanged(this.value!.value, false, false);
	}

	private setupReorderButtons(targetEntityInfo: EntityInfo) {
		if (this.readonly) {
			this.option.sortMode = 'none'; //
			this.option.allowReorder = false;
			return;
		}

		const indexField = targetEntityInfo.metadata.getFirstByeLogicalType(LogicalAttributeType.Index);

		if (indexField) {
			this.option.sortMode = 'none'; //
			this.option.allowReorder = true;
		} else {
			this.orders = this.node.advancedData2.orders; // this.getOrderFields(this.node.advancedData2, targetEntityInfo.metadata);
		}

		this.option.evaluateNumberOfIcons();
	}

	getColumn(nodeName: string): ViewResultNode | null {
		return this.view?.nodes.find((c) => c.name === nodeName) ?? null;
	}

	onCellClick(arg: DatagridClickEventArg): void {
		if (!arg.attributeName) return;

		console.log('LOG');

		this.getTargetEntityInfo(this.getTargetEntityId()).subscribe((c) => {
			const v = c.getViewOrDefault(this.node.refViewId ?? KnowItemNames.GUID_EMPTY);
			console.log(v, arg.attributeName);
			const n = v?.nodes.find((c) => c.name === arg.attributeName);
			// console.log(n);
			const x = arg.row.getField(arg.attributeName!)?.value;

			let instanceId: string | null = x.instanceId;

			if (!instanceId) {
				instanceId = arg.row.getField('_' + arg.attributeName!)?.value;
			}

			if (instanceId) {
				this.openTask(n?.advancedData2.entityId!, instanceId, TaskRelation.None);
			}
		});
	}

	onSelectionChange(rows: EntityHolder[]): void {
		// console.log(rows);
	}

	onRowEdit(holder: EntityHolder): void {
		this.getTargetEntityInfo(this.getTargetEntityId()).subscribe((c) => {
			const action = this.readonly ? 'view' : 'edit';

			if (this.isMember) {
				this.container.createNewTaskAndRedirect(holder, this.node.attributeName, this.isMember, action);
			} else {
				this.openTask(holder.entityId, holder.instanceId, TaskRelation.Parent, action);
			}
		});
	}

	onRowDelete(holder: EntityHolder): void {
		if (this.isMember) {
			this.deleteMember(holder.instanceId);
			return;
		}

		this.deleteRelated(holder.instanceId);
	}

	deleteMember(instanceId: string): void {
		let newValue: EntityHolder[] = [];

		// ???
		if (this.isCollection) {
			newValue = removeHolderFromCollectionById(this.container.holder, this.node.attributeName, instanceId);
		}

		// sincronizzo lo stato
		this.fieldValueIsChanging(newValue);
		// lo tolto dal datagrid
		this.removeFromDatasource(instanceId);
	}

	deleteRelated(instanceId: string) {
		this.getTargetEntityInfo(this.getTargetEntityId())
			.pipe(
				switchMap((ei: EntityInfo) => {
					return this.service.delete(ei.id, instanceId);
				}),
			)
			.subscribe((response: ApiResponse) => {
				if (response.success) {
					this.removeFromDatasource(instanceId);
					this.fieldValueIsChanging(this.options, false);
				} else {
					this.container.global.showError('Impossibile cancellare ' + response.message);
				}
			});
	}

	addRelatedInline(holder: EntityHolder): void {
		// console.log(holder);

		this.onFieldValueCollectionIsChanging([holder]);
		// this.service.save(holder)
		//   .subscribe((response:SaveResponse) => {
		//     this.refresh();
		//   });
	}

	addMemberInline(values: EntityHolder[]): EntityHolder[] {
		this.onFieldValueCollectionIsChanging(values);
		return values;
	}

	removeFromDatasource(instanceId: string) {
		const i = this.options.findIndex((c) => c.instanceId === instanceId);
		if (i >= 0) {
			this.options.splice(i, 1);
		}

		this.dataSource?.reload();
		// this.dataSource?.load(this.options);
		this.cdr.markForCheck();
	}

	onRowUp(row: EntityHolder) {
		this.reOrder(row, true);
	}

	onRowDown(row: EntityHolder) {
		this.reOrder(row, false);
	}

	reOrder(row: EntityHolder, up: boolean) {
		const i = row.getFirstByType(LogicalAttributeType.Index);
		const catIndex = row.getFirstByType(LogicalAttributeType.Category);
		if (!i) return;

		const rowIndex = this.options.indexOf(row);
		let otherRow: EntityHolder = this.options[rowIndex - 1];
		let currentRow: EntityHolder = this.options[rowIndex];

		if (up) {
			if (rowIndex > 0) {
				otherRow = this.options[rowIndex - 1];
				currentRow = this.options[rowIndex];
			} else {
				otherRow = this.options[this.options.length - 1];
				currentRow = this.options[rowIndex];
			}
		} else {
			if (rowIndex < this.options.length - 1) {
				otherRow = this.options[rowIndex + 1];
				currentRow = this.options[rowIndex];
			} else {
				otherRow = this.options[0];
				currentRow = this.options[rowIndex];
			}
		}

		const cIndex = currentRow.getFirstByType(LogicalAttributeType.Index)?.value;
		const oIndex = otherRow.getFirstByType(LogicalAttributeType.Index)?.value;

		otherRow.setField(i.name, cIndex);
		currentRow.setField(i.name, oIndex);

		if (catIndex) {
			const cCat = currentRow.getFirstByType(LogicalAttributeType.Category)?.value;
			const oCat = otherRow.getFirstByType(LogicalAttributeType.Category)?.value;

			otherRow.setField(catIndex.name, cCat);
			currentRow.setField(catIndex.name, oCat);
		}

		// stiamo skippando on change?
		this.dataSource?.reload();
		this.cdr.markForCheck();

		this.fieldValueIsChanged(this.value!, false, true);

		// this.onFieldValueCollectionIsChanging([otherRow, currentRow]);
	}

	onRowAdd() {
		if (this.option.multiselect) {
			return this.onSearchForSpecificField(null, null, null, this.container.holder);
		}

		this.getNewTargetEntity().subscribe((c) => {
			this.container.createNewTaskAndRedirect(c, this.node.attributeName, this.isMember, 'edit');
		});
	}

	override onAfterDialogClose(payload: KFormFieldRefreshAfterDialogClosePayload) {
		// nel reference viene messo il main member name

		const errors = handleDuplicateError(
			this.isMultiselect,
			this.allowMultiMainType,
			this.value?.value,
			payload.dialogPayload,
		);

		if (errors.length) {
			errors.forEach((e) => this.container.global.showError(e));
			return;
		}

		this.createEntitiesFromMainMembers(
			this.getTargetEntityId(),
			payload.dialogPayload.reference,
			payload.dialogPayload.entities,
			payload.dialogPayload.reference,
			payload.attributeName,
			payload.dialogPayload.parentTaskId,
		).subscribe((generatedEntities: EntityHolder[]) => {
			// console.log(generatedEntities);
			this.onFieldValueCollectionIsChanging(generatedEntities);
		});
	}

	getNewRow(arg: IDatagridNewRowArg) {
		arg.promise = this.getNewTargetEntity()
			.toPromise()
			.then((c) => {
				// console.log(c);
				arg.data = c;
			});
	}

	onColumnValueChange(event: DatagridColumnValueChange) {
		const node = this.view?.nodes.find((c) => c.name === event.arg.column.dataField);

		if (node && (node.advancedData2.uiRules?.length ?? 0) > 0) {
			this.processRules(node, event.arg);
		}
	}

	processRules(node: ViewResultNode, row: any) {
		for (const r of node.advancedData2.uiRules) {
			this.processRule(r, row);
		}
	}

	processRule(rule: UiRule, row: any) {
		switch (rule.type) {
			case UiRuleTypeEnum.Reload:
				// console.log(rule, row.entityId, rule.nodeName, rowIndex);

				this.service.executeElaborate(row.data.entityId, row.data, rule.nodeName).subscribe((eleboratedvalue) => {
					for (const field of eleboratedvalue.data!.fields) {
						row.data.setField(field.name, field.value, false);
					}
				});

				break;
			default:
				throwError('TODO');
		}
	}

	getColumnSource(arg: GridColumnDatasource): any {
		console.log('getColumnSource', arg.column.dataField, arg);

		const node = this.view?.nodes.find((c) => c.name === arg.column.dataField);
		return this.createStore(arg, node!);
	}

	createStore(arg: GridColumnDatasource, node: ViewResultNode): CustomStore {
		// console.log(arg);
		// console.log('createStore', arg.column.dataField, node?.advancedData2.entityId);
		// console.log('getColumnSource', arg.column.dataField, arg);

		return new CustomStore({
			// key: 'instanceId',
			loadMode: 'raw',
			cacheRawData: false,
			// totalCount: function() {
			//   console.log('totalCount');
			//   return of(238).toPromise();
			// },
			byKey: function (key) {
				// console.log(key);
				return of(key).toPromise();

				// console.log(key, node!.advancedData2!.entityId!);
				// return s.load(node!.advancedData2!.entityId!, key)
				//   .toPromise();
			},
			// update: function(key, values) {
			//   console.log(key, values);
			//   // TO DO for inline
			//   return Promise.resolve(null);
			// },
			load: (loadOptions) => {
				// console.log(loadOptions);

				if ((node?.values?.length ?? 0) > 0) {
					// console.log('static');
					const values = node!.values!.map((d) => EntityHolder.fromIdName(new IdName(d.uniqueId, d.value)));
					return new Promise((resolve, reject) => {
						resolve(values);
					});
				}

				if (node.name === 'InstanceId') {
					console.log(
						'CustomStore.load',
						node.name,
						node.advancedData2.entityId,
						arg.data.getField('Entity')?.value.instanceId,
					);
				}

				let targetEntityId = node!.advancedData2!.entityId!;

				if (targetEntityId.startsWith('@')) {
					targetEntityId = getVirtualTypeOf(arg.data, targetEntityId);
				}

				// console.log(targetEntityId);

				return this.service
					.getEntityInfo(targetEntityId)
					.pipe(
						first(),
						switchMap((c) => {
							return searchExtended(
								node?.advancedData2,
								c,
								node?.advancedData2.resultViewId,
								arg.row.data,
								this.container.getParentHolder(),
								this.container.getMainHolder(),
								this.service,
							);
						}),
					)
					.toPromise();
			},
		});
	}
}
