import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	OnDestroy,
	OnInit,
	Output,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { QuickFilter } from 'src/app/server/model/quick-filters-response';
import { SearchParameter } from 'src/app/server/model/search-parameter';
import {
	KToolbarPayload,
	KToolbarSelectorload,
} from '../../edit/ktoolbar/ktoolbar-payload';
import { KTaskBarEventType } from '../../manager/ktaskbar/ktaskbar-event';
import { KTaskbarService } from '../../manager/ktaskbar/ktaskbar.service';
import { KItem } from '../kitem';
import { EntityService } from './../../../server/entity.service';
import { KnowItemNames } from './../../../server/helpers/constants';
import { EntityHolder } from './../../../server/model/entity-holder';
import { GlobalService } from './../../../shared/services/global/global.service';
import { KSearchData } from './../../search/ksearch/ksearch-data';

@Component({
	selector: 'app-kquick-selector-pop-up',
	templateUrl: './kquick-selector-pop-up.component.html',
	styleUrls: ['./kquick-selector-pop-up.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KQuickSelectorPopUpComponent implements OnInit, OnDestroy {
	service: EntityService;
	datasource: EntityHolder[] = [];
	title: string | null = null;
	cdr: ChangeDetectorRef;
	items: any[] = [];
	isVisible: boolean = false;
	global: GlobalService;
	fromParent: boolean = false;
	subscription: Subscription;
	payload: KToolbarSelectorload | null = null;

	@Output() visibleChange = new EventEmitter<boolean>();
	private taskService: KTaskbarService;

	constructor(
		service: EntityService,
		cdr: ChangeDetectorRef,
		global: GlobalService,
		taskService: KTaskbarService,
	) {
		this.service = service;
		this.cdr = cdr;
		this.global = global;

		this.taskService = taskService;

		this.subscription = this.taskService.trigger.subscribe((event) => {
			if (event.type === KTaskBarEventType.FormFromModel) {
				this.payload = event.payload;
				this.isVisible = true;
				this.cdr.markForCheck();
			}
		});
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	ngOnInit(): void {}

	onHidden(event: any) {
		this.onItemClick({
			holder: null,
			fromParent: this.payload?.fromParent ?? false,
		});
	}

	onVisibleChange(event: any) {
		// console.log('show', event);

		if (this.payload) {
			this.title = this.payload.title;
			this.fromParent = this.payload.fromParent;

			this.service.getQuickFilters(this.payload.entityId).subscribe((c) => {
				this.items = c.map((f: QuickFilter) => KSearchData.toItem(f));
				if (this.fromParent) {
					this.selectFirstEntityType(this.items);
				}

				// console.log(this.items);
				this.refresh(this.payload!.entityId, this.payload!.filters);
				// this.cdr.markForCheck();
			});
		}
	}

	selectFirstEntityType(items: KItem[]) {
		// console.log(items);
		const entityTypeFilter = items.find(
			(c) => c.id === KnowItemNames.EntityType,
		)!.items![0];
		console.log(entityTypeFilter);
		if (entityTypeFilter) {
			entityTypeFilter.selected = true;
		}
	}

	refresh(entityId: string, filters: SearchParameter[]) {
		this.service
			.search(entityId, null, null, 0, 1000, filters, false)
			.subscribe((d) => {
				this.datasource = d?.results ?? [];
				this.cdr.markForCheck();
			});
	}

	onSelectionChange(event: any): void {
		let filters: KItem[] = event.component
			.getSelectedNodes()
			.map((node: any) => node.itemData);

		let entityId: string = this.payload!.entityId;

		const types = filters.filter(
			(c) => c.parentId === KnowItemNames.EntityType,
		);

		if (this.fromParent && types.length > 1) {
			this.global.showError('Selezionare solo un solo tipo di partenza');
			return;
		}

		if (this.fromParent && types.length === 0) {
			this.global.showError('Selezionare un nuovo tipo di partenza');
			return;
		}

		if (this.fromParent) {
			entityId = filters.find((c) => c.parentId === KnowItemNames.EntityType)!
				.value!;
			filters = filters.filter((c) => c.parentId !== KnowItemNames.EntityType);
		}

		const f: SearchParameter[] = [];

		if (this.payload!.filters.length > 0) {
			this.payload!.filters.map((f1) => f.push(f1));
		}

		if (filters.length > 0) {
			KSearchData.quickFiltersToApiFilter(f, filters);
		}

		// console.log(entityId, f);
		this.refresh(entityId, f);
	}

	onItemClick($event: any) {
		// console.log($event.itemData);

		const arg = {
			holder: $event.itemData,
			fromParent: this.payload?.fromParent ?? false,
		};

		this.onQuickSelection(arg);
		this.isVisible = false;
		this.cdr.markForCheck();
	}

	onQuickSelection(event: {
		holder: EntityHolder | null;
		fromParent: boolean;
	}) {
		if (event.holder) {
			if (event.fromParent) {
				this.taskService.on(
					KTaskBarEventType.FormParentSelected,
					new KToolbarPayload('add', this.taskService.task!, [event.holder]),
				);
			} else {
				this.taskService.on(
					KTaskBarEventType.FormModelSelected,
					new KToolbarPayload('add', this.taskService.task!, [event.holder]),
				);
			}
		}
	}
}
