import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { KFilterEditorComponent } from '../../kfilter-editor.component';
import { FilterEditorBaseComponent } from '../filter-editor-base.component';
@Component({
	selector: 'app-boolean-filter',
	templateUrl: './boolean-filter.component.html',
	styleUrls: ['./boolean-filter.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BooleanFilterComponent extends FilterEditorBaseComponent {
	constructor(parent: KFilterEditorComponent, cdr: ChangeDetectorRef) {
		super(parent, cdr);

		if (this.parent.filter && this.parent.filter.value === 'True') this.value = true;
		else this.value = false;
	}
}
