import { Injectable } from '@angular/core';
import IndexedDb from '../storage/index-db';
import { Table } from '../storage/table';
import { dbSchema } from './db-schema';

@Injectable({
	providedIn: 'root',
})
export class StorageService {
	private db: IndexedDb = new IndexedDb(dbSchema.DbName);
	private tables: Table[] = [
		{ tableName: dbSchema.CacheTableName, isAutoIncrement: false },
	];

	async init(): Promise<boolean> {
		return this.db.createObjectStore(this.tables).then((initialized) => {
			return initialized ?? false;
		});
	}

	dropDb(): void {
		this.db.deleteDatabase(dbSchema.DbName);
	}

	async getMany<T>(table: string): Promise<T[]> {
		return this.db.getAllValue(table);
	}

	async get<T>(table: string, key: string): Promise<T> {
		return this.db.getValue(table, key);
	}

	async set<T extends object>(table: string, data: T): Promise<T[]> {
		return this.db.putValue(table, data);
	}

	async setMany<T extends object>(table: string, data: T[]): Promise<T[]> {
		return this.db.putBulkValue(table, data);
	}

	async delete(table: string, ids: string[]): Promise<any[]> {
		return ids.map((d) => this.db.deleteValue(table, d));
	}
}
