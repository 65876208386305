import { AggregateMember } from 'src/app/server/model/agregate-member';
import { EntityHolder } from 'src/app/server/model/entity-holder';
import { AggregateTreeNodeType } from '../../edit/kcomponents/kaggregate-tree/aggregate-tree-option';

export class TreeNode {
	constructor(id: string, text: string, parent: TreeNode | null, icon?: string) {
		this.id = id;
		this.text = text;
		this.parent = parent;
		this.icon = icon;
	}

	id: string;
	text: string;
	expanded?: boolean;
	selected?: boolean;
	items?: TreeNode[];
	// price?: number;
	// image?: string;
	holder: EntityHolder | null = null;
	icon?: string;
	parent: TreeNode | null = null;
	tag?: any;
	type?: AggregateTreeNodeType;
	aggregateInfo?: AggregateMember;
}
