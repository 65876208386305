export class IdName {
	id: string;
	name: string;

	constructor(id: string, name: string) {
		this.id = id;
		this.name = name;
	}
}

export class IdNameIcon {
	id: string;
	name: string;
	icon: string;

	constructor(id: string, name: string, icon: string) {
		this.id = id;
		this.name = name;
		this.icon = icon;
	}
}


export class IntIdName {
	id: number;
	name: string;
	icon: string;

	constructor(id: number, name: string, icon: string) {
		this.id = id;
		this.name = name;
		this.icon = icon;
	}
}

export class IdNameArray {
	id: string;
	name: string[];

	constructor(id: string, name: string[]) {
		this.id = id;
		this.name = name;
	}
}

// export class NameArray {

//   name: string[];

//   constructor(name: string, vals: string[]) {
//     this.name = [];
//     this.name.push(name);
//     vals.forEach(element => this.name.push(element));
//   }

// }

export class ArgValuesArray {
	arg: string;
	val0: number;
	val1: number | null = null;
	val2: number | null = null;
	val3: number | null = null;
	val4: number | null = null;
	val5: number | null = null;
	val6: number | null = null;
	val7: number | null = null;
	val8: number | null = null;
	val9: number | null = null;

	val10: number | null = null;
	val11: number | null = null;
	val12: number | null = null;
	val13: number | null = null;
	val14: number | null = null;
	val15: number | null = null;
	val16: number | null = null;
	val17: number | null = null;
	val18: number | null = null;
	val19: number | null = null;

	constructor(arg: string, values: number[]) {
		this.arg = arg;
		this.val0 = values[0];

		if (values.length > 1) {
			this.val1 = values[1];
		}
		if (values.length > 2) {
			this.val2 = values[2];
		}
		if (values.length > 3) {
			this.val3 = values[3];
		}
		if (values.length > 4) {
			this.val4 = values[4];
		}
		if (values.length > 5) {
			this.val5 = values[5];
		}
		if (values.length > 6) {
			this.val6 = values[6];
		}
		if (values.length > 7) {
			this.val7 = values[7];
		}
		if (values.length > 8) {
			this.val8 = values[8];
		}
		if (values.length > 9) {
			this.val9 = values[9];
		}
		if (values.length > 10) {
			this.val10 = values[10];
		}
		if (values.length > 11) {
			this.val11 = values[11];
		}
		if (values.length > 12) {
			this.val12 = values[12];
		}
		if (values.length > 13) {
			this.val13 = values[13];
		}
		if (values.length > 14) {
			this.val14 = values[14];
		}
		if (values.length > 15) {
			this.val15 = values[15];
		}
		if (values.length > 16) {
			this.val16 = values[16];
		}
		if (values.length > 17) {
			this.val17 = values[17];
		}
		if (values.length > 18) {
			this.val18 = values[18];
		}
		if (values.length > 19) {
			this.val19 = values[19];
		}
	}
}
