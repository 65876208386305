import { AlignToEnum } from './align-enum';
import { ApiResponse } from './api.response';
import { EntityAttributeValueType } from './entity-attribute-value-type';
import { EntityHolder } from './entity-holder';
import { OrderVersusEnum } from './order-versus-enum';
import { SearchParameter } from './search-parameter';
import { ViewItemResultControl } from './view-item-result-control';
import { ViewResultNode } from './view-result.node';

export class SaveResponse extends ApiResponse {
	static parse(raw: any): SaveResponse {
		const s = new SaveResponse();
		s.success = raw.success;
		s.inErrorFields = raw.inErrorFields;
		s.message = raw.message;
		s.data = EntityHolder.parse(raw.data);
		return s;
	}

	inErrorFields: string[] = [];
	needFields: string[] = [];
	data: EntityHolder | null = null;
}

export class CloneApiResponse extends ApiResponse {
	data!: EntityHolder;
}

export class EntityAttachment {
	id!: string;
	name!: string;
	targetTypeName!: string;
	type!: string;
	isDocument!: boolean;
	isReverse!: boolean;
	viewId!: string;
}

export class DataPartitionUnit {
	id!: string;
	index!: number;
	name!: string;
	parentId!: string;
	units: DataPartitionUnit[] = [];
}

export enum DataPartitionStatusEnum {
	Disable,
	Enable,
}

export class DataPartition {
	id!: string;
	name!: string;
	status!: DataPartitionStatusEnum;
	index!: number;
	units: DataPartitionUnit[] = [];
}

export class GetOrganizationTreeResponse extends ApiResponse {
	tree: DataPartition | null = null;
}
export class GetUnitsResponse extends ApiResponse {
	units: DataPartitionUnit[] = [];
}

export class GetRelatedTreeResponse extends ApiResponse {
	holders: EntityHolder[] = [];
}
export class GetLinkHolderResponse extends ApiResponse {
	holder: EntityHolder | null = null;
}

export class PostSearchAnalisysResponse extends ApiResponse {
	items: EntityHolder[] = [];
}
export class GetDynamicFiltersResponse extends ApiResponse {
	filters: SearchParameter[] = [];
}

export class GetAttachmentsResponse extends ApiResponse {
	attachments: EntityAttachment[] = [];
}

export class EntityHistory {
	actionName!: string;
	activityName!: string;
	id!: string;
	occurredAt!: Date;
	processId!: string;
	processTypeId!: string;
	processTypeName!: string;
	requestName!: string;
}

export class EntityVersionInformation {
	idId!: string;
	idVersion!: string;
	occurredAt!: Date;
	userName!: string;
	version!: number;
	versionId!: string;
	versionLabel!: string;
}
export class GetVersionsResponse extends ApiResponse {
	versions: EntityVersionInformation[] = [];
}

export class PivotCustomColumn {
	virtualTypeName!: string;
	virtualIdName!: string;
	isHumanReadable: boolean = false;
	id!: string;
	type!: EntityAttributeValueType;
	format!: string;
	name!: string;
	caption!: string;
	size!: number;
	isGroup: boolean = false;
	alignTo!: AlignToEnum;
	control!: ViewItemResultControl;
	versus!: OrderVersusEnum;
}
export class SetCustomResultColumnsResponse extends ApiResponse {}
export class GetCustomResultColumnsResponse extends ApiResponse {
	columns: PivotCustomColumn[] = [];
}

export class GetHistoryResponse extends ApiResponse {
	histories: EntityHistory[] = [];
}

export class ExecuteElaborateResponse extends ApiResponse {
	data: EntityHolder | null = null;

	static parse(raw: any): ExecuteElaborateResponse {
		const response = new ExecuteElaborateResponse();
		response.success = raw.success;
		response.message = raw.message;
		response.data = EntityHolder.parse(raw.data);
		return response;
	}
}

export enum FeedbackTypeEnum {
	Message,
	OpenEntityUi,
	Confirm,
	Questions,
	Reload,
	Background,
	FileDownload,
}

export class EntityLogicFeedback {
	type: FeedbackTypeEnum;
	message: string;
	entityId: string;
	instanceId: string;
	questions: string[];
	needReload: boolean;
	needRefresh: boolean;

	constructor(
		type: FeedbackTypeEnum,
		message: string,
		entityId: string,
		instanceId: string,
		questions: string[],
		needReload: boolean,
		needRefresh: boolean,
	) {
		this.type = type;
		this.message = message;
		this.entityId = entityId;
		this.instanceId = instanceId;
		this.questions = questions;
		this.needReload = needReload;
		this.needRefresh = needRefresh;
	}
}

export class ExecuteCustomActionResponse extends ApiResponse {
	data: EntityHolder | null;
	feedback: EntityLogicFeedback | null;

	constructor(data: EntityHolder | null, feedback: EntityLogicFeedback | null) {
		super();
		this.data = data;
		this.feedback = feedback;
	}
}

export function rawToEntityLogicFeedback(raw: any): EntityLogicFeedback | null {
	if (!raw) return null;

	return new EntityLogicFeedback(
		raw.type,
		raw.message,
		raw.entityId,
		raw.instanceId,
		raw.questions,
		raw.needReload,
		raw.needRefresh,
	);
}

export function rawToExecuteCustomActionResponse(
	raw: any,
): ExecuteCustomActionResponse {
	const data: EntityHolder | null = EntityHolder.parse(raw.data);
	const feedback: EntityLogicFeedback | null = rawToEntityLogicFeedback(
		raw.feedback,
	);
	const response = new ExecuteCustomActionResponse(data, feedback);
	response.success = raw.success;
	response.message = raw.message;
	return response;
}
