import { EntityHolder } from 'src/app/server/model/entity-holder';

export class KSearchDialogPopUpDeletePayload {
	public entities: EntityHolder[] = [];

	constructor(entities: EntityHolder[] = []) {
		this.entities = entities;
	}
}

export class KSearchDialogClosePayload {
	public parentTaskId: string;
	public attributeName: string | null = null;
	public entities: EntityHolder[] = [];
	public reference: any = null;

	constructor(
		parentTaskId: string,
		attributeName: string | null = null,
		entities: EntityHolder[] = [],
		reference: any,
	) {
		this.parentTaskId = parentTaskId;
		this.attributeName = attributeName;
		this.entities = entities;
		this.reference = reference;
	}
}
