import { CommonModule } from '@angular/common';
import { Component, NgModule, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { DxFormModule } from 'devextreme-angular/ui/form';
import { DxLoadIndicatorModule } from 'devextreme-angular/ui/load-indicator';
// import { AuthService } from '../../services';
import { first } from 'rxjs/operators';
import { ApiService } from '../../../server/api.service';
import { GlobalEventType } from '../../services/global/global-event-type';
import { GlobalNotifyArg } from '../../services/global/global-notify-arg';
import { GlobalServerUrlChangeArg } from '../../services/global/global-server-url-change-arg';
import { GlobalService } from '../../services/global/global.service';
import { SetupData } from './setup-data';

@Component({
	selector: 'app-setup',
	templateUrl: './setup.component.html',
	styleUrls: ['./setup.component.scss'],
})
export class SetupComponent implements OnInit {
	loading = false;
	formData: SetupData = new SetupData();
	namePattern: RegExp = /^http[s]{0,1}:\/\/[^\s]*[^\/]{1}$/;

	constructor(private api: ApiService, private globalService: GlobalService) {
		this.formData.serverUrl = globalService.data.serverUrl;
	}

	ngOnInit() {}

	async onSubmit(e: any) {
		e.preventDefault();
		// const { email, password, serverUrl } = this.formData;
		// this.loading = true;

		this.api
			.isValidUrl(this.formData.serverUrl)
			.pipe(first())
			.subscribe((pingResponse) => {
				if (pingResponse.isUp) {
					this.globalService.on(
						GlobalEventType.ServerUrlChange,
						new GlobalServerUrlChangeArg(this.formData.serverUrl!, pingResponse.isProxy),
					);
				} else {
					this.globalService.on(
						GlobalEventType.Notify,
						new GlobalNotifyArg('Il serve non risponde o è spento', 'error', 2000),
					);
				}
			});

		// const result = await this.authService.logIn(email, password);
		// if (!result.isOk) {
		//   this.loading = false;
		//   notify(result.message, 'error', 2000);
		// }
	}
}

@NgModule({
	imports: [CommonModule, RouterModule, DxFormModule, DxLoadIndicatorModule],
	declarations: [SetupComponent],
	exports: [SetupComponent],
})
export class SetupFormModule {}
