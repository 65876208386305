import { Component } from '@angular/core';
import { FieldValue } from 'src/app/server/model/field-value';
import { KAttributeBaseComponent } from '../kbase/kattribute-base.component';

@Component({
	selector: 'app-kdatebox',
	templateUrl: './kdatebox.component.html',
	styleUrls: ['./kdatebox.component.scss'],
})
export class KDateboxComponent extends KAttributeBaseComponent {
	innerValue!: any;

	override setValue(value: FieldValue | null): void {
		// if (this.node.attributeName === 'RevisionDate') {
		// console.log(this.node);
		//console.log('DATE', value);
		// console.log(this.value);
		// console.log(this.innerValue);
		// console.log(this.metadata);
		// }

		super.setValue(value);

		// in keystone mettiamo non null e poi invice è nullabile :( questo va si che la data prenda il min value
		// questo fix lo rimuove
		if (value?.value === '0001-01-01T00:00:00') {
			value.value = null;
		}

		if (value?.value) {
			this.innerValue = value?.value;
			// this.control.value = value.value;
		} else {
			this.innerValue = null;
		}

		// console.log(this.node.attributeName, value?.value, this.innerValue);
	}

	onValueChanged(arg: { value: Date; event: any; previousValue: Date | null }): void {
		if (this.readonly) return;
		// console.log(this.control);
		// if (this.innerValue === event?.value) return;
		// previousValue: "NATALE"
		// value: "NATALE 12"

		if (this.value && arg?.previousValue !== arg?.value) {
			this.innerValue = arg.value;
			super.fieldValueIsChanging(arg.value);
		}
	}
}
