import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ServiceWorkerModule } from '@angular/service-worker';
import { DxLoadPanelModule } from 'devextreme-angular';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SideNavOuterToolbarModule, SingleCardModule } from './layouts';
import { ServerModule } from './server/common.module';
import { DiagnoseService } from './server/diagnose.service';
import {
	ChangePasswordFormModule,
	CreateAccountFormModule,
	FooterModule,
	LoginFormModule,
	ResetPasswordFormModule,
} from './shared/components';
import { SetupFormModule } from './shared/components/setup/setup.component';
import { AuthService } from './shared/services/auth.service';
import { ScreenService } from './shared/services/screen.service';
import { UiModule } from './ui/ui.module';
import { UnauthenticatedContentModule } from './unauthenticated-content';

@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserModule,
		SideNavOuterToolbarModule,
		SingleCardModule,
		FooterModule,
		ResetPasswordFormModule,
		CreateAccountFormModule,
		ChangePasswordFormModule,
		LoginFormModule,
		SetupFormModule,
		UnauthenticatedContentModule,
		AppRoutingModule,
		ServerModule,
		UiModule,
		DxLoadPanelModule,
		ServiceWorkerModule.register('ngsw-worker.js', {
			enabled: environment.production,
			// Register the ServiceWorker as soon as the app is stable
			// or after 30 seconds (whichever comes first).
			registrationStrategy: 'registerWhenStable:30000',
		}),
	],
	providers: [
		AuthService,
		ScreenService,
		DiagnoseService,

		// { provide: LOCALE_ID, useValue: 'it-IT' },

		//     {
		//       provide: APP_BASE_HREF,
		//       useFactory: (s: PlatformLocation) => s.getBaseHrefFromDOM(),
		//       deps: [PlatformLocation]
		//     }
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
