import { EntityHolder } from 'src/app/server/model/entity-holder';
import { Cacheable } from 'src/app/shared/caching/cacheable';
import { TreeNode } from 'src/app/ui/controls/tree/tree-note';

export class EntityChildInfo {
	entityId!: string;
	name!: string;
	possibileParents: string[] = [];
}

export class AggregateMember {
	entityId!: string;
	mainEntityId!: string;
	mainEntityName: string | null = null;
	name!: string;
	members: AggregateMember[] = [];
	recursive = false;
	isSubRoot = false;
	subEntities: EntityChildInfo[] = [];
	firstEntityId: string | null = null;
}

export class AggregateMemberCache extends Cacheable {
	constructor(entityId: string, raw: any) {
		super();
		this.id = entityId;
		this.member = raw;
		this._raw = JSON.stringify(raw);
	}

	member: AggregateMember;
}
export class CategoryCache extends Cacheable {
	constructor(entityId: string, raw: any) {
		super();

		this.id = entityId;
		this.results = raw;
		this._raw = JSON.stringify(raw);
	}

	results: EntityHolder[];
}

export class OrganizationTreeCache extends Cacheable {
	constructor(id: string, raw: any) {
		super();

		this.id = id;
		this.tree = raw;
		this._raw = JSON.stringify(raw);
	}

	tree: TreeNode[];
}
