import { Component, OnDestroy, OnInit } from '@angular/core';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import { Subject, Subscription, forkJoin, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { EnumHelper } from 'src/app/server/helpers/enum-helper';
import { EntityHolder } from 'src/app/server/model/entity-holder';
import { EntityInfo } from 'src/app/server/model/entity-info';
import { LogicalEntityType } from 'src/app/server/model/logical-entity-type';
import { DatagridClickEventArg } from 'src/app/ui/controls/datagrid/datagrid-click-event-args';
import { DatagridColumn } from 'src/app/ui/controls/datagrid/datagrid-column';
import { DatagridService } from 'src/app/ui/controls/datagrid/datagrid.service';
import { searchExtended } from '../kbase/functions';
import { KGridComponent } from '../kgrid/kgrid.component';

@Component({
	selector: 'app-kquery',
	templateUrl: './kquery.component.html',
	styleUrls: ['./kquery.component.scss'],
	providers: [DatagridService],
})
export class KQueryComponent extends KGridComponent implements OnInit, OnDestroy {
	// onValueReady(): void {
	//   console.log(this.options);
	// }

	override isDecorative = true;
	openSubscription: Subscription | null = null;
	openPipe = new Subject<{
		entityId: string;
		holder: EntityHolder;
	}>();
	// columns: DatagridColumn[] = [];
	// dataSource?: CustomStore;
	// option: DatagridOption = new DatagridOption();

	override onRowEdit(holder: EntityHolder): void {
		this.openPipe.next({
			entityId: this.node.advancedData2!.entityId!,
			holder: holder,
		});
	}

	override ngOnInit() {
		this.handleControlSize();

		this.openSubscription = this.openPipe
			.pipe(
				switchMap((c) => {
					var x = forkJoin({
						info: this.getTargetEntityInfo(c.entityId),
						holder: of(c.holder),
					});

					return x;
				}),
			)
			.subscribe((d: { info: EntityInfo; holder: EntityHolder }) => {
				if (!EnumHelper.is(d.info.logicalType, LogicalEntityType.StoreProcedure))
					this.openTask(d.holder.entityId, d.holder.instanceId);
			});

		// console.log(this.node);

		this.option.numberOfButtons = 1;
		this.option.allowSelect = true;

		if (!this.node.advancedData2.entityId) {
			console.error(this.node.advancedData2.entityId);
			return;
		}

		// this.option.allowSelect = true;

		this.resultViewId = this.node.advancedData2.resultViewId!;
		this.isInAggregate = null;

		this.getTargetEntityInfo().subscribe((c) => {
			const view = c.getViewOrDefault(this.resultViewId);
			this.columns = [];
			view?.nodes.forEach((column) => {
				this.columns.push(DatagridColumn.parse(column));
			});

			searchExtended(
				this.node.advancedData2,
				c,
				view?.id,
				this.root,
				this.container.getParentHolder(),
				this.root,
				this.service,
			).subscribe((results) => {
				this.loadDatasource(results);
			});
		});
	}

	override ngOnDestroy(): void {
		super.ngOnDestroy();
		this.openSubscription?.unsubscribe();
	}

	loadDatasource(records: EntityHolder[]): void {
		const store = new CustomStore({
			key: 'instanceId',
			load: (loadOptions) => {
				return records;
			},
			insert: function (values) {
				// TO DO for inline
				return Promise.resolve(null);
			},
		});

		this.dataSource = new DataSource({
			store: store,
		});

		this.cdr.markForCheck();
	}

	override onCellClick(arg: DatagridClickEventArg): void {
		console.log(arg.attributeName, this.view);

		//da verifiare
		// griglie con pointers
		// griglie con store procedure e pointer
		super.onCellClick(arg);
	}
}
