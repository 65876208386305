import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FilterEditorBaseComponent } from '../filter-editor-base.component';

@Component({
	selector: 'app-dinamic-identity-filter',
	templateUrl: './dinamic-identity-filter.component.html',
	styleUrls: ['./dinamic-identity-filter.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DinamicIdentityFilterComponent extends FilterEditorBaseComponent {}
