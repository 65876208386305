<dx-select-box
	[items]="values"
	placeholder=""
	displayExpr="name"
	valueExpr="id"
	(onValueChanged)="onValueChanged($event)"
	[value]="value"
	searchMode="contains"
	[minSearchLength]="3"
	[searchEnabled]="true"
	searchExpr="name"
	[showDataBeforeSearch]="false"
></dx-select-box>
