import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { KFilterEditorComponent } from '../kfilter-editor.component';

// export interface IFilterEditorBaseComponent {
//   parent: KFilterEditorComponent;
// }

@Component({
	selector: 'app-filter-base',
	template: '',
})
export class FilterEditorBaseComponent implements OnInit {
	parent: KFilterEditorComponent;
	value: any;
	cdr: ChangeDetectorRef;

	constructor(parent: KFilterEditorComponent, cdr: ChangeDetectorRef) {
		this.cdr = cdr;
		this.parent = parent;

		this.value = this.parent.filter?.value;
	}

	onValueChanged(e: { value: string; previousValue: string }) {
		this.parent.filter!.value = e.value;
		//console.log(e.value, typeof e.value);
	}

	ngOnInit(): void {}
}
