import {
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output,
	ViewChild
} from '@angular/core';
import { DxTreeViewComponent } from 'devextreme-angular';
import { Node } from 'devextreme/ui/tree_view';
import { TreeNodeContext } from './tree-node-context';
import { TreeNode } from './tree-note';

export type NodeSelectionChangeArg = { node: TreeNode; collapse: boolean };

@Component({
	selector: 'app-tree',
	templateUrl: './tree.component.html',
	styleUrls: ['./tree.component.scss'],
})
export class TreeComponent implements OnInit {
	@Input() nodes!: TreeNode[];
	@Input() selectByClick: boolean = true;
	@Input() selectionMode: string = 'single';
	@Input() showCheckBoxesMode: string = 'none';

	@Input() noDataText: string = 'nessun dato presente';
	@Output() onContext = new EventEmitter<TreeNodeContext>();
	@Output() onNodeClick = new EventEmitter<TreeNode>();
	@Output() onNodeMoved = new EventEmitter<TreeNode[]>();
	@Output() onNodeSelectionChange = new EventEmitter<NodeSelectionChangeArg>();
	@Output() onSelectedNodeChange = new EventEmitter<TreeNode[]>();

	@ViewChild('treeView') treeView!: DxTreeViewComponent;

	ngOnInit(): void { }

	selectItem(e: any) {
		console.log(e);
		this.onNodeClick.emit(e.itemData);
	}

	getTreeView() {
		return this.treeView.instance;
	}

	onDragChange(e: any) {
		// if (e.fromComponent === e.toComponent) {
		//   e.cancel = true;
		// }
	}

	canBeDropInside(fromNode: any, toNode: any) {
		return true;
	}

	onDragEnd(e: any) {
		if (e.fromComponent === e.toComponent && e.fromIndex === e.toIndex) {
			return;
		}

		const treeView = this.getTreeView();

		const fromNode = this.findNode(treeView, e.fromIndex);
		const toNode = this.findNode(treeView, this.calculateToIndex(e));

		if (e.dropInsideItem && !this.canBeDropInside(toNode, fromNode)) {
			return;
		}

		const fromTopVisibleNode = this.getTopVisibleNode(e.fromComponent);
		// const toTopVisibleNode = this.getTopVisibleNode(e.toComponent);

		const items = treeView.option('items');
		// const toItems = fromTreeView.option('items');
		this.moveNode(fromNode, toNode, items, items, e.dropInsideItem);

		treeView.option('items', items);
		treeView.scrollToItem(fromTopVisibleNode);

		this.onNodeMoved.emit(
			treeView.getNodes().map((d) => d.itemData as TreeNode),
		);
	}

	calculateToIndex(e: any) {
		if (e.dropInsideItem) {
			return e.toIndex;
		}

		return e.fromIndex >= e.toIndex ? e.toIndex : e.toIndex + 1;
	}

	findNode(treeView: any, index: any) {
		const nodeElement = treeView
			.element()
			.querySelectorAll('.dx-treeview-node')[index];
		if (nodeElement) {
			return this.findNodeById(
				treeView.getNodes(),
				nodeElement.getAttribute('data-item-id'),
			);
		}
		return null;
	}

	removeNode(node: TreeNode) {
		var newNodes = Array.from(Object.assign([], this.nodes));
		var ri = newNodes.indexOf(node);

		if (ri >= 0) {
			newNodes.splice(ri, 1);
		} else {
			var n = this.findTreeNodeById(newNodes, node);
			if (n && n.parent?.items) {
				var nu = n.parent.items.indexOf(n);
				if (nu >= 0) {
					n.parent.items.splice(nu, 1);
				}
			}
		}
		this.nodes = newNodes;
	}

	findNodeById(nodes: any, id: any): Node<TreeNode> | null {
		for (let i = 0; i < nodes.length; i++) {
			if (nodes[i].itemData.id === id) {
				return nodes[i];
			}
			if (nodes[i].children) {
				const node = this.findNodeById(nodes[i].children, id);
				if (node != null) {
					return node;
				}
			}
		}
		return null;
	}

	findTreeNodeById(nodes: TreeNode[], nodeToFind: TreeNode): TreeNode | null {
		for (let i = 0; i < nodes.length; i++) {
			if (nodes[i].id === nodeToFind.id) {
				return nodes[i];
			}
			if (nodes[i].items) {
				const node = this.findTreeNodeById(nodes[i].items!, nodeToFind);
				if (node != null) {
					return node;
				}
			}
		}
		return null;
	}

	removeNodeRecursive(nodes: TreeNode[], id: string) {
		for (const node of nodes) {
			if (node.id == id) {
			}
		}
	}

	moveNode(
		fromNode: any,
		toNode: any,
		fromItems: any,
		toItems: any,
		isDropInsideItem: any,
	) {
		const fromNodeContainingArray = this.getNodeContainingArray(
			fromNode,
			fromItems,
		);
		const fromIndex = fromNodeContainingArray.findIndex(
			(item: any) => item.id === fromNode.itemData.id,
		);

		fromNodeContainingArray.splice(fromIndex, 1);

		if (isDropInsideItem) {
			toNode.itemData.items.splice(
				toNode.itemData.items.length,
				0,
				fromNode.itemData,
			);
		} else {
			const toNodeContainingArray = this.getNodeContainingArray(
				toNode,
				toItems,
			);
			const toIndex =
				toNode === null
					? toNodeContainingArray.length
					: toNodeContainingArray.findIndex(
						(item: any) => item.id === toNode.itemData.id,
					);
			toNodeContainingArray.splice(toIndex, 0, fromNode.itemData);
		}
	}

	getNodeContainingArray(node: any, rootArray: any) {
		return node === null || node.parent === null
			? rootArray
			: node.parent.itemData.items;
	}

	isChildNode(parentNode: any, childNode: any) {
		let parent = childNode.parent;
		while (parent !== null) {
			if (parent.itemData.id === parentNode.itemData.id) {
				return true;
			}
			parent = parent.parent;
		}
		return false;
	}

	getTopVisibleNode(component: any) {
		const treeViewElement = component.element();
		const treeViewTopPosition = treeViewElement.getBoundingClientRect().top;
		const nodes = treeViewElement.querySelectorAll('.dx-treeview-node');
		for (let i = 0; i < nodes.length; i++) {
			const nodeTopPosition = nodes[i].getBoundingClientRect().top;
			if (nodeTopPosition >= treeViewTopPosition) {
				return nodes[i];
			}
		}

		return null;
	}

	onInnerContext(e: any) {
		e.event.preventDefault();
		e.event.stopPropagation();
		// console.log(e);
		// console.log(e.itemData);
		this.onContext.emit(
			new TreeNodeContext(e.event.clientX, e.event.clientY, e.itemData),
		);
	}

	onItemExanded(e: any) {
		this.onNodeSelectionChange.emit({ node: e.itemData, collapse: false });
	}

	onItemCollapsed(e: any) {
		this.onNodeSelectionChange.emit({ node: e.itemData, collapse: true });
	}

	onSelectionChanged(e: any) {
		const nodes = this.treeView.instance
			.getSelectedNodes()
			.map((c) => c.itemData as TreeNode);
		this.onSelectedNodeChange.emit(nodes);
	}
}
