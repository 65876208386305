import { KDataState } from 'src/app/server/kform-data-state.service';
import { EntityHolder } from 'src/app/server/model/entity-holder';
import { EntityInfo } from '../../../server/model/entity-info';
import { EntityStatus } from '../../../server/model/entity-status';
// import { LoadRuntimeInformation } from '../../../server/model/load-runtime-information';

export interface IKFormData {
	data: EntityHolder | null;
	info: EntityInfo | null;
	status: EntityStatus | null;
	isFav: boolean;
}

export class KFormData implements KDataState, IKFormData {
	#info: EntityInfo | null;
	viewId: string | null = null;
	public get info(): EntityInfo | null {
		return this.#info;
	}

	#data: EntityHolder | null;
	public get data(): EntityHolder | null {
		return this.#data;
	}

	#status: EntityStatus | null;
	public get status(): EntityStatus | null {
		return this.#status;
	}

	#isFav: boolean = false;
	public get isFav(): boolean {
		return this.#isFav;
	}

	constructor(
		data: EntityHolder | null,
		info: EntityInfo | null,
		isFav: boolean,
	) {
		this.#data = data;
		this.#info = info;
		this.#status = this.evaluateStatus();
		this.#isFav = isFav;
	}

	isChanged(): boolean {
		return (this.data?.history?.length ?? 0) > 0;
	}

	updateHolder(data: EntityHolder) {
		this.#data = data;
		this.#status = this.evaluateStatus();
	}

	evaluateStatus(): EntityStatus | null {
		if (!this.data) return null;

		const s = this.data.getStatusOrDefault();
		let status: EntityStatus | null = null;

		if (s != null && this.info) {
			status = this.info.metadata?.statuses.get(s) ?? null;
		}

		return status;
	}
}

export class KTodoData implements KDataState {
	selectedTab: string | null = null;

	#isChanged: boolean = false;

	isChanged(): boolean {
		return this.#isChanged;
	}
}
