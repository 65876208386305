import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { first, map, switchMap, tap } from 'rxjs/operators';
import { EntityHolder } from 'src/app/server/model/entity-holder';
import { EntityInfo } from 'src/app/server/model/entity-info';
import { LogicalAttributeType } from 'src/app/server/model/logical-attribute-type';
import { ExecuteElaborateResponse } from 'src/app/server/model/save-response';
import { ViewNode } from 'src/app/server/model/view-node';
import { TreeNodeReference } from '../ui/edit/kcomponents/kaggregate-tree/model';
import {
	createNewArguments,
	getVirtualTypeOf,
} from '../ui/edit/kcomponents/kbase/functions';
import { EntityService } from './entity.service';

export interface ICreateEntitiesFromMainMembers {
	entityId: string;
	mainMemberName: string | null;
	entities: EntityHolder[];
	node: TreeNodeReference | null;
	attributeName: string | null;
	taskId: string | null;
	viewNode: ViewNode;
	containerHolder: EntityHolder;
	containerTaskId: string;
}

export interface INewEntityArg {
	entityId: string;
	tei: EntityInfo;
	mainMemberName: string | null;
	node: TreeNodeReference | null;
	entities: EntityHolder[];
	viewNode: ViewNode;
	containerHolder: EntityHolder;
}

@Injectable({
	providedIn: 'root',
})
export class CreateNewEntitiesServices {
	service: EntityService;
	constructor(service: EntityService) {
		this.service = service;
	}

	getTargetEntityId(node: ViewNode, containerHolder: EntityHolder): string {
		if (!node.advancedData2.entityId) {
			return '';
		}

		const te = node.advancedData2.entityId!;

		if (te.startsWith('@')) {
			return getVirtualTypeOf(containerHolder, te);
		}
		return te;
	}

	getTargetEntityInfo(
		node: ViewNode,
		containerHolder: EntityHolder,
		entityId: string | null = null,
	): Observable<EntityInfo> {
		const eID = entityId ?? this.getTargetEntityId(node, containerHolder);
		// console.log(eID);
		return this.service.getEntityInfo(eID).pipe(first());
	}

	newEntities(arg: INewEntityArg): Observable<(EntityHolder | null)[]> {
		return forkJoin(
			arg.entities.map((d: EntityHolder) => {
				const info = arg.node?.node?.aggregateInfo ?? null;
				const isAggregateOrTree = info != null;
				// Di norma sono chi lo apre e la root
				let rootHolder = arg.containerHolder;
				let parentHolder = arg.node?.node?.holder;

				if (isAggregateOrTree) {
					// ma negli aggregate tree che hanno una sotto collezione l'owner è il parent
					// se è un figlio di una collezione recursiva l'owner è l'owner del parent
					const isRecursiveChild: boolean =
						info!.recursive && parentHolder?.entityId === arg.tei?.id;

					if (isRecursiveChild) {
						const owner = parentHolder?.getFirstByType(
							LogicalAttributeType.Owner,
						);
						rootHolder = parentHolder?.getField(owner!.name)?.value;

						if (!(rootHolder instanceof EntityHolder)) {
							rootHolder = EntityHolder.fromScratch(
								owner!.entityId,
								'',
								rootHolder,
							);
						}
					} else {
						const ownerEntityId =
							arg.tei?.metadata.getOwner()?.advancedData2.entityId;

						if (ownerEntityId === parentHolder?.entityId) {
							rootHolder = parentHolder!;
						}
					}

					const parentEntityId = arg.tei?.metadata.getFirstByeLogicalType(
						LogicalAttributeType.Parent,
					)?.advancedData2.entityId;

					// console.log('isAggregateOrTree', parentEntityId, parentHolder?.entityId);

					// attenzione collezioni ereditate
					if (
						parentEntityId !== parentHolder?.entityId &&
						((parentHolder?.parentEntityId &&
							parentEntityId !== parentHolder?.parentEntityId) ||
							!parentHolder?.parentEntityId)
					) {
						parentHolder = null;
					}

					// console.log('isAggregateOrTree', rootHolder, parentHolder);
				}

				const args = createNewArguments(
					arg.tei,
					arg.viewNode.lt,
					arg.viewNode.attribute?.metadata.isBase,
					rootHolder,
					parentHolder,
					arg.viewNode.advancedData2,
				);

				if (arg.mainMemberName) {
					args.set(arg.mainMemberName, arg.entities[0].instanceId);
					// console.log(mainMemberName, entities[0].instanceId);
				}

				// console.log(isAggregateOrTree);

				const parent = arg.tei.metadata.getFirstByeLogicalType(
					LogicalAttributeType.Parent,
				);

				if (parent && parentHolder) {
					// console.log('my parent is:', node?.node?.holder?.humanReadableName);
					args.set(parent.name, parentHolder?.instanceId);
					// console.log(parent.name, node?.node?.holder?.instanceId);
				}

				// console.log('args', args, entityId);

				return this.service.load(arg.entityId, null, args);
			}),
		);
	}

	createEntitiesFromMainMembers(
		arg: ICreateEntitiesFromMainMembers,
	): Observable<EntityHolder[]> {
		if (!arg.attributeName) arg.attributeName = arg.viewNode.attributeName;
		if (!arg.taskId) arg.taskId = arg.containerTaskId;
		let tei: EntityInfo;
		return this.getTargetEntityInfo(
			arg.viewNode,
			arg.containerHolder,
			arg.entityId,
		).pipe(
			switchMap((targetEntityInfo: EntityInfo) => {
				tei = targetEntityInfo;
				return this.newEntities({
					entityId: arg.entityId,
					tei: targetEntityInfo,
					mainMemberName: arg.mainMemberName,
					node: arg.node,
					entities: arg.entities,
					viewNode: arg.viewNode,
					containerHolder: arg.containerHolder,
				});
			}),
			tap((holders) => {
				console.log(holders);
				let i = 0;
				holders.forEach((holder) => {
					if (!holder) {
						console.error(
							'La nuova entità creata è nulla verificare il create default di ' +
								arg.entityId,
						);
					}

					// è questo un duplicato di quello che avviene negli arguments???
					if (arg.mainMemberName) {
						holder!.setField(
							arg.mainMemberName,
							EntityHolder.parse(arg.entities[i]),
						);
					}

					// è questo un duplicato di quello che avviene negli arguments???
					// if (node && node.type === AggregateTreeNodeType.Node) {
					//   const parent = LogicalHelper.getAllByType(holder!.fields, [LogicalAttributeType.Parent], [])[0];
					//   holder?.setField(parent.name, node.holder!);
					//   holder?.recalculateHumanReadable();
					// }

					i++;
				});
			}),
			map((holders) => {
				const notNullHolders: EntityHolder[] = [];

				holders.forEach((notNullHolder) => {
					if (notNullHolder) notNullHolders.push(notNullHolder);
				});

				return notNullHolders;
			}),
			switchMap((holders: EntityHolder[]) => {
				if (tei.metadata.requireElaborate) {
					// console.log(`Eseguo elaborate per ${tei.id} `);
					return forkJoin(
						holders.map((e) => this.service.executeElaborate(e.entityId, e)),
					);
				} else {
					const mapped: ExecuteElaborateResponse[] = holders.map(
						(e: EntityHolder) => {
							return {
								data: e,
								success: true,
								message: null,
							};
						},
					);

					return of(mapped);
				}
			}),
			map((responses: ExecuteElaborateResponse[]) => {
				return responses.map((c) => c.data!);
			}),
		);
	}
}
