import { EnumHelper } from 'src/app/server/helpers/enum-helper';
import { LogicalAttributeType } from '../model/logical-attribute-type';

type LogicalAttribute = {
	logicalType: LogicalAttributeType;
};

export class LogicalHelper {
	static getMainMember<T extends LogicalAttribute>(attributes: T[]): T | null {
		const typesFound = this.getAllByType(
			attributes,
			[LogicalAttributeType.Pointer, LogicalAttributeType.Type],
			[
				LogicalAttributeType.Owner,
				LogicalAttributeType.Nested,
				LogicalAttributeType.Parent,
			],
		);

		// console.log(typesFound);

		if (typesFound.length > 0) {
			return typesFound[0];
		}

		const founds = this.getAllByType(
			attributes,
			[LogicalAttributeType.Pointer],
			[
				LogicalAttributeType.Owner,
				LogicalAttributeType.Nested,
				LogicalAttributeType.Parent,
			],
		);

		// console.log(founds);

		if (founds.length > 0) return founds[0];
		return null;
	}

	static getChildMembers<T extends LogicalAttribute>(fields: T[]): T[] {
		return this.getAllByType(
			fields,
			[LogicalAttributeType.Collection, LogicalAttributeType.Member],
			[LogicalAttributeType.Nested],
		);
	}

	static getAllByType<T extends LogicalAttribute>(
		attributes: T[],
		included: LogicalAttributeType[],
		escluded: LogicalAttributeType[],
	): T[] {
		const output: T[] = [];

		attributes.forEach((a: T) => {
			let isIncluded: boolean = false;
			let isExcluded: boolean = false;

			included.forEach((i) => {
				if (EnumHelper.is(a.logicalType, i)) {
					isIncluded = true;
				} else {
					isIncluded = false;
				}
			});

			escluded.forEach((i) => {
				if (EnumHelper.is(a.logicalType, i)) {
					isExcluded = true;
				}
			});

			if (isIncluded && !isExcluded) {
				output.push(a);
			}
		});

		return output;
	}
}
