import { ChangeDetectionStrategy, Component, Input, OnInit, Type, ViewChild } from '@angular/core';
import { FormEntry } from 'src/app/server/model/commad';
import { ViewItemUiControl } from 'src/app/server/model/view-item-ui-control';
import { HostDirective } from '../../host.directive';
import { KCommandDialogComponent } from '../kcommand-dialog.component';
import { KDialogBaseSelectorComponent } from '../selectors/kdialog-base-selector';
import { KDialogCheckboxSelectorComponent } from '../selectors/kdialog-checkbox-selector/kdialog-checkbox-selector.component';
import { KDialogSearchSelectorComponent } from '../selectors/kdialog-search-selector/kdialog-search-selector.component';
import { KDialogTextareaSelectorComponent } from '../selectors/kdialog-textarea-selector/kdialog-textarea-selector.component';
import { KDialogTextboxSelectorComponent } from '../selectors/kdialog-textbox-selector/kdialog-textbox-selector.component';

@Component({
	selector: 'app-kcommand-selector-host',
	templateUrl: './kcommand-selector-host.component.html',
	styleUrls: ['./kcommand-selector-host.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KcommandSelectorHostComponent implements OnInit {
	@Input() formEntries: FormEntry[] = [];
	@ViewChild(HostDirective, { static: true }) host?: HostDirective;
	container: KCommandDialogComponent;

	constructor(container: KCommandDialogComponent) {
		this.container = container;
	}

	ngOnInit(): void {
		this.loadControls(this.formEntries);
	}

	loadControls(formEntries: FormEntry[]) {
		if (this.host) {
			const viewContainerRef = this.host.viewContainerRef;
			viewContainerRef.clear();

			let index = 0;

			formEntries.forEach((entry) => {
				let cType: Type<KDialogBaseSelectorComponent>;

				switch (entry.selectorType) {
					case ViewItemUiControl.CheckBox:
						cType = KDialogCheckboxSelectorComponent;

						break;
					case ViewItemUiControl.TextBox:
						cType = KDialogTextboxSelectorComponent;
						break;

					case ViewItemUiControl.TextArea:
						cType = KDialogTextareaSelectorComponent;
						break;

					case ViewItemUiControl.SearchPanel:
						cType = KDialogSearchSelectorComponent;
						break;
					default:
						console.error('not supported ' + entry);
						return;
				}

				const componentFactory = viewContainerRef.createComponent(cType);
				const cmp = componentFactory.instance as KDialogBaseSelectorComponent;
				cmp.index = index;
				cmp.entry = entry;
				index++;
			});
		}
	}
}
