import { Component } from '@angular/core';
import { forkJoin } from 'rxjs';
import { first } from 'rxjs/operators';
import { KnowEntityIds, KnowItemNames } from 'src/app/server/helpers/constants';
import { GenericHelper } from 'src/app/server/helpers/generic-helper';
import { TreeNode } from 'src/app/ui/controls/tree/tree-note';
import { DataPartitionUnit } from '../../../server/model/save-response';
import { TreeNodeContext } from '../../controls/tree/tree-node-context';
import { KBaseComponent } from '../kcomponents/kbase/kbase.component';

@Component({
	selector: 'app-entity-partition',
	templateUrl: './kpartition.component.html',
	styleUrls: ['./kpartition.component.scss'],
})
export class KPartitionComponent extends KBaseComponent {
	override isDecorative = true;
	treeData: TreeNode[] = [];
	icon!: string;
	units: DataPartitionUnit[] = [];

	override ngOnInit(): void {
		this.icon = GenericHelper.toIcon(KnowEntityIds.DataPartitionUnit);
		this.node.colSpan = 20;
		this.node.rowSpan = 10;

		super.ngOnInit();

		const $o = forkJoin({
			selected: this.container.service.getUnits(
				this.container.holder.entityId,
				this.container.holder.instanceId,
			),
			all: this.container.service.getOrganizationTree(null),
		}).pipe(first());

		$o.subscribe((result) => {
			if (!result.selected) {
				result.selected = [];
			}

			// console.log(result);
			this.treeData = [];
			this.container.unitsIds = result.selected.map((c) => c.id);
			this.appendUnits(
				this.treeData,
				result.all.units,
				KnowItemNames.GUID_EMPTY,
				this.container.unitsIds,
			);
			// console.log(this.treeData);
			this.cdr.markForCheck();
		});
	}

	appendUnits(
		container: TreeNode[],
		units: DataPartitionUnit[],
		parentId: string | null = null,
		selecteds: string[],
	) {
		units
			.sort((d) => d.index)
			.filter((c) => c.parentId === parentId)
			.forEach((unit) => {
				const m: TreeNode = {
					id: unit.id,
					text: unit.name,
					icon: this.icon,
					holder: null,
					parent: null,
					tag: unit,
					items: [],
					selected: selecteds.indexOf(unit.id) >= 0,
				};

				if (unit.units.length > 0) {
					this.appendUnits(m.items!, unit.units, m.id, selecteds);
				}
				container.push(m);
			});
	}

	onNodeClick(e: TreeNode): void {
		// console.log(e);
	}

	onContext(context: TreeNodeContext) {
		// const menus = AggreagateTreeUtil.getMenuItems(context.node, this.node.advancedData2.entityId!, this.searchOrNew);
		// this.openContextMenu(context.x, context.y, context.node, menus);
	}

	selectItem(e: any) {
		// console.log(e);
		// this.onNodeClick.emit(e.itemData);
	}

	selectionChange(e: any) {
		this.container.unitsIds = e.component
			.getSelectedNodes()
			.map(function (node: any) {
				return node.itemData.id;
			});
	}

	onInnerContext(e: any) {
		e.event.preventDefault();
		e.event.stopPropagation();
		// console.log(e);
		// console.log(e.itemData);
		// this.onContext.emit(new TreeNodeContext(e.event.clientX, e.event.clientY, e.itemData));
	}
}
