import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { KTaskBarEventType } from '../../manager/ktaskbar/ktaskbar-event';
import { KTaskbarService } from '../../manager/ktaskbar/ktaskbar.service';
import { KItem } from '../kitem';
import { KContextPayload, KContextPayloadCallback } from './kcontext-payload';

@Component({
	selector: 'app-kcontext-menu',
	templateUrl: './kcontext-menu.component.html',
	styleUrls: ['./kcontext-menu.component.scss'],
})
export class KContextMenuComponent implements OnInit, OnDestroy {
	contextMenuItems: KItem[] = [];
	left = 0;
	top = 0;
	isContextMenuVisible = false;
	subscription: Subscription | null = null;
	current: KContextPayload | null = null;
	private manager: KTaskbarService;
	constructor(manager: KTaskbarService) {
		this.manager = manager;
	}

	ngOnInit(): void {
		this.subscription = this.manager.trigger.subscribe((event) => {
			switch (event.type) {
				case KTaskBarEventType.ShowContextMenu:
					if (event.payload instanceof KContextPayload) {
						this.left = event.payload.x;
						this.top = event.payload.y;
						this.contextMenuItems = event.payload.items;

						this.isContextMenuVisible = true;
						this.current = event.payload;
					}

					break;
			}
		});
	}

	onItemClicked(event: any): void {
		const payload: KContextPayloadCallback = new KContextPayloadCallback(
			event.itemData,
			this.current?.target,
			this.current?.reference,
		);
		this.manager.on(KTaskBarEventType.ContextMenuCallBack, payload);
	}

	ngOnDestroy(): void {
		this.subscription?.unsubscribe();
	}
}
