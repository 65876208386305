<div class="dx-field">
	<div class="dx-field-label">Filtro</div>
	<div class="dx-field-value">
		<dx-select-box [items]="allFilters" placeholder="" displayExpr="name" valueExpr="attributeName"
			(onValueChanged)="onFilterChange($event)" [value]="selectedFilterAttributeName"></dx-select-box>
	</div>
</div>
<div class="dx-field">
	<div class="dx-field-label">Operatore</div>
	<div class="dx-field-value">
		<dx-select-box [items]="operators" placeholder="" displayExpr="name" valueExpr="id"
			(onValueChanged)="onOperatorChange($event)" [value]="selectedOperator"></dx-select-box>
	</div>
</div>

<div class="dx-field">
	<div class="dx-field-label">Valore</div>
	<div class="dx-field-value">
		<app-kfilter-editor-host *ngIf="this.selectedFilter" [selectedFilter]="selectedFilter"></app-kfilter-editor-host>
	</div>
</div>
