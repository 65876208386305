<app-kcomponent-container
	[label]="label"
	[mandatory]="mandatory"
	[description]="description"
>
	<dx-text-area
		[placeholder]="placeHolder"
		[readOnly]="readonly"
		[value]="innerValue"
		(onValueChanged)="onValueChanged($event)"
	>
	</dx-text-area>
</app-kcomponent-container>
