<dx-toolbar [disabled]="readOnly">
	<dxi-item location="before" *ngIf="!isNew" widget="dxMenu" locateInMenu="never" [options]="statusBeforeOptions">
	</dxi-item>

	<dxi-item location="before" *ngIf="!isNew && currentStatusOptions" widget="dxButton" locateInMenu="never"
		[options]="currentStatusOptions">
	</dxi-item>

	<dxi-item location="before" *ngIf="!isNew" widget="dxMenu" locateInMenu="never" [options]="statusAfterOptions">
	</dxi-item>

	<dxi-item location="before" *ngIf="!isNew"
		[widget]="canBeGeneratedByModel || isMultiInheritance ? 'dxDropDownButton' : 'dxButton'" locateInMenu="never"
		[options]="newButtonOptions">
	</dxi-item>

	<dxi-item location="before" [widget]="saveIsButton ? 'dxButton' : 'dxDropDownButton'" locateInMenu="never"
		[options]="saveButtonOptions">
	</dxi-item>

	<dxi-item location="before" widget="dxButton" locateInMenu="never" *ngIf="isChanged" [options]="undoButtonOptions">
	</dxi-item>

	<dxi-item location="before" widget="dxButton" locateInMenu="never" *ngIf="isDialog" [options]="closeButtonOptions">
	</dxi-item>

	<dxi-item location="before" *ngIf="!isNew" widget="dxButton" locateInMenu="never" [options]="favoriteButtonOptions">
	</dxi-item>

	<dxi-item location="before" *ngIf="!isNew" widget="dxButton" locateInMenu="never" [options]="clonaButtonOptions">
	</dxi-item>

	<dxi-item location="before" *ngIf="!isNew && isDoc" widget="dxButton" locateInMenu="never"
		[options]="downloadButtonOptions">
	</dxi-item>

	<dxi-item location="before" *ngIf="!isNew" widget="dxButton" locateInMenu="never" [options]="deleteButtonOptions">
	</dxi-item>

	<!-- <dxi-item
		location="before"
		*ngIf="!isNew && isVersionable"
		widget="dxButton"
		locateInMenu="never"
		[options]="versionButtonOptions"
	>
	</dxi-item> -->

	<dxi-item location="after" *ngIf="!isNew" widget="dxMenu" locateInMenu="never" [options]="toolsMenuOptions">
	</dxi-item>
</dx-toolbar>
