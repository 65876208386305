import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { EntityService } from 'src/app/server/entity.service';
import { GenericHelper } from 'src/app/server/helpers/generic-helper';
import { CacheService } from 'src/app/shared/caching/cache-service';
import { GlobalService } from 'src/app/shared/services';
import { KTaskStateService } from '../../../server/kform-data-state.service';
import { KContextPayload } from '../../shared/kcontext-menu/kcontext-payload';
import { KItem } from '../../shared/kitem';
import { KRouteRequestPayload } from '../ktaskbar/kroute-request-payload';
import { KTaskBarEvent, KTaskBarEventType } from '../ktaskbar/ktaskbar-event';
import { KTaskbarService } from '../ktaskbar/ktaskbar.service';
import { KTitleChangePayload } from '../ktaskbar/ktitle-change-payload';
import { Task, TaskAction, TaskRelation } from './../../manager/ktaskbar/task';

@Component({ selector: '', template: '' })
export abstract class KTaskBaseComponent implements OnInit, OnDestroy {
	task!: Task;
	protected manager: KTaskbarService;
	protected state: KTaskStateService;
	protected cdr: ChangeDetectorRef;
	protected cache: CacheService;
	protected route: ActivatedRoute;
	protected router: Router;
	public service: EntityService;
	public global: GlobalService;

	subscription: Subscription | null = null;
	menuItems: KItem[] = [];

	constructor(
		manager: KTaskbarService,
		state: KTaskStateService,

		cdr: ChangeDetectorRef,
		cache: CacheService,
		route: ActivatedRoute,
		router: Router,

		global: GlobalService,
		service: EntityService,
	) {
		this.manager = manager;
		this.cdr = cdr;
		this.cache = cache;
		this.route = route;
		this.service = service;
		this.global = global;
		this.router = router;
		this.state = state;
		this.task = this.manager.task!;
	}

	// get task(): Task {
	//   return this.manager.task;
	// }

	ngOnInit(): void {
		this.subscription = this.manager.trigger.subscribe({
			next: (x1: KTaskBarEvent) => {
				return this.handleEvents(x1);
			},
			error: () => {
				console.log('error');
			},
			complete: () => {
				console.log('complete');
			},
		});
	}

	handleEvents(event: KTaskBarEvent): void {}

	ngOnDestroy(): void {
		this.subscription?.unsubscribe();
		// console.log('un', this.container.manager.$events.observers.length);
	}

	onTitleChange(newTitle: string): void {
		this.on(
			KTaskBarEventType.TitleChange,
			new KTitleChangePayload(newTitle, this.task, this.task.entityId),
		);

		// this.container.task.text = this.data?.data?.humanReadableName ?? '';
		//  this.global.title = newTitle;
		// this.container.manager.updateTasks();
		// this.cdr.markForCheck();
	}

	// usato per i related
	// i quali vengono settati come parent per il refresh
	openTask(
		entityId: string,
		instanceId: string,
		taskId: string,
		attributeName: string | null = null,
		resultViewId: string | null = null,
		relation: TaskRelation = TaskRelation.Parent,
		action: TaskAction = 'edit',
	) {
		// console.log(this.node.advancedData2);
		const payload = this.createRelatedOpenTaskPayload(
			entityId,
			instanceId,
			taskId,
			attributeName,
			resultViewId,
			relation,
			action,
		);
		console.log('openTask', payload);
		this.on(KTaskBarEventType.OpenTask, payload);
	}

	protected createRelatedOpenTaskPayload(
		entityId: string,
		instanceId: string,
		taskId: string,
		attributeName: string | null = null,
		resultViewId: string | null = null,
		relation: TaskRelation = TaskRelation.Parent,
		action: TaskAction = 'edit',
	): KRouteRequestPayload {
		return new KRouteRequestPayload(
			action,
			entityId,
			instanceId,
			relation,
			taskId, // this.task.id,
			attributeName, // this.node.attributeName,
			'',
			GenericHelper.toIcon(entityId),
			resultViewId,
		);
	}

	onContext(context: any): void {
		context.event.preventDefault();
		context.event.stopPropagation();

		const item = context.itemData as KItem;

		if (item) {
			const payload: KContextPayload = Object.assign(new KContextPayload(), {
				x: context.event.clientX,
				y: context.event.clientY,
				items: this.menuItems,
				target: item,
			});

			this.manager.on(KTaskBarEventType.ShowContextMenu, payload);
		}
	}

	onContextCallBack(id: string): void {}

	inUi(action: any) {
		try {
			action();
		} finally {
			this.manager.stopLoader();
			this.cdr.markForCheck();
		}
	}

	on(type: KTaskBarEventType, arg: any = null) {
		this.manager.on(type, arg);
	}
}
