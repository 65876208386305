import { Component } from '@angular/core';
import { EnumHelper } from 'src/app/server/helpers/enum-helper';
import { DatagridService } from 'src/app/ui/controls/datagrid/datagrid.service';
import { KGridComponent } from '../kgrid/kgrid.component';
import { GroupableGridControlOption } from './groupable-grid-control-option';

@Component({
	selector: 'app-kgroupable-grid',
	templateUrl: './kgroupable-grid.component.html',
	styleUrls: ['./kgroupable-grid.component.scss'],
	providers: [DatagridService],
})
export class KGroupableGridComponent extends KGridComponent {
	override ngOnInit(): void {
		super.ngOnInit();

		// console.log(`${this.node.name} readonly:${this.readonly} option:`, this.node.optionId);
		// Multiselect, indica di solito mono colonna con Main member e selezione multipla del main member
		this.isMultiselect =
			!this.readonly &&
			EnumHelper.is(this.node.optionId, GroupableGridControlOption.MultiSelect);
		this.option.allowSelect = true;
		this.option.multiselect = this.isMultiselect ?? false;
		this.option.groupEnabled = true;
		this.option.allowAdding = true;
		this.option.allowDelete = true;
		this.option.pagingEnabled = false;
		this.option.evaluateNumberOfIcons();
	}
}
