<dx-popup width="50%" height="auto" style="" [showTitle]="true" [resizeEnabled]="true" title="Selezione categorie"
	[dragEnabled]="false" [hideOnOutsideClick]="true" [showCloseButton]="true" [visible]="visible"
	(visibleChange)="onVisibleChange($event)">

	<dxi-toolbar-item widget="dxButton" toolbar="bottom" location="before" [options]="selectButtonOptions">
	</dxi-toolbar-item>

	<dxi-toolbar-item widget="dxButton" toolbar="bottom" location="before" [options]="addButtonOptions">
	</dxi-toolbar-item>

	<dxi-toolbar-item widget="dxButton" *ngIf="lastSelectedText" toolbar="bottom" location="before"
		[options]="deleteButtonOptions">
	</dxi-toolbar-item>

	<dxi-toolbar-item widget="dxButton" toolbar="bottom" location="before" [options]="refreshButtonOptions">
	</dxi-toolbar-item>

	<dxi-toolbar-item widget="dxButton" toolbar="bottom" location="after" [options]="closeButtonOptions">
	</dxi-toolbar-item>

	<div *dxTemplate="let data of 'content'">
		<div *ngIf="addIsVisible" class="addBox box">
			<div>
				Inserire il nome della nuova categoria che verrà create sotto:
				<strong>{{ lastSelectedText }}</strong>
			</div>
			<div class="addSaveRow">
				<dx-text-box class="maxWith" (onValueChanged)="onNewCategoryNameChange($event)"></dx-text-box>
				<dx-button icon="save" (onClick)="onSaveNew()"></dx-button>
				<dx-button icon="back" (onClick)="onCloseNew()"></dx-button>
			</div>
		</div>

		<div *ngIf="deleteIsVisible" class="addBox box">
			<div>
				Scrivere <strong>elimina</strong> e premere sul pulsate per cancellare
				la categoria:
				<strong>{{ lastSelectedText }}</strong>
			</div>
			<div class="addSaveRow">
				<dx-text-box class="maxWith" (onValueChanged)="onNewCategoryNameChange($event)"></dx-text-box>
				<dx-button icon="trash" (onClick)="onDelete()"></dx-button>
				<dx-button icon="back" (onClick)="onCloseNew()"></dx-button>
			</div>
		</div>

		<dx-tabs #apiTabs [dataSource]="tabs" [(selectedIndex)]="selectedTabIndex" (onItemClick)="selectTab($event)">
		</dx-tabs>

		<dx-tree-view #treeView id="treeView" [height]="addIsVisible || deleteIsVisible ? '35vh' : '27vh'" class="tree-view"
			[items]="treeData" selectionMode="multiple" showCheckBoxesMode="normal" [selectByClick]="false"
			(onItemClick)="onNodeClick($event)" (onSelectionChanged)="onSelectedNodeChange($event)">
		</dx-tree-view>
	</div>
</dx-popup>
