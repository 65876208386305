import {
	ChangeDetectorRef,
	Component,
	ComponentFactoryResolver,
	ViewChild,
} from '@angular/core';
import { EntityHistory } from 'src/app/server/model/save-response';
import { KTaskBarEventType } from '../../manager/ktaskbar/ktaskbar-event';
import { HostDirective } from '../../shared/host.directive';
import { KBaseComponent } from '../kcomponents/kbase/kbase.component';

@Component({
	selector: 'app-khistory',
	templateUrl: './khistory.component.html',
	styleUrls: ['./khistory.component.scss'],
})
export class KHistoryComponent extends KBaseComponent {
	override isDecorative = true;
	icon!: string;
	dataSource!: EntityHistory[];
	focusedRowKey = -1;

	resolver: ComponentFactoryResolver;
	@ViewChild(HostDirective, { static: true }) viewHost?: HostDirective;

	constructor(
		cdr: ChangeDetectorRef,
		componentFactoryResolver: ComponentFactoryResolver,
	) {
		super(cdr);
		this.resolver = componentFactoryResolver;
		this.cdr = cdr;
	}

	override ngOnInit(): void {
		this.node.colSpan = 20;
		this.node.rowSpan = 10;
		super.ngOnInit();
		this.container.on(KTaskBarEventType.StartLoader);
		this.reload();
	}

	reload() {
		this.container.service
			.getHistory(this.container.task.entityId, this.container.task.instanceId!)
			.subscribe((histories) => {
				this.dataSource = histories;
				// console.log(this.dataSource);
				this.container.on(KTaskBarEventType.StopLoader);
				this.cdr.markForCheck();
			});
	}

	onEdit(e: { data: EntityHistory }) {
		// console.log(e.data);
		this.container.on(KTaskBarEventType.StartLoader);
		return import('src/app/modules/workflow/workflow.module').then(
			({ WorkflowModule }) => {
				return import(
					'src/app/modules/workflow/ui/controls/kprocess-pop-up/kprocess-pop-up.component'
				).then(({ KProcessPopUpComponent }) => {
					const viewContainerRef = this.viewHost!.viewContainerRef;
					viewContainerRef.clear();
					const dCmp = this.resolver.resolveComponentFactory(
						KProcessPopUpComponent,
					);
					const i = viewContainerRef.createComponent(dCmp);
					i.instance.processTypeId = e.data.processTypeId;
					i.instance.processId = e.data.processId;
					this.container.on(KTaskBarEventType.StopLoader);
					this.cdr.markForCheck();
				});
			},
		);
	}
}
