import notify from 'devextreme/ui/notify';
import { GenericHelper } from '../../../server/helpers/generic-helper';
import { KRouteRequestPayload } from './kroute-request-payload';
import { KSearchOpenPayload } from './ksearch-open-payload';
import { Task, TaskAction, TaskRelation } from './task';

const names = {
	dashboard: 'ntt_workspace_home',
};

export function removeAllBuNotThis(
	task: Task,
	tasks: Task[],
): { success: boolean; index: number } {
	const taskToRemoves: Task[] = [];
	for (let index = 0; index < tasks.length; index++) {
		const t = tasks[index];
		if (t.id !== task.id && t.entityId !== names.dashboard) {
			taskToRemoves.push(t);
		}
	}
	taskToRemoves.forEach((t) => {
		removeTask(t, tasks, false);
	});
	const i = tasks.findIndex((c) => c.id === task.id);
	return { success: true, index: i };
}

export function removeAllTasks(tasks: Task[]): {
	success: boolean;
	index: number;
} {
	const changeds = tasks.filter((c) => c.isChanged);

	if (changeds.length > 0) {
		changeds.forEach((task) => {
			notifyTaskIsChanged(task);
		});

		return { success: false, index: -1 };
	}

	tasks.splice(1, tasks.length);

	return { success: true, index: -1 };
}

export function removeTask(
	task: Task,
	tasks: Task[],
	force: boolean = false,
): { success: boolean; index: number; tasks: Task[] } {
	if (task.entityId === names.dashboard) {
		return { success: false, index: -1, tasks };
	}

	const i = tasks.findIndex((c) => c.id === task.id);

	if (task.isChanged && !force) {
		notifyTaskIsChanged(task);
		return { success: false, index: i, tasks };
	}

	tasks.splice(i, 1);

	if (tasks.length === 0) {
		return { success: true, index: -1, tasks };
	}

	return i === 0
		? { success: true, index: tasks.length - 1, tasks }
		: { success: true, index: i - 1, tasks };
}

export function notifyTaskIsChanged(task: Task): void {
	notify(
		`La scheda '${task.text}' è cambiata, bisogna prima salvare o annulare i cambiamenti`,
		'error',
		2000,
	);
}

export function taskFromPayload(payload: KRouteRequestPayload): Task {
	const t = new Task(
		GenericHelper.generateId(),
		payload.taskName,
		payload.icon,
		payload.entityId,
		payload.instanceId,
		payload.action,
	);
	t.relation = payload.relation;
	t.attributeName = payload.attributeName;
	t.parentTaskId = payload.parentTaskId;
	t.resultViewId = payload.resultViewId;

	return t;
}
export function taskFromSearch(payload: KSearchOpenPayload): Task {
	const t = new Task(
		GenericHelper.generateId(),
		payload.search.info.metadata.name,
		GenericHelper.toIcon(payload.search.info.id),
		payload.search.info.id,
		payload.parentTask.instanceId,
		'search',
	);

	t.relation = TaskRelation.SubTask;
	t.attributeName = null;
	t.parentTaskId = payload.parentTask.id;

	return t;
}

export function taskForNewParent(
	parentTaskId: string,
	newTaskName: string,
	targetEntityId: string,
	targetInstanceId: string,
	action: TaskAction,

	parentAttributeName: string | null = null,
	rootTaskId: string | null = null, // Form Inline rappresenza il task che contiene
	rootAttributeName: string | null = null,
	rootChildInstanceId: string | null = null,
): Task {
	const t = new Task(
		GenericHelper.generateId(),
		newTaskName,
		GenericHelper.toIcon(targetEntityId),
		targetEntityId,
		targetInstanceId,
		action,
	);

	t.relation = TaskRelation.Parent;
	t.attributeName = parentAttributeName;
	t.parentTaskId = parentTaskId;
	t.rootTaskId = rootTaskId;
	t.rootAttributeName = rootAttributeName;
	t.rootChildInstanceId = rootChildInstanceId;

	return t;
}
