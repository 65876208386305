import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import ArrayStore from 'devextreme/data/array_store';
import { Helper } from './../../../server/helpers/helper';
import { AttributeMetadata } from './../../../server/model/attribute-metadata';

import { DxListComponent } from 'devextreme-angular/ui/list';
import DataSource from 'devextreme/data/data_source';
import { EntityService } from 'src/app/server/entity.service';
import { PivotCustomColumn } from 'src/app/server/model/save-response';
import { KSearchComponent } from '../ksearch/ksearch.component';

@Component({
	selector: 'app-kcolumns-editor',
	templateUrl: './kcolumns-editor.component.html',
	styleUrls: ['./kcolumns-editor.component.scss'],
})
export class KColumnsEditorComponent implements OnInit {
	parent: KSearchComponent;
	dataSource!: DataSource;
	customStore!: ArrayStore;
	initialSelection: string[] = [];
	menuItems: any[] = [];
	selected: PivotCustomColumn | null = null;
	columns: PivotCustomColumn[] = [];
	entityId: string;
	queryId: string;
	userQueryId: string;
	service: EntityService;
	cdr: ChangeDetectorRef;
	@ViewChild(DxListComponent) list!: DxListComponent;
	attributes: AttributeMetadata[] = [];

	constructor(
		parent: KSearchComponent,
		service: EntityService,
		cdr: ChangeDetectorRef,
	) {
		this.cdr = cdr;
		this.parent = parent;
		this.service = service;
		this.entityId = this.parent.data!.info!.id;
		this.queryId = this.parent.data!.query.id;
		this.userQueryId = this.parent.data!.query.userQueryId;
		this.attributes = Array.from(
			this.parent.data!.info.metadata.attributes.values(),
		);
	}

	ngOnInit() {
		// console.log(this.parent.data);

		this.menuItems.push(
			{
				text: 'Aggiungi',
				icon: 'add',
				action: () => {
					this.addNewColumn();
				},
			},
			{
				text: 'Elimina',
				icon: 'remove',
				action: (arg: { itemData: PivotCustomColumn }) => {
					this.removeColumn(arg.itemData.id);
				},
			},
		);

		this.service
			.getCustomResultColumns(this.entityId, this.queryId, this.userQueryId)
			.subscribe((columns: PivotCustomColumn[]) => {
				this.columns = columns;

				this.customStore = new ArrayStore({
					key: 'id',
					data: this.columns,
				});

				this.dataSource = new DataSource({
					store: this.customStore,
				});

				this.refresh().then((success) => {
					this.selectByIndex(0);
					this.cdr.markForCheck();
				});
			});
	}

	removeColumn(id: string) {
		const toRemoveIndex = this.columns.findIndex((c) => c.id === id);

		if (toRemoveIndex >= 0) {
			this.columns.splice(toRemoveIndex, 1);
			this.refresh().then((success) => {
				this.selectByIndex(0);
				this.cdr.markForCheck();
			});
		}
	}

	addNewColumn() {
		const newColumn = new PivotCustomColumn();

		const currentAttributes: string[] = this.columns.map((c) => c.name);
		const f = this.attributes.find(
			(c) => currentAttributes.indexOf(c.name) === -1,
		)!;
		newColumn.name = f.name;
		newColumn.caption = f.label;
		newColumn.type = f.type;
		this.columns.push(newColumn);

		this.refresh().then((success) => {
			this.selectByIndex(this.columns.length - 1);
			this.cdr.markForCheck();
		});
	}

	async refresh(): Promise<boolean> {
		return this.dataSource.reload();
	}

	selectByIndex(toSelectIndex: number) {
		if (this.columns.length > toSelectIndex) {
			this.selected = this.columns[toSelectIndex];
			this.initialSelection = [this.selected.id];
		}
	}

	onDragStart(e: any) {
		e.itemData = e.fromData[e.fromIndex];
	}

	onItemReordered(e: any) {
		Helper.arrayMove(this.columns, e.fromIndex, e.toIndex);
	}

	onAdd(e: any) {
		e.toData.splice(e.toIndex, 0, e.itemData);
	}

	onRemove(e: any) {
		e.fromData.splice(e.fromIndex, 1);
	}

	onSelectionChanged(e: { addedItems: PivotCustomColumn[] }) {
		this.selected = e.addedItems[0];
		// console.log(e);
	}
}
