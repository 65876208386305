import { ChangeDetectionStrategy, Component, forwardRef, Input } from '@angular/core';
import { AttributeMetadata } from 'src/app/server/model/attribute-metadata';
import { EntityHolder } from 'src/app/server/model/entity-holder';
import { FieldValue } from 'src/app/server/model/field-value';
import { LogicalAttributeType } from 'src/app/server/model/logical-attribute-type';
import { ViewNode } from 'src/app/server/model/view-node';
import { Task, TaskAction } from 'src/app/ui/manager/ktaskbar/task';
import { KEditTaskComponent } from '../../manager/ktask/edit/kedit-task.component';
import { KTaskComponent } from '../../manager/ktask/ktask.component';
import { KRouteRequestPayload } from '../../manager/ktaskbar/kroute-request-payload';
import { KTaskSelectedElementChange } from '../../manager/ktaskbar/ktask-chage-payload';
import { KTaskBarEventType } from '../../manager/ktaskbar/ktaskbar-event';
import { TaskRelation } from '../../manager/ktaskbar/task';
import { taskForNewParent } from '../../manager/ktaskbar/task-functions';
import { KFieldValueChangePayload } from '../kcomponents/kbase/kfield-Value-change-payload';
import { KFormClosePayload } from '../kcomponents/kbase/kform-close-payload';
import { EnumHelper } from './../../../server/helpers/enum-helper';
import { KFormData } from './kform-data';
import { KFormComponent, KFormLoadArg } from './kform.component';
import { KListViewComponent } from './klist-view.component';

@Component({
	selector: 'app-kform-inline',
	templateUrl: './kform.component.html',
	styleUrls: ['./kform.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: KTaskComponent,
			useExisting: forwardRef(() => KEditTaskComponent),
		},
	],
})
export class KFormInlineComponent extends KFormComponent {
	protected hostComponent!: KListViewComponent;
	override isSubForm: boolean = true;
	#master: EntityHolder | null = null;
	protected action: TaskAction = 'edit';
	get master(): EntityHolder | null {
		return this.#master;
	}

	@Input() set master(value: EntityHolder | null) {
		this.#master = value?.prepareForSave()!;
		if (this.#master) {
			this.load();
		}
	}

	override createChildTask(
		targetEntity: EntityHolder | null,
		attributeName: string,
		isMember: boolean,
		action: TaskAction,
	): Task {
		var t = taskForNewParent(
			this.task.id,
			targetEntity!.humanReadableName,
			targetEntity!.entityId,
			targetEntity!.instanceId,
			action,
			attributeName,
			this.task.id,
			this.hostComponent.node.attributeName,
			this.holder.instanceId,
		);

		// la form inline è usata nel master>detail e nel tree>detail
		// il campo che la contiene può essere membro (quindi siamo a 3 livelli)
		// o essere related quindi i livelli sono 2
		var containerFieldIsMember = EnumHelper.is(LogicalAttributeType.Member, this.hostComponent.node.lt);

		if (containerFieldIsMember) {
			t.relation = isMember ? TaskRelation.SubTask : TaskRelation.Parent;
		} else {
			t.rootTaskId = this.hostComponent.container.task.id;
			t.relation = isMember ? TaskRelation.InlineRelatedSubTask : TaskRelation.InlineRelatedParent;

			if (t.relation == TaskRelation.InlineRelatedParent) {
				t.rootAttributeName = null;
				t.rootChildInstanceId = null;
			}
		}
		return t;
	}

	override broadcastSelectedUiElementChange(
		stepOrTab: number,
		currentCmp: string | null = null,
		currentCmpElement: string | null = null,
	) {
		// quando siamo in un inline la root è quella del contanitore
		// noi cambiamo solo i dati del child
		var payload = new KTaskSelectedElementChange(
			this.hostComponent.container.task,
			this.hostComponent.container.task.stepOrTab,
			this.hostComponent.container.task.currentCmp,
			this.hostComponent.container.task.currentCmpElement,
			stepOrTab,
			currentCmp,
			currentCmpElement,
		);

		this.on(KTaskBarEventType.SelectedUiElementChange, payload);
	}

	override createRelatedOpenTaskPayload(
		entityId: string,
		instanceId: string,
		taskId: string,
		attributeName: string | null = null,
		resultViewId: string | null = null,
		relation: TaskRelation = TaskRelation.Parent,
		action: TaskAction = 'edit',
	): KRouteRequestPayload {
		const basePayload = super.createRelatedOpenTaskPayload(
			entityId,
			instanceId,
			taskId,
			attributeName,
			resultViewId,
			relation,
			action,
		);

		basePayload.rootTaskId = this.task.id;
		basePayload.rootAttributeName = this.hostComponent.node.attributeName;
		basePayload.rootChildInstanceId = this.holder.instanceId;

		return basePayload;
	}

	override closeAndReopenParent(): void {
		this.on(KTaskBarEventType.FormClose, new KFormClosePayload(this.task.id, this.task.rootTaskId));
	}

	override load(): void {
		const t1 = this.manager.getTask(this.task.id);
		this.selectedIndex = this.hostComponent.container.task.innerStepOrTab ?? t1.task?.innerStepOrTab ?? 0;
		this.task = this.createNewState(this.#master, this.hostComponent.node, true);

		const arg: KFormLoadArg = {
			entityId: this.#master!.entityId,
			instanceId: this.#master!.instanceId,
			relation: TaskRelation.Inline,
			taskId: this.task.id,
			parentTaskId: this.task.id,
			attributeName: this.hostComponent.node.attributeName,
			resultViewId: this.hostComponent.node.advancedData2.resultViewId,
		};

		super.loadWitArg(arg);
	}

	createNewState(c: EntityHolder | null, node: ViewNode, isMember: boolean): Task {
		const t = taskForNewParent(
			this.task.id,
			c!.humanReadableName,
			c!.entityId,
			c!.instanceId,
			this.action,
			node.attributeName,
		);

		// add Member è un bel problema perchè
		t.relation = TaskRelation.Inline;

		const k = new KFormData(c, null, false);
		this.state.persist(t.id, k);

		return t;
	}

	override on(type: KTaskBarEventType, arg: any = null) {
		//
		// console.log(KTaskBarEventType[type]);

		switch (type) {
			case KTaskBarEventType.FieldValueChange:
				if (arg instanceof KFieldValueChangePayload) {
					const requireElaborate: boolean = this.data?.info?.metadata.requireElaborate ?? false;

					if (!requireElaborate) {
						this.hostComponent.updateItem(this.holder);
					} else {
						this.service.executeElaborate(this.holder.entityId, this.holder, '').subscribe((result) => {
							this.hostComponent.updateItem(result.data!);
						});
					}
				}

				break;

			case KTaskBarEventType.FormFieldRefresh:
			case KTaskBarEventType.OpenTask:
				this.manager.on(type, arg);
				break;

			case KTaskBarEventType.ShowContextMenu:
			case KTaskBarEventType.ShowDialog:
			case KTaskBarEventType.ShowCommandDialog:
			case KTaskBarEventType.SelectedUiElementChange:
				this.manager.on(type, arg);
				break;

			default:
			// console.log(KTaskBarEventType[type], arg);
		}

		// this.manager.on(type, arg);
	}

	override notifyFieldValueChanged(node: ViewNode, field: FieldValue, changes: number) {
		this.on(
			KTaskBarEventType.FieldValueChange,
			new KFieldValueChangePayload(this.task, node, field, changes, this.hostComponent.node),
		);
	}

	override getMainHolder(): EntityHolder {
		return this.hostComponent.container.holder!;
	}

	override getInAggregate(
		entityId: string,
		currentEntityId: string,
		currentAttributeName: string,
	): AttributeMetadata | null {
		// controllo se stessa
		let isInInlineAggregate = super.getInAggregate(entityId, currentEntityId, currentAttributeName);

		if (isInInlineAggregate) {
			return isInInlineAggregate;
		}

		// e se è inline anche la form che la contiene
		isInInlineAggregate = this.hostComponent.container.getInAggregate(entityId, currentEntityId, currentAttributeName);

		if (isInInlineAggregate) {
			return isInInlineAggregate;
		}

		return null;
	}
}
