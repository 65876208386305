export enum SearchPanelControlOption {
	None = 0,

	View = 1,

	// seleziona esistente
	Change = 2,

	// cancella il dato selezionato
	Delete = 4,

	// aggiunge nuova entità e selezionala
	Add = 8,

	// apre scheda esistente
	Edit = 16,

	// Eredita da labelled control
	HideLabel = 256,
	Half = 512,
	AQuater = 1024,
	OneThird = 2048,
	PopUpDelete = 4096,
}

export enum LookupOptions {
	// nessun bottone
	Deny = -1,

	// non specificato
	None = 0,

	// bottone nuovo
	Add = 1,

	// bottone modifica
	Edit = 2,

	// bottone elimina
	Delete = 4,

	// bottone di deselezione
	Unselect = 8,

	// bottoni di default (nuovo e modifica)
	Default = 11,

	// tutti i bottoni
	All = 15,
}

export enum GridControlOption {
	// Tutto on
	All = 0,

	//  solo vedere
	View = 1,

	// Permette modifica
	Edit = 2,

	// Abilita rimuovi
	Delete = 4,

	// Abilita aggiungi
	Add = 8,

	// Abilita in linea ( non nega pop up )
	InLine = 16,

	UseFirstColumnAsGroup = 32,

	// abilita la selezione multipla
	MultiSelect = 64,

	// consente l'inserimento di maintype duplicati nella sponda
	AllowMultiMainType = 128,
}
