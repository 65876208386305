import { UiRuleTypeEnum } from './rule-type';
import { SearchParameterType } from './search-parameter-type';

export class UiRule {
	nodeId!: string;
	nodeName!: string;
	type!: UiRuleTypeEnum;

	operator: SearchParameterType = SearchParameterType.Equal;
	reset: boolean = false;
	targetNodeNames: string[] = [];

	value: any;

	toString(): string {
		let stringMe: string = `${this.nodeName} ${UiRuleTypeEnum[this.type]}`;

		if (this.targetNodeNames?.length >= 0) {
			stringMe += ` ${this.targetNodeNames[0]}`;
		}

		stringMe += ` ${SearchParameterType[this.operator]} ${this.value}`;

		return stringMe;
	}
}

// export class LoadRuntimeInformation extends ApiResponse {

//   rules: UiRule[] = [];
//   relateds: Related[] = [];
//   commands: Command[] = [];

//   static parse(raw: any, entityId: string): LoadRuntimeInformation|null {

//     const d1 = raw as LoadRuntimeInformation;

//     if (d1) {

//       const o = Object.assign(new LoadRuntimeInformation(), raw);
//       o.commands = [];
//       o.relateds = [];
//       o.rules = [];

//       d1.commands.forEach((c) => {

//         o.commands.push(Object.assign(new Command(), c));

//       });

//       d1.relateds.forEach((c) => {

//         const r = Object.assign(new Related(), c);
//         r.filters = [];
//         c.filters.forEach((f) => {

//           r.filters.push(Object.assign(new Filter(), f));

//         });

//         o.relateds.push(r);

//       });

//       d1.rules?.forEach((c) => {

//         o.rules.push(Object.assign(new UiRule(), c));

//       });

//       return o;

//     }
//     return null;

//   }

// }
