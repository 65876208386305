<dx-sortable filter=".dx-tab" [data]="tasks" itemOrientation="horizontal" dragDirection="horizontal"
	(onDragStart)="onTabDragStart($event)" (onReorder)="onTabDrop($event)">
	<dx-tabs #tabPanel id="tabPanel" [items]="tasks" (onItemClick)="selectTab($event)" height="100%"
		[selectedIndex]="selectedIndex" [selectedItem]="selectedItem" [showNavButtons]="true" itemTemplate="tabItem">
		<div class="tab" (contextmenu)="onContext($event, task)" *dxTemplate="let task of 'tabItem'">
			<i [class]="task.icon+'-inverted'"></i>
			<span *ngIf="task.isChanged" class="changed">*</span>
			<span [title]="task.text"> {{ task.shorttext }}</span>
			<i class="dx-icon dx-icon-close" (click)="onCloseTab(task, $event)"></i>
		</div>
	</dx-tabs>
</dx-sortable>

<div class="content">
	<ng-container appHost></ng-container>
</div>

<app-kcontext-menu></app-kcontext-menu>
<app-kdialog></app-kdialog>
<app-kcommand-dialog></app-kcommand-dialog>
<app-kquick-selector-pop-up></app-kquick-selector-pop-up>
