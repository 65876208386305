import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { forkJoin, Observable, of, Subject, Subscription } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { LogicalAttributeType } from 'src/app/server/model/logical-attribute-type';
import { TemporaryImageUrlResponse } from 'src/app/server/model/temporari-image-url-response';
import { openContextMenu } from 'src/app/ui/shared/kcontext-menu/functions';
import { KContextPayloadCallback } from 'src/app/ui/shared/kcontext-menu/kcontext-payload';
import {
	ContextMenuCommands,
	KEntityBaseComponentMenuActions
} from '../kbase/kentity-base.component';
import { KEntityOrStaticBaseComponent } from '../kbase/kentity-or-static-base.component';
import { KFormFieldRefreshAfterDialogClosePayload } from '../kbase/kfield-refresh-payload';
import { handleDuplicateError } from '../kgrid/grid-control-functions';
import { EnumHelper } from './../../../../server/helpers/enum-helper';
import { EntityHolder } from './../../../../server/model/entity-holder';

interface KPictureListEntry {
	fullUrl: string;
	niceName: string;
	instanceId: string;
}
type enityAndPayload = {
	entityId: string;
	payload: KFormFieldRefreshAfterDialogClosePayload;
};
type typeInstance = { type: EntityHolder; i: string };
type labelInstanceImage = { label: string | null; instanceId: any; i: string };
type imageInsta = { i: TemporaryImageUrlResponse; instanceId: string };

@Component({
	selector: 'app-kpicture-list',
	templateUrl: './kpicture-list.component.html',
	styleUrls: ['./kpicture-list.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KPictureListComponent
	extends KEntityOrStaticBaseComponent
	implements OnDestroy {
	images!: Observable<KPictureListEntry[]>;
	override isCollection: boolean = true;
	override isMultiselect: boolean = true;
	override isMember: boolean = true;
	holderToPicturePipe: Subject<EntityHolder[]> = new Subject<EntityHolder[]>();
	newHoldesPipe: Subject<enityAndPayload> = new Subject<enityAndPayload>();
	onRefreshSubscription: Subscription | null = null;
	onNewSubscription: Subscription | null = null;
	isContextMenuOpen: boolean = false;
	selectedImage: any = null;

	override ngOnInit(): void {
		this.onNewSubscription = this.newHoldesPipe
			.pipe(
				switchMap((a: enityAndPayload) => {
					return this.createEntitiesFromMainMembers(
						a.entityId,
						a.payload.dialogPayload.reference,
						a.payload.dialogPayload.entities,
						a.payload.dialogPayload.reference,
						a.payload.attributeName,
						a.payload.dialogPayload.parentTaskId,
					);
				}),
			)
			.subscribe((generateds: EntityHolder[]) => {
				this.onNewItems(generateds);
			});

		this.onRefreshSubscription = this.holderToPicturePipe
			.pipe(
				map((r) => {
					// console.log('in', r);
					return this.holderToImagePipe(r);
				}),
			)
			.subscribe((r) => {
				// console.log('out', r);
				this.images = r;
				this.cdr.markForCheck();
			});

		super.ngOnInit();
	}

	holderToImagePipe(holders: EntityHolder[]): Observable<KPictureListEntry[]> {
		return of(holders).pipe(
			map((hs) => this.step1(hs)),
			switchMap((hs) => this.step2(hs)),
			switchMap((hs) => this.step3(hs)),
			map((hs) => this.step4(hs)),
		);
	}

	step1(holders: EntityHolder[]): typeInstance[] {
		return holders.map((holder) => {
			return {
				type: holder.fields.find((f) =>
					EnumHelper.is(f.logicalType, LogicalAttributeType.Type),
				)?.value,
				i: holder.instanceId,
			};
		});
	}

	step2(typeInstances: typeInstance[]): Observable<labelInstanceImage[]> {
		return forkJoin(
			typeInstances.map((imageHolder) => {
				return this.container.service
					.load(imageHolder.type.entityId, imageHolder.type.instanceId)
					.pipe(
						map((e1: EntityHolder | null) => {
							return {
								label: e1?.humanReadableName ?? null,
								instanceId:
									e1?.fields.find((f) => f.name === 'Icon')?.value ?? null,
								i: imageHolder.i,
							};
						}),
					);
			}),
		);
	}

	step3(labelInstanceImages: labelInstanceImage[]): Observable<imageInsta[]> {
		const x = labelInstanceImages.map((imageId) => {
			return this.container.service
				.getImage(imageId.instanceId!, imageId.label)
				.pipe(
					map((image: boolean | TemporaryImageUrlResponse) => {
						return {
							i: image,
							instanceId: imageId.i,
						} as imageInsta;
					}),
				);
		});

		return forkJoin(x);
	}

	step4(imageInstances: imageInsta[]): KPictureListEntry[] {
		return imageInstances.map((a) => {
			return {
				fullUrl: a.i.fullUrl,
				niceName: a.i.niceName,
				instanceId: a.instanceId,
			} as KPictureListEntry;
		});
	}

	override onValueReady(): void {
		let safeValue: EntityHolder[] = [];

		if (Array.isArray(this.value?.value)) {
			safeValue = this.value?.value ?? [];
		}

		this.holderToPicturePipe.next(safeValue);
	}

	onContext(event: any, entry: KPictureListEntry | null) {
		event.preventDefault();
		event.stopPropagation();

		const menus = [];
		menus.push(
			ContextMenuCommands.find(
				(c) => c.id === KEntityBaseComponentMenuActions.Search,
			)!,
		);

		if (entry) {
			menus.push(
				ContextMenuCommands.find(
					(c) => c.id === KEntityBaseComponentMenuActions.Delete,
				)!,
			);
		}
		this.selectedImage = entry;
		this.isContextMenuOpen = true;
		openContextMenu(this, event.clientX, event.clientY, entry, false, menus);
		document.addEventListener('contextmenu', () => {
			this.isContextMenuOpen = false;
		}, { once: true });
	}

	onNewItems(generatedEntities: EntityHolder[]) {
		this.onFieldValueCollectionIsChanging(generatedEntities);
	}

	override onAfterDialogClose(
		payload: KFormFieldRefreshAfterDialogClosePayload,
	) {
		const errors = handleDuplicateError(
			this.isMultiselect,
			this.allowMultiMainType,
			this.value?.value,
			payload.dialogPayload,
		);

		if (errors.length) {
			errors.forEach((e) => this.container.global.showError(e));
			return;
		}

		this.newHoldesPipe.next({
			entityId: this.getTargetEntityId(),
			payload: payload,
		});
	}

	override onSearch(payload: KContextPayloadCallback): void {
		return this.onSearchForSpecificField();
	}

	override onDelete(
		payload: KContextPayloadCallback,
		recursive: boolean,
	): void {
		const toDelete = (this.value?.value as EntityHolder[]).find(
			(c) => c?.instanceId === payload.reference.instanceId,
		);

		if (toDelete) {
			this.delete(toDelete, recursive);
		}
	}

	override ngOnDestroy(): void {
		super.ngOnDestroy();
		this.holderToPicturePipe?.unsubscribe();
		this.onRefreshSubscription?.unsubscribe();
		this.onNewSubscription?.unsubscribe();
	}
}
