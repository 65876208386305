import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DiagnoseService } from './diagnose.service';
import { EntityService } from './entity.service';
import { ApiResponse } from './model/api.response';

@Injectable({
	providedIn: 'root',
})
export class DevService {
	diagnose: DiagnoseService;
	route: ActivatedRoute;
	router: Router;
	service: EntityService;

	constructor(
		diagnose: DiagnoseService,
		route: ActivatedRoute,
		router: Router,
		service: EntityService,
	) {
		this.diagnose = diagnose;
		this.route = route;
		this.router = router;
		this.service = service;
	}

	execute() {
		const reindex = this.diagnose.isDebugOption('reindex');
		const reindexClean = this.diagnose.isDebugOption('reindex-clean');
		const resynch = this.diagnose.isDebugOption('resynch');
		const resynchAll = this.diagnose.isDebugOption('resynch-all');

		if (reindex || resynch || resynchAll || reindexClean) {
			this.route.paramMap.subscribe((r) => {
				const parts = window.location.pathname.split('/').reverse();

				if (parts.length >= 3) {
					const entityId = parts[1];
					if (!entityId.startsWith('ntt_')) {
						console.log(`Id entità non valido: ${entityId}`);
						return;
					}

					if (reindex || reindexClean) {
						console.warn(
							`Iniziato: ${
								reindex ? 'reindex' : 'resynch-clean'
							} di ${entityId}`,
						);
						this.service.reIndex(entityId, reindexClean).subscribe((r) => {
							this.postExecutiRedirect(r);
						});
					}

					if (resynch || resynchAll) {
						console.warn(
							`Iniziato: ${resynch ? 'resynch' : 'resynch-all'} di ${entityId}`,
						);
						this.service.reSynch(entityId, resynchAll).subscribe((r) => {
							this.postExecutiRedirect(r);
						});
					}
				}
			});
		}
	}

	private postExecutiRedirect(r: ApiResponse) {
		const clearedUrl = location.href.substring(
			0,
			location.href.length - location.search.length,
		);

		const msg = `Completato: ${r.type} ${r.success ? 'OK' : 'KO'}`;

		if (r.success) {
			console.warn(msg);
		} else {
			console.error(msg);
		}

		this.router.navigateByUrl(clearedUrl);
	}
}
