<app-kcomponent-container [label]="label" [isLoading]="isLoading" [mandatory]="mandatory" [description]="description">
	<dx-text-box
		[showClearButton]="true"
		[hint]="hint"
		(contextmenu)="onContext($event)"
		[value]="innerValue"
		[readOnly]="true"
		[title]="description"
	>
		<dx-validator (onOptionChanged)="onValidationChange($event)">
			<dxi-validation-rule *ngIf="this.mandatory" type="required" message="Campo obbligatorio"></dxi-validation-rule>
		</dx-validator>
	</dx-text-box>
</app-kcomponent-container>
