<!-- <ng-content></ng-content> -->

<div class="loader" *ngIf="loading">
	<dx-load-indicator></dx-load-indicator>
</div>

<div class="menu-container" *ngIf="!loading">
	<dx-tree-view #treeview id="treeview" [searchEnabled]="!compactMode" searchMode="contains" [items]="items"
		keyExpr="path" selectionMode="single" [focusStateEnabled]="false" expandEvent="click"
		(onItemClick)="onItemClick($event)" width="100%" (onItemContextMenu)="onCtxMenu($event)"
		itemTemplate="itemTemplate">
		<div *dxTemplate="let itemObj of 'itemTemplate'">
			<div [class]="itemObj.icon"></div>
			<div class="mnu-lbl">{{ itemObj.text }}</div>
		</div>
	</dx-tree-view>

	<dx-context-menu #ctxMenu class="favMenu" [dataSource]="menuItems" target="#treeview .dx-treeview-item"
		(onItemClick)="contextMenuItemClick($event)">
	</dx-context-menu>
</div>
