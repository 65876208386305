<div class="container">
	<dx-tree-view
		[disabled]="readonly"
		#treeView
		id="treeView"
		[items]="treeData"
		showCheckBoxesMode="normal"
		selectionMode="multiple"
		dataStructure="tree"
		[expandNodesRecursive]="false"
		(onItemClick)="selectItem($event)"
		(onSelectionChanged)="selectionChange($event)"
		(onItemContextMenu)="onInnerContext($event)"
	></dx-tree-view>
</div>
