<div class="wdg">
	<div class="wdg-header">
		<div
			class="wdg-button wdg-collapsable-btn"
			*ngIf="collapsable && enableCollapse"
		>
			<a
				[class]="collapsed ? 'dx-icon-chevronnext' : 'dx-icon-chevrondown'"
				(click)="onTitleClick()"
			></a>
		</div>
		<div
			class="wdg-title"
			[ngClass]="{ 'wdg-collapsable': collapsable }"
			(click)="onTitleClick()"
		>
			{{ title }}
		</div>
		<div class="buttons">
			<div class="wdg-button" *ngIf="enableAdd">
				<a class="dx-icon-plus" (click)="onAdd($event)"></a>
			</div>
			<div class="wdg-button" *ngIf="enableEdit">
				<a class="dx-icon-preferences" (click)="onEdit($event)"></a>
			</div>
			<div class="wdg-button" *ngIf="enableDelete">
				<a class="dx-icon-trash" (click)="onDelete($event)"></a>
			</div>
		</div>
	</div>

	<div class="wdg-body" *ngIf="!collapsed">
		<ng-content></ng-content>
	</div>
	<div *ngIf="footer && !collapsed" class="wdg-footer">{{ footer }}</div>
</div>
