import { LogicalHelper } from 'src/app/server/helpers/logical-helper';
import { EntityHolder } from 'src/app/server/model/entity-holder';
import { LogicalAttributeType } from 'src/app/server/model/logical-attribute-type';
import { TreeNode } from 'src/app/ui/controls/tree/tree-note';
import { GenericHelper } from './../../../../server/helpers/generic-helper';
import { AggregateMember } from './../../../../server/model/agregate-member';
import { AggregateTreeNodeType } from './aggregate-tree-option';

export function holderToTreeData(
	rootInfo: AggregateMember,
	rootHolder: EntityHolder,
	holders?: EntityHolder[],
): TreeNode {
	if (!rootInfo) {
		console.error('missing root member info' + rootHolder.entityId);
	}

	// console.log(holders);

	const root = new TreeNode(rootInfo.entityId, '', null);
	root.icon = GenericHelper.toIcon(rootInfo.entityId + '-inverted');
	root.text = rootHolder.humanReadableName;
	root.items = [];
	root.expanded = true;
	root.type = AggregateTreeNodeType.Root;
	root.aggregateInfo = rootInfo;

	let hasChild = false;

	if (holders && Array.isArray(holders) && holders.length > 0) {
		let firstHolder = holders[0];

		if (!(firstHolder instanceof EntityHolder)) {
			firstHolder = EntityHolder.parse(firstHolder)!;
		}

		const parentField = firstHolder.getFirstByType(LogicalAttributeType.Parent);
		if (!parentField) {
			if (LogicalHelper.getChildMembers(firstHolder.fields).length > 0) {
				hasChild = true;
				// console.log('no parent field for: ', firstHolder.entityId);
			}
		} else {
			const foundInfo = rootInfo.members.find(
				(c) =>
					c.entityId === firstHolder.entityId ||
					c.entityId === firstHolder.parentEntityId,
			);

			// console.log(foundInfo);
			if (!foundInfo && firstHolder.entityId !== rootInfo.entityId) {
				console.error('not found info for ', firstHolder.entityId, rootInfo);
			}
		}

		// console.log(parentField);

		if (parentField || hasChild) {
			holderToTreeDataRecursive(
				root,
				holders!,
				rootInfo,
				null,
				parentField?.name,
			);
		}
	}

	// console.log(root);
	return root;
}

function holderToTreeDataRecursive(
	root: TreeNode,

	allHolders: EntityHolder[],
	info: AggregateMember,
	parentInstanceId: string | null = null,
	parentFieldName: string | null = null,
	// ownerInstanceId: string|null = null,
	// ownerFieldName: string|null = null,
	index: number = -1,
) {
	index++;

	if (!root.items) root.items = [];

	const filterdHolders = allHolders.filter(
		(c) => getPointerInstanceId(c, parentFieldName) === parentInstanceId,
	);

	filterdHolders
		.sort((c) => c.index ?? 0)
		.forEach((holder) => {
			let childInfo: AggregateMember | null = null;

			if (info.entityId === holder.entityId) {
				childInfo = info;
			} else {
				childInfo =
					findChild(info, holder.parentEntityId ?? holder.entityId) ?? null;
			}

			if (!childInfo) {
				console.error('missing child member info for: ', holder.entityId, info);
				return;
			}

			// console.log(childInfo.entityId, holder.parentEntityId, holder.instanceId);

			const child = new TreeNode(
				holder.instanceId,
				holder.humanReadableName,
				root,
			);
			child.icon = GenericHelper.toIconOrMainIcon(holder) + '-inverted';
			child.holder = holder;
			child.aggregateInfo = childInfo;
			child.type = AggregateTreeNodeType.Node;

			const childMembers = LogicalHelper.getChildMembers(holder.fields);

			if (parentFieldName) {
				if (childInfo.isSubRoot) {
					child.icon = GenericHelper.toIcon(holder.entityId) + '-inverted';
				} else {
					switch (holder.entityId) {
						case 'ntt_grc_sustainability-report-factor':
							/* fix icona report-factor*/
							child.icon = 'icon icon-ntt_grc_sustainability-report-paragraph-inverted';
							break;
						default:
							child.icon = GenericHelper.toIconOrMainIcon(holder) + '-inverted';
							break;
					}
				}
				holderToTreeDataRecursive(
					child,
					allHolders,
					info,
					holder.instanceId,
					parentFieldName,
					index,
				);
			} else {
				child.icon = GenericHelper.toIconOrMainIcon(holder) + '-inverted';
				childMembers.forEach((childMember) => {
					holderToTreeDataRecursive(
						child,
						childMember.value,
						childInfo!,
						childMember.value.instanceId,
						parentFieldName,
						index,
					);
				});
			}
			root.items?.push(child);
		});
}

export function holderToAggregateTreeData(
	rootInfo: AggregateMember,
	targetEntityId: string,
	rootHolder: EntityHolder,
	holders?: EntityHolder[],
): TreeNode {
	if (!rootInfo) {
		console.error('missing root member info' + rootHolder.entityId);
	}

	// console.log(holders);

	const root = new TreeNode(rootInfo.entityId, '', null);
	root.icon = GenericHelper.toIcon(rootInfo.entityId + '-inverted');
	root.text = rootHolder.humanReadableName;
	root.items = [];
	root.expanded = true;
	root.type = AggregateTreeNodeType.Root;
	root.aggregateInfo = rootInfo;
	root.holder = rootHolder;

	const currentChild = rootInfo.members.find(
		(c) => c.entityId === targetEntityId,
	)!;
	return holderToAggregateNodeRecursive(
		root,
		currentChild,
		holders ?? [],
		null,
		root.holder.instanceId,
	);
}

function holderToAggregateNodeRecursive(
	containerNode: TreeNode,
	childInfo: AggregateMember,
	allHolders: EntityHolder[],
	parentInstanceId: string | null,
	ownerIstanceId: string | null,
): TreeNode {
	if (allHolders.length === 0) return containerNode;

	if (ownerIstanceId || parentInstanceId) {
		// console.log('cerco i figli di', ownerIstanceId, parentInstanceId);
	}

	let parentFieldName: string | null = null;
	let ownerFieldName: string | null = null;
	const parentField = allHolders[0].getFirstByType(LogicalAttributeType.Parent);

	if (parentField) {
		parentFieldName = parentField.name;
		// console.log('parentName', parentField.name, parentInstanceId);
	}

	const ownerField = allHolders[0].getFirstByType(LogicalAttributeType.Owner);
	if (ownerField) {
		ownerFieldName = ownerField.name;
		// console.log('ownerName', ownerField.name, ownerIstanceId);
	}

	// console.log('all', allHolders);
	let filterdHolders = Array.from(allHolders);

	if (parentField && filterdHolders.length > 0) {
		// console.log('parentField', parentFieldName, parentInstanceId, filterdHolders[0]);
		filterdHolders = filterdHolders.filter(
			(c) => getPointerInstanceId(c, parentFieldName) === parentInstanceId,
		);
	}

	if (ownerField && filterdHolders.length > 0) {
		// console.log('ownerField', ownerFieldName, ownerIstanceId, filterdHolders[0]);
		filterdHolders = filterdHolders.filter(
			(c) => getPointerInstanceId(c, ownerFieldName) === ownerIstanceId,
		);
	}

	// console.log('filtered', filterdHolders);

	for (const h of filterdHolders) {
		// console.log('aggiungo', h.id, h.humanReadableName, containerNode.text);
		appendChildTo(containerNode, childInfo, h, allHolders);
	}

	return containerNode;
}

function appendChildTo(
	containerNode: TreeNode,
	info: AggregateMember,
	holder: EntityHolder,
	allHolders: EntityHolder[],
) {
	const node = new TreeNode(
		holder.instanceId,
		holder.humanReadableName,
		containerNode,
	);
	node.icon = GenericHelper.toIconOrMainIcon(holder) + '-inverted';
	node.holder = holder;
	node.aggregateInfo = info;
	node.type = AggregateTreeNodeType.Node;
	const childMembers = LogicalHelper.getChildMembers(holder.fields);

	for (const childMember of childMembers) {
		const childInfo = info.members.find(
			(c) => c.entityId === childMember.entityId,
		)!;
		// console.log(childMember, childInfo);

		const values = childMember.value as EntityHolder[];

		// TODO remove
		// allHolders.filter(c => c.entityId === childInfo.entityId).forEach(m => {
		//   if (values.indexOf(m) < 0) { values.push(m); }
		// });

		// console.log('aggiungo', values.length, node.text);

		holderToAggregateNodeRecursive(
			node,
			childInfo,
			values,
			null,
			holder.instanceId,
		);

		// if (info.recursive) {
		// holderToAggregateNodeRecursive(child, childInfo, childMember.value, holder.instanceId, holder.instanceId);
		// } else {
		//   holderToAggregateNodeRecursive(child, childInfo, childMember.value, null, holder.instanceId);
		// }

		//

		// in teoria ogni child member deve avere il suo valore nella collezione, ma quando è nuovo questo non ha
		// valore va quindi preso dalla collezione
	}

	if (info.recursive) {
		appendChildToRecursive(node, allHolders);
		// holderToAggregateNodeRecursive(node, info, allHolders, holder.instanceId, null);
	}

	// if (parentFieldName) {
	//   if (childInfo.isSubRoot) { child.icon = GenericHelper.toIcon(holder.entityId); } else { child.icon = GenericHelper.toIconOrMainIcon(holder); }
	//   holderToTreeDataRecursive(child, allHolders, info, holder.instanceId, parentFieldName, index);
	// } else {
	//   child.icon = GenericHelper.toIconOrMainIcon(holder);
	//   childMembers.forEach((childMember) => {
	//     holderToTreeDataRecursive(child, childMember.value, childInfo!, childMember.value.instanceId, parentFieldName, index);
	//   });
	// }
	// console.log(`aggiungo ${node.text} A ${containerNode.text} `);

	if (!containerNode.items) {
		containerNode.items = [];
	}
	containerNode.items.push(node);
}

function appendChildToRecursive(
	containerNode: TreeNode,
	allHolders: EntityHolder[],
) {
	// console.log('----');
	// console.log(containerNode);

	const parentField = containerNode.holder?.getFirstByType(
		LogicalAttributeType.Parent,
	);

	// console.log('all', allHolders);
	const filterdHolders = allHolders.filter(
		(c) =>
			getPointerInstanceId(c, parentField!.name) ===
			containerNode.holder?.instanceId,
	);

	// console.log(filterdHolders);

	for (const h of filterdHolders) {
		// console.log('aggiungo', h.id, h.humanReadableName, containerNode.text);
		appendChildTo(containerNode, containerNode.aggregateInfo!, h, allHolders);
	}
}

function getPointerInstanceId(
	value: EntityHolder,
	fieldName: string | null,
): string | null {
	if (fieldName == null) return null;

	const v = value.getField(fieldName)?.value ?? null;
	// console.log(value);
	if (v instanceof EntityHolder) {
		return v.instanceId;
	}

	return v;
}

function findChild(
	m: AggregateMember,
	entityId: string,
): AggregateMember | null {
	if (m.entityId === entityId) return m;

	if (m.members.length > 0) {
		const x = m.members.map((c) => findChild(c, entityId)).filter((c) => c);
		if (x.length > 0) {
			return x[0];
		}
	}

	return null;
}
