import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiServiceBase } from '../../../server/api-service-base';
import { GetProcessInstancesResponse } from '../model/get-process-instances-response';
import { GetProcessesResponse } from '../model/get-processes-response';
import { Process } from '../model/process';
import { GetProcessDataResponse, GetProcessRelatedsResponse } from './../model/get-process-data-response';
import { JsonActivityDetail, ProcessInstance } from './../model/process-instance';

@Injectable({
	providedIn: 'root',
})
export class WorkflowService extends ApiServiceBase {
	getProcessInstance(processTypeId: string, processId: string): Observable<GetProcessDataResponse> {
		const r = {
			processTypeId,
			processId,
		};
		return this.post<GetProcessDataResponse>('/api/workflow/get-process-instance', r).pipe(
			map((c) => {
				return c;
			}),
		);
	}

	getProcesses(): Observable<Process[]> {
		const r = {};
		return this.post<GetProcessesResponse>('/api/workflow/get-processes', r).pipe(map((c) => c.processes));
	}

	getProcessInstances(): Observable<ProcessInstance[]> {
		const r = {};
		return this.post<GetProcessInstancesResponse>('/api/workflow/get-process-instances', r).pipe(
			map((c) => {
				return c.instances;
			}),
		);
	}

	getActivityInfo(processId: string, instanceId: string, activityId: string): Observable<JsonActivityDetail> {
		const r = {
			processId,
			instanceId,
			activityId,
		};
		return this.post<GetProcessInstancesResponse>('/api/workflow/get-activity-info', r).pipe(
			map((c) => {
				return c.activity;
			}),
		);
	}

	getProcessFromXoml(xoml: string): Observable<GetProcessDataResponse> {
		const request = { xoml };
		return this.post<GetProcessDataResponse>('/api/workflow/get-process-xoml', request).pipe(
			map((c) => {
				return c;
			}),
		);
	}

	getProcessRelateds(workflowId: string, all: boolean): Observable<GetProcessRelatedsResponse> {
		const request = { workflowId, all };
		return this.post<GetProcessRelatedsResponse>('/api/workflow/get-process-relateds', request).pipe(
			map((c) => {
				return c;
			}),
		);
	}

	getMultiProcessRelateds(workflowIds: string[], all: boolean): Observable<GetProcessRelatedsResponse> {
		const request = { workflowIds, all };
		return this.post<GetProcessRelatedsResponse>('/api/workflow/get-multiprocess-relateds', request).pipe(
			map((c) => {
				return c;
			}),
		);
	}
}
