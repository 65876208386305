export class EntityStatus {
	id = '';
	actionId = '';
	description = '';
	label = '';
	menuLabel = '';
	name = '';
	verb = '';

	enumId = 0;
	follows: string[] = [];
	length = 0;

	isAborted = false;
	isClosed = false;
	isEnabled = false;
	isPrivilege = false;
	isSign = false;

	requireWarning = false;
	returnDescription: string | null = null;
	returnLabel: string | null = null;
}
