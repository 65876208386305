import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import { of } from 'rxjs';
import { EntityHolder } from 'src/app/server/model/entity-holder';
import { FieldValue } from 'src/app/server/model/field-value';
import { KListViewComponent } from '../../kform/klist-view.component';

import { removeHolderFromCollectionById } from '../../kform/updateStateAndField';

@Component({
	selector: 'app-kmaster-detail',
	templateUrl: './kmaster-detail.component.html',
	styleUrls: ['./kmaster-detail.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KMasterDetailComponent
	extends KListViewComponent
	implements OnInit
{
	dataSource!: DataSource;
	customStore!: CustomStore;
	override selected: EntityHolder | null = null;
	initialSelection: string[] = [];
	menuItems: any[] = [];

	override ngOnInit() {
		this.menuItems.push(
			{
				text: 'Aggiungi',
				icon: 'add',
				action: () => {
					super.getNewTargetEntity().subscribe((newEntity) => {
						this.customStore.insert([newEntity]);
						this.dataSource.reload();
					});
				},
			},
			{
				text: 'Elimina',
				icon: 'remove',
				action: (arg: { itemData: EntityHolder }) => {
					this.customStore.remove(arg.itemData.instanceId);
					this.dataSource.reload();
				},
			},
		);

		super.ngOnInit();

		// console.log(this.node.advancedData2);
		// console.log(this.container.holder);
	}

	override setValue(value: FieldValue | null): void {
		let selectedId = this.selected?.instanceId ?? null;
		this.initialSelection = [];
		this.selected = null;
		this.cdr.markForCheck();

		this.options = value?.value as EntityHolder[];
		console.log(this.options);

		selectedId = this.fillUi(selectedId);

		if (this.options.length >= 0) {
			if (!selectedId) {
				selectedId = this.options[0].instanceId;
			}
			this.selected =
				this.options.find((c) => c.instanceId === selectedId) ?? null;
			this.initialSelection.push(selectedId);
		}

		this.cdr.markForCheck();
	}

	private fillUi(selectedId: string | null) {
		const me = this;
		// console.log(this.option);
		// https://js.devexpress.com/Documentation/ApiReference/Data_Layer/CustomStore/
		this.customStore = new CustomStore({
			key: 'instanceId',
			// loadMode: 'raw',
			load: (loadOptions) => {
				// console.log('load', loadOptions, this.options);
				return this.options;
			},
			insert: function (values) {
				return of(me.addMemberInline(values)).toPromise();
			},

			update: (key, values) => {
				console.log(key, values);
				// TO DO for inline
				return Promise.resolve(null);
			},

			onInserting: function (values) {
				console.log('onInserting');
				// Your code goes here
			},
			onInserted: function (values, key) {
				console.log('onInserted', values, key);
				// Your code goes here

				const i = values[0] as EntityHolder;

				if (i) {
					me.initialSelection = [i.instanceId];
					me.selected = i;
				}
			},

			onUpdating: function (values) {
				console.log('onUpdating');
				// Your code goes here
			},
			onUpdated: function (values, key) {
				console.log('onUpdated');
				// Your code goes here
			},
			onModified: function () {
				console.log('onModified');
				// Your code goes here
			},
			onModifying: function () {
				console.log('onModifying');
				// Your code goes here
			},
			onPush: function (changes) {
				console.log('onPush', changes);
				// Your code goes here
			},

			remove: (key) => {
				return of(this.deleteMember(key)).toPromise();
			},
			errorHandler: function (error: any) {
				console.log(error.message);
			},
		});

		this.dataSource = new DataSource({
			store: this.customStore,
		});

		return selectedId;
	}

	onSelectionChanged(e: { addedItems: EntityHolder[] }) {
		this.selected = e.addedItems[0];
		// console.log(this.container.holder);
	}

	addMemberInline(values: EntityHolder[]): EntityHolder[] {
		this.onFieldValueCollectionIsChanging(values);
		return values;
	}

	override updateItem(holder: EntityHolder) {
		// this.onFieldValueCollectionIsChanging([holder]);

		const newValues = this.container.holder.replaceInCollection(
			this.node.attributeName,
			[holder],
		);

		if (newValues) {
			this.options = newValues;
			this.dataSource.reload();
		}

		return this.fieldValueIsChanging(newValues, false);
	}

	deleteMember(instanceId: any): any {
		console.log(instanceId);

		let newValue: EntityHolder[] = [];

		// ???
		if (this.isCollection) {
			newValue = removeHolderFromCollectionById(
				this.container.holder,
				this.node.attributeName,
				instanceId,
			);
		}

		const e = this.options.findIndex((c) => c.instanceId === instanceId);
		this.options.splice(e, 1);

		// sincronizzo lo stato
		this.fieldValueIsChanging(newValue);

		this.selectSpecificOption();
	}

	selectSpecificOption(index: number = 0) {
		if (this.options.length <= index) {
			this.selected = null;
			this.initialSelection = [];
			this.cdr.markForCheck();
			return;
		}

		this.selected = this.options[index];
		this.initialSelection = [this.options[index].instanceId];
	}
}
