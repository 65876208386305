import { ChangeDetectorRef, Component } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { first, switchMap } from 'rxjs/operators';
import { CreateNewEntitiesServices } from 'src/app/server/create-new-entities.services';
import { LogicalHelper } from 'src/app/server/helpers/logical-helper';
import { AttributeMetadata } from 'src/app/server/model/attribute-metadata';
import { EntityHolder } from 'src/app/server/model/entity-holder';
import { ViewNodeFilter } from 'src/app/server/model/filter';
import { SearchParameterType } from 'src/app/server/model/search-parameter-type';
import { FileResponse } from 'src/app/server/model/temporari-image-url-response';
import { KContextPayloadCallback } from 'src/app/ui/shared/kcontext-menu/kcontext-payload';
import { KItem } from 'src/app/ui/shared/kitem';
import { EntityInfo } from '../../../../server/model/entity-info';
import { TreeNodeReference } from '../kaggregate-tree/model';
import { fileDownload } from '../kfile-manager/file-functions';
import { KnowEntityIds } from './../../../../server/helpers/constants';
import { FieldValue } from './../../../../server/model/field-value';
import { SearchParameter } from './../../../../server/model/search-parameter';
import { TaskAction, TaskRelation } from './../../../manager/ktaskbar/task';
import { IKEntityComponent } from './abstract';
import { createNewArguments, evalutateVirtualType, injectExtraAggreagateFilters, resolveFilters } from './functions';
import { KStaticBaseComponent } from './kadv-attribute-base.component';
import { KFormFieldRefreshAfterDialogClosePayload } from './kfield-refresh-payload';

export const KEntityBaseComponentMenuActions = {
	Search: 'srp-search',
	Add: 'srp-add',
	Delete: 'srp-delete',
	View: 'srp-view',
	Edit: 'srp-edit',
	AddBelow: 'srp-add-below',
	DeleteRecursive: 'srp-delete-recursive',
	Download: 'srp-download',
};

// https://js.devexpress.com/Documentation/Guide/Themes_and_Styles/Icons/
export const ContextMenuCommands: KItem[] = [
	{ id: KEntityBaseComponentMenuActions.View, text: 'Vedi', icon: 'search' },
	{ id: KEntityBaseComponentMenuActions.Edit, text: 'Modifica', icon: 'edit' },
	{ id: KEntityBaseComponentMenuActions.Add, text: 'Aggiungi', icon: 'add' },
	{
		id: KEntityBaseComponentMenuActions.AddBelow,
		text: 'Appendi',
		icon: 'add',
	},
	{
		id: KEntityBaseComponentMenuActions.Search,
		text: 'Seleziona/Crea',
		icon: 'find',
	},
	{
		id: KEntityBaseComponentMenuActions.Delete,
		text: 'Elimina',
		icon: 'trash',
	},
	{
		id: KEntityBaseComponentMenuActions.DeleteRecursive,
		text: 'Elimina con tutti quelli sotto',
		icon: 'clearformat',
	},
	{
		id: KEntityBaseComponentMenuActions.Download,
		text: 'Scarica il file',
		icon: 'download',
	},
];

export class SelectedInfo {
	selectedId: string | null = null;
	openNodesIds: string[] = [];
}

@Component({
	selector: 'app-adv-attribute-base',
	template: '',
})
export class KEntityBaseComponent extends KStaticBaseComponent implements IKEntityComponent {
	info: EntityInfo | null = null;

	isMultiselect = false;
	allowMultiMainType = false;
	resultViewId!: string;
	isInAggregate: AttributeMetadata | null | undefined;
	isMember = false;
	isCollection = false;

	newEntityService: CreateNewEntitiesServices;

	constructor(cdr: ChangeDetectorRef, newEntityService: CreateNewEntitiesServices) {
		super(cdr);
		this.newEntityService = newEntityService;
	}

	getSelectedUiElement(): SelectedInfo {
		if (this.container.task.currentCmp === this.node.name && this.container.task.currentCmpElement) {
			return JSON.parse(this.container.task.currentCmpElement);
		}
		return new SelectedInfo();
	}

	setSelectedUiElement(selectedInfo: SelectedInfo): void {
		this.container.broadcastSelectedUiElementChange(
			this.container.selectedIndex,
			this.node.name,
			JSON.stringify(selectedInfo),
		);
	}

	override onContextMenuCallback(command: string, payload: KContextPayloadCallback) {
		switch (command) {
			case KEntityBaseComponentMenuActions.Search:
				this.onSearch(payload);
				break;
			case KEntityBaseComponentMenuActions.Add:
				this.onAdd(payload);
				break;
			case KEntityBaseComponentMenuActions.Delete:
				this.onDelete(payload, false);
				break;

			case KEntityBaseComponentMenuActions.DeleteRecursive:
				this.onDelete(payload, true);
				break;

			case KEntityBaseComponentMenuActions.View:
				this.onView(payload);
				break;
			case KEntityBaseComponentMenuActions.Edit:
				this.onEdit(payload);
				break;

			case KEntityBaseComponentMenuActions.Download:
				this.onDownload(payload);
				break;
		}
	}

	onDownload(payload: KContextPayloadCallback): void {
		const eh = this.container.data?.data as EntityHolder;
		if (eh) {
			// // console.log(eh);
			const related = eh.getField(payload.reference.attributeName)?.value as EntityHolder;
			if (related) {
				this.container.service
					.getDocument(related.entityId, related.instanceId)
					.pipe(first())
					.subscribe((file: FileResponse | null) => {
						if (file) {
							fileDownload(file.fileName, file.data);
						}
					});
			}
		}
	}

	onView(payload: KContextPayloadCallback): void {
		throwError('not implemented, va implementato nel finale');
	}

	// Funzionamento del "SearchPanel"
	onEdit(payload: KContextPayloadCallback): void {
		throwError('not implemented, va implementato nel finale');
	}

	onDelete(payload: KContextPayloadCallback, recursive: boolean): void {
		// pointer delete
		this.delete(null, recursive);
	}

	delete(holder: EntityHolder | null, recursive: boolean = false): void {
		// console.log('delete', holder);

		if (this.isCollection) {
			this.collectionFieldValueIsReducing(holder!, recursive);
		} else {
			this.fieldValueIsChanging(null, true);
		}
	}

	onAdd(payload: KContextPayloadCallback): void {}

	getTargetEntityInfo(entityId: string | null = null): Observable<EntityInfo> {
		return this.newEntityService.getTargetEntityInfo(this.node, this.container.holder, entityId);
	}

	getTargetEntityId(): string {
		return this.newEntityService.getTargetEntityId(this.node, this.container.holder);
	}

	createEntitiesFromMainMembers(
		entityId: string,
		mainMemberName: string | null,
		entities: EntityHolder[],
		node: TreeNodeReference | null,
		attributeName: string | null,
		taskId: string | null,
	): Observable<EntityHolder[]> {
		return this.newEntityService.createEntitiesFromMainMembers({
			entityId,
			mainMemberName,
			entities,
			node,
			attributeName,
			taskId,
			viewNode: this.node,
			containerHolder: this.container.holder,
			containerTaskId: this.container.task.id,
		});
	}

	// apre il cerca per il target object
	onSearch(payload: KContextPayloadCallback): void {
		this.getTargetEntityInfo().subscribe((targetEntityInfo: EntityInfo) => {
			const filters = resolveFilters(
				targetEntityInfo,
				this.node.advancedData2.filters,
				this.container.holder,
				this.container.getParentHolder(),
				this.container.getMainHolder(),
			);

			injectExtraAggreagateFilters(
				this.service,
				this.container.data!,
				this.container.getParentData(),
				targetEntityInfo,
				filters,
			).subscribe((filters2) => {
				this.openSearchDialog(filters2, targetEntityInfo.id, payload.reference, this.isMultiselect, false, false);
			});
		});
	}

	/// apre il cerca per l'entità target
	// se si specifica il nodo per il nodo figlio della vista dell'entità target
	// se si specifica override, che di solito è un tipo concreto dell'entità di base vince
	// viene usato per come Add e Search in griglie tree e search pop up ( oltre che picture list )
	onSearchForSpecificField(
		reference: TreeNodeReference | string | null = null,
		nodeName: string | null = null,
		overridedEntityId: string | null = null,
		caller: EntityHolder | null = null,
	): void {
		this.getTargetEntityInfo(overridedEntityId).subscribe((targetEntityInfo: EntityInfo) => {
			const resultView = targetEntityInfo.getViewOrDefault(this.resultViewId);

			// se non specificata è esattamente quella trovata getTargetEntityInfo si occupa di carpirlo

			let filters: SearchParameter[] = [];
			let toSearchEntityId: string | null = null;
			console.log(`requested node: ${nodeName}`);

			// se non specifica il node name, si usano gli attributi dell'entità
			if (!nodeName) {
				const mainMember = LogicalHelper.getMainMember(
					Array.from<AttributeMetadata>(targetEntityInfo.metadata.attributes.values()),
				);

				if (mainMember == null) {
					throwError('missing main member for ' + targetEntityInfo.id);
				}

				nodeName = mainMember!.name;

				if (!reference) {
					reference = nodeName;
				}

				toSearchEntityId = mainMember!.advancedData2.entityId ?? mainMember!.targetEntity!.id;

				if (toSearchEntityId === KnowEntityIds.ModuleEntity && caller) {
					// che dio ci assista
					const attributes = Array.from(targetEntityInfo.metadata.attributes.values());
					const t1 = evalutateVirtualType(attributes, nodeName);
					const fieldOnRoot = caller.getField(t1);

					if (fieldOnRoot?.value) {
						toSearchEntityId = fieldOnRoot.value.instanceId;
						console.log(toSearchEntityId);
					}
				}

				// se l'entità da cerca è nel aggregato
				const isInAggregate = this.container.getInAggregate(
					toSearchEntityId!,
					this.node.advancedData2.entityId!,
					this.node.attributeName,
				);

				if (this.isInAggregate) {
					// console.log(mainMember);
					// console.log(toSearchEntityId, this.node.advancedData2.entityId!, this.node.attributeName);
				}

				// se il target del search ( quindi il main member) è nel aggregato va auto filtrato
				// es corporate context > entries > ambito > units
				if (isInAggregate) {
					const targetOwner = targetEntityInfo.metadata.getOwner(this.container.holder.entityId);
					// // console.log(`L'entità ${overridedEntityId}(${this.node.name}) è in aggregato con owner ${targetOwner?.name} applico filtro`);

					if (targetOwner) {
						const ownerFilter = new ViewNodeFilter();
						ownerFilter.type = targetOwner.type;
						ownerFilter.name = targetOwner.name;
						ownerFilter.op = SearchParameterType.Equal;
						ownerFilter.value = this.container.getMainHolder().instanceId;
						mainMember!.advancedData2.filters.push(ownerFilter);
						// // console.log('owner filter', ownerFilter);
						// // console.log(mainMember!.advancedData2.filters);
					}
				}

				filters = resolveFilters(
					targetEntityInfo,
					mainMember!.advancedData2.filters,
					this.container.holder,
					this.container.getParentHolder(),
					this.container.getMainHolder(),
				);
				// console.log(filters);
				// console.log(`search in ${toSearchEntityId} because of the main member: ${mainMember?.name} of type ${mainMember?.targetEntity?.id}`);
			} else if (resultView) {
				const resultViewNode = resultView?.nodes.find((c) => c.name === nodeName)!;

				if (resultViewNode) {
					if (resultViewNode) {
						filters = resolveFilters(
							targetEntityInfo,
							resultViewNode.advancedData2.filters,
							this.container.holder,
							this.container.getParentHolder(),
							this.container.getMainHolder(),
						);
					}

					toSearchEntityId = resultViewNode.advancedData2.entityId!;
				} else {
					toSearchEntityId = overridedEntityId;
				}

				// console.log(`search in ${toSearchEntityId} because of the node: ${nodeName} nodes:`, resultView?.nodes.map(c => c.name));
			}

			this.openSearchDialog(filters, toSearchEntityId!, reference, this.isMultiselect, false, false, caller);
		});
	}

	openTask(
		entityId: string,
		instanceId: string,
		relation: TaskRelation = TaskRelation.Parent,
		action: TaskAction = 'edit',
	) {
		this.container.openTask(
			entityId,
			instanceId,
			this.container.task.id,
			this.node.attributeName,
			this.node.advancedData2.resultViewId,
			relation,
			action,
		);
	}

	override onAfterDialogClose(payload: KFormFieldRefreshAfterDialogClosePayload) {
		if (this.isCollection) {
			this.onFieldValueCollectionIsChanging(payload.dialogPayload.entities);
		} else if (payload.dialogPayload.entities.length > 0) {
			// this.setFieldValue(payload.dialogPayload.entities[0]);
			this.fieldValueIsChanging(payload.dialogPayload.entities[0], true);
		}
	}

	getNew(tei: EntityInfo): Observable<EntityHolder | null> {
		const args = createNewArguments(
			tei,
			this.node.lt,
			this.metadata.isBase,
			this.container.holder,
			null,
			this.node.advancedData2,
		);
		return this.service.load(tei.id, null, args, this.container.holder);
	}

	getNewTargetEntity(): Observable<EntityHolder | null> {
		return this.getTargetEntityInfo().pipe(
			first(),
			switchMap((ei) => this.getNew(ei)),
		);
	}

	onFieldValueCollectionIsChanging(holders: EntityHolder[]) {
		if (this.isMember) {
			this.collectionFieldValueIsChanging(holders);
		} else {
			this.service.saveAll(holders).subscribe((c) => {
				const newFieldValue = new FieldValue();
				newFieldValue.name = this.node.attributeName;
				newFieldValue.entityId = this.node.advancedData2.entityId!;
				newFieldValue.logicalType = this.node.lt;
				newFieldValue.value = EntityHolder.replaceInCollection(holders, this.options); // dovrei fare il merge
				this.fieldValueIsChanged(newFieldValue, false, true);
			});
		}
	}

	override fieldValueIsChanging(newValue: any, refreshUiValue: boolean = false): void {
		// colle
		if (this.isMember || !this.isCollection) {
			super.fieldValueIsChanging(newValue, refreshUiValue);
		} else {
			const newFieldValue = new FieldValue();
			newFieldValue.name = this.node.attributeName;
			newFieldValue.entityId = this.node.advancedData2.entityId!;
			newFieldValue.logicalType = this.node.lt;
			newFieldValue.value = newValue;
			super.fieldValueIsChanged(newFieldValue, false, refreshUiValue);
		}
	}

	updateItem(holder: EntityHolder) {}
}
