import { Task } from './task';

export class KTitleChangePayload {
	public newTitle: string;
	public task: Task;
	public entityId: string;

	constructor(newTitle: string, task: Task, entityId: string) {
		this.newTitle = newTitle;
		this.task = task;
		this.entityId = entityId;
	}
}
