import { KItem } from '../kitem';

export class KContextPayload {
	x = 0;
	y = 0;
	items: KItem[] = [];
	target: any;
	reference: any;
}

export class KContextPayloadCallback {
	public item: KItem;
	public target: any;
	public reference: any;

	constructor(item: KItem, target: any, reference: any) {
		this.item = item;
		this.target = target;
		this.reference = reference;
	}
}
