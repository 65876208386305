import { Component, ViewChild } from '@angular/core';
import { DxoUploadComponent } from 'devextreme-angular/ui/nested';
import dxFileUploader from 'devextreme/ui/file_uploader';
import { first } from 'rxjs/operators';
import { KAttributeBaseComponent } from '../kbase/kattribute-base.component';
import { FileResponse } from './../../../../server/model/temporari-image-url-response';
import { fileDownload } from './file-functions';

@Component({
	selector: 'app-kfile-manager',
	templateUrl: './kfile-manager.component.html',
	styleUrls: ['./kfile-manager.component.scss'],
})
export class KFileManagerComponent extends KAttributeBaseComponent {
	@ViewChild('fileUploader') fileUploader!: DxoUploadComponent;
	override isDecorative = true;
	uploadUrl!: string;
	files: any[] = [];
	headers: any;

	panelIsVisible: boolean = false;
	fileName: string | null = null;
	fileExt: string | null = null;

	override ngOnInit() {
		this.node.rowSpan = 1; // why?
		this.node.colSpan = 10;

		this.headers = {
			instanceId: this.container.holder.instanceId,
			entityId: this.container.holder.entityId,
			attribute: this.node.attributeName,
		};

		// console.log(this.fileUploader);

		this.uploadUrl = encodeURI(this.container.global.data.serverUrl + '/api/upload');
		// super.ngOnInit();

		this.fileName = this.container.holder.getField('OriginalName', false)?.value;
		this.fileExt = this.container.holder.getField('Extension', false)?.value;

		super.ngOnInit();
		// console.log(this.container.holder);
	}

	onError($event: any) {
		const msg = $event.error?.responseText;

		if (msg) {
			this.container.global.showError(msg);
		}
	}

	onFilesUploaded($event: any) {
		const fileName: string = $event.file.name;

		const i = fileName.indexOf('.');
		this.fileName = fileName.substr(0, i);
		this.fileExt = fileName.substr(i + 1);

		const x = this.fileUploader.instance as dxFileUploader;

		if (x) {
			x.removeFile(0);
		}

		this.panelIsVisible = false;
	}

	onView() {
		this.container.service
			.getDocument(this.container.holder.entityId, this.container.holder.instanceId)
			.pipe(first())
			.subscribe((file: FileResponse | null) => {
				if (file) {
					fileDownload(file.fileName, file.data);
				}
			});
	}

	onClear() {
		this.fileExt = null;
		this.fileName = null;
	}

	onShopUploadPanel() {
		this.panelIsVisible = !this.panelIsVisible;
	}
}
