import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { KFormData } from './../ui/edit/kform/kform-data';
import { EntityService } from './entity.service';
import { EnumHelper } from './helpers/enum-helper';
import { EntityHolder } from './model/entity-holder';
import { FieldValue } from './model/field-value';
import { LogicalAttributeType } from './model/logical-attribute-type';

type updateFieldResponseType = {
	success: boolean;
	field: FieldValue | null;
	data: EntityHolder | null;
};

export interface KDataState {
	isChanged(): boolean;
	// getStateKey(): string;
}

@Injectable({
	providedIn: 'root',
})
export class KTaskStateService {
	#cache = new Map<string, KDataState>();
	private service: EntityService;

	constructor(service: EntityService) {
		this.service = service;
	}

	isChanged(key: string): boolean {
		return this.#cache.get(key)?.isChanged() ?? false;
	}

	get<T extends KDataState>(key: string): T | null {
		return (this.#cache.get(key) as T) ?? null;
	}

	getOrNull<T extends KDataState>(key: string): Observable<T | null> {
		if (this.isInCache(key)) {
			return of((this.#cache.get(key) as T) ?? null);
		} else {
			return of(null);
		}
	}

	private isInCache(key: string): boolean {
		return this.#cache.has(key);
	}

	persist(taskId: string, newState: KDataState): void {
		// console.log('persisting', taskId, (newState as KFormData).data?.entityId);
		this.#cache.set(taskId, newState);
	}

	updateHolder(taskId: string, newHolder: EntityHolder): boolean {
		const state = this.get(taskId);
		if (state instanceof KFormData) {
			state.updateHolder(newHolder);
			return true;
		}
		return false;
	}

	getTaskField(
		taskId: string,
		attributeName: string,
		instanceId: string,
	): EntityHolder | null {
		const parentTask = this.#cache.get(taskId);

		if (parentTask instanceof KFormData && parentTask.data) {
			const f = parentTask.data.getField(attributeName);

			if (f && EnumHelper.is(f.logicalType, LogicalAttributeType.Collection)) {
				const holders = f.value as EntityHolder[];
				const holder = holders.find((c) => c.instanceId === instanceId) ?? null;

				return holder;
			} else if (
				f &&
				EnumHelper.is(f.logicalType, LogicalAttributeType.Pointer)
			) {
				return f.value as EntityHolder;
			} else {
				return null;
			}
		}

		return null;
	}

	updateTaskField(
		taskId: string,
		attributeName: string,
		datas: EntityHolder[],
	): updateFieldResponseType {
		const response: updateFieldResponseType = {
			success: false,
			field: null,
			data: null,
		};

		const parentTask = this.#cache.get(taskId);

		if (!parentTask) {
			return response;
		}

		if (parentTask instanceof KFormData && parentTask.data) {
			// TODO logica per gestion ricalcolo forme se regola

			let f = parentTask.data.getField(attributeName);
			response.field = f;
			response.data = parentTask.data;

			if (f && EnumHelper.is(f.logicalType, LogicalAttributeType.Collection)) {
				const collection: EntityHolder[] =
					parentTask.data.replaceInCollection(attributeName, datas) ?? [];
				parentTask.data?.setField(attributeName, collection);
				response.success = true;
			} else if (datas.length > 0) {
				parentTask.data?.setField(
					attributeName,
					Object.assign(new EntityHolder(), datas[0]),
				);
				response.success = true;
			}

			f = parentTask.data.getField(attributeName);
			response.field = f;
		}

		return response;
	}

	remove(key: string): boolean {
		return this.#cache.delete(key);
	}
}
