<div class="dx-field">
	<div class="dx-field-label">
		{{ label }} <span *ngIf="!optional" class="dx-field-required">*</span>
	</div>
	<div class="dx-field-value">
		<dx-select-box [dataSource]="datasource" placeholder="" [showClearButton]="optional" displayExpr="humanReadableName"
			valueExpr="instanceId" (onValueChanged)="onValueChanged($event)" [value]="value" searchMode="contains"
			[searchExpr]="'humanReadableName'" [searchTimeout]="200" [minSearchLength]="3" [searchEnabled]="true">
		</dx-select-box>
	</div>
</div>
