<app-kcomponent-container [label]="label" [description]="description">
	<dx-text-box *ngIf="isSimple" id="progressTextbox" [value]="prglabel" [readOnly]="true"
		[ngStyle]="{ 'background-color': color }" [title]="description"></dx-text-box>

	<div *ngIf="!isSimple">
		<dx-progress-bar #progressBar id="progress-bar-status" width="100%" [class.complete]="progressBar.value == max"
			[class.start]="progressBar.value == min" [min]="min" [max]="max" [statusFormat]="format" [value]="ival">
		</dx-progress-bar>
	</div>
</app-kcomponent-container>
