import { FieldValue } from 'src/app/server/model/field-value';
import { KSearchDialogClosePayload } from 'src/app/ui/shared/ksearch-dialog/ksearch-dialog-close-payload';
import { Task } from '../../../manager/ktaskbar/task';
export class KFormFieldRefreshPayload {
	// public task: Task, public attributeName: string, public value: FieldValue|null, public pendingChanges: number

	public task: Task;
	public attributeName: string;
	public value: FieldValue | null = null;
	public pendingChanges: number = 0;
	public skipRuleProcess = false;
	public updateValue = true;

	constructor(
		task: Task,
		attributeName: string,
		value: FieldValue | null = null,
		pendingChanges: number = 0,
		skipRuleProcess: boolean = false,
		updateValue: boolean = true,
	) {
		this.task = task;
		this.attributeName = attributeName;
		this.pendingChanges = pendingChanges;
		this.value = value;
		this.skipRuleProcess = skipRuleProcess;
		this.updateValue = updateValue;
	}
}
export class KFormFieldRefreshAfterDialogClosePayload {
	// public task: Task, public attributeName: string, public value: FieldValue|null, public pendingChanges: number

	public task: Task;
	public attributeName: string;
	dialogPayload: KSearchDialogClosePayload;

	constructor(
		task: Task,
		attributeName: string,
		dialogPayload: KSearchDialogClosePayload,
	) {
		this.task = task;
		this.attributeName = attributeName;
		this.dialogPayload = dialogPayload;
	}
}
