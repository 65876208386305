import { EntityHolder } from 'src/app/server/model/entity-holder';
import { ViewResultNode } from 'src/app/server/model/view-result.node';
import { ToKString } from 'src/app/shared/formatting/formatting-helper';
import { EntityAttributeValueType } from './../../../server/model/entity-attribute-value-type';
import { LookUpEntry } from './../../../server/model/lookup-entry';
import { ViewItemResultControl } from './../../../server/model/view-item-result-control';

export type GridColumnDatasource = {
	value: any;
	// values:[],
	data: EntityHolder;
	columnIndex: number;
	column: {
		dataField: string;
		editCellTemplate: string;
		lookup: { items: EntityHolder[] };
	};
	row: {
		data: EntityHolder;
		rowIndex: number;
	};
};

export interface IDatagridNewRowArg {
	data: any;
	promise: any;
}

export interface IDatagridContaner {
	getColumnSource(arg: GridColumnDatasource): any;
	getNewRow(arg: IDatagridNewRowArg): any;
}

function applyNetFormat(column: DatagridColumn, netFormat: string | null): any {
	// https://js.devexpress.com/Documentation/ApiReference/Common/Object_Structures/format/#type
	// FOrmatHElper

	//console.log(netFormat);

	switch (netFormat) {
		case '{0:d}':
			column.format = 'shortDate';

			break;

		case '{0:C}':
			column.format = {
				type: 'currency',
				currency: 'EUR',
			};
			break;

		case '{0:D}':
		case '{0:g}':
			column.format = 'shortDateShortTime';
			break;

		case '{0:t}':
			column.format = 'shortTime';
			break;
		case '{0:f}':
		case '{0:T}':
			column.format = 'longTime';
			break;

		case '{0:H}':
			column.format = (value: any) => {};
			break;

		case '{0:HM}':
			column.format = {
				formatter: (value: string) => {
					return ToKString(value, EntityAttributeValueType.TimeSpan, netFormat);
				},
			};

			break;
	}

	// https://js.devexpress.com/Documentation/ApiReference/Common/Object_Structures/format/#type
	// TO  Custom format
}

export class DatagridColumn {
	minWidth: number | string | undefined;

	constructor(field: string, caption: string) {
		this.field = field;
		this.caption = caption;
	}

	field: string;
	caption: string;
	dataType?: string;
	type: string | undefined;

	isLookUp = false;
	lookUpData: EntityHolder[] = [];

	sortOrder: string | null = null;
	format: any = null;
	formatPrecision: string | null = null;
	sortIndex = 0;
	editCellTemplate: string | null = null;
	cellTemplate: string | null = null;
	allowEditing: boolean = false;
	public static parse(column: ViewResultNode): DatagridColumn {
		const me = new DatagridColumn(column.getUniqueId(), column.caption);

		switch (column.control) {
			case ViewItemResultControl.EditableColumn:
				switch (column.type) {
					case EntityAttributeValueType.Boolean:
						me.cellTemplate = 'BooleanColumn';
						me.editCellTemplate = 'EditableBooleanColumn';

						break;
					case EntityAttributeValueType.Date:
					case EntityAttributeValueType.DateTime:
						me.editCellTemplate = 'EditableDateColumn';
						break;

					default:
						me.editCellTemplate = 'EditableColumn';
						break;
				}
				me.allowEditing = true;
				break;

			case ViewItemResultControl.DropColumn:
				me.editCellTemplate = 'DropColumn';
				me.allowEditing = true;
				console.log(me);
				break;

			case ViewItemResultControl.SearchColumn:
				me.editCellTemplate = 'SearchColumn';
				me.allowEditing = true;
				break;

			case ViewItemResultControl.ImageButtonColumn:
				me.editCellTemplate = 'ImageButtonColumn';
				break;

			case ViewItemResultControl.ColoredColumn:
				me.cellTemplate = 'ColoredColumn';
				break;
		}

		me.isLookUp = (column.values?.length ?? 0) > 0;

		if (me.isLookUp && column.values != null) {
			// me.lookUpData = this.toLookUp(column.values);
			me.lookUpData = column.values.map((c) => EntityHolder.fromScratch('', c.value, String(c.uniqueId)));
		}

		//  'string' | 'number' | 'date' | 'boolean' | 'object' | 'datetime'
		if (column.type === EntityAttributeValueType.Date) {
			me.dataType = 'date';
		} else if (column.type === EntityAttributeValueType.DateTime) {
			me.dataType = 'datetime';
			// me.format = 'shortDate';
			// me.formatPrecision = null;
			// console.log(me);
		} else if (column.type === EntityAttributeValueType.Boolean) {
			me.dataType = 'boolean';
		} else if (column.type === EntityAttributeValueType.TimeSpan) {
			me.dataType = 'numeric';
			me.cellTemplate = 'TimeSpanColumn';
			me.editCellTemplate = 'EditableTimeSpanColumn';
		}
		if (column.size < 0) {
			me.minWidth = Math.abs(column.size);
		} else if (column.size > 0) {
			// me.minWidth = column.size + '%';
		}

		// console.log(me);
		applyNetFormat(me, column.format);

		return me;
	}

	static toLookUp(values: LookUpEntry[]): any {
		const lookup = {
			store: {
				type: 'array',
				data: values.map((c) => {
					return {
						id: c.uniqueId,
						name: c.value,
					};
				}),
				key: 'id',
			},
			pageSize: 10,
			paginate: false,
		};

		return lookup;
	}
}
