import { Component } from '@angular/core';
import { KnowEntityIds, KnowItemNames } from 'src/app/server/helpers/constants';
import { GenericHelper } from 'src/app/server/helpers/generic-helper';
import { EntityHolder } from 'src/app/server/model/entity-holder';
import { TaskRelation } from '../../manager/ktaskbar/task';
import { KBaseComponent } from '../kcomponents/kbase/kbase.component';
import { EntityAttachment } from './../../../server/model/save-response';

@Component({
	selector: 'app-kattached',
	templateUrl: './kattached.component.html',
	styleUrls: ['./kattached.component.scss'],
})
export class KAttachedComponent extends KBaseComponent {
	override isDecorative = true;
	icon!: string;
	dataSource!: EntityAttachment[];
	focusedRowKey = -1;

	override ngOnInit(): void {
		this.icon = GenericHelper.toIcon(KnowEntityIds.Attached);
		this.node.colSpan = 20;
		this.node.rowSpan = 10;

		super.ngOnInit();

		this.container.service
			.getAttachments(
				this.container.task.instanceId!,
				this.container.task.entityId,
				null,
				true,
			)
			.subscribe((attachments) => {
				this.dataSource = attachments;
				this.cdr.markForCheck();
			});
	}

	onToolbarPreparing(e: any): any {
		e.toolbarOptions.items.unshift({
			location: 'after',
			widget: 'dxButton',
			options: {
				icon: 'insertrowabove',
				onClick: () => {
					this.onAdd();
				},
			},
		});
	}

	onEdit(e: {
		data: {
			id: string;
			isDocument: boolean;
			isReverse: boolean;
			name: string;
			targetTypeName: string;
			type: string;
			viewId: string;
		};
	}) {
		this.container.openTask(
			KnowEntityIds.Attached,
			e.data.id,
			this.container.task.id,
			null,
			e.data.viewId,
			TaskRelation.Parent,
			'view',
		);
	}

	onRowRemoved(e: { key: string }) {
		console.log(e);

		this.container.service
			.delete(KnowEntityIds.Attached, e.key)
			.subscribe((result) => {
				console.log(result);
			});

		// this.openTask(c.id, holder.instanceId, this.isMember ? TaskRelation.SubTask : TaskRelation.Parent);
	}

	onAdd() {
		const args = new Map<string, any>();
		args.set(KnowItemNames.MemberId, this.container.task.instanceId);
		args.set(KnowItemNames.MemberType, this.container.task.entityId);

		this.container.service
			.load(KnowEntityIds.Attached, null, args, null)
			.subscribe((newHolder: EntityHolder | null) => {
				this.container.createNewTaskAndRedirect(
					newHolder,
					'none',
					false,
					'edit',
				);
			});
	}
}
