export const entityCachaType = {
	EntityInfo: 'EntityInfo',
	MenuCache: 'MenuCache',
	EntitiesCache: 'EntitiesCache',
	TasksCache: 'TasksCache',
	QuickFiltersCache: 'QuickFiltersCache',
	AgregateMemberCache: 'AgregateMember',
	CategoryCache: 'CategoryCache',
	OrganizationTreeCache: 'OrganizationTreeCache',
	DashboardCache: 'DashboardCache',
	GetPossibleUiCache: 'GetPossibleUiCache',
	TodoListCache: 'TodoListCache',
};
