import { ViewNode } from 'src/app/server/model/view-node';
import { Task } from '../../../manager/ktaskbar/task';

export class KFieldValidChangePayload {
	constructor(
		public task: Task,
		public node: ViewNode,
		public isValid: boolean,
	) {}
}
