<app-datagrid
	[columns]="columns"
	[source]="dataSource"
	[option]="option"
	[host]="this"
	(selectionChange)="onSelectionChange($event)"
	(cellClick)="onCellClick($event)"
	(rowAdd)="onRowAdd()"
	(rowEdit)="onRowEdit($event)"
	(rowDelete)="onRowDelete($event)"
	(rowUp)="onRowUp($event)"
	(rowDown)="onRowDown($event)"
	(columnValueChange)="onColumnValueChange($event)"
></app-datagrid>
