import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FilterEditorBaseComponent } from '../filter-editor-base.component';

@Component({
	selector: 'app-categories-filter',
	templateUrl: './categories-filter.component.html',
	styleUrls: ['./categories-filter.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CategoriesFilterComponent extends FilterEditorBaseComponent {}
