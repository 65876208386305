import { Component } from '@angular/core';
import { FieldValue } from 'src/app/server/model/field-value';
import { KAttributeBaseComponent } from '../kbase/kattribute-base.component';

@Component({
	selector: 'app-kcolor-box',
	templateUrl: './kcolor-box.component.html',
	styleUrls: ['./kcolor-box.component.scss'],
})
export class KColorBoxComponent extends KAttributeBaseComponent {
	innerValue!: string;

	override setValue(value: FieldValue | null): void {
		super.setValue(value);
		this.innerValue = value?.value;
		// console.log(this.innerValue);
		this.cdr.markForCheck();
	}

	onValueChanged(arg: {
		value: string;
		event: any;
		previousValue: string | null;
	}): void {
		if (this.readonly) return;
		// console.log(arg);

		if (this.value && arg?.previousValue !== arg?.value) {
			this.innerValue = arg.value;
			super.fieldValueIsChanging(arg.value);
		}
	}
}
