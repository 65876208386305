<div class="container">
	<div class="toolbar" height="auto">
		<ng-content select="[toolbar]"></ng-content>
	</div>
	<div id="content">
		<as-split direction="horizontal">
			<as-split-area [size]="navSize" class="nav">
				<ng-content select="[nav]"></ng-content>
			</as-split-area>
			<as-split-area [size]="detailSize" class="detail">
				<ng-content select="[detail]"></ng-content>
			</as-split-area>
		</as-split>
	</div>
</div>
