import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { EntityInfo } from 'src/app/server/model/entity-info';
import { ExecuteCustomActionResponse } from 'src/app/server/model/save-response';
import { FeedbackResponseEnum, FeedbackTypeEnum } from '../../../server/model/entity-logic-feedback';
import { KSearchOpenPayload } from '../../manager/ktaskbar/ksearch-open-payload';
import { KSearchCompontentBase } from '../../search/ksearch/ksearch-base.component';
import { KSearchData } from '../../search/ksearch/ksearch-data';
import { resolveFilters } from '../kcomponents/kbase/functions';
import { KRelatedPayload } from '../kcomponents/kbase/krelated-payload';
import { GlobalEventType } from './../../../shared/services/global/global-event-type';
import { GlobalNotifyArg } from './../../../shared/services/global/global-notify-arg';
import { KTaskBarEventType } from './../../manager/ktaskbar/ktaskbar-event';

import { formReloadAfterElaborate } from './form-refresh-after-change';
import { KFormComponent } from './kform.component';

export function processCustomActionResponse(
	form: KFormComponent | KSearchCompontentBase,
	response: ExecuteCustomActionResponse,
	keepEntityModified: boolean = true,
): Observable<boolean> {
	console.log(response.feedback);
	// console.log(response);

	if (!response.success) {
		form.global.on(GlobalEventType.Notify, new GlobalNotifyArg(response.message ?? '', 'error'));
		return of(false);
	}

	if (response.feedback == null) return of(false);
	switch (response.feedback.type) {
		case FeedbackTypeEnum.Reload:
			refreshFormIfNeed(form, response, keepEntityModified);
			break;
		case FeedbackTypeEnum.Message:
			form.global.on(GlobalEventType.Notify, new GlobalNotifyArg(response.feedback.message, 'info'));
			if (response.feedback?.needRefresh || response.feedback?.needReload) {
				refreshFormIfNeed(form, response, keepEntityModified);
			}
			break;
		case FeedbackTypeEnum.OpenEntityUi:
			const openTaskInNewTab = (response: ExecuteCustomActionResponse) => {
				form.openTask(response.feedback!.entityId, response.feedback!.instanceId, form.task.id);
			};

			if (response.message) {
				form.global.showConfirm(response.message, () => {
					openTaskInNewTab(response);
				});
			} else {
				openTaskInNewTab(response);
			}

			break;

		case FeedbackTypeEnum.Confirm:
			if (form instanceof KFormComponent) {
				form.global.showConfirm(response.message!, () => {
					form.doOnExecuteCustomAction(form.data!.status!, response.data!, FeedbackResponseEnum.Ok);
				});
			}

			break;

		case FeedbackTypeEnum.Background:
			form.global.showConfirm(response.message!, () => {
				if (form instanceof KFormComponent) {
					form.closeAndReopenParent();
				}
			});

			break;

		case FeedbackTypeEnum.Questions:
			console.error('TODO:' + FeedbackTypeEnum[response.feedback.type]);
			console.log(response);
			break;

		case FeedbackTypeEnum.FileDownload:
			forceDownload(response.feedback.message);
			break;
	}

	return of(response.feedback.needReload);
}

function refreshFormIfNeed(
	form: KFormComponent | KSearchCompontentBase,
	response: ExecuteCustomActionResponse,
	keepEntityModified: boolean = true,
) {
	if (form instanceof KFormComponent && response.data) {
		formReloadAfterElaborate(form, response.data, keepEntityModified);
	}
}

function forceDownload(href: string): void {
	const anchor = document.createElement('a');
	anchor.href = href;
	anchor.download = href;
	document.body.appendChild(anchor);
	anchor.click();
}

export function formExecuteRelated(form: KFormComponent, payload: KRelatedPayload): void {
	form.service
		.getEntityInfo(payload.related.relatedEntityId)
		.pipe(
			map((metadata: EntityInfo) => {
				const search = new KSearchData(metadata, [], null);
				search.addExtraFilters(
					resolveFilters(metadata, payload.related.filters, form.holder, form.getParentHolder(), form.getMainHolder()),
				);
				return search;
			}),
		)
		.subscribe({
			next: (search) => {
				form.on(KTaskBarEventType.OpenTask, new KSearchOpenPayload(form.task, search));
			},
			complete: () => {},
		});
}
