import { Task } from '../../ui/manager/ktaskbar/task';
import { Cacheable } from './cacheable';

export class TasksCache extends Cacheable {
	constructor(raw: any) {
		super();
		this.id = 'Shared';
		this.tasks = raw.nodes;
		this._raw = JSON.stringify(raw);
	}

	tasks: Task[] = [];
}
