import { KAttributeBaseComponent } from 'src/app/ui/edit/kcomponents/kbase/kattribute-base.component';
import { ContextMenuCommands } from '../../edit/kcomponents/kbase/kentity-base.component';
import { KTaskBarEventType } from '../../manager/ktaskbar/ktaskbar-event';
import { KItem } from './../kitem';
import { KContextPayload } from './kcontext-payload';

export function createContextMenuTaskBarEvent(
	x: number,
	y: number,
	items: KItem[],
	target: string,
	reference: any = null,
): KContextPayload {
	const payload: KContextPayload = Object.assign(new KContextPayload(), {
		x: x,
		y: y,
		items: items,
		target: target,
		reference,
	});

	return payload;
}

export function openContextMenu(
	me: KAttributeBaseComponent,
	x: number,
	y: number,
	reference: any,
	readOnly: boolean,
	commands?: KItem[],
	commandKeys?: string[],
) {
	if (readOnly) return;

	if (commandKeys) {
		const commands: KItem[] = [];

		commandKeys.forEach((commandKey) => {
			const f = ContextMenuCommands.find((c) => c.id === commandKey);

			if (f) {
				commands.push(f);
			}
		});
	}

	if (!commands) {
		return;
	}

	me.container.on(
		KTaskBarEventType.ShowContextMenu,
		createContextMenuTaskBarEvent(x, y, commands, me.node.name, reference),
	);
}
