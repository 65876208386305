<dx-diagram #diagram id="diagram" [contextToolbox]="contextToolboxConfiguration" [toolbox]="toolBoxConfiguration"
	[historyToolbar]="historyToolbarConfiguration" [mainToolbar]="mainToolbarConfiguration"
	[viewToolbar]="viewToolbarConfiguration" [simpleView]="true" [width]="width" [height]="height" [readOnly]="true"
	[autoZoomMode]="autoZoomMode" customShapeTemplate="customShapeTemplate"
	(onSelectionChanged)="selectionChangedHandler($event)">
	<dxo-nodes [dataSource]="nodesDs" keyExpr="id" [textExpr]="textExpr" typeExpr="type" containerChildrenExpr="childs"
		[imageUrlExpr]="imageUrlExpr" [styleExpr]="itemStyleExpr" [autoSizeEnabled]="true" [heightExpr]="heigthExpr"
		[widthExpr]="widthExpr">
		<dxo-auto-layout type="layered"></dxo-auto-layout>
	</dxo-nodes>

	<dxo-edges [dataSource]="edgesDs" keyExpr="id" fromExpr="fromId" toExpr="toId" textExpr="text"> </dxo-edges>

	<ng-container *dxTemplate="let item of 'customShapeTemplate'">
		<svg *ngIf="item.dataItem.legendId > 0" x="100%" y="0%" class="template" style=" overflow: visible;">
			<ellipse *ngIf="!item.dataItem.isCritical" style="fill: rgb(255, 0, 0); stroke: rgb(155, 0, 0);" cx="0" cy="0"
				rx="8.5" ry="8.5" />
			<ellipse *ngIf="item.dataItem.isCritical" style="fill: rgb(255, 120, 0); stroke: rgb(127, 60, 0);" cx="0" cy="0"
				rx="8.5" ry="8.5" />
			<text
				style="fill: whitesmoke; font-family: Arial, sans-serif; font-size: 12px; font-weight: 700; white-space: pre"
				x="0" y="4">
				{{ item.dataItem.legendId }}
			</text>
		</svg>
	</ng-container>

	<dxi-custom-shape type="processItemContainer" baseType="verticalContainer" [allowEditText]="false"
		[allowResize]="false">
	</dxi-custom-shape>

	<dxi-custom-shape type="icon" [maxWidth]="1" [maxHeight]="2" [allowEditText]="false" [allowResize]="false">
	</dxi-custom-shape>

	<dxi-custom-shape type="processItem" baseType="cardWithImageOnLeft" [allowEditText]="false" [allowResize]="false">
	</dxi-custom-shape>

	<dxi-custom-shape type="block" [maxWidth]="3" [maxHeight]="3" [allowEditText]="false" [allowResize]="false">
	</dxi-custom-shape>
</dx-diagram>
