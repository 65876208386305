import { CommonModule } from '@angular/common';
import { Component, NgModule } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { SingleCardModule } from 'src/app/layouts';

@Component({
	selector: 'app-unauthenticated-content',
	template: `
		<app-single-card
			*ngIf="isSingleCard"
			[title]="title"
			[description]="description"
		>
			<router-outlet></router-outlet>
		</app-single-card>
		<router-outlet *ngIf="!isSingleCard"></router-outlet>
	`,
	styles: [
		`
			:host {
				width: 100%;
				height: 100%;
			}
		`,
	],
})
export class UnauthenticatedContentComponent {
	router: Router;
	constructor(router: Router) {
		this.router = router;
	}

	get isSingleCard(): boolean {
		const path = this.router.url.split('/')[1];
		switch (path) {
			case 'login-form':
				return true;
			case 'reset-password':
				return true;
			case 'create-account':
				return true;
			case 'change-password':
				return true;
			case 'setup':
				return true;
		}

		return false;
	}

	get title(): string {
		const path = this.router.url.split('/')[1];
		switch (path) {
			case 'login-form':
				return 'Benvenuto in Keystone';
			case 'reset-password':
				return 'Resetta la password';
			case 'create-account':
				return 'Registrati';
			case 'change-password':
				return 'Cambia password';
		}

		return '';
	}

	get description(): string {
		const path = this.router.url.split('/')[1];
		switch (path) {
			case 'reset-password':
				return 'Please enter the email address that you used to register, and we will send you a link to reset your password via Email.';
		}

		return '';
	}
}
@NgModule({
	imports: [CommonModule, RouterModule, SingleCardModule],
	declarations: [UnauthenticatedContentComponent],
	exports: [UnauthenticatedContentComponent],
})
export class UnauthenticatedContentModule {}
