<div class="dx-field">
	<div class="dx-field-label">
		{{ label }}
		<span *ngIf="!optional" class="dx-field-required">*</span>
	</div>
	<div class="dx-field-value">
		<!-- {{node?.colSpan}} -->
		<dx-text-area [value]="value" (onValueChanged)="onValueChanged($event)" height="50vh"> </dx-text-area>
	</div>
</div>
