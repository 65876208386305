import { LogicalAttributeType } from 'src/app/server/model/logical-attribute-type';
import { ViewItemUiControl } from 'src/app/server/model/view-item-ui-control';
import { EntityAttributeValueType } from './entity-attribute-value-type';
import { LookUpEntry } from './lookup-entry';
import { SearchParameterType } from './search-parameter-type';

export class FormEntry {
	attributeId: string | null = null;
	attributeName: string | null = null;
	entityId: string | null = null;
	format: string | null = null;
	isVirtual: boolean = false;
	label: string | null = null;
	logicalType: LogicalAttributeType = LogicalAttributeType.Id;
	operator: SearchParameterType | null = null;
	optional: boolean = false;
	selectorType: ViewItemUiControl = ViewItemUiControl.TextBox;
	staticValues: LookUpEntry[] = [];
	targetEntityId: string | null = null;
	type: EntityAttributeValueType = EntityAttributeValueType.Int;
}

export function commandRequireForm(command: Command): boolean {
	return command.formEntries != null && command.formEntries.length > 0;
}

export class Command {
	id!: string;
	name!: string;

	avaibleFromStatusId: number | null = null;
	avaibleToStatusId: number | null = null;
	availableInSearch = false;
	refAttributeName: string | null = null;
	formEntries: FormEntry[] | null = null;
	isCustom: boolean = false;
}
