<div class="container">
	<div id="result">
		<app-datagrid
			[host]="this"
			[columns]="columns"
			[source]="dataSource"
			(selectionChange)="onSelectionChange($event)"
			(cellClick)="onCellClick($event)"
			(stateChange)="onStateChange($event)"
			[option]="option"
		>
		</app-datagrid>
	</div>

	<div id="tools">
		<app-kfilters id="filters"></app-kfilters>
		<app-kquick-filters id="quicks"></app-kquick-filters>
	</div>
</div>

<dx-popup
	width="80%"
	height="70%"
	[showTitle]="true"
	title="Configurazione colonne"
	[dragEnabled]="false"
	[hideOnOutsideClick]="true"
	[showCloseButton]="true"
	[(visible)]="columnEditorIsOpen"
>
	<div *dxTemplate="let data of 'content'">
		<app-kcolumns-editor></app-kcolumns-editor>
	</div>

	<dxi-toolbar-item
		widget="dxButton"
		toolbar="bottom"
		location="after"
		[options]="saveButtonOptions"
	>
	</dxi-toolbar-item>

	<dxi-toolbar-item
		widget="dxButton"
		toolbar="bottom"
		location="after"
		[options]="resetButtonOptions"
	>
	</dxi-toolbar-item>
	<dxi-toolbar-item
		widget="dxButton"
		toolbar="bottom"
		location="after"
		[options]="closeButtonOptions"
	>
	</dxi-toolbar-item>
</dx-popup>

<dx-popup
	width="80%"
	height="70%"
	[showTitle]="true"
	title="Configurazione filtri"
	[dragEnabled]="false"
	[hideOnOutsideClick]="true"
	[showCloseButton]="true"
	[(visible)]="filterEditorIsOpen"
>
	<div *dxTemplate="let data of 'content'">
		<app-kfilters-editor></app-kfilters-editor>
	</div>

	<dxi-toolbar-item
		widget="dxButton"
		toolbar="bottom"
		location="after"
		[options]="saveFilterButtonOptions"
	>
	</dxi-toolbar-item>

	<dxi-toolbar-item
		widget="dxButton"
		toolbar="bottom"
		location="after"
		[options]="closeFilterButtonOptions"
	>
	</dxi-toolbar-item>
</dx-popup>
