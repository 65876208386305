import { Component } from '@angular/core';
import { ViewItemUiControl } from 'src/app/server/model/view-item-ui-control';

import { KBaseComponent } from '../kbase/kbase.component';

@Component({
	selector: 'app-kempty',
	templateUrl: './kempty.component.html',
	styleUrls: ['./kempty.component.scss'],
})
export class KEmptyComponent extends KBaseComponent {
	controlTypeName: string | null = null;

	override ngOnInit() {
		super.ngOnInit();

		this.controlTypeName = ViewItemUiControl[this.node.controlType];
	}
}
