<app-kcomponent-container [label]="label" [mandatory]="mandatory" [description]="description">
	<dx-date-box
		[value]="innerValue"
		type="date"
		dateSerializationFormat="yyyy-MM-ddTHH:mm:ss"
		(onValueChanged)="onValueChanged($event)"
		[readOnly]="readonly"
		[showClearButton]="this.metadata.allowNull"
		applyValueMode="useButtons"
		[title]="description"
	>
		<dx-validator (onOptionChanged)="onValidationChange($event)">
			>
			<dxi-validation-rule *ngIf="this.mandatory" type="required" message="Campo obbligatorio"></dxi-validation-rule>
		</dx-validator>
	</dx-date-box>
</app-kcomponent-container>
