import {
	ChangeDetectionStrategy,
	Component,
	forwardRef,
	Input,
} from '@angular/core';
import { EntityInfo } from 'src/app/server/model/entity-info';
import { KSearchDialogPopUpDeletePayload } from 'src/app/ui/shared/ksearch-dialog/ksearch-dialog-close-payload';
import { KToolbarPayload } from '../../edit/ktoolbar/ktoolbar-payload';
import { KTaskComponent } from '../../manager/ktask/ktask.component';
import { KSearchTaskComponent } from '../../manager/ktask/search/ksearch-task.component';
import { KTaskBarEventType } from '../../manager/ktaskbar/ktaskbar-event';
import { Task } from '../../manager/ktaskbar/task';
import { KSearchDialogComponent } from '../../shared/ksearch-dialog/ksearch-dialog.component';
import { KSearchToolbarComponent } from './ksearch-toolbar.component';

@Component({
	selector: 'app-ksearch-dialog-toolbar',
	templateUrl: './ksearch-dialog-toolbar.component.html',
	styleUrls: ['./ksearch-toolbar.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: KTaskComponent,
			useExisting: forwardRef(() => KSearchTaskComponent),
		},
	],
})
export class KSearchDialogToolbarComponent extends KSearchToolbarComponent {
	@Input() dialog!: KSearchDialogComponent;
	@Input() override task!: Task;
	@Input() multiselect: boolean = false;
	@Input() delete: boolean = false;

	override createMenuItems(entityInfo: EntityInfo): void {
		const me = this;

		this.selectButtonOptions = {
			icon: 'check',
			onClick: () => {
				this.manager.on(
					KTaskBarEventType.SearchSelect,
					new KToolbarPayload('select', this.task),
				);
			},
		};

		this.deleteButtonOptions = {
			icon: 'trash',
			type: 'danger',
			stylingMode: 'outlined',
			onClick: () => {
				if (confirm("Sicuro di voler cancellare l'elemento?")) {
					this.manager.on(
						KTaskBarEventType.SearchPopUpDelete,
						new KSearchDialogPopUpDeletePayload(me.selectedRows),
					);
				}
			},
		};

		this.closeButtonOptions = {
			icon: 'export',
			// type: 'success',
			onClick: () => {
				this.manager.on(
					KTaskBarEventType.SearchClose,
					new KToolbarPayload('close', this.task),
				);
			},
		};
	}
}
