import { EntityHolder } from 'src/app/server/model/entity-holder';

export function removeHolderFromCollectionById(
	holder: EntityHolder,
	attributeName: string,
	instanceId: string,
): EntityHolder[] {
	const currentHolders = holder.getField(attributeName)
		?.value as EntityHolder[];

	const newHolders = [...currentHolders];

	if (newHolders) {
		const x = newHolders.findIndex((c) => c.instanceId === instanceId);

		if (x >= 0) {
			newHolders.splice(x, 1);
		}
	}

	return newHolders;
}

// export function updateStateAndField(payload: KDialogClosePayload, form: KFormComponent) {
//   updateStateSetField(payload.attributeName!, payload.entities, form, payload.parentTaskId);
// }

// export function updateStateSetField(attributeName:string, holders: EntityHolder[], form: KFormComponent, taskId: string|null, dontRefresh: boolean = false) {
//   if (!taskId) taskId = form.container.task.id;

//   const response = form.state.updateTaskField(taskId, attributeName, holders);

//   if (response.success && !dontRefresh) {
//     form.container.manager.on(KTaskBarEventType.FormFieldRefresh, new KFormFieldRefreshPayload(
//       form.container.task,
//       attributeName,
//       response.field,
//       response.data?.history?.length ?? 0)
//     );
//   }
// }
