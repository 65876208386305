import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { AuthService, defaultPath } from './auth.service';
import { GlobalService } from './global/global.service';

@Injectable()
export class AuthGuardService implements CanActivate {
	private router: Router;
	private authService: AuthService;
	private globalService: GlobalService;

	constructor(
		router: Router,
		authService: AuthService,
		globalService: GlobalService,
	) {
		this.router = router;
		this.authService = authService;
		this.globalService = globalService;
	}

	canActivate(route: ActivatedRouteSnapshot): boolean {
		if (
			route == null ||
			route.routeConfig == null ||
			route.routeConfig.path == null
		) {
			return false;
		}

		const isLoggedIn = this.globalService.isloggedIn;

		const isAuthForm = [
			'login-form',
			'reset-password',
			'create-account',
			'change-password/:recoveryCode',
		].includes(route.routeConfig.path);

		const isSetup = ['setup'].includes(route.routeConfig.path);

		if (isLoggedIn && isAuthForm) {
			this.router.navigate([defaultPath]);
			return false;
		}

		if (
			isAuthForm &&
			!isLoggedIn &&
			this.globalService.data.serverUrl == null
		) {
			this.globalService.redirectToSetup();
		}

		if (!isLoggedIn && !isAuthForm && !isSetup) {
			this.globalService.redirectToLogin();
		}

		// if (isLoggedIn) {

		//   this.router.navigate([defaultPath]);

		// }

		return isLoggedIn || isAuthForm || isSetup;
	}
}
