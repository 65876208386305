import { KDataState } from 'src/app/server/kform-data-state.service';
import { EntityHolder } from 'src/app/server/model/entity-holder';
import { EntityInfo } from 'src/app/server/model/entity-info';
import { QuickFilter } from 'src/app/server/model/quick-filters-response';
import { SearchColumnResult } from 'src/app/server/model/search-column-result';
import { SearchParameter } from 'src/app/server/model/search-parameter';
import {
	getOperatorSign,
	SearchParameterType,
} from 'src/app/server/model/search-parameter-type';
import { SearchResponse } from 'src/app/server/model/search-response';
import { ViewResultNode } from 'src/app/server/model/view-result.node';
import { EntityQuery } from '../../../server/model/entity-query';
import { KItem } from '../../shared/kitem';

export type argType = {
	entityId: string;
	queryId: string | null;
	cacheKey: string | null;
};

export class KSearchData implements KDataState {
	public filterItems: KItem[] = [];
	public quickFiltersItems: KItem[] = [];

	public query: EntityQuery;
	public columns: ViewResultNode[];
	public orders: SearchColumnResult[];

	public filters: SearchParameter[];
	public apiFilters: SearchParameter[] = [];

	constructor(
		public info: EntityInfo,
		public quickFilters: QuickFilter[],
		queryId: string | null = null,
	) {
		let q = info.queries.find((q1) => q1.id === queryId) ?? null;

		if (!q) {
			q = info.queries[0];
		}

		this.query = q;
		this.columns = q?.customColumns ?? [];
		this.orders = q?.orders ?? [];
		this.filters = q?.filters ?? [];

		// console.log(this);
		this.rebuildItems();
	}

	rebuildItems(): void {
		// console.log('rebulding');

		this.apiFilters = [];
		this.quickFiltersItems = [];
		this.filterItems = [];
		this.apiFilters = [];
		this.filterToApiFilter(this.filters);

		this.quickFiltersItems = this.quickFilters.map((f: QuickFilter) =>
			KSearchData.toItem(f),
		);
		// tolto dai quick filters i filter
		this.filters.forEach((f) => {
			const toRemoves = this.quickFiltersItems.filter(
				(qfi) => qfi.id === f.attributeName,
			);

			toRemoves.forEach((toRemove) => {
				this.quickFiltersItems.splice(
					this.quickFiltersItems.indexOf(toRemove),
					1,
				);
			});
		});

		// per poterli visualizzare devo avere oltre che il filtro il valore leggibile
		this.filterItems = this.apiFilters.map((f) => {
			const a = this.info.metadata?.attributes.get(f.attributeName);

			let hr: string | null = null;
			let label: string | null = null;
			// lo cerco nei filtri veloci
			const d1 = this.quickFilters.find((c) => c.id === f.attributeName);

			hr = d1?.values.find((c) => c.id === f.value)?.name ?? '';

			// se non lo trovo li provo nei value degli attriuti
			if (!hr) {
				hr = a?.values.find((c) => c.id === f.value)?.name ?? null;
			}

			// o nei filtri
			if (!hr) {
				const hhh = this.filters.find(
					(c) => c.attributeName === f.attributeName,
				);

				hr = hhh?.valueHr ?? null;
				label = hhh?.label ?? null;
			}
			// console.log(hr, f);
			if (!hr) {
				const qf = this.apiFilters.find(
					(c) => c.attributeName === f.attributeName,
				);

				if (a?.values.length === 0 && qf?.value) {
					hr = qf.value;
				}

				// console.log(qf, f, a?.values);

				// hr = this.apiFilters.find(c => c.attributeName === f.name)?.valueHr ?? null;
			}

			return new KItem(
				f.attributeName,
				`${a?.label ?? label} ${getOperatorSign(f.operator)} ${hr}`,
			);
		});
	}

	addExtraFilters(extraFilters: SearchParameter[]): void {
		extraFilters.forEach((f: SearchParameter) => {
			const ff = this.filters.find((c) => c.attributeName === f.attributeName);
			if (!ff) {
				this.filters.push(f);
			} else {
				ff.value = f.value;
			}
		});
		this.rebuildItems();
	}

	reApplyQuickFilterItems(quickFiltersItems: KItem[]): void {
		this.apiFilters = [];
		this.filterToApiFilter(this.filters);
		KSearchData.quickFiltersToApiFilter(this.apiFilters, quickFiltersItems);
	}

	filterToApiFilter(filters: SearchParameter[]): void {
		filters.forEach((f: SearchParameter) => {
			this.apiFilters.push(
				SearchParameter.createWithOperator(
					f.attributeName,
					f.operator,
					f.value,
				),
			);
		});
	}

	static quickFiltersToApiFilter(
		filters: SearchParameter[],
		quickFilters: KItem[],
	): void {
		// carico i restanti quick filter
		quickFilters.forEach((f: KItem) => {
			if (f.parentId && f.selected) {
				const a1 = filters.find((c) => c.attributeName === f.parentId);

				if (!a1) {
					filters.push(
						SearchParameter.createWithOperator(
							f.parentId,
							SearchParameterType.Equal,
							f.value,
						),
					);
				} else {
					a1.operator = SearchParameterType.Contains;
					a1.value = a1.value ? a1.value + '|' + f.value : f.value;
				}
			}
		});

		console.log(filters);
	}

	public static toItem(
		f: any,
		field: string | null = null,
		parentId: string | null = null,
	): KItem {
		if (parentId == null) {
			parentId = field;
		}

		return {
			id: field ? field + '_' + f.id : f.id,
			text: f.name,
			parentId: parentId ?? undefined,
			selected: false,
			value: f.id,
			items: f.values
				? f.values.map((f1: QuickFilter) => this.toItem(f1, f.id, parentId))
				: [],
		};
	}

	isChanged(): boolean {
		return false;
	}
}

export type SearchArg = {
	searchData: KSearchData;
	caller: EntityHolder | null;
	isForNew: boolean;
	excludeFilter: boolean;
	response: SearchResponse | null;
	requireDynamicFilters: boolean;
	requirePostSearchAnalisys: boolean;
};
