<div class="container">
	<div class="list">
		<dx-list
			#list
			[dataSource]="dataSource"
			[repaintChangesOnly]="true"
			height="100%"
			[allowItemDeleting]="false"
			itemDeleteMode="toggle"
			[showSelectionControls]="false"
			(onSelectionChanged)="onSelectionChanged($event)"
			selectionMode="single"
			[showSelectionControls]="true"
			[selectedItemKeys]="initialSelection"
			menuMode="context"
			[menuItems]="menuItems"
			(onItemReordered)="onItemReordered($event)"
		>
			<dxo-item-dragging
				[data]="dataSource"
				[allowReordering]="true"
				[onDragStart]="onDragStart"
				[onAdd]="onAdd"
				[onRemove]="onRemove"
			>
			</dxo-item-dragging>

			<div *dxTemplate="let item of 'item'">
				{{ item.caption }}
			</div>
		</dx-list>
	</div>
	<div class="detail">
		<app-kcolum-editor [column]="selected"></app-kcolum-editor>
	</div>
</div>
