import { ChangeDetectionStrategy, Component } from '@angular/core';
import { KTaskComponent } from '../ktask.component';

@Component({
	selector: 'app-kedit-task',
	templateUrl: './kedit-task.component.html',
	styleUrls: ['./kedit-task.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KEditTaskComponent extends KTaskComponent {}
