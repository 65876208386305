import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { EntityHolder } from 'src/app/server/model/entity-holder';
import { LogicalEntityType } from 'src/app/server/model/logical-entity-type';
import { TaskRelation } from 'src/app/ui/manager/ktaskbar/task';
import { openContextMenu } from 'src/app/ui/shared/kcontext-menu/functions';
import { KContextPayloadCallback } from 'src/app/ui/shared/kcontext-menu/kcontext-payload';
import { ContextMenuCommands, KEntityBaseComponentMenuActions } from '../kbase/kentity-base.component';
import { KEntityOrStaticBaseComponent } from '../kbase/kentity-or-static-base.component';
import { SearchPanelControlOption } from '../kgrid/grid-control-option';
import { EnumHelper } from './../../../../server/helpers/enum-helper';
import { IKEntityOrStaticComponent } from './../kbase/abstract';

@Component({
	selector: 'app-ksearch-panel',
	templateUrl: './ksearch-panel.component.html',
	styleUrls: ['./ksearch-panel.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KSearchPanelComponent extends KEntityOrStaticBaseComponent implements IKEntityOrStaticComponent {
	innerValue!: string;

	allowAdd: boolean = false;
	allowDelete: boolean = false;
	allowModify: boolean = false;
	allowView: boolean = false;
	allowSearch: boolean = false;

	override ngOnInit(): void {
		super.ngOnInit();
		this.evaluateSearch();
	}

	evaluateSearch() {
		if (this.readonly) return;

		if (
			EnumHelper.is(this.node.optionId, SearchPanelControlOption.Change) ||
			this.node.optionId === SearchPanelControlOption.None
		) {
			this.allowSearch = true;
		}
	}

	evaluateEditAndDelete(valueExist: boolean) {
		// view and delete vannno valutati dopo
		// console.log('evaluateEditAndDelete', this.node.name, valueExist);

		if (this.readonly) {
			this.allowView =
				EnumHelper.is(this.node.optionId, SearchPanelControlOption.View) ||
				EnumHelper.is(this.node.optionId, SearchPanelControlOption.Edit);
			this.allowView = true;
			return;
		}

		this.allowModify = false;
		this.allowDelete = false;
		this.allowView = false;

		if (
			valueExist &&
			(EnumHelper.is(this.node.optionId, SearchPanelControlOption.View) ||
				this.node.optionId === SearchPanelControlOption.None)
		) {
			this.allowView = true;
		}

		if (
			valueExist &&
			(EnumHelper.is(this.node.optionId, SearchPanelControlOption.Edit) ||
				this.node.optionId === SearchPanelControlOption.None)
		) {
			this.allowModify = true;
		}

		if (
			valueExist &&
			(EnumHelper.is(this.node.optionId, SearchPanelControlOption.Delete) ||
				this.node.optionId === SearchPanelControlOption.None)
		) {
			this.allowDelete = true;
		}

		if (this.allowModify) {
			this.allowView = false;
		}

		if (
			!valueExist &&
			(EnumHelper.is(this.node.optionId, SearchPanelControlOption.Add) ||
				this.node.optionId === SearchPanelControlOption.None)
		) {
			this.allowAdd = true;
		}

		// console.log(`${this.node.name} readonly:${this.readonly} type:${ViewItemUiControl[this.node.controlType]} optionId:${this.node.optionId} : allowAdd: ${this.allowAdd} - allowView: ${this.allowView} - allowModify:${this.allowModify} - allowSearch: ${this.allowSearch} `);
	}

	reload: Subject<{ entityId: string; instanceId: string }> | null = null;
	reloadSubscription: Subscription | null = null;
	override onKeystoneInit() {
		this.reload = new Subject<{ entityId: string; instanceId: string }>();
		this.reloadSubscription = this.reload
			.pipe(
				switchMap((info: { entityId: string; instanceId: string }) => {
					return this.service.load(info.entityId, info.instanceId);
				}),
			)
			.subscribe((loadedHolder) => {
				if (loadedHolder) {
					this.container.updateTaskField(this.node.attributeName, [loadedHolder]);
					this.innerValue = loadedHolder?.humanReadableName;
					this.evaluateEditAndDelete(true);
					this.cdr.markForCheck();
				}
			});
	}

	override onValueReady(): void {
		// console.error(this.node.attributeName, this.value?.value);
		const holderOrId = this.value?.value;

		if (holderOrId == null) {
			this.innerValue = '';
			this.evaluateEditAndDelete(false);
			this.cdr.markForCheck();
			return;
		}

		if (holderOrId.instanceId) {
			this.innerValue = this.value?.value?.humanReadableName;
			this.evaluateEditAndDelete(true);
			this.cdr.markForCheck();
		} else if (holderOrId) {
			this.reload?.next({
				entityId: this.getTargetEntityId(),
				instanceId: holderOrId,
			});

			// this.service.load(this.getTargetEntityId(), maybeHolder).subscribe((loadedHolder) => {
			//   if (loadedHolder) {
			//     this.container.updateTaskField(this.node.attributeName, [loadedHolder]);
			//     this.innerValue = loadedHolder?.humanReadableName;
			//     this.evaluateEditAndDelete(true);
			//     this.cdr.markForCheck();
			//   }
			// });
		}
	}

	onContext(event: MouseEvent): void {
		event.preventDefault();
		event.stopPropagation();

		const menus = [];

		if (this.allowView) {
			menus.push(ContextMenuCommands.find((c) => c.id === KEntityBaseComponentMenuActions.View)!);
		}
		if (this.allowModify) {
			menus.push(ContextMenuCommands.find((c) => c.id === KEntityBaseComponentMenuActions.Edit)!);
		}
		if (this.allowAdd) {
			menus.push(ContextMenuCommands.find((c) => c.id === KEntityBaseComponentMenuActions.Add)!);
		}
		if (this.allowSearch) {
			menus.push(ContextMenuCommands.find((c) => c.id === KEntityBaseComponentMenuActions.Search)!);
		}
		if (this.allowDelete) {
			menus.push(ContextMenuCommands.find((c) => c.id === KEntityBaseComponentMenuActions.Delete)!);
		}

		const isDoc: boolean = EnumHelper.is(this.info?.logicalType, LogicalEntityType.Document);

		if (isDoc && this.value?.value != null) {
			const download = ContextMenuCommands.find((c) => c.id === KEntityBaseComponentMenuActions.Download)!;
			menus.unshift(download);
		}

		openContextMenu(this, event.clientX, event.clientY, this.node, false, menus);
	}

	override onView(payload: KContextPayloadCallback): void {
		const holder: EntityHolder = this.value?.value;

		if (holder) {
			if (!this.isMember) {
				this.openTask(this.getTargetEntityId(), holder.instanceId, TaskRelation.Parent, 'view');
			} else {
				this.container.createNewTaskAndRedirect(holder, this.node.attributeName, this.isMember, 'view');
			}
		}
	}

	// Funzionamento del "SearchPanel"
	override onEdit(payload: KContextPayloadCallback): void {
		const holder: EntityHolder = this.value?.value;

		if (holder) {
			if (!this.isMember) {
				this.openTask(this.getTargetEntityId(), holder.instanceId, TaskRelation.Parent, 'edit');
			} else {
				this.container.createNewTaskAndRedirect(holder, this.node.attributeName, this.isMember, 'edit');
			}
		}
	}

	override onAdd(payload: KContextPayloadCallback): void {
		this.getNewTargetEntity().subscribe((c) => {
			// console.log(c);
			this.fieldValueIsChanging(c, true);
			this.container.createNewTaskAndRedirect(c, this.node.attributeName, this.isMember, 'edit');
		});
	}

	override ngOnDestroy(): void {
		super.ngOnDestroy();
		this.reloadSubscription?.unsubscribe();
	}
}
