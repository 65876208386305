import { Type } from '@angular/core';
import { ViewItemFilterControl } from 'src/app/server/model/view-item-filter-control';
import { BooleanFilterComponent } from './boolean-filter/boolean-filter.component';
import { DateFilterComponent } from './date-filter/date-filter.component';
import { DateRangeFilterComponent } from './date-range-filter/date-range-filter.component';
import { FilterEditorBaseComponent } from './filter-editor-base.component';
import { LookUpFilterComponent } from './look-up-filter/look-up-filter.component';
import { NumberFilterComponent } from './number-filter/number-filter.component';
import { SearchPopUpFilterComponent } from './search-pop-up-filter/search-pop-up-filter.component';
import { TextFilterComponent } from './text-filter/text-filter.component';

export function evaluateFilterComponent(type: ViewItemFilterControl): Type<FilterEditorBaseComponent> {
	switch (type) {
		case ViewItemFilterControl.LookUp:
			return LookUpFilterComponent;

		case ViewItemFilterControl.Text:
			return TextFilterComponent;

		case ViewItemFilterControl.Number:
			return NumberFilterComponent;

		case ViewItemFilterControl.Date:
			return DateFilterComponent;

		case ViewItemFilterControl.DateRange:
			return DateRangeFilterComponent;

		case ViewItemFilterControl.Unit:
		case ViewItemFilterControl.Role:
		case ViewItemFilterControl.Factory:
		case ViewItemFilterControl.SearchPopUp:
			return SearchPopUpFilterComponent;

		case ViewItemFilterControl.Boolean:
			return BooleanFilterComponent;
	}

	throw new Error('Type is not supported:' + ViewItemFilterControl[type]);
}
