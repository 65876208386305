import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from './../../server/api.service';
import { GlobalService } from './global/global.service';
import { createUserFromResponse, User } from './user';

export const defaultPath = '/';

@Injectable()
export class AuthService {
	// private _user: User|null = null;
	// get loggedIn(): boolean {
	//   return !!this._user;
	// }

	// private _lastAuthenticatedPath: string = defaultPath;
	// set lastAuthenticatedPath(value: string) {

	//   this._lastAuthenticatedPath = value;

	// }

	private router: Router;
	private api: ApiService;
	private globalService: GlobalService;

	constructor(router: Router, api: ApiService, globalService: GlobalService) {
		this.router = router;
		this.api = api;
		this.globalService = globalService;
	}

	logIn(
		email: string,
		password: string,
	): Observable<{
		success: boolean;
		user: User | null;
		message: string | null;
		accessToken: string | null;
	}> {
		// TODO remove it
		email = email.split('@')[0];

		return this.api.login(email, password).pipe(
			map((response) => {
				if (response == null || !response.success) {
					return {
						success: false,
						user: null,
						message: response?.message ?? null,
						accessToken: null,
					};
				}

				return {
					success: true,
					user: createUserFromResponse(response),
					message: null,
					accessToken: response?.token ?? null,
				};
			}),
		);
	}

	async createAccount(email: string, password: string) {
		try {
			// Send request

			this.router.navigate(['/create-account']);
			return {
				isOk: true,
			};
		} catch {
			return {
				isOk: false,
				message: 'Failed to create account',
			};
		}
	}

	async changePassword(email: string, recoveryCode: string | null) {
		if (recoveryCode == null) {
			return {
				isOk: false,
				message: 'Missing recovery code',
			};
		}

		try {
			// Send request

			return {
				isOk: true,
			};
		} catch {
			return {
				isOk: false,
				message: 'Failed to change password',
			};
		}
	}

	async resetPassword(email: string) {
		try {
			// Send request

			return {
				isOk: true,
			};
		} catch {
			return {
				isOk: false,
				message: 'Failed to reset password',
			};
		}
	}

	async logOut() {}
}
