import { TimeSpan } from 'src/app/server/helpers/timespan';
import { EntityAttributeValueType } from 'src/app/server/model/entity-attribute-value-type';
import { EntityHolder } from 'src/app/server/model/entity-holder';

export enum ControlFormatEnum {
	None,
	Number,
	Decimal,
	DateLong,
	DateShort,
	TimeLong,
	TimeShort,
	Money,
	Fixed,
	DateTimeShort,
	DateTimeLong,
	Boolean,
	Hour,
	HourAndMinute,
}

export function toCurrency(value: number): string {
	const formatter = new Intl.NumberFormat('it-IT', {
		style: 'currency',
		currency: 'EUR',
	});

	return formatter.format(value);
}

const dateTimeFormats = {
	dateFormat: new Intl.DateTimeFormat('it-IT', {
		day: 'numeric',
		month: 'numeric',
		year: 'numeric',
	}),
	timeFormat: new Intl.DateTimeFormat('it-IT', {
		hour: 'numeric',
		minute: 'numeric',
	}),
};

export function ToKString(
	v: any,
	type: EntityAttributeValueType,
	format: string | null = null,
	formatEnum: ControlFormatEnum | null = null,
): string {
	if (v instanceof EntityHolder) {
		return v.humanReadableName;
	} else {
		let d: any;
		let t: TimeSpan;

		// AttributeHolderHelper.ToText
		switch (type) {
			case EntityAttributeValueType.Decimal:
				//console.log(v);
				if (formatEnum === ControlFormatEnum.Money) return toCurrency(v);
				else return v;

			case EntityAttributeValueType.Boolean:
				return !v ? 'Si' : 'No';
			case EntityAttributeValueType.Date:
				if (v) {
					d = new Date(v);
					return dateTimeFormats.dateFormat.format(d); // d.toLocaleDateString('it-IT');
				} else {
					return '';
				}
			case EntityAttributeValueType.DateTime:
				if (v) {
					d = new Date(v);
					return dateTimeFormats.dateFormat.format(d);
				} else {
					return '';
				}

			case EntityAttributeValueType.Time:
				d = new Date(v);
				return dateTimeFormats.timeFormat.format(d);
			case EntityAttributeValueType.TimeSpan:
				t = TimeSpan.parse(v ?? '00:00');
				if (format === '{0:H}') {
					return t.totalHours.toLocaleString();
				} else if (format === '{0:HM}') {
					return t.totalHours.toLocaleString().padStart(2, '0') + ':' + t.minutes.toLocaleString().padStart(2, '0');
				} else if (format === '{0:t}') {
					return t.hours.toLocaleString().padStart(2, '0') + ':' + t.minutes.toLocaleString().padStart(2, '0');
				} else {
					return t.totalHours.toLocaleString().padStart(2, '0') + ':' + t.minutes.toLocaleString().padStart(2, '0');
				}

			default:
				return v;
		}
	}
}

export function timeSpanToString(newValue: string) {
	let h = parseInt(newValue.substr(0, 2), 0);
	const m = parseInt(newValue.substr(2, 2));
	let d = 0;
	const s = 0;

	if (h > 23) {
		d = Math.floor(h / 24);
		h = h % 24;
	}

	return `${d}.${h}:${m}:${s}`;
}

export function SetKString(newValue: string, type: EntityAttributeValueType, format: string | null = null): any {
	switch (type) {
		case EntityAttributeValueType.TimeSpan:
			return timeSpanToString(newValue);

		case EntityAttributeValueType.Text:
			return newValue;
		case EntityAttributeValueType.Decimal:
			return parseFloat(newValue);
		case EntityAttributeValueType.Int:
			return parseInt(newValue);

		default:
			return newValue;
	}
}

export enum NumberFormats {
	IntKSeparators = '#,##0',
	IntNoKSeparators = '#0',

	MoneyKSeparators = '#,##0.## €',
	Float2DgtKSeparators = '#,##0.##',
	Float4DgtKSeparators = '#,##0.####',
}

export function date2ddmmyyyy(inputDate: Date | null): string | null {
	if (inputDate) {
		let date, month, year;
		date = inputDate.getDate();
		month = inputDate.getMonth() + 1;
		year = inputDate.getFullYear();

		date = date.toString().padStart(2, '0');
		month = month.toString().padStart(2, '0');
		return `${date}/${month}/${year}`;
	} else return null;
}
