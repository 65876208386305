import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ComponentFactoryResolver,
	OnDestroy,
	OnInit,
	ViewChild
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { EntityService } from 'src/app/server/entity.service';
import { KnowEntityIds } from 'src/app/server/helpers/constants';
import { CacheService } from 'src/app/shared/caching/cache-service';
import { GlobalService } from 'src/app/shared/services';
import { KTaskStateService } from '../../../server/kform-data-state.service';
import { HostDirective } from '../../shared/host.directive';
import { KContextPayload } from '../../shared/kcontext-menu/kcontext-payload';
import { KEditTaskComponent } from './../ktask/edit/kedit-task.component';
import { KSearchTaskComponent } from './../ktask/search/ksearch-task.component';
import { KTaskBarEvent, KTaskBarEventType } from './ktaskbar-event';
import { KTaskbarService } from './ktaskbar.service';
import { Task } from './task';

@Component({
	selector: 'app-ktaskbar',
	templateUrl: './ktaskbar.component.html',
	styleUrls: ['./ktaskbar.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KTaskbarComponent implements OnInit, OnDestroy {
	@ViewChild(HostDirective, { static: true }) host?: HostDirective;
	private componentFactoryResolver: ComponentFactoryResolver;

	taskService: KTaskbarService;
	cache: CacheService;
	service: EntityService;
	state: KTaskStateService;
	global: GlobalService;

	cdr: ChangeDetectorRef;
	route: ActivatedRoute;
	router: Router;

	tasks: Task[] = [];
	selectedIndex: number = -1;
	selectedItem: Task | null = null;
	subscription: Subscription | null = null;

	constructor(
		cdr: ChangeDetectorRef,
		cache: CacheService,
		route: ActivatedRoute,
		service: EntityService,
		router: Router,
		global: GlobalService,
		state: KTaskStateService,
		componentFactoryResolver: ComponentFactoryResolver,
		taskService: KTaskbarService,
	) {
		this.cdr = cdr;
		this.cache = cache;
		this.route = route;
		this.service = service;
		this.router = router;
		this.global = global;
		this.state = state;
		this.componentFactoryResolver = componentFactoryResolver;
		this.taskService = taskService;

		this.subscription = this.taskService.trigger.subscribe((r) => {
			this.onEvent(r);
		});
	}

	onEvent(r: KTaskBarEvent) {
		if (r.type === KTaskBarEventType.TaskClosed) {
			// console.log(KTaskBarEventType[r.type], r);
			this.selectedIndex = r.payload.index;
			this.selectedItem = this.tasks[this.selectedIndex];
			return;
		}

		const i = this.selectedIndex;
		this.tasks = this.taskService.tasks;
		this.selectedIndex = i;
		this.selectedItem = this.tasks[i];
		this.cdr.markForCheck();
	}

	ngOnInit(): void {
		this.cache.init().then((r) => {
			this.route.params
				.pipe(switchMap((params) => this.taskService.init(params)))
				.subscribe((arg) => {
					this.tasks = arg.tasks;
					this.selectedIndex = arg.selectedIndex;
					if (arg.action) {
						this.loadEditor(arg.action, arg.entityId).then((succed) => {
							this.cdr.markForCheck();
						});
					} else {
						this.cdr.markForCheck();
					}
				});
		});
	}

	loadEditor(action: string, entityId: string | null): Promise<boolean> {
		if (this.host) {
			const viewContainerRef = this.host.viewContainerRef;
			viewContainerRef.clear();

			return this.loadRightType(action, entityId).then((type) => {
				viewContainerRef.createComponent(type);
				return true;
			});
		}

		return new Promise(() => false);
	}

	loadRightType(action: string, entityId: string | null): Promise<any> {
		switch (action) {
			case 'view':
			case 'edit':
				if (entityId == KnowEntityIds.WorkflowInstanceMonitor) {
					return import('src/app/modules/workflow/workflow.module').then(
						({ WorkflowModule }) => {
							return import(
								'src/app/modules/workflow/ui/components/kworkflow-instance-monitor-detail/kworkflow-instance-monitor-detail.component'
							).then(
								({
									KWorkflowInstanceMonitorDetailComponent:
									KWorkflowInstanceMonitorDetailComponent,
								}) => {
									return KWorkflowInstanceMonitorDetailComponent;
								},
							);
						},
					);
				}

				return new Promise((resolve, reject) => resolve(KEditTaskComponent));
			case 'search':
				return new Promise((resolve, reject) => resolve(KSearchTaskComponent));

			case 'workflow':
				if (entityId == KnowEntityIds.Workflow) {
					return import('src/app/modules/workflow/workflow.module').then(
						({ WorkflowModule }) => {
							return import(
								'src/app/modules/workflow/ui/components/kworkflow-instance-task/kworkflow-instance-task.component'
							).then(({ KWorkflowInstanceTaskComponent }) => {
								return KWorkflowInstanceTaskComponent;
							});
						},
					);
				} else if (entityId == KnowEntityIds.WorkflowInstanceMonitor) {
					return import('src/app/modules/workflow/workflow.module').then(
						({ WorkflowModule }) => {
							return import(
								'src/app/modules/workflow/ui/components/kworkflow-instance-monitor/kworkflow-instance-monitor.component'
							).then(({ KWorkflowInstanceMonitorComponent }) => {
								return KWorkflowInstanceMonitorComponent;
							});
						},
					);
				}

				break;

			case 'dashboard':
				return import('src/app/modules/charts/charts.module').then(
					({ ChartsModule }) => {
						return import(
							'src/app/modules/charts/ui/dashboard/kdashboard-task.component'
						).then(({ KDashboardTaskComponent }) => {
							return KDashboardTaskComponent;
						});
					},
				);

			case 'todolist':
				return import('src/app/modules/todo/todo.module').then(
					({ TodoModule }) => {
						return import(
							'src/app/modules/todo/ui/components/ktodo-task-list/ktodo-task-list.component'
						).then(({ KTodoTaskListComponent }) => {
							return KTodoTaskListComponent;
						});
					},
				);
			case 'todo':
				return import('src/app/modules/todo/todo.module').then(
					({ TodoModule }) => {
						return import(
							'src/app/modules/todo/ui/components/ktodo-task/ktodo-task.component'
						).then(({ KTodoTaskComponent }) => {
							return KTodoTaskComponent;
						});
					},
				);
		}

		return new Promise(() => null);
	}

	onContext(event: MouseEvent, task: Task): void {
		event.preventDefault();
		event.stopPropagation();

		const payload: KContextPayload = Object.assign(new KContextPayload(), {
			x: event.clientX,
			y: event.clientY,
			items: [
				{ id: 'tbr-close', text: 'Chiudi', icon: 'close' },
				{ id: 'tbr-closeall', text: 'Chiudi tutti', icon: 'clear' },
				{
					id: 'tbr-notThis',
					text: 'Chiudi tutti tranne questo',
					icon: 'clear',
				},
				{ id: 'tbr-force', text: 'Chiudi forzatamente il task', icon: 'clear' },
			],
			target: task,
		});

		this.taskService.on(KTaskBarEventType.ShowContextMenu, payload);
	}

	selectTab(event: any): void {
		this.selectedIndex = event.itemIndex;
		const task = Task.fromRaw(event.itemData);
		this.taskService.selectTab(task);
	}

	onCloseTab(task: Task, event: MouseEvent): void {
		event.preventDefault();
		event.stopPropagation();
		this.taskService.closeTab(task);
		this.cdr.markForCheck();
	}

	onTabDragStart(e: any): void {
		e.itemData = e.fromData[e.fromIndex];
	}

	onTabDrop(e: any): void {
		e.fromData.splice(e.fromIndex, 1);
		e.toData.splice(e.toIndex, 0, e.itemData);
		this.taskService.updateTasks(this.tasks);
		this.taskService.ruoteToTask(this.tasks[e.toIndex]);
	}

	ngOnDestroy(): void {
		this.subscription?.unsubscribe();
	}
}
