import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Observable, forkJoin, of } from 'rxjs';
import { EntityHolder } from 'src/app/server/model/entity-holder';
import { DatagridClickEventArg } from '../../controls/datagrid/datagrid-click-event-args';
import { DatagridService } from '../../controls/datagrid/datagrid.service';
import {
	KTaskBarEvent,
	KTaskBarEventType,
} from '../../manager/ktaskbar/ktaskbar-event';
import { createSearchKeyForDialog } from '../../manager/ktaskbar/manager-functions';
import { Task } from '../../manager/ktaskbar/task';
import { KSearchDialogComponent } from '../../shared/ksearch-dialog/ksearch-dialog.component';
import { KSearchDialogPopUpDeletePayload } from './../../shared/ksearch-dialog/ksearch-dialog-close-payload';
import { KSearchData, SearchArg } from './ksearch-data';
import { KSearchComponent } from './ksearch.component';

@Component({
	selector: 'app-ksearch-in-dialog',
	templateUrl: './ksearch.component.html',
	styleUrls: ['./ksearch.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [DatagridService],
})
export class KSearchInDialogComponent extends KSearchComponent {
	@Input() dialog!: KSearchDialogComponent;
	@Input() override task!: Task;

	close() {
		this.dialog.close();
	}

	override onSelect() {
		this.dialog.closeWithPayLoad(
			this.task.parentTaskId,
			this.task.attributeName,
			this.selectedRows,
		);
	}

	override onQueryTap(arg: SearchArg): Observable<EntityHolder[]> {
		// console.log('requirePostSearchAnalisys');
		return this.service.postSearchAnalisys(
			arg.searchData.info.id,
			this.dialog!.caller,
			arg.response?.results ?? [],
			this.dialog!.excludeFilter,
			arg.searchData.columns,
		);
	}

	override createSearchKey(task: Task): string {
		return createSearchKeyForDialog(task);
	}

	override onAddExtraFilters(data: KSearchData | null) {
		this.multiselect = this.dialog.multiselect;
		data?.addExtraFilters(this.dialog.extraFilters);
	}

	override onCellClick(arg: DatagridClickEventArg): void {
		// if (!this.option.multiselect) {
		//   // va caricata dal DB perchè quella nella grid è risolta "_" e cosi via
		//   this.service.load(arg.row.entityId, arg.row.instanceId)
		//     .subscribe(c => {
		//       if (c) {
		//         console.log(this.task);
		//           this.dialog!.closeWithPayLoad(this.task.parentTaskId, this.task.attributeName, [c]);
		//       } else {
		//         console.error(`L'entity ${arg.row.entityId} con id:${arg.row.instanceId} è nel indice forse ma non nel DB sicuro`);
		//       }
		//     });
		// }
	}

	override search(searchData: KSearchData): Observable<EntityHolder[]> {
		return of({
			searchData: searchData,
			caller: this.dialog ? this.dialog.caller : null,
			isForNew: this.dialog ? this.dialog.isForNew : false,
			excludeFilter: this.dialog ? this.dialog?.excludeFilter : false,
			response: null,
			requireDynamicFilters: this.dialog
				? searchData.info.metadata.requireDynamicFilters
				: false,
			requirePostSearchAnalisys: this.dialog
				? searchData.info.metadata.requirePostSearchAnalisys
				: false,
		}).pipe((b) => this.searchPipe(b));
	}

	override evaluateOptions() {
		this.option.multiselect = this.dialog.multiselect;

		if (this.option.multiselect) {
			this.option.selectionMode = 'multiple';
		} else {
			this.option.selectionMode = 'single';
		}

		this.option.evaluateNumberOfIcons();
	}

	override handleEvents(event: KTaskBarEvent): void {
		switch (event.type) {
			case KTaskBarEventType.ShowDialog:
				this.onInDialogRefresh();
				break;

			case KTaskBarEventType.SearchClose:
				this.close();
				break;

			case KTaskBarEventType.SearchPopUpDelete:
				if (event.payload instanceof KSearchDialogPopUpDeletePayload) {
					const toDeletes = event.payload.entities.map((x) =>
						this.manager.service.delete(x.entityId, x.instanceId),
					);

					forkJoin(toDeletes)
						.toPromise()
						.then(() => {
							this.onInDialogRefresh();
						});
				}

				break;

			case KTaskBarEventType.SearchSelect:
				this.onSelect();
				break;
			default:
				super.handleEvents(event);
		}
	}
}
