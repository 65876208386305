import { Component } from '@angular/core';
import { FieldValue } from 'src/app/server/model/field-value';
import { KAttributeBaseComponent } from '../kbase/kattribute-base.component';

@Component({
	selector: 'app-ktext',
	templateUrl: './ktext.component.html',
	styleUrls: ['./ktext.component.scss'],
})
export class KTextComponent extends KAttributeBaseComponent {
	override isDecorative = true;
	innerValue = '';

	override setValue(value: FieldValue | null): void {
		super.setValue(value);
		this.innerValue = this.node.description ?? '';
	}
}
