import { SearchParameter } from 'src/app/server/model/search-parameter';
import { Task } from '../../manager/ktaskbar/task';
import { EntityHolder } from './../../../server/model/entity-holder';
import { IdName } from './../../../server/model/id-name';

export type FormAction =
	| 'add'
	| 'save'
	| 'undo'
	| 'close'
	| 'favourite'
	| 'print'
	| 'clone'
	| 'delete'
	| 'status'
	| 'select'
	| 'model'
	| 'query';

export class KToolbarPayload {
	type: FormAction;
	task: Task;
	refs: EntityHolder[];
	payload: any;
	constructor(type: FormAction, task: Task, refs: EntityHolder[] = [], payload: any = null) {
		this.type = type;
		this.task = task;
		this.refs = refs;
		this.payload = payload;
	}
}

export class KToolbarSelectorload {
	entityId: string;
	filters: SearchParameter[];
	title: string;
	fromParent: boolean;

	constructor(entityId: string, title: string, filters: SearchParameter[], fromParent: boolean) {
		this.entityId = entityId;
		this.filters = filters;
		this.title = title;
		this.fromParent = fromParent;
	}
}

export class KToolbarStatusPayload {
	status: IdName;
	task: Task;

	constructor(task: Task, status: IdName) {
		this.task = task;
		this.status = status;
	}
}
