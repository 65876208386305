import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { KTaskBarEventType } from '../../manager/ktaskbar/ktaskbar-event';
import { KSearchCompontentBase } from '../ksearch/ksearch-base.component';
import { KSearchCompletePayload } from '../ksearch/ksearch-complete-payload';
import { KItem } from './../../shared/kitem';
import { KQuickFilterPayload } from './kquickfilter-payload';

@Component({
	selector: 'app-kquick-filters',
	templateUrl: './kquick-filters.component.html',
	styleUrls: ['./kquick-filters.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KQuickFiltersComponent
	extends KSearchCompontentBase
	implements OnInit
{
	items: any[] = [];

	override onSearchReady(payload: KSearchCompletePayload): void {
		this.items = payload.data.quickFiltersItems;
		this.cdr.markForCheck();
	}

	onSelectionChange(event: any): void {
		const filters: KItem[] = event.component
			.getSelectedNodes()
			.map((node: any) => node.itemData);

		this.manager.on(
			KTaskBarEventType.QuickFilterChange,
			new KQuickFilterPayload(filters),
		);
	}

	onContentReady(event: any): void {}
}
