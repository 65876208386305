import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EntityService } from '../server/entity.service';
import { CacheService } from '../shared/caching/cache-service';

@Component({
	template: '',
})
export class KPageComponent implements OnInit {
	constructor(
		protected cache: CacheService,
		protected route: ActivatedRoute,
		protected service: EntityService,
		protected router: Router,
	) {}

	ngOnInit(): void {
		this.cache.init().then(() => this.kInit());
	}

	kInit(): void {}
}
