import { GenericHelper } from 'src/app/server/helpers/generic-helper';
import { GlobalTitle } from './global-title';

export class GlobalTitleChangeArg {
	title: string;
	icon: string;
	constructor(title: GlobalTitle) {
		this.title = title.title;
		this.icon = GenericHelper.toIcon(title.entityId);
	}
}
