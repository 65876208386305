import { IdName } from './id-name';
import { ViewResultNode } from './view-result.node';

export class PrintView extends IdName {
	isDefault: boolean = false;
}
export class ResultView {
	id = '';
	// isDefault = false;
	name = '';
	nodes: ViewResultNode[] = [];

	constructor(raw: any) {
		this.id = raw.id;
		this.name = raw.name;

		raw.nodes.forEach((element: any) => {
			this.nodes.push(Object.assign(new ViewResultNode(), element));
		});
	}
}
