import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FieldValue } from 'src/app/server/model/field-value';
import { KAttributeBaseComponent } from '../kbase/kattribute-base.component';

@Component({
	selector: 'app-khtml-editor',
	templateUrl: './khtml-editor.component.html',
	styleUrls: ['./khtml-editor.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KHtmlEditorComponent extends KAttributeBaseComponent {
	innerValue = '';
	height: string = '3em';

	override setValue(value: FieldValue | null): void {
		this.height = 8 + this.node.rowSpan + 'em';
		//this.height = (3 + (this.node.rowSpan * 3)) + 'em';

		super.setValue(value);
		// console.log(value?.value);
		this.innerValue = value?.value;
	}

	onValueChange(newHtml: string) {
		if (this.readonly) return;
		super.fieldValueIsChanging(newHtml);
	}
}
