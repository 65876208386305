import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { switchMap } from 'rxjs/operators';
import { EnumHelper } from 'src/app/server/helpers/enum-helper';
import { IdName } from 'src/app/server/model/id-name';
import { LogicalAttributeType } from 'src/app/server/model/logical-attribute-type';
import { KFilterEditorComponent } from '../kfilter-editor.component';
import { EntityService } from './../../../../server/entity.service';
import { FilterEditorBaseComponent } from './filter-editor-base.component';

@Component({
	selector: 'app-select-filter-base',
	template: '',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectBoxFilterEditorBaseComponent extends FilterEditorBaseComponent {
	values: IdName[] = [];

	constructor(parent: KFilterEditorComponent, service: EntityService, cdr: ChangeDetectorRef) {
		super(parent, cdr);

		const isCollection = EnumHelper.is(parent.attribute?.logicalType, LogicalAttributeType.Collection);

		if (isCollection) {
			service
				.getAggregateInfos(parent.selectedFilter.refEntityId!)
				.pipe(switchMap((tei) => service.search(tei.mainEntityId, null, null, 0, 1000, [], false)))
				.subscribe((result) => {
					this.values = result!.results.map((d) => new IdName(d.instanceId, d.humanReadableName));

					if (!this.value && this.values.length > 0) {
						this.value = this.values[0].id;
					}

					// console.log(this.values, this.value);
					this.cdr.markForCheck();
				});
		} else {
			// console.log(isCollection);

			if (parent.attribute?.values?.length > 0) {
				this.values = parent.attribute.values;

				if (!this.value && this.values.length > 0) {
					this.value = this.values[0].id;
				}
			} else {
				service.search(parent.selectedFilter.refEntityId!, null, null, 0, 1000, [], false).subscribe((result) => {
					this.values = result!.results.map((d) => new IdName(d.instanceId, d.humanReadableName));

					if (!this.value && this.values.length > 0) {
						this.value = this.values[0].id;
					}

					// console.log(this.values, this.value);
					this.cdr.markForCheck();
				});
			}
		}
	}

	override ngOnInit(): void {}

	override onValueChanged(e: { value: string; previousValue: string }) {
		//  console.log(e);

		this.parent.filter!.value = e.value;
		this.parent.filter!.valueHr = this.values.find((c) => c.id === e.value)?.name ?? '';
		this.parent.filter!.label = this.parent.selectedFilter.name;
	}
}
