import { ApiResponse } from './api.response';

export class LoginResponse extends ApiResponse {
	userName!: string;
	userEmail!: string;
	token!: string;
	userId!: string;
	accountId!: string;
	userRoles: string[] = [];
	mainProfile!: string;
}
