import { EntityHolder } from 'src/app/server/model/entity-holder';
import { SearchParameter } from '../../../server/model/search-parameter';
import { TaskAction } from '../../manager/ktaskbar/task';

export class KSearchDialogPayload {
	public action: TaskAction;
	public entityId: string;
	public parentTaskId: string; // non so se serve ancora
	public attributeName: string;
	public filters: SearchParameter[];
	public multiselect: boolean;
	public reference: any;
	excludeFilter: boolean = false;
	isForNew: boolean = false;
	caller: EntityHolder | null = null;
	popUpDelete: boolean = false;
	constructor(
		action: TaskAction = 'search',
		entityId: string,
		parentTaskId: string,
		attributeName: string,
		filters: SearchParameter[],
		multiselect: boolean,
		reference: any,
		excludeFilter: boolean,
		isForNew: boolean,
		caller: EntityHolder | null,
		popUpDelete: boolean,
	) {
		this.action = action;
		this.entityId = entityId;
		this.parentTaskId = parentTaskId;
		this.attributeName = attributeName;
		this.filters = filters;
		this.multiselect = multiselect;
		this.reference = reference;
		this.excludeFilter = excludeFilter;
		this.isForNew = isForNew;
		this.caller = caller;
		this.popUpDelete = popUpDelete;
	}
}
