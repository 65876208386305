import { Component } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { AdvancedData } from 'src/app/server/model/advanced-data';
import { EntityHolder } from 'src/app/server/model/entity-holder';
import { FieldValue } from 'src/app/server/model/field-value';
import { KSearchPanelComponent } from './ksearch-panel.component';

@Component({
	selector: 'app-klink',
	templateUrl: './ksearch-panel.component.html',
	styleUrls: ['./ksearch-panel.component.scss'],
})
export class KLinkComponent extends KSearchPanelComponent {
	refresh!: Subject<{ data: AdvancedData; holder: EntityHolder }>;
	refreshSubscription: Subscription | null = null;
	override onKeystoneInit() {
		if (!this.refresh) {
			this.refresh = new Subject<{
				data: AdvancedData;
				holder: EntityHolder;
			}>();
			this.refreshSubscription = this.refresh
				.pipe(
					switchMap((arg) => this.service.getLinkHolder(arg.data, arg.holder)),
					map((e) => {
						var fv = new FieldValue();
						fv.value = EntityHolder.parse(e);
						fv.entityId = this.node.advancedData2.entityId!;
						fv.format = '{0}';
						fv.name = this.node.name;
						return fv;
					}),
				)
				.subscribe((r) => {
					super.setValue(r);
					this.onValueReady();
				});
		}
	}

	override ngOnInit(): void {
		super.ngOnInit();
		this.allowSearch = false;

		var parts = this.node.description?.split('|') ?? [];

		if (parts.length == 2) {
			this.label = parts[0];
			this.hint = parts[1];
		}
	}

	override ngOnDestroy(): void {
		super.ngOnDestroy();
		this.refreshSubscription?.unsubscribe();
	}

	override setValue(value: FieldValue | null): void {
		this.refresh.next({
			data: this.node.advancedData2,
			holder: this.container.holder,
		});
	}

	override onValueReady(): void {
		if (this.value?.value) {
			this.innerValue = this.value?.value?.humanReadableName;
		}

		this.allowModify = false;
		this.allowDelete = false;
		this.allowView = true;

		this.cdr.markForCheck();
	}
}
