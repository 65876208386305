<app-kcomponent-container
	[label]="isCompact ? null : label"
	[mandatory]="mandatory"
	[description]="description"
	(contextmenu)="onContext($event)"
	[title]="description"
>
	<dx-drop-down-box
		[value]="innerValue"
		placeholder="Non selezionato"
		[showClearButton]="allowNull"
		displayExpr="text"
		labelMode="static"
		valueExpr="id"
		[label]="isCompact ? label! : null!"
		[dataSource]="nodes"
		[readOnly]="readonly"
		(onValueChanged)="onClearPress($event)"
	>
		<div *dxTemplate="let data of 'content'">
			<dx-tree-view
				[dataSource]="nodes"
				keyExpr="id"
				displayExpr="text"
				[selectionMode]="selectionMode"
				showCheckBoxesMode="normal"
				[selectByClick]="true"
				(onItemSelectionChanged)="onValueChanged($event)"
			>
			</dx-tree-view>
		</div>
		<dx-validator (onOptionChanged)="onValidationChange($event)">
			<dxi-validation-rule *ngIf="mandatory" type="required" message="Campo obbligatorio"></dxi-validation-rule>
		</dx-validator>
	</dx-drop-down-box>
</app-kcomponent-container>
