import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	forwardRef,
	Input,
	OnDestroy,
	OnInit
} from '@angular/core';
import { Subscription, throwError } from 'rxjs';
import { Command, FormEntry } from 'src/app/server/model/commad';
import { statuses } from 'src/app/server/model/entity-metadata';
import { KSearchDialogComponent } from 'src/app/ui/shared/ksearch-dialog/ksearch-dialog.component';
import { KEditTaskComponent } from '../../manager/ktask/edit/kedit-task.component';
import { KTaskComponent } from '../../manager/ktask/ktask.component';
import { KTaskBarEventType } from '../../manager/ktaskbar/ktaskbar-event';
import { Task, TaskRelation } from '../../manager/ktaskbar/task';
import { KCommandDialogPayload } from '../../shared/kcommand-dialog/kcommand-dialog-payload';
import { createNewButton } from '../../shared/new-button';
import { createSaveButton } from '../../shared/save-button';
import { KCommandPayload } from '../kcomponents/kbase/kcommand-payload';
import { KFormFieldRefreshPayload } from '../kcomponents/kbase/kfield-refresh-payload';
import { KFieldValueChangePayload } from '../kcomponents/kbase/kfield-Value-change-payload';
import { KPrintPayload, KRelatedPayload } from '../kcomponents/kbase/krelated-payload';
import { KFormData } from '../kform/kform-data';
import { EnumHelper } from './../../../server/helpers/enum-helper';
import { EntityStatusStateStatus } from './../../../server/model/entity-metadata';
import { LogicalEntityType } from './../../../server/model/logical-entity-type';
import { KTaskbarService } from './../../manager/ktaskbar/ktaskbar.service';
import { KToolbarPayload, KToolbarStatusPayload } from './ktoolbar-payload';

@Component({
	selector: 'app-ktoolbar',
	templateUrl: './ktoolbar.component.html',
	styleUrls: ['./ktoolbar.component.scss'],
	providers: [
		{
			provide: KTaskComponent,
			useExisting: forwardRef(() => KEditTaskComponent),
		},
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KToolbarComponent implements OnInit, OnDestroy {
	// @Input() entityId = '';
	// @Input() id = '';

	currentStatusOptions: any;
	statusBeforeOptions: any;
	statusAfterOptions: any;

	newButtonOptions: any;
	saveButtonOptions: any;
	versionButtonOptions: any;
	toolsMenuOptions: any;
	clonaButtonOptions: any;
	deleteButtonOptions: any;
	downloadButtonOptions: any;
	favoriteButtonOptions: any;

	datasource: any;
	closeButtonOptions: any;
	isDialog = false;
	isNew = false;
	isChanged = false;
	isFav = false;
	isDoc: boolean = false;
	isModel: boolean = false;
	isVersionable: boolean = false;
	undoButtonOptions: any;
	subscription: Subscription | null = null;
	readOnly: boolean = false;
	private manager: KTaskbarService;
	private cdr: ChangeDetectorRef;
	canBeGeneratedByModel: boolean = false;
	isMultiInheritance: boolean = false;
	saveIsButton: boolean = false;

	task!: Task;
	@Input() dialog: KSearchDialogComponent | null = null;

	constructor(manager: KTaskbarService, cdr: ChangeDetectorRef) {
		this.manager = manager;
		this.cdr = cdr;
		this.task = this.manager.task!;
	}

	ngOnDestroy(): void {
		this.subscription?.unsubscribe();
	}

	ngOnInit(): void {
		this.isNew = this.task.instanceId == null;
		this.readOnly = this.task.action === 'view';
		this.isDialog = this.dialog != null;

		this.subscription = this.manager.trigger.subscribe((c) => {
			if (c.type === KTaskBarEventType.FieldValueChange) {
				if (c.payload instanceof KFieldValueChangePayload) {
					this.isChanged = c.payload.pendingChanges > 0;
					this.cdr.markForCheck();
				}
			} else if (c.type === KTaskBarEventType.FormFieldRefresh) {
				if (c.payload instanceof KFormFieldRefreshPayload) {
					this.isChanged = c.payload.pendingChanges > 0;
					this.cdr.markForCheck();
				}
			} else if (c.type === KTaskBarEventType.FormSave) {
				this.isChanged = false;
				this.cdr.markForCheck();
			} else if (c.type === KTaskBarEventType.FavIsChanged) {
				this.isFav = c.payload;

				// todo
				this.favoriteButtonOptions = this.createFav(this.isFav);

				// console.log(this.favoriteButtonOptions.type);
				this.cdr.detectChanges();
			} else if (c.type === KTaskBarEventType.Ready && c.payload instanceof KFormData) {
				// console.log(c.payload);
				this.isChanged = (c.payload.data?.history?.length ?? 0) > 0;
				this.isFav = c.payload.isFav;
				this.isDoc = EnumHelper.is(c.payload.info?.logicalType, LogicalEntityType.Document);
				this.isVersionable = EnumHelper.is(c.payload.info?.logicalType, LogicalEntityType.Versionable);
				this.canBeGeneratedByModel = c.payload.info?.canBeGeneratedByModel ?? false;
				this.isMultiInheritance = c.payload.info?.isMultiInheritance ?? false;
				this.isModel = c.payload.data?.isModel ?? false;
				this.datasource = [];

				if (c.payload.info?.metadata && c.payload.status) {
					const sC = statuses(c.payload.info?.metadata, c.payload.status);

					this.statusBeforeOptions = {
						onItemClick: (e: any) => {
							e.event.preventDefault();
							e.event.stopPropagation();
							this.excuteChangeState(e.itemData as EntityStatusStateStatus);
						},
						visible: sC.prevs.length > 0,
						displayExpr: 'name',

						showFirstSubmenuMode: 'onHover',
						dataSource: [
							{
								icon: 'revert',
								name: 'Ritorna',
								hint: 'Ritorna',
								displayExpr: 'name',
								items: sC.prevs,
							},
						],
					};

					this.currentStatusOptions = {
						// icon: 'save',
						type: 'success',
						text: sC.current.label,
					};

					this.statusAfterOptions = {
						visible: sC.nexts.length > 0,
						displayExpr: 'name',

						showFirstSubmenuMode: 'onHover',
						onItemClick: (e: any) => {
							e.event.preventDefault();
							e.event.stopPropagation();

							this.excuteChangeState(e.itemData as EntityStatusStateStatus);
						},
						dataSource: [
							{
								icon: 'redo',
								name: 'Avanza',
								hint: 'Avanza',
								items: sC.nexts.map((item) => {
									return {
										icon: 'redo',
										...item,
									};
								}),
							},
						],
					};
				}

				this.closeButtonOptions = {
					icon: 'close',
					hint: 'Chiudi',
					// type: 'success',
					onClick: () => {
						this.manager.on(KTaskBarEventType.FormClose, new KToolbarPayload('close', this.task));
					},
				};

				this.undoButtonOptions = {
					icon: 'revert',
					hint: 'Annulla modifiche',
					disabled: true, // broken
					// type: 'success',
					onClick: () => {
						this.manager.on(KTaskBarEventType.FormUndo, new KToolbarPayload('undo', this.task));
					},
				};

				this.newButtonOptions = createNewButton(
					this.manager,
					c.payload.info!.id,
					this.canBeGeneratedByModel,
					this.isMultiInheritance,
					c.payload.info?.parentId,
				);

				this.saveIsButton = !this.canBeGeneratedByModel && this.task.relation !== TaskRelation.None;
				// console.log(this.canBeGeneratedByModel, this.task.relation !== TaskRelation.None);

				this.saveButtonOptions = createSaveButton(
					this.manager,
					c.payload.info!.id,
					this.canBeGeneratedByModel,
					this.task.relation === TaskRelation.None,
				);

				this.favoriteButtonOptions = this.createFav(this.isFav);

				this.versionButtonOptions = {
					icon: 'exportselected',
					hint: 'Crea nuova versione',
					onClick: () => {
						var command = new Command();
						command.formEntries = [new FormEntry()];

						this.manager.on(KTaskBarEventType.ShowCommandDialog, new KCommandDialogPayload(command));
					},
				};

				this.clonaButtonOptions = {
					icon: 'copy',
					hint: 'Crea una copia e apri per la modifica',
					onClick: () => {
						this.manager.on(KTaskBarEventType.FormClone, new KToolbarPayload('clone', this.task, [c.payload.data]));
					},
				};

				if (this.isDoc) {
					this.downloadButtonOptions = {
						icon: 'pdffile',
						hint: 'Esporta PDF',
						// type: 'danger',
						onClick: (e: any) => {
							this.manager.on(KTaskBarEventType.FileDownload, {});
						},
					};
				}

				this.deleteButtonOptions = {
					icon: 'trash',
					type: 'danger',
					hint: 'Elimina',
					stylingMode: 'outlined',
					onClick: () => {
						if (confirm("Sicuro di voler cancellare l'elemento corrente?")) {
							this.manager.on(KTaskBarEventType.FormDelete, new KToolbarPayload('delete', this.task, [c.payload.data]));
						}
					},
				};

				this.toolsMenuOptions = {
					displayExpr: 'name',
					showFirstSubmenuMode: 'onHover',
					onItemClick: (e: any) => {
						e.event.preventDefault();
						e.event.stopPropagation();

						if (e.itemData.isDefault !== undefined) {
							this.manager.on(KTaskBarEventType.ShowPrint, new KPrintPayload(this.task, e.itemData, []));
						} else if (e.itemData.relatedEntityId) {
							this.manager.on(KTaskBarEventType.ExecuteRelated, new KRelatedPayload(this.task, e.itemData));
						} else if (e.itemData.id) {
							this.manager.on(KTaskBarEventType.ExecuteCommand, new KCommandPayload(this.task, e.itemData, []));
						}
					},
					dataSource: [
						{
							visible: (c.payload.info?.commands.length ?? 0) > 0,
							icon: 'icotbar icot-tb_action',
							items:
								(c.payload.info?.commands.length ?? 0) > 0
									? c.payload.info?.commands
										.filter((c) => !c.availableInSearch)
										.map((item) => {
											return {
												icon: 'icotbar icot-tb_action',
												...item,
											};
										})
									: [],
						},
						{
							visible: (c.payload.info?.relateds.length ?? 0) > 0,
							icon: 'icotbar icot-tb_related',
							items:
								(c.payload.info?.relateds.length ?? 0) > 0
									? c.payload.info?.relateds.map((item) => {
										return {
											icon: 'icotbar icot-tb_related',
											...item,
										};
									})
									: [],
						},
						{
							visible: (c.payload.info?.metadata?.documents.length ?? 0) > 0,
							icon: 'textdocument',
							items: c.payload.info?.metadata?.documents.map((item) => {
								return {
									icon: 'textdocument',
									...item,
								};
							}),
						},
						{
							visible: (c.payload.info?.prints.length ?? 0) > 0,
							icon: 'print',
							hint: 'Stampa',
							items: c.payload.info?.prints.map((item) => {
								return {
									icon: 'print',
									...item,
								};
							}),
						},
					],
				};

				this.cdr.markForCheck();
			}
		});
	}

	excuteChangeState(newStatus: EntityStatusStateStatus) {
		if (!newStatus.status) {
			throwError('invalid status');
		}

		if (!newStatus.status) {
			return;
		}

		if (
			!newStatus.status.requireWarning ||
			window.confirm('Questo cambio di stato produce modifiche permantenti, sei sicuro di voler procedere?')
		) {
			this.manager.on(KTaskBarEventType.FormStatusChange, new KToolbarStatusPayload(this.task, newStatus));
		}
	}

	createFav(isFav: boolean): any {
		return {
			icon: 'favorites',
			hint: 'Aggiungi ai preferiti',

			type: this.isFav ? 'success' : 'normal',
			onClick: () => {
				this.manager.on(KTaskBarEventType.FormAddFavorites, new KToolbarPayload('favourite', this.task));
			},
		};
	}
}
