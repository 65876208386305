import { Type } from '@angular/core';
export enum EntityAttributeValidationType {
	Text,
	Number,
	Date,
	RegExp,
	Decimal,
	Money,
	None,
	Time,
}

export class Validation {
	constructor(raw: any) {
		if (!raw) {
			return;
		}

		this.optional = raw.optional;
		this.regExp = raw.regExp;
		this.type = raw.type;

		if (
			this.type === EntityAttributeValidationType.Number ||
			this.type === EntityAttributeValidationType.Text
		) {
			this.min = parseInt(raw.min, 0);
			this.max = parseInt(raw.max, 0);
		} else if (this.type === EntityAttributeValidationType.Date) {
			this.min = new Date(raw.min);
			this.max = new Date(raw.max);
		}
	}

	max: number | Date = 0;
	min: number | Date = 0;
	optional = false;
	regExp: string | null = null;
	type: EntityAttributeValidationType = EntityAttributeValidationType.None;
}
