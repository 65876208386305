<div class="container">
	<dx-data-grid
		id="gridContainer"
		[dataSource]="dataSource"
		[remoteOperations]="false"
		[allowColumnReordering]="false"
		[rowAlternationEnabled]="true"
		[allowColumnResizing]="true"
		[showBorders]="true"
		columnResizingMode="widget"
		[focusedRowEnabled]="false"
		[(focusedRowKey)]="focusedRowKey"
		[keyExpr]="'idId'"
		(onCellDblClick)="onEdit($event)"
		(onToolbarPreparing)="onToolbarPreparing($event)"
	>
		<dxo-paging [pageSize]="10"></dxo-paging>
		<dxo-pager
			[showPageSizeSelector]="true"
			[allowedPageSizes]="[10, 25, 50, 100]"
		></dxo-pager>
		<dxo-search-panel
			[visible]="false"
			[highlightCaseSensitive]="true"
		></dxo-search-panel>
		<dxo-group-panel [visible]="false"></dxo-group-panel>
		<dxo-grouping [autoExpandAll]="false"></dxo-grouping>

		<!-- <dxo-selection
      selectAllMode="allPages"
      showCheckBoxesMode="onClick"
      mode="multiple"
      [deferred]="false"
    ></dxo-selection> -->

		<dxi-column
			dataField="occurredAt"
			dataType="datetime"
			caption="Data"
			format="shortDate"
			width="20%"
		></dxi-column>
		<dxi-column
			dataField="idVersion"
			caption="Versione"
			width="20%"
		></dxi-column>
		<dxi-column
			dataField="versionLabel"
			caption="Etichetta"
			width="60%"
		></dxi-column>
	</dx-data-grid>

	<dx-popup
		[width]="600"
		[height]="280"
		[showTitle]="true"
		title="Creazione nuova versione"
		[dragEnabled]="false"
		[hideOnOutsideClick]="true"
		[showCloseButton]="false"
		container=".dx-viewport"
		[(visible)]="popupVisible"
		[disabled]="readonly"
	>
		<dxi-toolbar-item
			widget="dxButton"
			toolbar="bottom"
			location="before"
			[options]="confirmOptions"
		>
		</dxi-toolbar-item>

		<div *dxTemplate="let data of 'content'">
			<p>
				<i
					>Inserire un nome di archiviazione della versione precedente. Se non
					si inserisce nulla verrà automaticamente nominato "Versione X"</i
				>
			</p>

			<div class="dx-field">
				<div class="dx-field-label">Nome riferimento</div>
				<div class="dx-field-value">
					<dx-text-box [(value)]="newName"></dx-text-box>
				</div>
			</div>
		</div>
	</dx-popup>
</div>
