export enum FeedbackTypeEnum {
	Message,
	OpenEntityUi,
	Confirm,
	Questions,
	Reload,
	Background,
	FileDownload,
}

export enum FeedbackResponseEnum {
	None = -1,
	Ok = 0,
}

export class EntityLogicFeedback {
	type: FeedbackTypeEnum = FeedbackTypeEnum.Message;
	message: string | null = null;
	entityId: string | null = null;
	instanceId: string | null = null;
	questions: string[] = [];
	needReload: boolean = false;
	needRefresh: boolean = false;
}
