import { CommonModule } from '@angular/common';
import { Component, NgModule } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { DxFormModule } from 'devextreme-angular/ui/form';
import { DxLoadIndicatorModule } from 'devextreme-angular/ui/load-indicator';
import notify from 'devextreme/ui/notify';
import { AuthService } from '../../services/auth.service';

@Component({
	selector: 'app-create-account-form',
	templateUrl: './create-account-form.component.html',
	styleUrls: ['./create-account-form.component.scss'],
})
export class CreateAccountFormComponent {
	loading = false;
	formData: any = {};

	constructor(private authService: AuthService, private router: Router) {}

	async onSubmit(e: any) {
		e.preventDefault();
		const { email, password } = this.formData;
		this.loading = true;

		const result = await this.authService.createAccount(email, password);
		this.loading = false;

		if (result.isOk) {
			this.router.navigate(['/login-form']);
		} else {
			notify(result.message, 'error', 2000);
		}
	}

	confirmPassword = (e: { value: string }) => {
		return e.value === this.formData.password;
	};
}
@NgModule({
	imports: [CommonModule, RouterModule, DxFormModule, DxLoadIndicatorModule],
	declarations: [CreateAccountFormComponent],
	exports: [CreateAccountFormComponent],
})
export class CreateAccountFormModule {}
