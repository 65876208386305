import { ChangeDetectionStrategy, Component } from '@angular/core';
import { SelectBoxFilterEditorBaseComponent } from '../selectbox-filter-editor-base.component';

@Component({
	selector: 'app-look-up-filter',
	templateUrl: './look-up-filter.component.html',
	styleUrls: ['./look-up-filter.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LookUpFilterComponent extends SelectBoxFilterEditorBaseComponent {}
