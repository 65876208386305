import { ChangeDetectionStrategy, Component } from '@angular/core';
import { KDialogBaseSelectorComponent } from '../kdialog-base-selector';

@Component({
	selector: 'app-kdialog-textarea-selector',
	templateUrl: './kdialog-textarea-selector.component.html',
	styleUrls: ['./kdialog-textarea-selector.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KDialogTextareaSelectorComponent extends KDialogBaseSelectorComponent {}
