import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { AttributeMetadata } from 'src/app/server/model/attribute-metadata';
import { EntityInfo } from 'src/app/server/model/entity-info';
import { SearchParameter } from 'src/app/server/model/search-parameter';
import { allOperators, getDefaultOperator } from 'src/app/server/model/search-parameter-type';
import { KFiltersEditorComponent } from '../kfilters-editor/kfilters-editor.component';
import { IntIdName } from './../../../server/model/id-name';
import { SearchParameterType } from './../../../server/model/search-parameter-type';
import { ViewNode } from './../../../server/model/view-node';

@Component({
	selector: 'app-kfilter-editor',
	templateUrl: './kfilter-editor.component.html',
	styleUrls: ['./kfilter-editor.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KFilterEditorComponent implements OnInit {
	#filter: SearchParameter | null = null;

	@Input() set filter(value: SearchParameter | null) {
		// console.log(value);

		if (!value) {
			this.#filter = null;
		}

		this.#filter = value;

		if (this.#filter) {
			this.onFilterParameterChange(this.#filter);
		}
	}

	get filter(): SearchParameter | null {
		return this.#filter;
	}

	parent: KFiltersEditorComponent;
	// attributeOption: any;
	// operatorOption:any;
	entityInfo: EntityInfo;
	allFilters: ViewNode[] = [];
	allAttributes: AttributeMetadata[] = [];
	selectedFilterAttributeName!: string;
	operators: IntIdName[] = [];
	selectedOperator!: number;

	private cdr: ChangeDetectorRef;
	selectedFilter!: ViewNode;
	attribute!: AttributeMetadata;

	constructor(parent: KFiltersEditorComponent, cdr: ChangeDetectorRef) {
		this.cdr = cdr;
		this.parent = parent;
		this.entityInfo = this.parent.parent.data!.info;
		this.allFilters = this.parent.parent.data?.info.queries[0].allFilters ?? [];
		this.allAttributes = Array.from(this.parent.parent.data?.info.metadata.attributes.values() ?? []);
	}

	ngOnInit(): void {}

	onFilterChange(e: { value: string; previousValue: string }) {
		this.selectedFilter = this.allFilters.find((c) => c.attributeName === e.value)!;
		this.attribute = this.allAttributes.find((c) => c.name === this.selectedFilter.attributeName)!;
		this.#filter!.label = this.selectedFilter.name;
		this.#filter!.attributeName = this.selectedFilter.attributeName;
		this.#filter!.operator = getDefaultOperator(this.attribute);
		this.operators = allOperators(this.attribute.type, this.selectedFilter.controlType);
		if (this.operators.length > 0) this.selectedOperator = this.operators[0].id; // this.#filter!.operator;
		else this.selectedOperator = this.#filter!.operator;
		this.#filter!.value = null;
		this.cdr.markForCheck();

		console.log(this.selectedFilter);
	}

	onOperatorChange(e: { value: SearchParameterType; previousValue: SearchParameterType }) {
		this.#filter!.operator = e.value;
	}

	private onFilterParameterChange(filter: SearchParameter) {
		this.selectedFilter = this.allFilters.find((c) => c.attributeName === this.#filter?.attributeName)!;
		this.attribute = this.allAttributes.find((c) => c.name === this.#filter?.attributeName)!;
		this.selectedFilterAttributeName = this.selectedFilter.attributeName;
		this.operators = allOperators(this.attribute?.type, this.selectedFilter.controlType);
		this.selectedOperator = filter.operator;

		console.log(filter);
	}
}
