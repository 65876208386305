import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { KTaskBarEventType } from '../../manager/ktaskbar/ktaskbar-event';
import { KTaskbarService } from '../../manager/ktaskbar/ktaskbar.service';
import { Command, FormEntry } from './../../../server/model/commad';
import { KCommandDialogPayload } from './kcommand-dialog-payload';
import { KDialogCommandClosePayload } from './kdialog-command-close-payload';

@Component({
	selector: 'app-kcommand-dialog',
	templateUrl: './kcommand-dialog.component.html',
	styleUrls: ['./kcommand-dialog.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KCommandDialogComponent implements OnInit, OnDestroy {
	manager: KTaskbarService;
	cdr: ChangeDetectorRef;
	args: string[] = [];
	saveButtonOptions: any;
	closeButtonOptions: any;

	title: string | null = null;
	width: string = '60%';
	height: string = '60%';
	visible = false;
	subscription: Subscription | null = null;
	command: Command | null = null;
	formEntries: FormEntry[] = [];

	constructor(manager: KTaskbarService, cdr: ChangeDetectorRef) {
		this.cdr = cdr;
		this.manager = manager;
	}

	ngOnDestroy(): void {
		console.log('des');
		this.subscription?.unsubscribe();
	}

	ngOnInit(): void {
		const me = this;

		this.saveButtonOptions = {
			icon: 'todo',
			onClick: (e: any) => me.closeWithCommand(),
		};

		this.closeButtonOptions = {
			icon: 'clear',
			onClick: (e: any) => me.close(),
		};

		this.subscription = this.manager.trigger.subscribe((event) => {
			switch (event.type) {
				case KTaskBarEventType.ShowCommandDialog:
					if (event.payload instanceof KCommandDialogPayload) {
						this.onShow(event.payload);
					}
					break;
			}
		});
	}

	private onShow(payload: KCommandDialogPayload) {
		this.command = payload.command;

		if (this.command) {
			this.title = this.command.name;
			this.formEntries = this.command.formEntries ?? [];
			this.args = new Array(this.formEntries.length);
		}
		this.visible = true;
		this.cdr.markForCheck();
	}

	setArg(index: number, newValue: string) {
		this.args[index] = newValue;
	}

	isValid(): boolean {
		let result: boolean = true;

		this.command?.formEntries?.forEach((entry: FormEntry, i: number) => {
			if (!entry.optional && this.args[i] == null) {
				result = false;
			}
		});

		return result;
	}

	close(): void {
		this.reset();
		this.cdr.markForCheck();
	}

	closeWithCommand(): void {
		if (!this.isValid()) {
			this.manager.global.showError('Necessario compilare tutti i campi obbligatori');
			return;
		}

		if (this.command) {
			const payload: KDialogCommandClosePayload = new KDialogCommandClosePayload(this.command, this.args);
			this.manager.on(KTaskBarEventType.CloseCommandDialog, payload);
		}

		this.reset();
		this.cdr.markForCheck();
	}

	reset() {
		this.visible = false;
		this.command = null;
	}
}
