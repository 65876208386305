import { TaskAction, TaskRelation } from './task';
export class KRouteRequestPayload {
	action: TaskAction;
	entityId: string;
	instanceId: string;
	relation: TaskRelation = TaskRelation.None;
	parentTaskId: string | null = null;
	attributeName: string | null = null;
	taskName: string;
	icon: string | undefined;
	resultViewId: string | null;
	rootTaskId: string | null = null;
	rootAttributeName: string | null = null;
	rootChildInstanceId: string | null = null;

	constructor(
		action: TaskAction,
		entityId: string,
		instanceId: string,
		relation: TaskRelation = TaskRelation.None,
		parentTaskId: string | null = null,
		attributeName: string | null = null,
		taskName: string,
		icon: string | undefined,
		resultViewId: string | null,
		rootTaskId: string | null = null, // Form Inline rappresenza il task che contiene
		rootAttributeName: string | null = null,
		rootChildInstanceId: string | null = null,
	) {
		this.action = action;
		this.entityId = entityId;
		this.instanceId = instanceId;
		this.relation = relation;
		this.parentTaskId = parentTaskId;
		this.attributeName = attributeName;
		this.taskName = taskName;
		this.icon = icon;
		this.resultViewId = resultViewId;
		this.rootTaskId = rootTaskId;
		this.rootAttributeName = rootAttributeName;
		this.rootChildInstanceId = rootChildInstanceId;
	}
}
