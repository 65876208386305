import { TreeNode } from 'src/app/ui/controls/tree/tree-note';
import { KItem } from 'src/app/ui/shared/kitem';
import {
	ContextMenuCommands,
	KEntityBaseComponentMenuActions,
} from '../kbase/kentity-base.component';
import { AggregateMember } from './../../../../server/model/agregate-member';
import { AggregateTreeNodeType } from './aggregate-tree-option';

export class AggreagateTreeUtil {
	static getPossibleChildNumber(aggregateInfo: AggregateMember) {
		return aggregateInfo.recursive ? 1 : aggregateInfo.members.length;
	}

	static getEntityToAdd(
		aggregateInfo: AggregateMember,
		targetEntityId: string,
		openerEntityId: string | null,
		useSearchInsteadOfCreate: boolean = false,
	): string | null {
		const isSomethingSelected = openerEntityId !== null;
		let attributeRoot = aggregateInfo.members.find(
			(c) => c.entityId === targetEntityId,
		);

		if (openerEntityId) {
			attributeRoot = aggregateInfo;
		}

		for (const n of attributeRoot?.subEntities ?? []) {
			const rightSubEntity = n.possibileParents?.find(
				(d) => d === openerEntityId,
			);
			if (
				rightSubEntity ||
				(!isSomethingSelected && n.possibileParents == null)
			) {
				return n.entityId;
			}
		}

		return null;
	}

	static getOrderTreeMenuItems(
		attributeAggregateInfo: AggregateMember,
		targetEntityId: string,
		openerEntityId: string | null,
		useSearchInsteadOfCreate: boolean = false,
	): KItem[] {
		const menus: KItem[] = [];
		const searchOrAdd = useSearchInsteadOfCreate
			? KEntityBaseComponentMenuActions.Search
			: KEntityBaseComponentMenuActions.Add;
		const isSomethingSelected = openerEntityId !== null;
		let attributeRoot = attributeAggregateInfo?.members.find(
			(c) => c.entityId === targetEntityId,
		);

		if (openerEntityId) {
			attributeRoot = attributeAggregateInfo;
		}

		if (!attributeRoot) {
			attributeRoot = attributeAggregateInfo;
		}
		// console.log(openerEntityId, attributeRoot);

		// console.log(attributeRoot);
		attributeRoot?.subEntities.forEach((n) => {
			const rightSubEntity = n.possibileParents?.find(
				(d) => d === openerEntityId,
			);
			// console.log(rightSubEntity);
			if (
				rightSubEntity ||
				(!isSomethingSelected && n.possibileParents == null)
			) {
				// console.log(node.aggregateInfo);

				menus.push(this.createMenuItem(searchOrAdd, n.name, n.entityId));
			}
		});

		menus.push(
			this.createMenuItem(
				KEntityBaseComponentMenuActions.Delete,
				' e tutti quelli sotto',
				targetEntityId,
			),
		);

		// console.log(menus);
		return menus;
	}

	static getAggregateMenuItems(
		node: TreeNode,
		targetEntityId: string,
		selectedEntityId: string,
		useSearchInsteadOfCreate: boolean = false,
	): KItem[] {
		if (!node.holder) {
			console.error('Missing holder in node:' + node.text);
		}
		const menus: KItem[] = [];
		const searchOrAdd = useSearchInsteadOfCreate
			? KEntityBaseComponentMenuActions.Search
			: KEntityBaseComponentMenuActions.Add;

		let follows = this.GetFollowUp(node.holder!.entityId!, node.aggregateInfo!);

		if (follows.length === 0) {
			follows = this.GetFollowUp(
				node.holder!.parentEntityId!,
				node.aggregateInfo!,
			);
		}

		if (node.type === AggregateTreeNodeType.Root) {
			const x = node.aggregateInfo?.members.find(
				(c) => c.entityId === targetEntityId,
			);
			if (x) {
				follows = [x];
			}

			// follows = follows.filter(f => f.entityId === selectedEntityId);
		}
		// console.log(node.type === AggregateTreeNodeType.Root);
		// console.log(node.aggregateInfo);
		// console.log(selectedEntityId);
		// console.log(follows);

		follows.forEach((follow) => {
			// console.log(follow);
			menus.push(
				this.createMenuItem(searchOrAdd, follow.name, follow.entityId),
			);
		});

		if (node.aggregateInfo!.recursive) {
			menus.push(
				this.createMenuItem(
					searchOrAdd,
					node.aggregateInfo!.name,
					node.aggregateInfo!.entityId,
				),
			);
		}

		if (node.type === AggregateTreeNodeType.Node) {
			const edit = ContextMenuCommands.find(
				(c) => c.id === KEntityBaseComponentMenuActions.Edit,
			)!;
			menus.push(edit);

			const clear = ContextMenuCommands.find(
				(c) => c.id === KEntityBaseComponentMenuActions.DeleteRecursive,
			)!;
			menus.push(clear);

			const view = ContextMenuCommands.find(
				(c) => c.id === KEntityBaseComponentMenuActions.View,
			)!;
			menus.push(view);
		}

		return menus;
	}

	static createMenuItem(
		action: string,
		nameSuffix: string,
		entityId: string,
	): KItem {
		const add = ContextMenuCommands.find((c) => c.id === action)!;
		const m = new KItem(add.id, `${add.text} ${nameSuffix}`);
		m.icon = add.icon;
		m.value = entityId;
		return m;
	}

	static GetFollowUp(
		entityId: string,
		container: AggregateMember,
	): AggregateMember[] {
		const g = [container];

		const f = this.searchRecursive(g, entityId);

		if (f != null) {
			return f.members;
		} else {
			return [];
		}
	}

	static searchRecursive(
		collection: AggregateMember[],
		entityId: string,
	): AggregateMember | null {
		let found: AggregateMember | null = null;

		collection.forEach((agregateMemberDTO: AggregateMember) => {
			// console.log(agregateMemberDTO);

			if (agregateMemberDTO.entityId === entityId) {
				found = agregateMemberDTO;
			}

			if (agregateMemberDTO.members.length > 0) {
				const a = this.searchRecursive(agregateMemberDTO.members, entityId);

				if (a != null) {
					found = a;
				}
			}
		});

		return found;
	}
}
