import { ChangeDetectionStrategy, Component } from '@angular/core';
import { EnumHelper } from 'src/app/server/helpers/enum-helper';
import { EntityAttributeValueType } from 'src/app/server/model/entity-attribute-value-type';
import { KItem } from 'src/app/ui/shared/kitem';
import { LookupOptions } from '../kgrid/grid-control-option';
import { KSearchPanelComponent } from '../ksearch-panel/ksearch-panel.component';
import { EntityHolder } from './../../../../server/model/entity-holder';

@Component({
	selector: 'app-kcombobox',
	templateUrl: './kcombobox.component.html',
	styleUrls: ['./kcombobox.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KComboboxComponent extends KSearchPanelComponent {
	isDropDownBoxOpened = false;
	override innerValue: any;
	diagnose = true;
	selectionMode: string = 'single';
	nodes: KItem[] = [];
	override hint!: string;
	isCompact: boolean = false;
	useName: boolean = true;

	override onKeystoneInit() {
		super.onKeystoneInit();
		this.useName = this.metadata.type != 0;
	}

	override ngOnInit(): void {
		if (this.node.colSpan <= 6) {
			this.isCompact = true;
		}
		super.ngOnInit();
	}

	override evaluateSearch() {
		if (this.readonly) {
			this.allowAdd = false;
			return;
		}

		if (this.node.optionId === LookupOptions.Default) {
			this.allowAdd = true;
		} else if (this.node.optionId === LookupOptions.All) {
			this.allowAdd = true;
		} else if (this.node.optionId === LookupOptions.Deny) {
			this.allowAdd = false;
		} else {
			this.allowAdd = EnumHelper.is(this.node.optionId, LookupOptions.Add);
		}
	}

	override evaluateEditAndDelete(valueExist: boolean) {
		if (this.readonly) {
			this.allowView = EnumHelper.is(this.node.optionId, LookupOptions.Edit);
			return;
		}

		if (this.node.optionId === LookupOptions.Default) {
			this.allowModify = true;
		} else if (this.node.optionId === LookupOptions.All) {
			this.allowDelete = true;
			this.allowModify = true;
		} else if (this.node.optionId === LookupOptions.Deny) {
			this.allowDelete = false;
			this.allowModify = false;
		} else {
			this.allowDelete = EnumHelper.is(this.node.optionId, LookupOptions.Delete);
			this.allowModify = EnumHelper.is(this.node.optionId, LookupOptions.Edit);
		}
	}

	override onValueReady(): void {
		if (this.metadata.isFlag) {
			this.onFlagIsReady(this.value?.value);
			this.selectionMode = 'multiple';
		} else if (this.isStatic) {
			this.onStaticIsReady(this.value?.value);
		} else {
			this.onHoldersAreReady(this.value?.value);
		}

		this.createTreeNodes();

		// console.log('onValueReady', this.node.attributeName, this.value?.value, this.innerValue);
		if (!this.innerValue) {
			this.setDefaultInnerValue();
			if (this.innerValue) {
				// TODO test default value e null
				console.log(this.node.name, 'impostato defaul o primo valore perchè nullo ma non consente nullo');
				// this.hint = this.innerValue;

				super.fieldValueIsChanging(this.innerValue, false);
			}
		}

		this.evaluateEditAndDelete(this.innerValue != null);
		this.cdr.markForCheck();
	}

	onHoldersAreReady(value: EntityHolder | string) {
		if (value) {
			if (value instanceof EntityHolder) {
				this.innerValue = [value.instanceId];
			} else {
				this.innerValue = [value];
			}
		} else {
			this.innerValue = null;
		}
	}

	onStaticIsReady(value: number) {
		this.innerValue = value?.toString();
	}

	onFlagIsReady(value: number) {
		this.innerValue = EnumHelper.fromFlag(
			value,
			this.options.map((o) => o.instanceId),
		);
	}

	entitySorter(a: EntityHolder, b: EntityHolder, useName: boolean): number {
		return (useName ? a.humanReadableName : +a.instanceId) < (useName ? b.humanReadableName : b.instanceId) ? -1 : 1;
	}

	createTreeNodes() {
		this.nodes = this.options
			.sort((a, b) => this.entitySorter(a, b, this.useName))
			.map((c) => {
				const selected = (this.innerValue ?? []).indexOf(c.instanceId.toString()) >= 0;
				return new KItem(c.instanceId, c.humanReadableName, undefined, selected, c);
			});
	}

	private setDefaultInnerValue() {
		if (this.allowNull) {
			delete this.innerValue;
		} else if (!this.metadata.defaultValue) {
			this.innerValue = this.options[0]?.instanceId;
		} else {
			this.innerValue = this.metadata.defaultValue;
		}
	}

	onValueChanged(event: any): void {
		// sembra che invia prima la delesezione e poi la selezione creando un doppio evento
		// this.container.global.showLoader();
		if (!event.node.selected) return;

		var values = this.nodes.filter((c) => c.selected === true).map((c) => c.id);
		let currentValue: string | number | null = null;

		if (this.metadata.isFlag) {
			currentValue = EnumHelper.toFlag(values);
		} else if (this.isStatic && this.metadata.type === EntityAttributeValueType.Int) {
			currentValue = values?.length > 0 ? parseInt(values[0]) : null;
		} else if (this.isStatic) {
			currentValue = values?.length > 0 ? values[0] : null;
		} else {
			currentValue = values?.length > 0 ? values[0] : null;
		}

		if (currentValue == null && this.allowNull) {
			this.setDefaultInnerValue();
			super.fieldValueIsChanging(currentValue, false);
		} else if (this.value) {
			this.innerValue = values;
			super.fieldValueIsChanging(currentValue, false);
		}

		// console.log('value change', this.innerValue);
	}

	onClearPress(event: { previousValue: any; value: any }) {
		if (!event.value) {
			for (const n of this.nodes) {
				n.selected = false;
			}

			this.cdr.markForCheck();

			this.onValueChanged(event);
		}
	}
}
