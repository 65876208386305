import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	Output,
} from '@angular/core';

@Component({
	selector: 'app-widget-box',
	templateUrl: './widget-box.component.html',
	styleUrls: ['./widget-box.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WidgetBoxComponent {
	@Input() lastUpdate: Date = new Date();
	@Input() title: string = '';
	@Input() footer: string | null = null;

	@Input() enableAdd: boolean = false;
	@Input() enableEdit: boolean = true;
	@Input() enableDelete: boolean = true;
	@Input() enableCollapse: boolean = true;

	@Input() collapsable: boolean = false;
	@Input() collapsed: boolean = false;

	@Output() edit = new EventEmitter();
	@Output() delete = new EventEmitter();
	@Output() add = new EventEmitter();
	@Output() collapse = new EventEmitter();

	onEdit(event: any) {
		this.edit.emit(event);
	}

	onDelete(event: any) {
		this.delete.emit(event);
	}

	onAdd(event: any) {
		this.add.emit(event);
	}

	onTitleClick() {
		if (this.collapsable) {
			this.collapsed = !this.collapsed;
		}
	}
}
