import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EntityService } from 'src/app/server/entity.service';
import { KTaskStateService } from 'src/app/server/kform-data-state.service';
import { CacheService } from 'src/app/shared/caching/cache-service';
import { GlobalService } from 'src/app/shared/services';
import { KTaskbarService } from '../../manager/ktaskbar/ktaskbar.service';
import { TaskAction } from '../../manager/ktaskbar/task';
import { KFormViewComponent } from '../kcomponents/kform-view/kform-view.component';
import { KFormInlineComponent } from './kform-inline.component';

@Component({
	selector: 'app-kform-read-only',
	templateUrl: './kform.component.html',
	styleUrls: ['./kform.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [],
})
export class KFormReadOnlyComponent extends KFormInlineComponent {
	protected override action: TaskAction = 'view';

	constructor(
		fv: KFormViewComponent,
		container: KTaskbarService,
		state: KTaskStateService,

		cdr: ChangeDetectorRef,
		cache: CacheService,
		route: ActivatedRoute,
		router: Router,

		global: GlobalService,
		service: EntityService,
	) {
		super(container, state, cdr, cache, route, router, global, service);
		this.hostComponent = fv;
	}
}
