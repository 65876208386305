import { Cacheable } from '../../shared/caching/cacheable';
import { Command } from './commad';
import { EntityMetadata } from './entity-metadata';
import { EntityQuery } from './entity-query';
import { LogicalEntityType } from './logical-entity-type';
import { Related } from './realted';
import { PrintView, ResultView } from './result-view';
import { View } from './view';
import { VirtualData } from './virtual-info';

export class ChildInfo {
	entityId!: string;
	name!: string;
	possibileParents: [] = [];
}

export class EntityInfo extends Cacheable {
	relateds: Related[] = [];
	commands: Command[] = [];
	metadata!: EntityMetadata;
	uis: View[] = [];
	results: ResultView[] = [];
	prints: PrintView[] = [];
	queries: EntityQuery[] = [];
	permissions: string[] = [];
	virtuals: VirtualData[] = [];
	moduleId!: string;
	isCustom!: boolean;
	isSubEntity!: boolean;
	logicalType!: LogicalEntityType;
	nameFormat = '{0}';
	parentModuleId!: string;
	hasChild: any;
	isMultiInheritance: any;
	canBeGeneratedByModel: boolean = false;
	possibleChildren: ChildInfo[] = [];
	categoryEntityId: string | null = null;

	static parse(raw: any): EntityInfo {
		if (!raw) {
			console.error('Impossibile parsare un entità vuota');
		}

		const entityInfo = new EntityInfo();
		entityInfo._raw = raw;
		entityInfo.id = raw.entity.id;
		entityInfo.moduleId = raw.entity.moduleId;
		entityInfo.isCustom = raw.entity.isCustom;
		entityInfo.isSubEntity = raw.entity.isSubEntity;
		entityInfo.logicalType = raw.entity.logicalType;
		entityInfo.nameFormat = raw.entity.nameFormat;
		entityInfo.parentId = raw.entity.parentId;
		entityInfo.categoryEntityId = raw.categoryEntityId;
		entityInfo.parentModuleId = raw.entity.parentModuleId;
		entityInfo.metadata = EntityMetadata.parse(raw);
		entityInfo.hasChild = raw.entity.hasChild;
		entityInfo.isMultiInheritance = raw.entity.isMultiInheritance;
		entityInfo.canBeGeneratedByModel = entityInfo.metadata.canBeGeneratedByModel;
		entityInfo.uis = raw?.uis.map((ui: any) => new View(ui)) ?? [];

		entityInfo.relateds = raw.relateds;
		entityInfo.commands = raw.commands;
		entityInfo.prints = raw.prints;

		entityInfo.permissions = raw.permissions;
		entityInfo.virtuals = raw.virtuals;

		raw?.results?.forEach((resultViewNode: any) => {
			entityInfo.results.push(new ResultView(resultViewNode));
		});

		raw?.queries?.forEach((queryRaw: any) => {
			const x = EntityQuery.parse(queryRaw);
			if (x) {
				entityInfo.queries.push(x);
			}
		});

		entityInfo.possibleChildren =
			raw.possibleChildren?.map((child: any) => Object.assign(new ChildInfo(), child)) ?? [];

		return entityInfo;
	}

	getUiOrDefault(refViewId: string | null): View | null {
		if (this.uis.length === 0) {
			console.error('missing uis for', this.id);
		}

		let r = this.uis.filter((c) => c.id === refViewId);

		if (r?.length == 0) {
			r = this.uis.filter((c) => c.isDefault);
		}

		if (r.length === 0) {
			r.push(this.uis[0]);
		}

		if (r.length === 0) {
			return null;
		}

		return r[0];
	}

	getViewOrDefault(refViewId: string): ResultView | null {
		// console.log('search for', refViewId);
		// console.log('in', this.results.map(c => c.id).join(','));
		const r = this.results.filter((c) => c.id.toLowerCase() === refViewId?.toLowerCase());

		if (r.length === 0) {
			r.push(this.results[0]);
		}

		if (r.length === 0) {
			return null;
		}

		return r[0];
	}
}
