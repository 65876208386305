import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { EntityService } from 'src/app/server/entity.service';
import { KnowEntityIds } from 'src/app/server/helpers/constants';
import { GenericHelper } from 'src/app/server/helpers/generic-helper';
import { SearchParameter } from 'src/app/server/model/search-parameter';
import { SearchResponse } from 'src/app/server/model/search-response';
import { KItem } from 'src/app/ui/shared/kitem';
import { ViewResultNode } from './../../../server/model/view-result.node';

export function openFavourite(
	entitySelected: string,
	service: EntityService,
	menuItems: KItem[],
): Promise<boolean> {
	const filters: SearchParameter[] = [];
	filters.push(SearchParameter.create('Entity', entitySelected));

	const columns: ViewResultNode[] = [];

	const c = new ViewResultNode();
	c.name = 'InstanceId';
	columns.push(c);

	return service
		.quickSearch(KnowEntityIds.UserFavourite, null, filters, columns, null)
		.pipe(
			switchMap((response) => {
				return createFavouriteMenu(response, menuItems, entitySelected);
			}),
		)
		.toPromise();
}

function createFavouriteMenu(
	response: SearchResponse | null,
	menuItems: KItem[],
	entitySelected: string,
): Observable<boolean> {
	console.log(response);

	const fav = menuItems.find((c) => c.id === 'fav');
	if (fav != null) {
		const i = menuItems.indexOf(fav);
		menuItems.splice(i, 1);
	}

	if ((response?.results.length ?? 0) > 0) {
		const favItem: KItem = {
			id: 'fav',
			text: 'Preferiti',
			icon: 'bookmark',
			items: [],
		};

		const icon = GenericHelper.toIcon(entitySelected);

		response?.results.forEach((favouriteEntry) => {
			// console.log(favouriteEntry);

			favItem.items?.push({
				id: 'fav',
				text: favouriteEntry.humanReadableName,
				icon: icon,
				value: favouriteEntry.fields.find((c) => c.name === '_InstanceId')
					?.value,
			});
		});

		menuItems.push(favItem);
	}

	return of(true);
}
