export enum EntityAttributeValidationType {
	Text,

	Number,

	Date,

	RegExp,

	Decimal,

	Money,

	None,

	Time,
}
