import { ControlFormatEnum } from 'src/app/shared/formatting/formatting-helper';
import { ControlNode } from './../../ui/edit/ktab/createChildNode';
import { AdvancedData } from './advanced-data';
import { AttributeMetadata } from './attribute-metadata';
import { LogicalAttributeType } from './logical-attribute-type';
import { ViewItemFilterControl } from './view-item-filter-control';
import { ViewItemUiControl } from './view-item-ui-control';

export class ViewNode {
	index = 0;
	name: string = 'unnamed';
	attributeName!: string;
	required = false;
	placeholder: string | null = null;
	description: string | null = null;
	icon: string = 'spinnext';
	colSpan = 0;
	controlType: ViewItemUiControl | ViewItemFilterControl =
		ViewItemUiControl.Separator;
	rowSpan = 0;
	refViewId: string | null = null;
	refEntityId: string | null = null;
	lt: LogicalAttributeType = LogicalAttributeType.Value;
	nodes: ViewNode[] = [];
	evaluatedNodes: ControlNode[] = [];

	optionId: number | null = null;
	attribute: AttributeMetadata | null = null;
	advancedData2!: AdvancedData;
	formatId: ControlFormatEnum | null = null;
	// hidden: boolean = false;

	constructor(private rawNode: any | null = null) {
		if (!rawNode) {
			return;
		}
		// console.log(rawNode);
		this.name = rawNode.name;
		this.icon = 'spinnext';
		this.attributeName = rawNode.attributeName;
		this.placeholder = rawNode.description;
		this.description = rawNode.description;
		this.controlType = rawNode.controlTypeId as ViewItemUiControl;
		this.rowSpan = this.evaluateRowSpan();
		this.colSpan = this.evaluateColSpan();

		this.advancedData2 = AdvancedData.parse(rawNode.advancedData2);

		rawNode.nodes?.forEach((node: any) => {
			this.nodes.push(new ViewNode(node));
		});

		this.formatId = rawNode.formatId;
		this.refViewId = rawNode.refViewId;
		this.refEntityId = rawNode.refEntityId;
		this.lt = rawNode.lt;
		this.index = rawNode.index;
		this.optionId = rawNode.optionId;
		// console.log(rawNode.attributeName, rawNode);
	}

	evaluateColSpan(): number {
		// return this.rawNode.width;
		let w = this.rawNode.width;
		if (w === 33) {
			w = 30;
		}
		if (w === 34) {
			w = 40;
		}
		if (w === 67) {
			w = 70;
		}

		if (w % 5 > 0) {
			w = w - (w % 5);
		}

		w = w / 5;

		return w;
	}

	evaluateRowSpan(): number {
		return this.rawNode.height;
	}
}
