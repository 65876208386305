import { LoginResponse } from './../../server/model/login-response';
export class User {
	constructor(
		userName: string,
		userEmail: string,
		userId: string,
		accountId: string,
		userRoles: string[],
		mainProfile: string,
		avatarUrl: string | null = null,
	) {
		this.userName = userName;
		this.userEmail = userEmail;
		this.userId = userId;
		this.accountId = accountId;
		this.userRoles = userRoles;
		this.mainProfile = mainProfile;
	}

	userName!: string;
	userEmail!: string;
	email!: string;
	userId!: string;
	accountId!: string;
	userRoles: string[] = [];
	mainProfile!: string;
	avatarUrl?: string | null = null;
}

export function createUserFromResponse(response: LoginResponse): User {
	return new User(
		response.userName,
		response.userEmail,
		response.userId,
		response.accountId,
		response.userRoles,
		response.mainProfile,
	);
}
