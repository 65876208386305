import { ViewNodeFilter } from './filter';
import { UiRule } from './load-runtime-information';

import { SearchColumnResult } from './search-column-result';

export class AdvancedData {
	entityId: string | null = null;
	filters: ViewNodeFilter[] = [];
	orders: SearchColumnResult[] = [];
	resultViewId: string | null = null;
	targetEntityIds: string[] = [];
	uiRules: UiRule[] = [];

	static parse(raw: any): AdvancedData {
		const h = new AdvancedData();
		if (!raw) return h;
		h.entityId = raw.entityId;
		h.resultViewId = raw.resultViewId;
		h.targetEntityIds = raw.targetEntityIds;

		raw.filters.forEach((element: any) => {
			h.filters.push(
				Object.assign(new ViewNodeFilter(), element) as ViewNodeFilter,
			);
		});

		raw.orders.forEach((element: any) => {
			if (element != null) {
				h.orders.push(SearchColumnResult.parse(element)!);
			}
		});

		h.uiRules = raw.uiRules.map((rawRule: any) =>
			Object.assign(new UiRule(), rawRule),
		);

		return h;
	}
}
