<dx-toolbar>
	<dxi-item
		location="before"
		widget="dxButton"
		locateInMenu="never"
		[options]="selectButtonOptions"
	>
	</dxi-item>
	<dxi-item
		location="before"
		widget="dxButton"
		locateInMenu="never"
		[options]="deleteButtonOptions"
	>
	</dxi-item>
	<dxi-item
		location="before"
		widget="dxButton"
		locateInMenu="never"
		[options]="closeButtonOptions"
	>
	</dxi-item>
</dx-toolbar>
