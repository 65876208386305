import { Injectable } from '@angular/core';
import { entityCachaType } from 'src/app/server/entity-cache-type';
import { dbSchema } from '../storage/db-schema';
import { StorageService } from './../storage/storage-service';
import { CacheEntry } from './cache-entry';
import { CacheHelper } from './cache-helper';
import { Cacheable } from './cacheable';

@Injectable({
	providedIn: 'root',
})
export class CacheService {
	db: StorageService;
	constructor(db: StorageService) {
		this.db = db;
	}

	async init(): Promise<boolean> {
		return this.db.init();
	}

	async clearTask(): Promise<any[]> {
		console.log('clearTask');
		return this.db.delete(dbSchema.CacheTableName, ['TasksCache_Shared']);
	}

	async reset(): Promise<boolean> {
		return this.db.getMany<CacheEntry>(dbSchema.CacheTableName).then((c: CacheEntry[]) => {
			const entriesIds: string[] = c.map((d: CacheEntry) => d.id);
			const k: string = CacheHelper.toKey(entityCachaType.TasksCache, 'Shared');
			const i = entriesIds.findIndex((d) => d === k);
			if (i >= 0) {
				entriesIds.splice(i, 1);
			}
			this.db.delete(dbSchema.CacheTableName, entriesIds);
			return true;
		});
	}

	setEntry(data: CacheEntry): void {
		this.db.set(dbSchema.CacheTableName, data);
	}

	set(data: Cacheable, type: string): Promise<boolean> {
		const x = CacheHelper.toEntry(data, type);

		return this.db.set(dbSchema.CacheTableName, x).then((c) => {
			return true;
		});
	}

	get(id: string, type: string): Promise<CacheEntry | undefined> {
		const k = CacheHelper.toKey(type, id);
		return this.db.get<CacheEntry>(dbSchema.CacheTableName, k).then((c: any) => {
			if (typeof c?.data === 'string') {
				// console.log(c.data, typeof c.data);
				c.data = JSON.parse(c.data);
			}

			return c;
		});
	}

	delete(id: string, type: string): Promise<any> {
		const k = CacheHelper.toKey(type, id);
		return this.db.delete(dbSchema.CacheTableName, [k]);
	}
}
