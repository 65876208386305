import { GlobalEventType } from './global-event-type';

export class GlobalEvent {
	constructor(type: GlobalEventType, arg: any) {
		this.type = type;
		this.arg = arg;
	}

	type: GlobalEventType = GlobalEventType.Login;
	arg: any;
}
