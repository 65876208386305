<!-- [columnHidingEnabled]="true" -->
<dx-data-grid id="datagrid" class="fullSize" [columnAutoWidth]="true" [showBorders]="true" [allowColumnResizing]="true"
	[showBorders]="true" columnResizingMode="widget" [columnMinWidth]="50" [columnAutoWidth]="true" [dataSource]="source"
	(onSelectionChanged)="onSelectionChanged($event)" (onCellClick)="onCellClick($event)"
	(onInitNewRow)="onInitNewRow($event)" (onToolbarPreparing)="onToolbarPreparing($event)">
	<dxo-column-chooser [enabled]="option.columnChooser"></dxo-column-chooser>

	<dxo-state-storing [enabled]="option.storeState" type="custom" [storageKey]="storageKey" [customLoad]="onLoadState"
		[customSave]="onSaveState"></dxo-state-storing>

	<dxo-sorting [mode]="option.sortMode"></dxo-sorting>

	<dxo-editing *ngIf="this.option.allowUpdating" mode="row" [allowUpdating]="option.allowUpdating"
		[allowDeleting]="option.allowDelete" [allowAdding]="option.allowAdding" [useIcons]="true"></dxo-editing>

	<!--
    tolto per la selezione singola nel dialog
    *ngIf="option.multiselect"
  -->
	<dxo-selection selectAllMode="allPages" [showCheckBoxesMode]="option.showCheckBoxesMode" [mode]="option.selectionMode"
		[deferred]="option.deferred"></dxo-selection>

	<dxi-column type="buttons" *ngIf="option.numberOfButtons > 0 && !option.deferred" [width]="option.width">
		<dxi-button *ngIf="option.allowSelect && !option.allowUpdating" hint="Vedi" icon="tableproperties"
			[onClick]="onRowEdit">
		</dxi-button>

		<dxi-button name="edit" hint="Modifica"></dxi-button>
		<dxi-button name="delete" hint="Elimina"></dxi-button>

		<dxi-button *ngIf="option.allowReorder" hint="up" icon="arrowup" [onClick]="onRowUp">
		</dxi-button>
		<dxi-button *ngIf="option.allowReorder" hint="down" icon="arrowdown" [onClick]="onRowDown">
		</dxi-button>

		<dxi-button *ngIf="option.allowDelete && !option.allowUpdating" hint="Elimina" icon="clearformat"
			[onClick]="onRowDelete">
		</dxi-button>
	</dxi-column>

	<dxo-export [enabled]="true"></dxo-export>

	<dxi-column *ngFor="let columnDef of columns; let colIndex = index" [dataField]="columnDef.field"
		[caption]="columnDef.caption" [sortIndex]="columnDef.sortIndex" [sortOrder]="columnDef.sortOrder"
		[dataType]="columnDef.dataType" [calculateCellValue]="calculateCellValue"
		[editCellTemplate]="columnDef.editCellTemplate" [cellTemplate]="columnDef.cellTemplate"
		[minWidth]="columnDef.minWidth" [format]="columnDef.format" [allowEditing]="columnDef.allowEditing">
		<dxo-lookup *ngIf="columnDef.isLookUp" [dataSource]="columnDef.lookUpData" valueExpr="instanceId"
			displayExpr="humanReadableName">
		</dxo-lookup>
	</dxi-column>

	<dxo-grouping [contextMenuEnabled]="true"></dxo-grouping>
	<dxo-group-panel [visible]="option.groupEnabled"></dxo-group-panel>

	<dxo-remote-operations [filtering]="false" [paging]="false" [sorting]="false" [summary]="false" [grouping]="false"
		[groupPaging]="false">
	</dxo-remote-operations>

	<dxo-scrolling mode="standard">
		<!-- "standard" or "virtual" | "infinite" -->
	</dxo-scrolling>
	<dxo-filter-row [visible]="option.rowFilterEnable"></dxo-filter-row>
	<dxo-search-panel [visible]="option.searchPanelEnabled"></dxo-search-panel>
	<dxo-load-panel [enabled]="true"></dxo-load-panel>
	<dxo-paging [pageSize]="20" [enabled]="option.pagingEnabled"></dxo-paging>
	<dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[25, 50, 100]" [showInfo]="true">
	</dxo-pager>
	<dxo-paging [pageSize]="pageSize" [pageIndex]="pageIndex"> </dxo-paging>

	<div *dxTemplate="let cellInfo of 'EditableDateColumn'">
		<dx-date-box [value]="getCellValue(cellInfo)" (onValueChanged)="setCellValue(cellInfo, $event)" type="date">
		</dx-date-box>
	</div>
	<div *dxTemplate="let cellInfo of 'BooleanColumn'">
		<dx-check-box [readOnly]="true" [value]="getCellValue(cellInfo)" (onValueChanged)="setCellValue(cellInfo, $event)">
		</dx-check-box>
	</div>

	<div *dxTemplate="let cellInfo of 'TimeSpanColumn'">
		<!-- <dx-check-box
      [readOnly]="true"
      [value]="getCellValue(cellInfo)"
      (onValueChanged)="setCellValue(cellInfo, $event)"
    ></dx-check-box> -->

		{{ formatTypeSpan(cellInfo) }}
	</div>

	<div *d xTemplate="let cellInfo of 'EditableTimeSpanColumn'">
		<!-- <dx-check-box [value]="getCellValue(cellInfo)" (onValueChanged)="setCellValue(cellInfo, $event)"></dx-check-box> -->
	</div>

	<div *dxTemplate="let cellInfo of 'EditableBooleanColumn'">
		<dx-check-box [value]="getCellValue(cellInfo)" (onValueChanged)="setCellValue(cellInfo, $event)"></dx-check-box>
	</div>

	<div *dxTemplate="let cellInfo of 'EditableColumn'">
		<dx-text-box [value]="getCellValue(cellInfo)" (onValueChanged)="setCellValue(cellInfo, $event)"></dx-text-box>
	</div>

	<div *dxTemplate="let cellInfo of 'ColoredColumn'" [style.background-color]="getColor(cellInfo)" class="coloredCell">
		{{ getColorLabel(cellInfo) }}
	</div>

	<div *dxTemplate="let cellInfo of 'DropColumn'">
		<dx-select-box [dataSource]="getColumnSource(cellInfo)" placeholder="" [value]="getCellValue(cellInfo)"
			displayExpr="humanReadableName" (onValueChanged)="setCellValue(cellInfo, $event)" [searchEnabled]="true"
			searchMode="contains" [minSearchLength]="0"></dx-select-box>
	</div>

	<!-- displayExpr="getCellReadable" -->
	<div *dxTemplate="let cellInfo of 'SearchColumn'">
		<!-- copia di drop comun ma in realtà è da fare-->

		<dx-select-box [dataSource]="getColumnSource(cellInfo)" placeholder="" [value]="getCellValue(cellInfo)"
			displayExpr="humanReadableName" (onValueChanged)="setCellValue(cellInfo, $event)" [searchEnabled]="true"
			searchMode="contains" [minSearchLength]="1"></dx-select-box>

		<!-- <dx-select-box
      [dataSource]="getColumnSource(cellInfo)"
      placeholder=""
      displayExpr="humanReadableName"
      valueExpr="instanceId"
      (onValueChanged)="setCellValue(cellInfo,$event)"
      [value]="getCellValue(cellInfo)"
      ></dx-select-box> -->
	</div>
</dx-data-grid>
