import { ChangeDetectionStrategy, Component } from '@angular/core';
import { SelectBoxFilterEditorBaseComponent } from './../selectbox-filter-editor-base.component';

@Component({
	selector: 'app-search-pop-up-filter',
	templateUrl: './search-pop-up-filter.component.html',
	styleUrls: ['./search-pop-up-filter.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchPopUpFilterComponent extends SelectBoxFilterEditorBaseComponent {}
