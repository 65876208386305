import { Type } from '@angular/core';
import { SearchColumnResult } from './search-column-result';

import { EntityHolder } from './entity-holder';
import { supportsPassiveEventListeners } from '@angular/cdk/platform';
import { ApiResponse } from './api.response';

export class SearchResponse extends ApiResponse {
	constructor(raw: any) {
		super();
		this.lenght = raw.total;
		this.pageIndex = raw.page;
		this.pageSize = raw.pageSize;

		raw.results?.forEach((element: any) => {
			const e = EntityHolder.parse(element);

			if (e) {
				this.results.push(e);
			}
		});
	}

	results: EntityHolder[] = [];
	pageSize: number;
	pageIndex: number;
	lenght: number;
}
