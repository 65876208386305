<app-kcomponent-container [label]="label" [mandatory]="mandatory" [description]="description">
	<div class="container" style="width:100% !important; height: 50% !important ;display: flex; align-items: center">
		<div class="image" style="width:auto;height:auto ;max-height:22vh">
			<img [src]="image.fullUrl" class="img" [title]="label" [alt]="image.fileName" (click)="onShopUploadPanel()"
				[title]="description" (dblclick)="openImagePopup()" />
		</div>
	</div>
	<div class="toolbar" style="width:100% !important; display: flex; align-items: center;">
		<div class="buttons" style="width: 73px !important;">
			<dx-button class="firstButton" icon="icotbar icot-tb_undo-gray" hint="Cancella immagine"
				*ngIf="image.fileName!='none'" (onClick)="onImageClear()">
			</dx-button>
			<dx-button class="secondButton" icon="image" hint="Visualizza immagine" *ngIf="image.fileName!='none'"
				(click)="openImagePopup()">
			</dx-button>
		</div>
		<div class="uploader" style="width:75%">
			<dx-file-uploader #fileUploader [uploadUrl]="uploadUrl" [multiple]="false" accept="*" [(value)]="files"
				uploadMode="instantly" [selectButtonText]="getAttachIcon()" (onUploaded)="onFilesUploaded($event)">
			</dx-file-uploader>
		</div>
	</div>

	<dx-popup #popupWindow [visible]="popupVisible" (onHiding)="hidePopup()"
		style="display: flex; align-items: center; justify-content: center; height: auto; width: auto; max-height: 70vh; max-width: 70vw; background: white;">
		<div class="popupContent">
			<img #popupImage class="picture" [src]="image.fullUrl" [alt]="image.fileName" />
		</div>
	</dx-popup>
</app-kcomponent-container>
