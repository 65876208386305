import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	OnInit,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { EntityHolder } from 'src/app/server/model/entity-holder';
import { SearchParameter } from '../../../server/model/search-parameter';
import {
	KTaskBarEvent,
	KTaskBarEventType,
} from '../../manager/ktaskbar/ktaskbar-event';
import { KTaskbarService } from '../../manager/ktaskbar/ktaskbar.service';
import { Task } from '../../manager/ktaskbar/task';
import { KSearchDialogClosePayload } from './ksearch-dialog-close-payload';
import { KSearchDialogPayload } from './ksearch-dialog-payload';

@Component({
	selector: 'app-kdialog',
	templateUrl: './ksearch-dialog.component.html',
	styleUrls: ['./ksearch-dialog.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KSearchDialogComponent implements OnInit {
	visible = false;
	subscription: Subscription | null = null;
	task: Task | null = null; // = Task.untitled('');

	extraFilters: SearchParameter[] = [];
	multiselect = false;
	popUpDelete = false;
	reference: any = null;
	excludeFilter: boolean = false;
	isForNew: boolean = false;
	caller: EntityHolder | null = null;

	private manager: KTaskbarService;
	protected cdr: ChangeDetectorRef;

	title: string | null = null;
	width: string = '80%';
	height: string = '80%';

	constructor(manager: KTaskbarService, cdr: ChangeDetectorRef) {
		this.manager = manager;
		this.cdr = cdr;
	}

	ngOnInit(): void {
		this.subscription = this.manager.trigger.subscribe((event) => {
			switch (event.type) {
				case KTaskBarEventType.ShowDialog:
					if (event.payload instanceof KSearchDialogPayload) {
						this.prepareSearch(event);
					}
					this.visible = true;
					this.cdr.markForCheck();
					break;
			}
		});
	}

	private prepareSearch(event: KTaskBarEvent) {
		this.task = Task.untitled('');

		this.task.action = event.payload.action;
		this.task.entityId = event.payload.entityId;
		this.task.parentTaskId = event.payload.parentTaskId;
		this.task.attributeName = event.payload.attributeName;
		this.width = '80%';
		this.height = '80%';
		this.extraFilters = event.payload.filters;
		this.multiselect = event.payload.multiselect;
		this.popUpDelete = event.payload.popUpDelete;
		this.reference = event.payload.reference;
		this.caller = event.payload.caller;
		this.isForNew = event.payload.isForNew;
		this.excludeFilter = event.payload.excludeFilter;

		this.title = null;
	}

	close(): void {
		this.reset();
		this.cdr.markForCheck();
	}

	reset() {
		this.visible = false;
		this.task = null;
	}

	closeWithPayLoad(
		parentTaskId: string | null = null,
		attributeName: string | null = null,
		entities: EntityHolder[],
	): void {
		this.reset();

		if (parentTaskId) {
			const payload: KSearchDialogClosePayload = new KSearchDialogClosePayload(
				parentTaskId,
				attributeName,
				entities,
				this.reference,
			);
			this.manager.on(KTaskBarEventType.CloseSearchDialog, payload);
		}

		this.cdr.markForCheck();
	}
}
