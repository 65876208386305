import { environment } from 'src/environments/environment';
import { User } from '../../shared/services/user';

// TODO remove and replace with https://hasura.io/blog/best-practices-of-using-jwt-with-graphql/#jwt_persist

export class GlobalData {
	readonly serverUrl: string | null = null;
	readonly accessToken: string | null = null;
	readonly user: User | null = null;
	readonly isProxy: boolean = false;

	constructor(serverUrl: string | null, accessToken: string | null = null, user: User | null = null, isProxy: boolean) {
		this.serverUrl = serverUrl;
		this.accessToken = accessToken;
		this.user = user;
		this.isProxy = isProxy;

		if (!this.serverUrl && environment.production) {
			this.serverUrl = location.origin;
		}
		// console.log(user, environment, this.serverUrl);
	}

	static parse(raw: any): GlobalData {
		return new GlobalData(raw.serverUrl, raw.accessToken, raw.user, raw.isProxy);
	}

	static default(): GlobalData {
		return new GlobalData(null, null, null, false);
	}
}
