<app-kcomponent-container [label]="label" (contextmenu)="onContext($event)">
	<div class="content">
		<app-tree
			[nodes]="treeData"
			(onContext)="onContext($event)"
			(onNodeClick)="onNodeClick2($event)"
			(onNodeMoved)="onNodeMoved($event)"
			[noDataText]="'Nessuna categoria, fare tasto destro per aggiungere'"
		>
		</app-tree>
	</div>

	<app-category-selector-dialog
		*ngIf="holderEntity"
		[entity]="holderEntity"
		[visible]="selectorIsVisible"
		(selectOrClose)="onSelectorClose($event)"
	></app-category-selector-dialog>
</app-kcomponent-container>
