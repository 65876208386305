import { CommonModule } from '@angular/common';
import {
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	Input,
	NgModule,
	OnDestroy,
	Output,
	SimpleChanges,
	ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { DxContextMenuComponent, DxContextMenuModule } from 'devextreme-angular/ui/context-menu';
import { DxLoadIndicatorModule } from 'devextreme-angular/ui/load-indicator';
import { DxTreeViewComponent, DxTreeViewModule } from 'devextreme-angular/ui/tree-view';
import * as events from 'devextreme/events';
import { EntityService } from 'src/app/server/entity.service';
import { NavigationNode } from 'src/app/server/model/navigation-node';
import { CacheService } from 'src/app/shared/caching/cache-service';
import { KItem } from './../../../ui/shared/kitem';
import { openFavourite } from './fav.function';

export class MenuItem {
	id = '';
	text = '';
}

@Component({
	selector: 'app-side-navigation-menu',
	templateUrl: './side-navigation-menu.component.html',
	styleUrls: ['./side-navigation-menu.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SideNavigationMenuComponent implements AfterViewInit, OnDestroy {
	// @ViewChild(DxTreeViewComponent, { static: true }) menu: DxTreeViewComponent|null = null;

	@ViewChild('treeview') menu: DxTreeViewComponent | null = null;

	@Output() selectedItemChanged = new EventEmitter<any>();
	@ViewChild(DxContextMenuComponent, { static: false })
	contextMenu?: DxContextMenuComponent;
	@Output() openMenu = new EventEmitter<any>();

	menuItems: KItem[] = [
		{ id: 'new', text: 'Nuovo', icon: 'add' },
		{ id: 'search', text: 'Cerca', icon: 'find' },
		{ id: 'dashboard', text: 'Dashboard', icon: 'smalliconslayout' },
	];

	private _selectedItem: String | null = null;
	private _items: NavigationNode[] = [];
	private _allItems: (NavigationNode | undefined)[] = [];
	private _compactMode = true;
	entitySelected: string | null = null;
	loading: boolean = true;

	@Input()
	set selectedItem(value: String) {
		// console.log(value);

		this._selectedItem = value;
		if (!this.menu?.instance) {
			return;
		}

		this.menu.instance.selectItem(value);
	}

	get items() {
		return this._items;
	}

	@Input()
	get compactMode() {
		return this._compactMode;
	}

	set compactMode(val) {
		this._compactMode = val;

		if (!this.menu?.instance) {
			return;
		}

		if (val) {
			this.menu.instance.collapseAll();
		} else {
			this.menu.instance.expandItem(this._selectedItem);
		}
	}

	constructor(
		private elementRef: ElementRef,
		private service: EntityService,
		private cache: CacheService,
		private router: Router,
		private cdr: ChangeDetectorRef,
	) {
		this.cache.init().then(() => {
			this.service.getMenu().subscribe((response) => {
				this._items = response;
				this._allItems = this.items.flatMap((c) => c.items).flatMap((c) => c?.items);
				this.loading = false;
				this.cdr.markForCheck();
			});
		});
	}

	onItemClick(event: any) {
		this.selectedItemChanged.emit(event);
		// console.log(event.itemData);
		if (event.itemData.path !== null) {
			this.menu?.instance.collapseAll();
		}
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['compactMode']?.currentValue === false) {
			this.onShow();
		} else if (changes['compactMode']?.currentValue === true) {
			this.onHide();
		}
	}

	onHide() {
		// console.log('hiding', this.router.url);

		if (this.menu) {
			this.menu.searchValue = '';
		}
	}

	onShow() {
		console.log('showing', this.router.url);
		let parts = this.router.url.split('/');
		parts = parts.splice(0, parts.length - 1);
		const recomposed = parts.join('/');

		const f = this._allItems.find((i) => {
			return recomposed === i?.path;
		});

		if (f) {
			console.log('expanding', f.path);
			this.menu?.instance.expandItem(f);
			this.menu?.instance.selectItem(f);
		}
	}

	ngAfterViewInit() {
		events.on(this.elementRef.nativeElement, 'dxclick', (e: MouseEvent) => {
			this.openMenu.next(e);
		});
	}

	ngOnDestroy() {
		events.off(this.elementRef.nativeElement, 'dxclick');
	}

	contextMenuItemClick($event: any) {
		// console.log($event);

		if (this.entitySelected && $event.itemData.id === 'fav') {
			this.service.clone(this.entitySelected, $event.itemData.value).subscribe((response) => {
				if (response.success) {
					const path = `/entity/edit/${this.entitySelected}/${response.data.instanceId}`;
					// console.log(path);

					this.selectedItemChanged.emit({
						event: $event.event,
						itemData: {
							path: path,
						},
					});
				} else {
					console.error(response.message);
				}

				// this.cdr.markForCheck();
			});

			// console.log($event);
		} else if (this.entitySelected) {
			// this.compactMode = true;
			// console.log([$event.itemData.id, this.entitySelected]);
			// this.router.navigate(['entity', $event.itemData.id, this.entitySelected, 'any']);

			this.selectedItemChanged.emit({
				event: $event.event,
				itemData: {
					path: `/entity/${$event.itemData.id}/${this.entitySelected}`,
				},
			});
		}
	}

	//  @ViewChild('ctxMenu') customContextMenu!: DxContextMenuComponent;

	async onCtxMenu($event: any) {
		// console.log($event);
		// console.log($event.element.style);
		// console.log($event.itemElement.style);

		// $event.element.style.overflow = 'visible';
		// $event.itemElement.style.overflow = 'visible';

		if ($event.itemData.path) {
			this.entitySelected = $event.itemData.path.split('/')[3];
			await openFavourite(this.entitySelected!, this.service, this.menuItems);
			this.cdr.markForCheck();
		} else {
			this.entitySelected = null;
		}
	}
}

@NgModule({
	imports: [CommonModule, DxTreeViewModule, DxContextMenuModule, DxLoadIndicatorModule],
	declarations: [SideNavigationMenuComponent],
	exports: [SideNavigationMenuComponent],
})
export class SideNavigationMenuModule {}
