import { ViewNode } from '../../../../server/model/view-node';
import { Task } from '../../../manager/ktaskbar/task';

export class KFieldValueChangePayload {
	task: Task;
	node: ViewNode;
	newValue: any;
	pendingChanges: number;
	childNode: ViewNode | null;

	constructor(
		task: Task,
		node: ViewNode,
		newValue: any,
		pendingChanges: number,
		childNode: ViewNode | null = null,
	) {
		this.task = task;
		this.node = node;
		this.newValue = newValue;
		this.pendingChanges = pendingChanges;
		this.childNode = childNode;
	}
}
