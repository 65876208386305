<div id="dr-container">
	<div class="drclabel">dal</div>
	<div class="drcdate">
		<dx-date-box id="dfrom" [value]="from" (onValueChanged)="onValueChanged($event)"></dx-date-box>
	</div>
	<div class="drclabel">al</div>
	<div class="drcdate">
		<dx-date-box id="dto" [value]="to" (onValueChanged)="onValueChanged($event)"></dx-date-box>
	</div>
</div>
