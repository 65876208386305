import { ChangeDetectionStrategy, Component, Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { EnumHelper } from 'src/app/server/helpers/enum-helper';
import { FieldValue } from 'src/app/server/model/field-value';
import { KAttributeBaseComponent } from '../kbase/kattribute-base.component';
import { HtmlViewerOption } from './khtml-viewer-option';

@Pipe({ name: 'safeHtml' })
export class SafeHtmlPipe implements PipeTransform {
	constructor(private sanitizer: DomSanitizer) {}

	transform(value: any) {
		return this.sanitizer.bypassSecurityTrustHtml(value);
	}
}

@Component({
	selector: 'app-khtml-viewer',
	templateUrl: './khtml-viewer.component.html',
	styleUrls: ['./khtml-viewer.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KHtmlViewerComponent extends KAttributeBaseComponent {
	innerValue = '';
	viewerType: HtmlViewerOption | null = null;
	style: string = 'htmlView';

	override buildOptions(optionId: number | null): void {
		this.viewerType = optionId ?? null;
	}

	getRightCss(): string {
		let cumulative: string = this.style;

		if (EnumHelper.is(HtmlViewerOption.Prettify, this.viewerType)) {
			cumulative += ' pretty';
		}

		return cumulative;
	}

	override setValue(value: FieldValue | null): void {
		this.innerValue = value?.value ?? '';
	}
}
