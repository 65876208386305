import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { EntityService } from 'src/app/server/entity.service';
import { EnumHelper } from 'src/app/server/helpers/enum-helper';
import { EntityAttributeValidationType } from 'src/app/server/model/entity-attribute-validation-type';
import { EntityAttributeValueType } from 'src/app/server/model/entity-attribute-value-type';
import { EntityHolder } from 'src/app/server/model/entity-holder';
import { LogicalAttributeType } from 'src/app/server/model/logical-attribute-type';
import { SearchParameter } from 'src/app/server/model/search-parameter';
import { Validation } from 'src/app/server/model/validation';
import { KSearchDialogPayload } from 'src/app/ui/shared/ksearch-dialog/ksearch-dialog-payload';
import { AttributeMetadata } from '../../../../server/model/attribute-metadata';
import { FieldValue } from '../../../../server/model/field-value';
import { KTaskBarEvent, KTaskBarEventType } from '../../../manager/ktaskbar/ktaskbar-event';
import { KTitleChangePayload } from '../../../manager/ktaskbar/ktitle-change-payload';
import { KContextPayloadCallback } from './../../../shared/kcontext-menu/kcontext-payload';
import {
	ProcessRuleCompletePayload,
	ProcessRuleErrorPayload,
	ProcessRuleStartPayload,
	processOnChangeRule,
} from './../../kform/process-on-change-rule.function';
import { SearchPanelControlOption } from './../kgrid/grid-control-option';
import { IKAttributeBaseComponent } from './abstract';
import { KBaseComponent } from './kbase.component';
import { KFormFieldRefreshAfterDialogClosePayload, KFormFieldRefreshPayload } from './kfield-refresh-payload';
import { KFieldValidChangePayload } from './kfield-valid-change-payload';

@Component({
	selector: 'app-attribute-base',
	template: '',
})
export class KAttributeBaseComponent extends KBaseComponent implements OnInit, OnDestroy, IKAttributeBaseComponent {
	value: FieldValue | null = null;
	type!: EntityAttributeValueType;
	metadata!: AttributeMetadata;
	allowNull = false;
	isType = false;

	validationType = 'none';
	validation = new Validation(null);
	subscription: Subscription | null = null;

	get service(): EntityService {
		return this.container.service;
	}

	get root(): EntityHolder {
		return this.container.holder ?? EntityHolder.fromScratch('', '', '');
	}

	override ngOnInit(): void {
		super.ngOnInit();

		// https://js.devexpress.com/Demos/WidgetsGallery/Demo/Validation/Overview/Angular/Light/
		if (this.metadata?.validation) {
			this.validation = this.metadata?.validation;

			switch (this.validation.type) {
				case EntityAttributeValidationType.Text:
					this.validationType = 'stringLength';

					break;
				case EntityAttributeValidationType.Date:
					break;
				case EntityAttributeValidationType.Decimal:
					break;
				case EntityAttributeValidationType.Money:
					break;
				case EntityAttributeValidationType.Number:
					break;

				case EntityAttributeValidationType.Time:
					break;

				case EntityAttributeValidationType.RegExp:
					break;
			}
		}

		this.handleManagerEvent();
	}

	handleManagerEvent() {
		// console.log('subscribe', this.container);

		this.subscription = this.container.events.subscribe((event: KTaskBarEvent) => {
			switch (event.type) {
				case KTaskBarEventType.FormFieldRefreshAfterSearchDialogClose:
					if (
						event.payload instanceof KFormFieldRefreshAfterDialogClosePayload &&
						event.payload.attributeName === this.node.attributeName
					) {
						this.onAfterDialogClose(event.payload);
					}

					break;

				case KTaskBarEventType.FormFieldRefresh:
					if (
						event.payload instanceof KFormFieldRefreshPayload &&
						event.payload.attributeName === this.node.attributeName
					) {
						this.fieldValueIsChanged(event.payload.value!, event.payload.skipRuleProcess, event.payload.updateValue);
					}
					break;

				case KTaskBarEventType.ProcessRuleStart:
					if ((event.payload as ProcessRuleStartPayload) && event.payload.attributeName === this.node.attributeName) {
						this.onRuleStart(event.payload);
					}
					break;
				case KTaskBarEventType.ProcessRuleError:
					if ((event.payload as ProcessRuleErrorPayload) && event.payload.attributeName === this.node.attributeName) {
						this.onRuleError(event.payload);
					}
					break;

				case KTaskBarEventType.ProcessRuleComplete:
					// console.log(event);
					if (
						(event.payload as ProcessRuleCompletePayload) &&
						event.payload.attributeName === this.node.attributeName
					) {
						this.onRuleComplete(event.payload);
					}
					break;

				case KTaskBarEventType.ContextMenuCallBack:
					if (event.payload instanceof KContextPayloadCallback && event.payload.target === this.node.name) {
						this.onContextMenuCallback(event.payload.item.id, event.payload);
					}
					break;
			}
		});
	}

	openSearchDialog(
		filters: SearchParameter[],
		entityId: string,
		reference: any,
		isMultiselect: boolean,
		isForNew: boolean,
		excludeFilter: boolean,
		caller: EntityHolder | null = null,
	) {
		const payload: KSearchDialogPayload = new KSearchDialogPayload(
			'search',
			entityId,
			this.container.task.id,
			this.node.attributeName,
			filters,
			isMultiselect,
			reference,
			excludeFilter,
			isForNew,
			caller ?? this.container.holder,
			EnumHelper.is(this.node.optionId, SearchPanelControlOption.PopUpDelete),
		);
		this.container.on(KTaskBarEventType.ShowDialog, payload);
	}

	onAfterDialogClose(payload: KFormFieldRefreshAfterDialogClosePayload) {}

	onRuleStart(payload: ProcessRuleStartPayload) {
		// console.log('onRuleStart', payload);
		this.container.on(KTaskBarEventType.StartLoader);
		// this.container.container.manager.startLoader();
	}

	onRuleComplete(payload: ProcessRuleCompletePayload) {
		// console.log('onRuleComplete', payload);
		this.container.global.hideLoader();
		// this.container.container.manager.stopLoader();
	}

	onRuleError(payload: ProcessRuleErrorPayload) {
		// console.log('onRuleError', payload);
		this.container.global.hideLoader();
		// this.container.container.manager.stopLoader();
	}

	onContextMenuCallback(command: string, payload: KContextPayloadCallback) {}

	ngOnDestroy(): void {
		this.subscription?.unsubscribe();
	}

	override setValue(value: FieldValue | null): void {
		this.value = value;

		if (!this.type && this.metadata) {
			this.type = this.metadata.type;
		}
	}

	collectionFieldValueIsReducing(toRemoveHolders: EntityHolder | EntityHolder[], recursive: boolean) {
		const newValue = this.container.holder.removeInCollection(this.node.attributeName, toRemoveHolders, recursive);
		return this.fieldValueIsChanging(newValue, true);
	}

	collectionFieldValueIsChanging(changedOrAddedHolders: EntityHolder[]) {
		const newValue = this.container.holder.replaceInCollection(this.node.attributeName, changedOrAddedHolders);
		return this.fieldValueIsChanging(newValue, true);
	}

	fieldValueIsChanging(newValue: any, refreshUiValue: boolean = false): void {
		// console.log('valueIsChanging', this.node.name, newValue);

		const oldField = this.container.holder.getField(this.node.attributeName);
		const oldFiedValue = oldField?.value;

		if (oldFiedValue !== newValue) {
			this.setFieldValue(newValue);
			const newField = this.container.holder.getField(this.node.attributeName)!;
			this.fieldValueIsChanged(newField, false, refreshUiValue);
		}
	}

	fieldValueIsChanged(newField: FieldValue, skipProcessRule: boolean, refreshUiValue: boolean): void {
		// console.log('valueIsChanged', this.node.name, newField.value);
		// ad altri non field che il titolo è cambiato
		this.broadcastTitleChangeIfNeed(newField);

		// setto il valore nel caso fosse un esterno che lo cambia
		if (refreshUiValue) {
			this.setValue(newField);
		}

		// forse è meglio sempre processarle e poi nel complete fare le cose dopo
		// come prevenire i loop?

		if (!skipProcessRule) {
			processOnChangeRule(this.container, this.node.advancedData2.uiRules, this.node.attributeName).then(
				(success: boolean) => {
					if (success) {
						this.broadcastFieldValueChange(newField);
						// this.container.global.hideLoader();
					}
				},
			);
		} else {
			// ad altri non field che un valore è cambiato
			this.broadcastFieldValueChange(newField);
			// this.container.global.hideLoader();
		}
	}

	private setFieldValue(newValue: any) {
		this.container.updateField(this.node.attributeName, newValue);
	}

	broadcastFieldValueChange(field: FieldValue) {
		this.container.notifyFieldValueChanged(this.node, field, this.container.holder.history?.length ?? 0);
	}

	broadcastTitleChangeIfNeed(field: FieldValue) {
		if (EnumHelper.is(field.logicalType, LogicalAttributeType.NamePart)) {
			this.container.holder.recalculateHumanReadable();
			const newTitle: string = this.container.holder.humanReadableName;
			this.broadcastTitleChange(newTitle);
		}
	}

	broadcastTitleChange(newTitle: string) {
		this.container.on(
			KTaskBarEventType.TitleChange,
			new KTitleChangePayload(newTitle, this.container.task, this.container.task.entityId),
		);
	}

	broadcastFieldRefresh(skipRuleProcess: boolean = false, updateValue: boolean = true) {
		this.container.on(
			KTaskBarEventType.FormFieldRefresh,
			new KFormFieldRefreshPayload(
				this.container.task,
				this.node.attributeName,
				this.container.holder.getField(this.node.attributeName),
				this.container.holder.history?.length,
				skipRuleProcess,
				updateValue,
			),
		);
	}

	onValidationChange(event: any): void {
		if (event.name === 'isValid') {
			if (this.container.data) {
				const i = this.container.data?.data?.invalids?.indexOf(this.node.attributeName) ?? 0;

				if (!event.value) {
					if (i === -1) {
						this.container.data?.data?.invalids?.push(this.node.attributeName);
					}
				} else {
					if (i >= 0) {
						this.container.data?.data?.invalids?.splice(i, 1);
					}
				}

				this.container.saveState();

				this.container.on(
					KTaskBarEventType.FieldValidChange,
					new KFieldValidChangePayload(this.container.task, this.node, event.value),
				);
			}
		}
	}
}
