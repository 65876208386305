<ng-container *ngIf="isLoggedIn">
	<app-side-nav-outer-toolbar [title]="title" [icon]="icon">
		<router-outlet></router-outlet>

		<app-footer>
			Copyright © 2011-{{ global.currentYear }} Mindset S.r.l. Tutti i diritti
			riservati.
		</app-footer>
	</app-side-nav-outer-toolbar>
</ng-container>

<ng-container *ngIf="!isLoggedIn">
	<app-unauthenticated-content></app-unauthenticated-content>
</ng-container>

<dx-load-panel
	#loadPanel
	shadingColor="rgba(0,0,0,0.4)"
	[position]="{ of: '#employee' }"
	[(visible)]="working"
	[showIndicator]="true"
	[showPane]="true"
	[shading]="true"
	[hideOnOutsideClick]="false"
>
</dx-load-panel>
