import { Component } from '@angular/core';
import { FieldValue } from 'src/app/server/model/field-value';
import { KAttributeBaseComponent } from '../kbase/kattribute-base.component';

@Component({
	selector: 'app-kprogress-box',
	templateUrl: './kprogress-box.component.html',
	styleUrls: ['./kprogress-box.component.scss'],
})
export class KProgressBoxComponent extends KAttributeBaseComponent {
	prglabel: string = '';
	isSimple: boolean = true;
	color: string = '#fa0000';
	min: number = 0;
	max: number = 100;
	ival: number = 0;

	override ngOnInit(): void {
		super.ngOnInit();
	}

	override setValue(value: FieldValue | null): void {
		super.setValue(value);

		this.ival = value?.value as number;

		// this.ival = 60;

		if (this.ival <= 0) {
			this.prglabel = 'In attesa';
			this.color = '#ff0000';
		} else if (this.ival >= 100) {
			this.prglabel = 'Conclusa';
			this.color = '#3ecc00';
		} else {
			this.prglabel = 'In esecuzione';
			this.color = '#f1c232';
		}

		this.cdr.markForCheck();
	}

	format(val: number) {
		return `Avanzamento: ${val * 100}%`;
	}
}
