<div class="container">
	<div id="content">
		<as-split direction="horizontal">
			<as-split-area [size]="40" class="nav">
				<ng-content select="[nav]"></ng-content>
			</as-split-area>
			<as-split-area [size]="60" class="detail">
				<ng-content select="[detail]"></ng-content>
			</as-split-area>
		</as-split>
	</div>
</div>
