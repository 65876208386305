import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DxDataGridModule, DxFormModule } from 'devextreme-angular';
import { EntityComponent } from './pages/entity/entity.component';
import { HomeComponent } from './pages/home/home.component';
import {
	ChangePasswordFormComponent,
	CreateAccountFormComponent,
	LoginFormComponent,
	ResetPasswordFormComponent,
} from './shared/components';
import { SetupComponent } from './shared/components/setup/setup.component';
import { knownRuotes } from './shared/known-ruotes';
import { AuthGuardService } from './shared/services/auth-guard.service';
import { UiModule } from './ui/ui.module';

const routes: Routes = [
	{
		path: 'survey',
		loadChildren: () =>
			import('./modules/survey/survey.module').then((m) => m.SurveyModule),
	},

	{
		path: 'workflow/:id',
		loadChildren: () =>
			import('./modules/workflow/workflow.module').then(
				(m) => m.WorkflowModule,
			),
	},

	// {
	//   path: 'entity/search/ntt_workspace_home/any',
	//   component: EntityComponent,
	//   canActivate: [AuthGuardService]
	// },

	{
		path: 'setup',
		component: SetupComponent,
		canActivate: [AuthGuardService],
		// redirectTo: '/setup'
	},

	{
		path: 'home',
		component: HomeComponent,
		canActivate: [AuthGuardService],
	},
	{
		path: 'entity/:actionId/:entityId/:id',
		component: EntityComponent,
		canActivate: [AuthGuardService],
	},

	{
		path: 'login-form',
		component: LoginFormComponent,
		canActivate: [AuthGuardService],
	},
	{
		path: 'reset-password',
		component: ResetPasswordFormComponent,
		canActivate: [AuthGuardService],
	},
	{
		path: 'create-account',
		component: CreateAccountFormComponent,
		canActivate: [AuthGuardService],
	},
	{
		path: 'change-password/:recoveryCode',
		component: ChangePasswordFormComponent,
		canActivate: [AuthGuardService],
	},
	{
		path: '**',
		redirectTo: knownRuotes.default,
	},
];
//, { enableTracing: true }
@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			// onSameUrlNavigation: 'reload', //TODO verify on kform da entità a entità
		}),
		DxDataGridModule,
		DxFormModule,
		UiModule,
	],
	providers: [AuthGuardService],
	exports: [RouterModule],
	declarations: [HomeComponent, EntityComponent],
})
export class AppRoutingModule {}
