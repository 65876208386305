import { Component } from '@angular/core';
import { KBaseComponent } from '../kbase/kbase.component';

@Component({
	selector: 'app-kseparator',
	templateUrl: './kseparator.component.html',
	styleUrls: ['./kseparator.component.scss'],
})
export class KSeparatorComponent extends KBaseComponent {
	override isDecorative = true;
}
