import { KnowItemNames } from 'src/app/server/helpers/constants';
import { Command, commandRequireForm } from 'src/app/server/model/commad';
import { ExecuteCustomActionResponse } from 'src/app/server/model/save-response';
import { KTaskBarEventType } from '../../manager/ktaskbar/ktaskbar-event';
import { KSearchCompontentBase } from '../../search/ksearch/ksearch-base.component';
import { KCommandDialogPayload } from '../../shared/kcommand-dialog/kcommand-dialog-payload';
import { EntityHolder } from './../../../server/model/entity-holder';
import { KSearchComponent } from './../../search/ksearch/ksearch.component';
import { processCustomActionResponse } from './kform-functions';
import { KFormComponent } from './kform.component';

export function onExecuteCommand(form: KFormComponent | KSearchComponent, holders: EntityHolder[], command: Command) {
	if (!holders || holders.length === 0) {
		form.global.showError('Selezione alemeno un elemento');

		return;
	}

	if (commandRequireForm(command)) {
		form.on(KTaskBarEventType.ShowCommandDialog, new KCommandDialogPayload(command));
		return;
	}

	const updatedData = holders[0];

	const args: string[] = [];
	if (form instanceof KSearchComponent && holders.length > 0) {
		args.push(holders.map((c) => c.instanceId).join(KnowItemNames.MultiValueSeparator));
	}

	executeCommand(
		form,
		updatedData.entityId,
		command.availableInSearch ? null : updatedData.instanceId,
		command.availableInSearch ? null : updatedData,
		command,
		args,
	);
}

export function executeCommand(
	form: KFormComponent | KSearchCompontentBase,
	entityId: string,
	instanceId: string | null,
	updatedData: EntityHolder | null,
	command: Command,
	args: string[],
) {
	form.service
		.executeCommand(entityId, instanceId, updatedData, command, args, true)
		.subscribe((reponse: ExecuteCustomActionResponse) => {
			processCustomActionResponse(form, reponse);
		});
}
