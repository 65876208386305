import { EntityAttributeValueType } from './entity-attribute-value-type';
import { IdName } from './id-name';
import { SearchParameterType } from './search-parameter-type';

export class QuickFilterValue extends IdName {
	constructor(public op: SearchParameterType) {
		super('', '');
	}

	values: QuickFilterValue[] = [];
	removable = true;

	static create(
		attributeName: string,
		label: string,
		valueId: string,
		valueLabel: string,
		type: EntityAttributeValueType = EntityAttributeValueType.Text,
		op: SearchParameterType = SearchParameterType.Contains,
	): QuickFilterValue {
		let c: boolean | null = null;

		if (
			op === SearchParameterType.Contains ||
			op === SearchParameterType.Equal
		) {
			c = true;
		} else if (
			op === SearchParameterType.NotContains ||
			op === SearchParameterType.NotEqual
		) {
			c = false;
		}

		return Object.assign(new QuickFilterValue(op), {
			id: valueId,
			name: valueLabel,
			removable: true,
			values: null,
			checked: c,
		});
	}

	static parse(raw: any): QuickFilterValue {
		const me = new QuickFilterValue(raw.op);
		me.id = raw.id;
		me.name = raw.name;
		me.values = raw.values?.map((v: QuickFilterValue) =>
			QuickFilterValue.parse(v),
		);
		return me;
	}

	private static evaluateOp(
		value: boolean | null,
		defaultOp: SearchParameterType,
	): SearchParameterType {
		if (value === true || value == null) {
			return defaultOp;
		} else {
			switch (defaultOp) {
				case SearchParameterType.Contains:
					return SearchParameterType.NotContains;
				case SearchParameterType.Equal:
					return SearchParameterType.NotEqual;
				default:
					return defaultOp;
			}
		}
	}
}

export class QuickFilter extends IdName {
	type: EntityAttributeValueType = EntityAttributeValueType.Text;
	values: QuickFilterValue[] = [];
	op: SearchParameterType = SearchParameterType.Equal;
	single = false;

	constructor(op: SearchParameterType) {
		super('', '');
		this.op = op;
	}

	static parse(raw: any): QuickFilter {
		const me = new QuickFilter(raw.op);
		me.id = raw.id;
		me.type = raw.type;
		me.name = raw.name;
		me.values = raw.values?.map((v: QuickFilterValue) =>
			QuickFilterValue.parse(v),
		);
		me.op = raw.op;
		me.single = raw.single;

		return me;
	}
}
