import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { date2ddmmyyyy } from 'src/app/shared/formatting/formatting-helper';
import { KFilterEditorComponent } from '../../kfilter-editor.component';
import { FilterEditorBaseComponent } from '../filter-editor-base.component';

@Component({
	selector: 'app-date-range-filter',
	templateUrl: './date-range-filter.component.html',
	styleUrls: ['./date-range-filter.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateRangeFilterComponent extends FilterEditorBaseComponent {
	from!: Date;
	to!: Date;

	constructor(parent: KFilterEditorComponent, cdr: ChangeDetectorRef) {
		super(parent, cdr);

		// if (this.parent.filter && this.parent.filter.value) {
		// 	this.value = this.parent.filter.value;
		// }
		// else {

		// 	this.value = [];
		// 	this.value.push(new Date());

		// 	let dayafter = new Date();
		// 	dayafter.setDate(dayafter.getDate() + 1);

		// 	this.value.push(dayafter);
		// }
	}

	override ngOnInit(): void {
		if (!this.value) {
			const curryear = new Date().getFullYear();

			this.from = new Date(curryear, 0, 1);
			this.to = new Date(curryear, 11, 31);
			this.updateValue();
		} else {
			console.log(this.value);
		}
	}

	override onValueChanged(e: any) {
		if (e.element.id == 'dfrom') {
			this.from = e.value;
		} else if (e.element.id == 'dto') {
			this.to = e.value;
		}

		this.updateValue();
	}

	updateValue() {
		this.value = date2ddmmyyyy(this.from) + '|' + date2ddmmyyyy(this.to);
		this.parent.filter!.value = this.value;
	}

	// formatDate(inputDate: Date | null): string | null {
	// 	if (inputDate) {
	// 		let date, month, year;
	// 		date = inputDate.getDate();
	// 		month = inputDate.getMonth() + 1;
	// 		year = inputDate.getFullYear();

	// 		date = date.toString().padStart(2, '0');
	// 		month = month.toString().padStart(2, '0');
	// 		return `${date}/${month}/${year}`;
	// 	} else return null;
	// }
}
