import { EnumHelper } from '../helpers/enum-helper';
import { AttributeMetadata } from './attribute-metadata';
import { EntityStatus } from './entity-status';
import { IdName } from './id-name';
import { LogicalAttributeType } from './logical-attribute-type';
import { Related } from './realted';

export class EntityStatusStateStatus extends IdName {
	status!: EntityStatus;
}

export class EntityStatusState {
	constructor(s: EntityStatus) {
		this.current = s;
	}

	current: EntityStatus;
	prevs: EntityStatusStateStatus[] = [];
	nexts: EntityStatusStateStatus[] = [];
}

export function statuses(metadata: EntityMetadata, status1: EntityStatus): EntityStatusState {
	const s = new EntityStatusState(status1);
	const status = metadata?.statuses;
	console.log('status', status1);

	if (status != null) {
		metadata?.statuses.forEach((status: EntityStatus) => {
			if (status1?.follows?.indexOf(status.actionId) >= 0) {
				if (status.enumId < status1.enumId) {
					s.prevs.push({
						id: status.enumId.toString(),
						name: status.returnLabel ?? status.label,
						status: status,
					});
				} else {
					s.nexts.push({
						id: status.enumId.toString(),
						name: status.menuLabel,
						status: status,
					});
				}
			}
		});
	}

	return s;
}

export class EntityMetadata {
	id = '';
	name = '';
	permissions: string[] = [];
	documents: Related[] = [];

	statuses = new Map<number, EntityStatus>();
	attributes = new Map<string, AttributeMetadata>();
	nameFormat = '';
	requireDynamicFilters: boolean = false;
	requirePostSearchAnalisys: boolean = false;
	requireElaborate: boolean = false;
	canBeGeneratedByModel: boolean = false;
	// dbName: "[Organizationchart].[OrganizationUnit]"
	// hasChild: false
	// id: "ntt_install_organization-unit"
	// idKeyName: "Id"
	// isCustom: false
	// isMultiInheritance: false
	// isNew: false
	// isSubEntity: false
	// keyType: 9
	// logicalType: 10
	// menuAction: null
	// moduleId: "mdl_install"
	// name: "Unità organizzativa"
	// nameFormat: "{0}"
	// parentId: null
	// parentModuleId: "mdl_install"
	// requireDynamicFilters: false
	// requireElaborate: true
	// requirePostSearchAnalisys: false

	static parse(raw: any): EntityMetadata {
		const me = new EntityMetadata();

		// console.log(raw);

		me.id = raw.entity.id;
		me.nameFormat = raw.entity.nameFormat;
		me.name = raw.entity.name;
		me.requireElaborate = raw.entity.requireElaborate;
		me.requirePostSearchAnalisys = raw.entity.requirePostSearchAnalisys;
		me.requireDynamicFilters = raw.entity.requireDynamicFilters;

		raw.attributes
			?.sort((a: any, b: any) => a.nameIndex - b.nameIndex)
			?.forEach((attr: any) => {
				const a = new AttributeMetadata(attr);

				if (EnumHelper.is(a.logicalType, LogicalAttributeType.IsModel)) {
					me.canBeGeneratedByModel = true;
				}

				me.attributes.set(attr.name, a);
			});

		raw.statuses.forEach((status: any) => {
			me.statuses.set(status.enumId as number, Object.assign(new EntityStatus(), status));
		});

		raw.documents.forEach((document: any) => {
			me.documents.push(Object.assign(new Related(), document));
		});

		return me;
	}

	getInAggregate(entityId: string): AttributeMetadata | null {
		let found: AttributeMetadata | null = null;

		for (const entry of this.attributes) {
			if (
				entry[1].targetEntity?.id === entityId &&
				EnumHelper.is(entry[1].logicalType, LogicalAttributeType.Collection) &&
				EnumHelper.is(entry[1].logicalType, LogicalAttributeType.Member)
			) {
				found = entry[1];
				break;
			}
		}
		return found;
	}

	getOwner(entityId: string | null = null): AttributeMetadata | null {
		const owners = this.getByLogicalType(LogicalAttributeType.Owner);
		// console.log(owners);
		if (entityId) {
			return owners.find((c) => c.targetEntity?.id === entityId) ?? null;
		} else {
			return owners.length > 0 ? owners[0] : null;
		}
	}

	getByLogicalType(lt: LogicalAttributeType): AttributeMetadata[] {
		const founds: AttributeMetadata[] = [];

		for (const entry of this.attributes) {
			if (EnumHelper.is(entry[1].logicalType, lt)) {
				founds.push(entry[1]);
			}
		}
		return founds;
	}

	getFirstByeLogicalType(lt: LogicalAttributeType): AttributeMetadata | null {
		let found: AttributeMetadata | null = null;

		for (const entry of this.attributes) {
			if (EnumHelper.is(entry[1].logicalType, lt)) {
				found = entry[1];
				break;
			}
		}
		return found;
	}
}
