<dx-popup width="60%" height="auto" [showTitle]="title != null" [title]="title!" [dragEnabled]="false"
	[hideOnOutsideClick]="false" [showCloseButton]="true" [showCloseButton]="true" container=".dx-viewport"
	[(visible)]="isVisible" (onShown)="onVisibleChange($event)" (onHidden)="onHidden($event)">
	<div *dxTemplate="let data of 'content'">
		<div class="container">
			<dx-list #list [dataSource]="datasource" height="63vh" [searchEnabled]="true" searchExpr="humanReadableName"
				searchMode="contains" (onItemClick)="onItemClick($event)">
				<div *dxTemplate="let data of 'item'">
					<div>{{ data.humanReadableName }}</div>
				</div>
			</dx-list>

			<div>
				<dx-tree-view id="simple-treeview" height="63vh" [items]="items" showCheckBoxesMode="normal"
					selectionMode="multiple" [selectNodesRecursive]="true" [selectByClick]="true"
					(onSelectionChanged)="onSelectionChange($event)">
				</dx-tree-view>
			</div>
		</div>
	</div>
</dx-popup>
