export class Helper {
	static arrayMove(arr: any[], oldIndex: number, newIndex: number) {
		if (newIndex >= arr.length) {
			let k = newIndex - arr.length + 1;
			while (k--) {
				arr.push(undefined);
			}
		}
		arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
	}
}
