export enum EntityAttributeValueType {
	// Base 0 +
	Int,

	Text,

	LongText,

	Boolean,

	Date,

	Time,

	DateTime,

	Decimal,

	Double,

	Guid,

	Serializable,

	TimeSpan,

	Long,

	// Virtual Type
	FullText = 100,

	ProcessType = 101,

	RequestType = 102,

	// Components 1000 +
	File = 1001,

	RichText = 1002,

	Image = 1003,

	// hrVirtial
	Role = 600,

	Unit = 601,

	Factory = 602,
}
