import { KnowItemNames } from 'src/app/server/helpers/constants';
import { SearchParameter } from 'src/app/server/model/search-parameter';
import { SearchParameterType } from 'src/app/server/model/search-parameter-type';
import {
	KToolbarPayload,
	KToolbarSelectorload
} from '../edit/ktoolbar/ktoolbar-payload';
import { KTaskBarEventType } from '../manager/ktaskbar/ktaskbar-event';
import { KTaskbarService } from './../manager/ktaskbar/ktaskbar.service';

export function createNewButton(
	manager: KTaskbarService,
	entityId: string,
	canBeGeneratedByModel: boolean,
	isMultiInheritance: boolean,
	parentId: string | null = null,
): any {
	// console.log(canBeGeneratedByModel, isMultiInheritance, parentId);

	if (canBeGeneratedByModel || isMultiInheritance) {
		return multiNew(
			manager,
			entityId,
			canBeGeneratedByModel,
			isMultiInheritance,
			parentId,
		);
	} else {
		return singleNew(manager);
	}
}

function multiNew(
	manager: KTaskbarService,
	entityId: string,
	canBeGeneratedByModel: boolean,
	isMultiInheritance: boolean,
	parentId: string | null = null,
) {
	const items = [];

	if (canBeGeneratedByModel) {
		items.push({ value: 1, name: 'Nuovo da modello', icon: 'fa fa-files-o' });
	}
	if (isMultiInheritance) {
		items.push({
			value: 2,
			name: 'Nuovo da altra entità',
			icon: 'fa fa-file-text',
		});
	}

	// console.log(items);

	return {
		icon: 'icotbar icot-tb_add',
		hint: 'Nuovo/a',
		stylingMode: 'outlined',
		type: 'default',
		splitButton: true,
		useSelectMode: false,
		displayExpr: 'name',
		keyExpr: 'value',
		dropDownOptions: { width: 'auto' },

		items: items,
		onItemClick: (e: any) => {
			if (e.itemData.value === 1) {
				manager.on(
					KTaskBarEventType.FormFromModel,
					new KToolbarSelectorload(
						entityId,
						'Seleziona il modello',
						[
							SearchParameter.createWithOperator(
								KnowItemNames.IsModel,
								SearchParameterType.Equal,
								'true',
							),
						],
						false,
					),
				);
			} else if (e.itemData.value === 2) {
				manager.on(
					KTaskBarEventType.FormFromModel,
					new KToolbarSelectorload(parentId!, 'A partire da', [], true),
				);
			}
		},
		onButtonClick: (e: any) => {
			manager.on(
				KTaskBarEventType.FormAdd,
				new KToolbarPayload('add', manager.task!),
			);
		},
	};
}

function singleNew(manager: KTaskbarService): any {
	return {
		icon: 'icotbar icot-tb_add',
		stylingMode: 'outlined',
		type: 'default',
		hint: 'Aggiungi',

		onClick: () => {
			manager.on(
				KTaskBarEventType.FormAdd,
				new KToolbarPayload('add', manager.task!),
			);
		},
	};
}
