export class KItem {
	items?: KItem[] = [];
	icon?: string;
	selected?: boolean;
	expanded?: boolean;
	parentId?: string;
	value?: any;
	id: string;
	text: string;

	constructor(
		id: string,
		text: string,
		icon: string = 'pin',
		selected?: boolean,
		value?: any,
	) {
		this.id = id;
		this.text = text;
		this.icon = icon;
		this.value = value;
		this.selected = selected;
	}
}
