import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DxDiagramComponent } from 'devextreme-angular';
import ArrayStore from 'devextreme/data/array_store';
import { JsonProcessNode, JsonProcessNodeEdge, JsonProcessNodeSimple } from '../../../model/json-process-node';

@Component({
	selector: 'app-process',
	templateUrl: './process.component.html',
	styleUrls: ['./process.component.scss'],
})
export class ProcessComponent implements OnInit {
	#edges: JsonProcessNodeEdge[] = [];
	#nodess: JsonProcessNodeSimple[] = [];
	edgesDs!: ArrayStore;
	nodesDs!: ArrayStore;

	//shapeType: string = 'processItem';
	shapeType: string = 'cardWithImageOnLeft';

	@Input() get nodeAndEdges(): {
		edges: JsonProcessNodeEdge[];
		nodes: JsonProcessNodeSimple[];
	} {
		return {
			edges: this.#edges,
			nodes: this.#nodess,
		};
	}

	set nodeAndEdges(value: { edges: JsonProcessNodeEdge[]; nodes: JsonProcessNodeSimple[] }) {
		this.#edges = value.edges;
		this.#nodess = value.nodes;
		this.loadNodes(this.#nodess, this.#edges);
	}

	loadNodes(arg0: JsonProcessNodeSimple[], arg1: JsonProcessNodeEdge[]) {
		this.nodesDs = new ArrayStore({
			key: 'id',
			data: arg0,
		});

		this.edgesDs = new ArrayStore({
			key: 'id',
			data: arg1,
		});
	}

	nodes: any;
	@Input() isStandAlone: boolean = false;
	@Input() width: string = '100%';
	@Input() height: string = '100%';
	@Input() autoZoomMode: 'fitContent' | 'fitWidth' | 'disabled' = 'fitWidth';

	@Output() selectItem: EventEmitter<JsonProcessNode> = new EventEmitter<JsonProcessNode>();

	@ViewChild(DxDiagramComponent, { static: false })
	diagram!: DxDiagramComponent;
	toolBoxConfiguration: any;
	historyToolbarConfiguration: any;
	mainToolbarConfiguration: any;
	contextToolboxConfiguration: any;
	toolboxConfiguration: any;

	viewToolbarConfiguration: any;

	configurateControl() {
		this.contextToolboxConfiguration = {
			enabled: false,
		};

		this.toolboxConfiguration = {
			visibility: 'disabled',
		};

		this.historyToolbarConfiguration = {
			visible: false,
		};
		this.viewToolbarConfiguration = {
			visible: this.isStandAlone,
		};
		this.mainToolbarConfiguration = {
			visible: false,
		};
	}

	//  https://js.devexpress.com/Documentation/Guide/UI_Components/Diagram/Data_Binding/#Optional_Binding_Expressions
	//  https://js.devexpress.com/Documentation/Guide/UI_Components/Diagram/Shape_Types/

	ngOnInit(): void {
		this.configurateControl();
	}

	textExpr(obj: JsonProcessNode) {
		{
			switch (obj.type) {
				case 'verticalContainer':
				case 'processItemContainer':
					return obj.title;
				case 'cardWithImageOnLeft':
				case 'processItem':
					if (obj.title.length > 80) {
						return obj.title.slice(0, 77) + '...';
					} else {
						return obj.title;
					}
				default:
					return obj.title;
			}
		}
	}

	heigthExpr(obj: JsonProcessNode) {
		return 0.5;
	}

	widthExpr(obj: JsonProcessNode) {
		return 2;
	}

	imageUrlExpr(obj: JsonProcessNode) {
		//console.log(obj)
		return 'assets/images/workflow/' + obj?.wfType + '.svg';
	}

	itemStyleExpr(obj: JsonProcessNode) {
		const style = {
			stroke: '#444444',
			fill: '#FFFFFF',
		};

		switch (obj.wfType) {
			case 'DoWhileActivity':
				style.fill = '#F2F2F2';
				break;
			case 'IfActivity':
				style.fill = '#F2F2F2';
				break;
			case 'KeyParallelActivity':
				style.fill = 'F2F2F2';
				break;
			case 'ListOptionsActivity':
				style.fill = 'F2F2F2';
				break;
			case 'IfBranchActivity':
				style.fill = '#E6E6E6';
				break;
			case 'PhaseActivity':
				style.fill = '#E6E6E6';
				break;
			case 'ListBranchActivity':
				style.fill = 'E6E6E6';
				break;
			case 'SeqActivity':
				style.fill = '#E6E6E6';
				break;

			/*
						case 'StepActivity': style.fill = 'darkgray'
							break;
						case 'WorkflowActivity': style.fill = 'darkgray'
							break;
						case 'EntityContextActivity': style.fill = 'red'
							break;
						case 'ChoiceActivity': style.fill = 'cyan'
						break; */
		}
		// if (obj?.relatedRisks > 0) {
		//   if (obj?.isCritical) {
		//     style.stroke = 'darkorange';
		//   } else {
		//     style.stroke = 'red';
		//   }
		// }

		if (obj?.status === 'executing') {
			style.fill = '#ffcccc';
			// style.stroke = 'darkgrey';
		}
		if (obj?.status === 'closed') {
			style.fill = '#b3ffb3';
			// style.stroke = 'darkgrey';
		}

		return style;
	}

	selectionChangedHandler(e: { items: { dataItem: JsonProcessNode }[] }) {
		if (e.items.length > 0) {
			const selectedItem = e.items[0].dataItem;
			this.selectItem.emit(selectedItem);
		}
	}

}
