import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FilterEditorBaseComponent } from '../filter-editor-base.component';

@Component({
	selector: 'app-text-filter',
	templateUrl: './text-filter.component.html',
	styleUrls: ['./text-filter.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextFilterComponent extends FilterEditorBaseComponent {}
