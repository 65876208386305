import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { GlobalService } from '../shared/services/global/global.service';
import { ApiResponse } from './model/api.response';
import { LoginResponse } from './model/login-response';
import { PingResponse } from './model/ping-response';

@Injectable({
	providedIn: 'root',
})
export class ApiService {
	private http: HttpClient;
	private globalService: GlobalService;

	constructor(http: HttpClient, globalService: GlobalService) {
		this.http = http;
		this.globalService = globalService;
	}

	private checkVersion(baseUrl: string): Observable<PingResponse | null> {
		const pingUrl = baseUrl + '/api/core/ping';

		return this.http.get(pingUrl).pipe(
			map((c) => Object.assign(new PingResponse(), c)),
			tap((c) => console.log(c)),
			catchError(() => {
				console.error('not found: ' + pingUrl);
				return of(null);
			}),
		);
	}

	public getUrl(api: string): string {
		return `${this.globalService.data.serverUrl ?? ''}${api}`;
	}

	isValidUrl(value: any): Observable<PingResponse> {
		return this.checkVersion(value).pipe(
			map((response: PingResponse | null) => {
				return response ?? new PingResponse();
			}),
		);
	}

	login(login: string, pass: string): Observable<LoginResponse | null> {
		const request = {
			login,
			password: pass,
		};

		return this.post<LoginResponse>(this.getUrl('/api/infra/web-login'), request, undefined).pipe(
			map(
				(d) => {
					return Object.assign(new LoginResponse(), d);
				},
				catchError(() => {
					return of(null);
				}),
			),
		);
	}

	post<T extends ApiResponse>(url: string, request: any, accessToken: string | null | undefined = null): Observable<T> {
		if (accessToken == null) {
			accessToken = this.globalService.data.accessToken;
		}

		let headers = new HttpHeaders({
			'Content-Type': 'application/json',
		});

		if (accessToken != null) {
			headers = headers.set('Authorization', 'Bearer ' + accessToken);
		}

		const httpOptions = {
			headers,
		};

		return this.http.post<T>(url, request, httpOptions).pipe(catchError(this.handleError));
	}

	postWithErrorHandle<T extends ApiResponse, T2>(
		url: string,
		request: any,
		callback: (response: T) => T2 | null,
	): Observable<T2 | null> {
		url = this.getUrl(url);

		const accessToken = this.globalService.data.accessToken;

		let headers = new HttpHeaders({
			'Content-Type': 'application/json',
		});

		if (accessToken != null) {
			headers = headers.set('Authorization', 'Bearer ' + accessToken);
		}

		const httpOptions = {
			headers,
		};

		return this.http.post<T>(url, request, httpOptions).pipe(
			catchError(this.handleError),
			// tap(r1 => console.log(r1)),
			map((r) => this.handleApiReponseFail<T, T2>(r, callback)),
		);
	}

	handleApiReponseFail<T extends ApiResponse, T2>(response: T, callback: (response: T) => T2 | null): T2 | null {
		if (!response.success) {
			console.error(response.message);
		}
		return callback(response);
	}

	private handleError(error: HttpErrorResponse) {
		if (error.status === 0) {
			// A client-side or network error occurred. Handle it accordingly.
			console.error('An error occurred:', error.error);
		} else {
			// The backend returned an unsuccessful response code.
			// The response body may contain clues as to what went wrong.
			console.error(`Backend returned code ${error.status}, body was: `, error.error);
		}
		// Return an observable with a user-facing error message.
		return throwError('Something bad happened; please try again later.');
	}

	// private handleError(error: HttpErrorResponse) {
	//   let errorMessage:string = '';

	//   if (error.error instanceof ErrorEvent) {
	//     // A client-side or network error occurred. Handle it accordingly.
	//     errorMessage = 'An error occurred';
	//     console.error(error.error);
	//   } else {
	//     errorMessage = `Backend returned code ${error.status}, ` +
	//     `body was: ${error.error}`;
	//     console.log(error);
	//   }

	//   // console.log(errorMessage);

	//   return {
	//     success: false,
	//     message: errorMessage
	//   };
	// }
}
