import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { DxoUploadComponent } from 'devextreme-angular/ui/nested';
import dxFileUploader from 'devextreme/ui/file_uploader';
import { map } from 'rxjs/operators';
import { FieldValue } from 'src/app/server/model/field-value';
import { TemporaryImageUrlResponse } from 'src/app/server/model/temporari-image-url-response';
import { KAttributeBaseComponent } from '../kbase/kattribute-base.component';

@Component({
	selector: 'app-kimage',
	templateUrl: './kimage.component.html',
	styleUrls: ['./kimage.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KImageComponent extends KAttributeBaseComponent {
	image!: TemporaryImageUrlResponse;
	@ViewChild('fileUploader') fileUploader!: DxoUploadComponent;
	@ViewChild('popupWindow', { static: false }) popupWindow: any;
	@ViewChild('popupImage', { static: false }) popupImage: any;
	uploadUrl!: string;
	files: any[] = [];
	popupVisible: boolean = false;

	panelIsVisible: boolean = false;
	// fileName:string|null = null;
	// fileExt:string|null = null;

	override ngOnInit() {
		this.uploadUrl = encodeURI(
			this.container.global.data.serverUrl + '/api/upload-image',
		);
		this.image = new TemporaryImageUrlResponse(
			this.container.global.data.serverUrl!,
		);
		// this.fileName = this.container.holder.getField('OriginalName')?.value;
		// this.fileExt = this.container.holder.getField('Extension')?.value;

		// console.log(this.container.holder);

		super.ngOnInit();
	}


	getAttachIcon() {
		return "Carica";
	}

	openFullResolution() {
		window.open(this.image.fullUrl, '_blank');
	}

	// Method to open the popup
	openImagePopup() {
		this.popupVisible = true;
	}

	// Method to hide the popup
	hidePopup() {
		this.popupVisible = false;
	}



	onImageClear() {
		this.image = new TemporaryImageUrlResponse(
			this.container.global.data.serverUrl!,
		);
		this.fieldValueIsChanging(null, true);
		this.panelIsVisible = !this.panelIsVisible;
		this.cdr.markForCheck();
	}

	override setValue(value: FieldValue | null): void {
		super.setValue(value);

		if (!this.value || !this.value.value) {
			return;
		}

		this.container.service
			.getImage(this.value.value)
			.pipe(
				map((c) => {
					if (c instanceof TemporaryImageUrlResponse) {
						this.image = c;
						// this.image.url = encodeURI(this.container.global.data.serverUrl + c.url);

						this.cdr.markForCheck();
					}
				}),
			)
			.toPromise();
	}
	getPopupMaxHeight(): string {
		if (this.image.fullUrl) {
			const img = new Image();
			img.src = this.image.fullUrl;

			if (img.width > img.height) {
				return '73%';
			} else if (img.width < img.height) {
				return '100%';
			}
		}

		return '73%';
	}

	getPopupMaxWidth(): string {
		if (this.image.fullUrl) {
			const img = new Image();
			img.src = this.image.fullUrl;

			if (img.width > img.height) {
				return '73%';
			} else if (img.width < img.height) {
				return '100%';
			}
		}

		return '73%';
	}

	onFilesUploaded($event: any) {
		console.log($event);

		const tokenId = JSON.parse($event.request.response).tokenId;

		const x = this.fileUploader.instance as dxFileUploader;

		if (x) {
			x.removeFile(0);
		}

		this.panelIsVisible = false;

		this.fieldValueIsChanging(tokenId, true);
	}

	onShopUploadPanel() {
		this.panelIsVisible = !this.panelIsVisible;
	}

	ngAfterViewInit() {
		const imageElement = this.popupImage.nativeElement;
		imageElement.onload = () => {
			this.popupWindow.instance.option('width', 'auto');
			this.popupWindow.instance.option('height', 'auto');
		};
	}

}


