export enum LabelControlOption {
	// per le opzioni di default, vedi codice
	Default = 0,

	// nasconde la label
	HideLabel = 256,
	// Etichetta 50%
	Half = 512,
	// Etichetta 1/4
	AQuater = 1024,
	// Etichetta 1/3
	OneThird = 2048,

	TwoRows = 4096,
	// messaggio di alert
	Alert = 8192,
}
