import { ChangeDetectorRef, Component } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { FieldValue } from 'src/app/server/model/field-value';
import { KAttributeBaseComponent } from 'src/app/ui/edit/kcomponents/kbase/kattribute-base.component';
import { JsonProcessNodeEdge, JsonProcessNodeSimple } from '../../../model/json-process-node';
import { WorkflowService } from '../../../services/workflow.service';

@Component({
	selector: 'app-kworkflow-view',
	templateUrl: './kworkflow-view.component.html',
	styleUrls: ['./kworkflow-view.component.scss'],
})
export class KWorkflowViewComponent extends KAttributeBaseComponent {
	edges: JsonProcessNodeEdge[] = [];
	nodes: JsonProcessNodeSimple[] = [];
	workflowService: WorkflowService;

	subba: Subscription | null = null;
	refresh = new Subject<string>();

	constructor(cdr: ChangeDetectorRef, workflowService: WorkflowService) {
		super(cdr);

		this.workflowService = workflowService;

		this.subba = this.refresh
			.pipe(
				switchMap((x) => {
					return this.workflowService.getProcessFromXoml(x);
				}),
			)
			.subscribe((c) => {
				this.edges = c.edges;
				this.nodes = c.nodes;
				this.cdr.markForCheck();
			});
	}

	override setValue(value: FieldValue | null): void {
		//console.log(value);
		this.refresh.next(value?.value);
	}

	override ngOnDestroy(): void {
		this.subba?.unsubscribe();
		super.ngOnDestroy();
	}
}
