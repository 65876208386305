import { KTaskBaseComponent } from '../../manager/ktask/ktask-base.component';
import {
	KTaskBarEvent,
	KTaskBarEventType,
} from '../../manager/ktaskbar/ktaskbar-event';
import { KContextPayloadCallback } from '../../shared/kcontext-menu/kcontext-payload';
import { KQuickFilterPayload } from '../kquick-filters/kquickfilter-payload';
import {
	KSearchCompletePayload,
	KSearchSelectionChangePayload,
} from './ksearch-complete-payload';
import { KSearchData } from './ksearch-data';

export class KSearchCompontentBase extends KTaskBaseComponent {
	cacheKey!: string;
	columnEditorIsOpen: boolean = false;
	filterEditorIsOpen: boolean = false;

	get data(): KSearchData | null {
		if (this.cacheKey) {
			return this.state.get(this.cacheKey) as KSearchData;
		} else {
			return null;
		}
	}

	override handleEvents(event: KTaskBarEvent): void {
		switch (event.type) {
			case KTaskBarEventType.ShowColumnEditor:
				this.columnEditorIsOpen = true;
				this.cdr.markForCheck();
				break;

			case KTaskBarEventType.ShowFilterEditor:
				this.filterEditorIsOpen = true;
				this.cdr.markForCheck();
				break;

			case KTaskBarEventType.QuickFilterChange:
				if (event.payload instanceof KQuickFilterPayload) {
					this.onQuickFilterChange(event.payload);
				}

				break;

			case KTaskBarEventType.SelectionChange:
				if (event.payload instanceof KSearchSelectionChangePayload) {
					this.onSelectionChanged(event.payload);
				}

				break;

			case KTaskBarEventType.Ready:
				if (event.payload instanceof KSearchCompletePayload) {
					this.onSearchReady(event.payload);
				}

				break;

			case KTaskBarEventType.ContextMenuCallBack:
				if (event.payload instanceof KContextPayloadCallback) {
					this.onContextCallBack(event.payload.item.id);
				}
				break;
		}
	}

	onSelectionChanged(payload: KSearchSelectionChangePayload) {}

	onSearchReady(payload: KSearchCompletePayload): void {}

	onQuickFilterChange(payload: KQuickFilterPayload): void {}
}
