import { ViewNode } from './view-node';

export function getNodeByAttributeName(
	view: View,
	attributeName: string,
): ViewNode | null {
	return findRecursiveNode(view.nodes, attributeName);
}

function findRecursiveNode(
	nodes: ViewNode[],
	attributeName: string,
): ViewNode | null {
	for (let index = 0; index < nodes.length; index++) {
		const element = nodes[index];

		if (element.attributeName === attributeName) {
			return element;
		}

		const f1 = findRecursiveNode(element.nodes, attributeName);

		if (f1 != null) {
			return f1;
		}
	}

	return null;
}

export class View {
	id: string;
	// viewId:string;
	isDefault: boolean;
	isReadOnly: boolean;
	viewName: string;
	nodes: ViewNode[] = [];

	// id: "ntt_install_organization-chart"
	// isDefault: true
	// isReadOnly: true
	// name: "Organigramma"

	// type: 2
	// unusedAttributes: (2) [{…}, {…}]
	// viewId: "9d25c6e6-ec2e-4415-801d-e31ac79f0d73"
	// viewName: "Predefinita"
	// viewType: 1

	constructor(view: any) {
		// console.log(view);

		// più chiaro cosi?
		this.id = view.viewId;
		this.isDefault = view.isDefault;
		this.isReadOnly = view.isReadOnly;
		this.viewName = view.viewName;

		view?.nodes?.forEach((node: any) => {
			this.nodes.push(new ViewNode(node));
		});
	}
}
