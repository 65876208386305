import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Subject, Subscription, forkJoin, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { ICreateEntitiesFromMainMembers } from 'src/app/server/create-new-entities.services';
import { AggregateMember } from 'src/app/server/model/agregate-member';
import { EntityHolder } from 'src/app/server/model/entity-holder';
import { EntityInfo } from 'src/app/server/model/entity-info';
import { FieldValue } from 'src/app/server/model/field-value';
import { LogicalAttributeType } from 'src/app/server/model/logical-attribute-type';
import { SearchParameter } from 'src/app/server/model/search-parameter';
import { TreeNode } from 'src/app/ui/controls/tree/tree-note';
import { NodeSelectionChangeArg } from 'src/app/ui/controls/tree/tree.component';
import { KContextPayloadCallback } from 'src/app/ui/shared/kcontext-menu/kcontext-payload';
import { KSearchDialogClosePayload } from 'src/app/ui/shared/ksearch-dialog/ksearch-dialog-close-payload';
import { resolveFilters } from '../kbase/functions';
import { SaveResponse } from './../../../../server/model/save-response';
import { KAggregateTreeComponent } from './kaggregate-tree.component';
import { TreeNodeContextReference, TreeNodeReference } from './model';

@Component({
	selector: 'app-kordered-tree',
	templateUrl: './kordered-tree.component-related.html',
	styleUrls: ['./kaggregate-tree.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KOrderedTreeComponentRelated extends KAggregateTreeComponent {
	override searchOrNew: boolean = false;
	override hasDetails: boolean = true;
	override isTreeOrdered: boolean = true;
	refresh!: Subject<void>;
	loadHolterToInLineForm!: Subject<EntityHolder>;
	deletePipe!: Subject<EntityHolder>;
	save!: Subject<EntityHolder[]>;
	reIndex!: Subject<EntityHolder[]>;
	createNewAndSave!: Subject<ICreateEntitiesFromMainMembers>;

	refreshSubscription: Subscription | null = null;
	loadSubscription: Subscription | null = null;
	deleteSubscription: Subscription | null = null;
	saveSubscription: Subscription | null = null;
	reIndexSubscription: Subscription | null = null;
	createNewAndSaveSubscription: Subscription | null = null;

	override onKeystoneInit() {
		if (!this.refreshSubscription) {
			this.prepareAllPipes();
		}
	}

	private prepareAllPipes() {
		this.createLoadPipe();
		this.createReindexPipe();
		this.createSavePipe();
		this.createDeletePipe();
		this.createRefreshPipe();
		this.createNewAndSavePipe();
	}

	private createNewAndSavePipe() {
		this.createNewAndSave = new Subject<ICreateEntitiesFromMainMembers>();
		this.createNewAndSaveSubscription = this.createNewAndSave
			.pipe(
				switchMap((arg) => {
					return this.newEntityService.createEntitiesFromMainMembers(arg);
				}),
				switchMap((entities) => {
					return this.service.saveAll(entities);
				}),
				tap(() => {
					this.refresh.next();
				}),
			)
			.subscribe(() => {
				console.log('createNewAndSaveSubscription completed');
			});
	}

	private createRefreshPipe() {
		this.refresh = new Subject();
		this.refreshSubscription = this.refresh
			.pipe(
				switchMap(() => this.getTargetEntityInfo()),
				map((entityInfo: EntityInfo) => {
					this.info = entityInfo;
					return {
						info: entityInfo,
					};
				}),
				map((info: { info: EntityInfo }) => {
					var filters: SearchParameter[] = [];
					if (this.node.advancedData2.filters) {
						filters = resolveFilters(
							info.info,
							this.node.advancedData2.filters,
							this.container.holder,
							this.container.holder,
							this.container.holder,
						);
					}
					return {
						info: info.info,
						filters: filters,
					};
				}),
				switchMap((data: { info: EntityInfo; filters: SearchParameter[] }) => {
					return forkJoin({
						holders: this.service.getRelatedTree(
							this.node.advancedData2.entityId!,
							data.filters,
							this.node.advancedData2.orders,
						),
						info: this.service.getAggregateInfos(this.container.holder.entityId),
					});
				}),
				tap((data: { info: AggregateMember; holders: EntityHolder[] }) => {
					// console.log(data);
					this.HolderToTreeData(data.info, data.holders);
				}),
				tap((data) => {
					this.markNodeAsExpanded(this.treeData, this.selectionInfo.openNodesIds);
				}),
				map(() => {
					return this.selectSelectionInfoOrDefault();
				}),
				switchMap((h: EntityHolder | null) => {
					if (h) return this.service.load(h!.entityId, h!.instanceId);
					else {
						return of(null);
					}
				}),
				tap((data: EntityHolder | null) => {
					if (data) return this.setCurrentNodeCurrentSelection(data);
				}),
			)
			.subscribe(() => {
				// console.log('Collection and default node loaded');
				this.cdr.markForCheck();
			});
	}

	private createDeletePipe() {
		this.deletePipe = new Subject<EntityHolder>();
		this.deleteSubscription = this.deletePipe
			.pipe(
				switchMap((holder: EntityHolder) => {
					return this.service.delete(holder.entityId, holder.instanceId);
				}),
				tap((response) => {
					if (!response.success) {
						console.error(response);
						this.container.global.showError(response.message!);
					}
				}),
			)
			.subscribe(() => {
				// console.log('node delete');
				// this.cdr.markForCheck();
				this.refresh.next();
			});
	}

	private createReindexPipe() {
		this.reIndex = new Subject<EntityHolder[]>();
		this.reIndexSubscription = this.reIndex
			.pipe(
				switchMap((holders: EntityHolder[]) => {
					return forkJoin(holders.map((c) => this.service.load(c.entityId, c.instanceId)));
				}),
				tap((holders) => {
					var indexField = holders[0]!.getFirstByType(LogicalAttributeType.Index);
					console.log(indexField?.name);

					if (indexField) {
						let i = 0;
						for (const holder of holders) {
							holder?.setField(indexField?.name, i);
							i++;
						}
					}
				}),
				switchMap((holders) => {
					return this.service.saveAll(holders.map((f) => f!));
				}),
			)
			.subscribe((r: SaveResponse[]) => {
				console.log('node reindex', r);
			});
	}

	private createSavePipe() {
		this.save = new Subject<EntityHolder[]>();

		this.saveSubscription = this.save
			.pipe(
				switchMap((holders) => {
					return this.service.saveAll(holders);
				}),
				tap((response) => {
					response
						.filter((c) => !c.success)
						.forEach((r) => {
							console.error(r);
							this.container.global.showError(r.message!);
						});
				}),
			)
			.subscribe((r: SaveResponse[]) => {
				if (r.length > 0) {
					var node = this.findNodeRecursive(this.treeData, r[0].data!.instanceId);

					if (node) {
						node.text = r[0].data!.humanReadableName;
					}
				}
			});
	}

	private createLoadPipe() {
		this.loadHolterToInLineForm = new Subject<EntityHolder>();
		this.loadSubscription = this.loadHolterToInLineForm
			.pipe(
				switchMap((h: EntityHolder) => {
					this.container.global.showLoader();
					return this.service.load(h!.entityId, h!.instanceId);
				}),
				tap((data: EntityHolder | null) => {
					if (data) this.setCurrentNodeCurrentSelection(data);
				}),
				tap(() => {
					this.setSelectedUiElement(this.selectionInfo);
				}),
			)
			.subscribe(() => {
				// console.log('InLineForm Loaded');
				this.cdr.markForCheck();
			});
	}

	override setValue(value: FieldValue | null): void {
		this.refresh.next();
	}

	override addFromDialogSelector(
		entityId: string,
		mainMemberName: string | null,
		dialogPayload: KSearchDialogClosePayload,
		node: TreeNodeReference,
	): void {
		// console.log('vai qui?', entityId, this.node.advancedData2.entityId!);

		if (!(node instanceof TreeNodeReference)) {
			console.error(node);
		}

		this.createNewAndSave.next({
			entityId,
			mainMemberName,
			entities: dialogPayload.entities,
			node,
			attributeName: dialogPayload.attributeName,
			taskId: dialogPayload.parentTaskId,
			viewNode: this.node,
			containerHolder: this.container.holder,
			containerTaskId: this.container.task.id,
		});

		// this.createEntitiesFromMainMembers(
		//   entityId,
		//   mainMemberName,
		//   dialogPayload.entities,
		//   node,
		//   dialogPayload.attributeName,
		//   dialogPayload.parentTaskId
		// ).subscribe((newHolders: EntityHolder[]) => {
		//   this.save.next(newHolders);
		// });
	}

	onNodeClick(e: TreeNode): void {
		// this.loadHolterToInLineForm.next(e.holder);
	}

	updatedHolders: EntityHolder[] = [];

	override updateItem(holder: EntityHolder) {
		var holderIndex = this.updatedHolders.findIndex((d) => d.instanceId == holder.instanceId);

		if (holderIndex >= 0) {
			this.updatedHolders.splice(holderIndex, 1);
		}

		this.updatedHolders.push(holder);
	}

	override onNodeSelectionChange(arg: NodeSelectionChangeArg): void {
		this.updateSelectedNodeIds(arg);
		if (this.selected != null) {
			var toUpdateIndex = this.updatedHolders.findIndex((c) => c.instanceId == this.selected?.instanceId);

			if (toUpdateIndex >= 0) {
				this.save.next([this.updatedHolders[toUpdateIndex]]);
				this.updatedHolders.splice(toUpdateIndex, 1);
			}
		}

		this.loadHolterToInLineForm?.next(arg.node.holder!);
	}

	override onDelete(payload: KContextPayloadCallback): void {
		this.container.global.showConfirm('Sicuro di voler eliminare il selezionato e nodo sotto di esso?', () => {
			const e = payload.reference as TreeNodeContextReference;
			this.setCurrentNodeCurrentSelection(e.node.parent?.holder ?? null);
			this.setSelectedUiElement(this.selectionInfo);

			if (e.node.holder) this.deletePipe.next(e.node.holder);
		});
	}

	override onNodeMoved(nodes: TreeNode[]): void {
		var holders = nodes.map((c) => c.holder!);
		this.reIndex.next(holders);
	}

	override fieldValueIsChanging(newValue: any, refreshUiValue: boolean = false) {
		// console.log('fieldValueIsChanging', newValue);
	}

	override onFieldValueCollectionIsChanging(holders: EntityHolder[]) {
		// console.log('fieldValueIsChanging', holders);
	}

	override ngOnDestroy(): void {
		this.refreshSubscription?.unsubscribe();
		this.loadSubscription?.unsubscribe();
		this.deleteSubscription?.unsubscribe();
		this.saveSubscription?.unsubscribe();
		this.reIndexSubscription?.unsubscribe();
		this.createNewAndSaveSubscription?.unsubscribe();
		super.ngOnDestroy();
	}
}
