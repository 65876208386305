<div class="container" id="box">
	<div class="list">
		<dx-list
			#list
			[dataSource]="dataSource"
			[repaintChangesOnly]="true"
			noDataText="nessun filtro applicato"
			height="100%"
			[allowItemDeleting]="false"
			itemDeleteMode="toggle"
			[showSelectionControls]="false"
			(onSelectionChanged)="onSelectionChanged($event)"
			selectionMode="single"
			[showSelectionControls]="true"
			[selectedItemKeys]="initialSelection"
			menuMode="context"
			[menuItems]="menuItems"
			(onItemReordered)="onItemReordered($event)"
			displayExpr="label"
			keyExpr="attributeName"
		>
			<dxo-item-dragging
				[data]="dataSource"
				[allowReordering]="true"
				[onDragStart]="onDragStart"
				[onAdd]="onAdd"
				[onRemove]="onRemove"
			>
			</dxo-item-dragging>
		</dx-list>

		<dx-context-menu
			*ngIf="this.filters.length == 0"
			#menu
			[dataSource]="emptyItems"
			[width]="200"
			target="#box"
			(onItemClick)="addNewColumn()"
		>
		</dx-context-menu>
	</div>
	<div class="detail">
		<app-kfilter-editor *ngIf="selected" [filter]="selected"></app-kfilter-editor>
	</div>
</div>
