<dx-popup
	[width]="width"
	[height]="height"
	[(visible)]="visible"
	[showTitle]="title != null"
	[title]="title ?? ''"
	[dragEnabled]="false"
	[hideOnOutsideClick]="true"
>
	<div *dxTemplate="let data of 'content'">
		<dx-scroll-view width="100%" height="100%" *ngIf="task">
			<div class="container">
				<div class="toolbar">
					<app-ksearch-dialog-toolbar
						[dialog]="this"
						[multiselect]="multiselect"
						[delete]="popUpDelete"
						[task]="task"
					></app-ksearch-dialog-toolbar>
				</div>
				<div class="form">
					<app-ksearch-in-dialog
						[dialog]="this"
						[task]="task"
					></app-ksearch-in-dialog>
				</div>
			</div>
		</dx-scroll-view>
	</div>
</dx-popup>
