import { ApiResponse } from './api.response';

export class TemporaryUploadResponse extends ApiResponse {
	tokenId!: string;
}

export class TemporaryImageUrlResponse extends ApiResponse {
	constructor(
		private baseHref: string,
		fileName: string | null = null,
		url: string | null = null,
		size: number = 0,
		niceName: string | null = null,
	) {
		super();
		this.fileName = fileName ?? 'none';
		this.url = url ?? '/assets/images/empty.png';
		this.size = size;
		this.fullUrl = encodeURI(this.baseHref + this.url);
		this.niceName = niceName ?? this.fileName;
	}

	niceName = 'Empty';
	fileName = 'Empty';
	url: string;
	fullUrl: string;
	size = 0;
}
export class FavoriteApiResponse extends ApiResponse {
	isFavorite: boolean = false;
}
export class FileResponse extends ApiResponse {
	fileName!: string;
	length: number = 0;
	data!: string;
}

export class WidgetRange {
	from!: number;
	to!: number;
	name!: string;
	color!: string;
}
